define("admin/adapters/estimated-payout", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/missions/{missionId}/estimated_payouts{/id}',
    createRecordUrlTemplate: '{+namespace}/missions/{missionId}/estimated_payouts',
    urlSegments: {
      missionId(type, id, snapshot, query) {
        return snapshot.belongsTo('mission', {
          id: true
        });
      }
    }
  });
});