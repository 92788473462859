define("admin/components/available-timeslot-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    currPage: 1,
    loadingData: false,
    schedulingTimeslotSpan: Ember.computed(function () {
      return this.get('model.mission.durationInHours') || 1;
    }),
    dateToday: Ember.computed(function () {
      return moment.tz(moment(), this.get('model.mission.location.timezone_id'));
    }),
    timezone: Ember.computed(function () {
      return this.get('dateToday').format('z ZZ');
    }),
    precipitationProbabilityProvided: Ember.computed('model.currDays', 'model.currDays.length', function () {
      if (!this.get('model.currDays') || !this.get('model.currDays').length) return;
      return !!this.get('model.currDays').find(day => day?.timeslots[0]?.weather_forecast?.precipProbability);
    }),
    loadedObserver: Ember.observer('loadingData', function () {
      if (!this.get('loadingData')) {
        this.set('model.busy', false);
        this.addHoverListeners();
      }
    }),
    didInsertElement: function () {
      if (this.get('loadingData')) {
        this.set('model.busy', true);
      } else {
        return this.addHoverListeners();
      }
    },
    addHoverListeners: function () {
      return Ember.run.schedule('afterRender', this, function () {
        this.$().find('.capacity-details').on('mouseenter mouseover', function (e) {
          return $(e.currentTarget).find('.popup').removeClass('hidden');
        });
        this.$().find('.capacity-details').on('mouseout', function (e) {
          return $(e.currentTarget).find('.popup').addClass('hidden');
        });
      });
    },
    updatePagedDays: function (newPage) {
      this.set('currPage', newPage);
      var dayRanges = [[0, 3], [3, 7]];
      var dayRange = dayRanges[newPage - 1];
      this.set('model.currDays', this.get('model.upcomingDays').slice(dayRange[0], dayRange[1]));
      this.addHoverListeners();
    },
    actions: {
      prevPage: function (currPage) {
        if (currPage > 1) {
          var newPage = currPage - 1;
          this.updatePagedDays(newPage);
        }
      },
      nextPage: function (currPage) {
        if (currPage < 2) {
          var newPage = currPage + 1;
          this.updatePagedDays(newPage);
        }
      },
      selectSlots: function (timeslot_data, slotIndex, date, numberOfSlots) {
        Em.$('.capacity').removeClass('selected');
        if (slotIndex + numberOfSlots > 12) {
          return;
        }
        for (let i = 0; i < numberOfSlots; i++) {
          Em.$('.capacity.' + date + '-' + (slotIndex + i)).addClass('selected');
        }
        this.selectAction(timeslot_data[slotIndex]);
      }
    }
  });
});