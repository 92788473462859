define("admin/components/missions/asset-processes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssetProcessesComponent;
  AssetProcessesComponent = Ember.Component.extend({
    actions: {
      reprocess: function (shot) {
        return this.reprocessShot(shot);
      }
    }
  });
  var _default = _exports.default = AssetProcessesComponent;
});