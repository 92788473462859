define("admin/controllers/dropships/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    datepickerOptions: {
      minDate: (0, _moment.default)().startOf('day')
    },
    groupedDropships: Ember.computed('model.dropships.[]', function () {
      const items = this.get('model.dropships');
      items.forEach(function (dropship) {
        if (dropship.get('missions.length')) {
          dropship.set('requireDate', false);
        }
      });
      const kinds = {
        drones: 'Drone',
        cameras: 'DroneCamera',
        adapters: 'Adapter',
        other_equipments: 'PilotEquipment'
      };
      let result = [];
      for (let kind in kinds) {
        const filteredItems = items.filterBy('inventory_item.inventory_type', kinds[kind]);
        if (filteredItems.length !== 0) {
          result.push({
            kind: kind,
            items: filteredItems
          });
        }
      }
      return result;
    }),
    pilotMissions: Ember.computed('model.pilotMissions.[]', function () {
      return this.get('model.pilotMissions');
    }),
    actions: {
      setRequireDate: function (dropship, value) {
        dropship.set('requireDate', value);
      },
      sureWantToDisassociate: function (dropship) {
        if (dropship.get('missions.length') > 0) {
          dropship.set('requireDate', false);
          this.set('disassociatingDropship', dropship);
          this.set('showDisassociateModal', true);
        }
      },
      closeShowDisassociateModal: function () {
        this.set('showDisassociateModal', false);
        return false;
      },
      confirmDisassociate: function () {
        this.get('disassociatingDropship').set('requireDate', true);
        this.send('closeShowDisassociateModal');
      },
      submit: function () {
        const promises = this.get('model.dropships').map(dropship => {
          if (dropship.get('requireDate')) {
            dropship.set('missions', []);
          } else {
            dropship.set('missions', dropship.get('missionsArray'));
          }
          if (Ember.isPresent(dropship.get('formatedReturnDate'))) {
            dropship.set('return_date', new Date(dropship.get('formatedReturnDate')));
          }
          return dropship.save();
        });
        Ember.RSVP.all(promises).then(() => {
          this.transitionToRoute('inventories-lists.dropships-list');
        });
      }
    }
  });
});