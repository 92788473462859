define("admin/components/badge-dispatch-requirement-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    equipments: [],
    devices: [],
    drones: [],
    numLocations: 0,
    model: null,
    show: false,
    droneSelectionCount: Ember.computed('model.required_drones.length', 'model.required_drone_cameras.length', function () {
      return this.get('model.required_drones.length') + this.get('model.required_drone_cameras.length');
    }),
    droneLength: Ember.computed('drones.length', function () {
      return this.get('drones.length');
    }),
    dronesHalf: Ember.computed('drones.length', function () {
      return Math.floor(this.get('droneLength') / 2) - 1;
    }),
    dronesOne: Ember.computed('drones.length', function () {
      return this.get('drones').toArray().slice(0, +this.get('dronesHalf') + 1 || 9e9);
    }),
    dronesTwo: Ember.computed('drones.length', function () {
      return this.get('drones').toArray().slice(this.get('dronesHalf') + 1, +(this.get('droneLength') - 1) + 1 || 9e9);
    }),
    cameras: Ember.computed('drones.length', function () {
      var cameras = [];
      var seen = {};
      var availableCameraIds = [];
      this.get('drones').toArray().forEach(function (drone) {
        drone.get('optional_cameras').forEach(function (camera) {
          if (!seen[camera.get('id')]) {
            seen[camera.get('id')] = true;
            cameras.push(camera);
            availableCameraIds.push(camera.get('id'));
          }
        });
      });
      return cameras;
    }),
    phones: Ember.computed('devices.length', function () {
      return this.get('devices').toArray().filter(function (device) {
        return device.get('device_type') == 'phone';
      });
    }),
    tablets: Ember.computed('devices.length', function () {
      return this.get('devices').toArray().filter(function (device) {
        return device.get('device_type') == 'tablet';
      });
    }),
    runValidation: function () {
      return this.get('validate')();
    },
    actions: {
      addBadgeRequiredLocation: function () {
        var dz = this.get('store').createRecord('dispatch-zone');
        this.get('model.badge_dispatch_zones').addObject(dz);
        dz.set('distance', 20);
      },
      removeBadgeRequiredLocation: function (dispatch_zone) {
        this.get('model.badge_dispatch_zones').removeObject(dispatch_zone);
      },
      close: function () {
        if (this.runValidation()) {
          this.close();
        } else {
          var confirmDisable = confirm("You need to have at least one dispatch zone to enable auto-dispatch. Do you want to disable auto-dispatch?");
          if (confirmDisable) {
            this.close(true);
          }
        }
      },
      toggleDroneList(event) {
        $($('#drone-header-toggle').data('target')).toggle();
        this.set('droneCameraOpen', !this.get('droneCameraOpen'));
      },
      toggleDeviceList(event) {
        $($('#drone-header-toggle').data('target')).toggle();
        this.set('devicesOpen', !this.get('devicesOpen'));
      },
      toggleEquipmentList(event) {
        $($('#other-equipment-header-toggle').data('target')).toggle();
        this.set('equipmentOpen', !this.get('equipmentOpen'));
      }
    }
  });
});