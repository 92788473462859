define("admin/routes/organizations/subscription", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    store: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        currencies: this.store.findAll('currency'),
        countries: this.store.findAll('country'),
        states: this.store.findAll('state'),
        subscription: this.store.queryRecord('subscription', {
          organization_id: params.organization_id,
          id: params.subscription_id
        })
      });
    }
  });
});