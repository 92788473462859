define("admin/models/catalog-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    supported_geometry: _emberData.default.attr('string'),
    delivery_sla: _emberData.default.attr('number'),
    delivery_sla_starts_on: _emberData.default.attr('string'),
    has_pano_shot_type: _emberData.default.attr('boolean'),
    deliverable_work_types: _emberData.default.hasMany(),
    shot_list: _emberData.default.belongsTo('shot-list'),
    panoShotType: Ember.computed('shot_list', function () {
      return this.get('shot_list.shot_types.[]').filter(item => {
        if (item.get('template') === 'pano_tiles') {
          return item;
        }
      }).firstObject;
    })
  });
});