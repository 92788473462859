define("admin/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ge55ChoV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[35,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"reachedInfinity\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"loadingText\",\"loadedText\",\"infinityModelContent\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/infinity-loader.hbs"
    }
  });
});