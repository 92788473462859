define("admin/models/drone", ["exports", "ember-data", "ember-model-validator/mixins/model-validator", "admin/models/inventoriable"], function (_exports, _emberData, _modelValidator, _inventoriable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Drone;
  Drone = _inventoriable.default.extend(_modelValidator.default, {
    name: _emberData.default.attr('string'),
    drone_type: _emberData.default.attr('string'),
    stock_cameras: _emberData.default.hasMany('drone-camera', {
      async: false
    }),
    optional_cameras: _emberData.default.hasMany('drone-camera', {
      async: false
    }),
    drone_manufacturer: _emberData.default.belongsTo('drone-manufacturer', {
      async: true
    }),
    validations: {
      name: {
        presence: true
      },
      drone_type: {
        presence: true
      }
    },
    full_name: Ember.computed('name', 'drone_manufacturer', function () {
      return this.get('drone_manufacturer.name') + ' ' + this.get('name');
    })
  });
  var _default = _exports.default = Drone;
});