define("admin/helpers/format-float", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatFloat = formatFloat;
  // Format the number to the given number of decimal places
  function formatFloat([number, precision]) {
    if (number || number == 0) {
      const float = Number.parseFloat(number);
      return float.toFixed(precision);
    }
  }
  var _default = _exports.default = Ember.Helper.helper(formatFloat);
});