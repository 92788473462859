define("admin/data/verticals_groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Synced with attributes hash at MissionsSearch at Sake
  var _default = _exports.default = [{
    label: 'Any Vertical',
    value: null
  }, {
    label: 'Properties',
    value: 'properties'
  }, {
    label: 'Construction',
    value: 'construction'
  }, {
    label: 'Telecom',
    value: 'telecom'
  }, {
    label: 'Creative',
    value: 'creative'
  }, {
    label: 'Aggregates',
    value: 'aggregates'
  }, {
    label: 'Solar',
    value: 'solar'
  }, {
    label: 'Wind',
    value: 'wind'
  }, {
    label: 'T&D',
    value: 'tnd'
  }, {
    label: 'Other',
    value: 'other'
  }];
});