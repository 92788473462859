define("admin/data/mission_type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    "type": "client_mission",
    "name": "Client Mission",
    "color": "client-mission-icon-color",
    "fontIcon": "icon-ClientPin",
    "png": "client_mission_assigned.png"
  }, {
    "type": "client_mission_available",
    "name": "Available Mission",
    "color": "client-mission-available-icon-color",
    "fontIcon": "icon-ClientPin",
    "png": "client_mission_available.png"
  }, {
    "type": "pano_commercial_available",
    "name": "Commercial Pano",
    "color": "pano-icon-color",
    "fontIcon": "icon-CommercialPin",
    "png": "pano_commercial_available.png"
  }, {
    "type": "pano_commercial_active",
    "name": "Active Pano",
    "color": "pano-icon-color",
    "fontIcon": "icon-DroneBase_Icons_Commercial_Star-Pin",
    "png": "pano_commercial_active.png"
  }, {
    "type": "pano_commercial_completed",
    "name": "Completed Pano",
    "color": "pano-icon-color",
    "fontIcon": "icon-DroneBase_Icons_Commercial_complete_pin",
    "png": "pano_commercial_completed.png"
  }, {
    "type": "pano_residential_available",
    "name": "Residential Pano",
    "color": "pano-icon-color",
    "fontIcon": "icon-ResidentialPin",
    "png": "pano_residential_available.png"
  }, {
    "type": "pano_residential_active",
    "name": "Active Pano",
    "color": "pano-icon-color",
    "fontIcon": " icon-DroneBase_Icons_Residential_Star_pin",
    "png": "pano_residential_active.png"
  }, {
    "type": "pano_residential_completed",
    "name": "Completed Pano",
    "color": "pano-icon-color",
    "fontIcon": "icon-DroneBase_Icons_Residential_complete_pin",
    "png": "pano_residential_completed.png"
  }, {
    "type": "creative_mission",
    "name": "Creative Mission",
    "color": "creative-icon-color",
    "fontIcon": "icon-DroneBase_Icons_Residential_complete_pin",
    "png": "creative_mission.png"
  }];
});