define("admin/models/notified-pilot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotifiedPilot;
  NotifiedPilot = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    created_on: _emberData.default.attr('string'),
    estimated_payout: _emberData.default.attr('string'),
    badge_name_pending: _emberData.default.attr('string'),
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),
    pilot: _emberData.default.belongsTo('pilot', {
      async: true
    }),
    mission: _emberData.default.belongsTo('mission', {
      async: true
    })
  });
  var _default = _exports.default = NotifiedPilot;
});