define("admin/components/mission-status-update-button", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionStatusUpdateButtonComponent;
  MissionStatusUpdateButtonComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    type: 'submit',
    classNameBindings: ['isHidden:hide'],
    isHidden: false,
    isSendingRequest: false,
    confirmAndAutoDispatchButton: Em.computed('model.pilot_invitations_dispatch', 'model.package.auto_dispatch_enabled', 'model.isReshoot', function () {
      return this.get('model.package.auto_dispatch_enabled') && this.get('model.pilot_invitations_dispatch') === null && !this.get('model.isReshoot');
    }),
    statusDisabledTooltip: Em.computed('model.ready_for_auto_dispatch', 'model.status', function () {
      if (!this.get('model.ready_for_auto_dispatch') && this.get('model.status') === 'created' && this.get('confirmAndAutoDispatchButton')) {
        return 'You must set an account rep and estimated pilot payout before proceeding. Please check below';
      }
    }),
    goToShedulingCall: Ember.computed('model.status', 'model.scheduling_requirement', 'model.scheduling_contact', 'model.recalculateStatus', function () {
      if (this.get('model.status') === 'created') {
        if (this.get('model.scheduling_requirement') === 'call_to_schedule') {
          if (this.get('model.scheduling_contact.name') && this.get('model.scheduling_contact.phone')) {
            return !this.get('model').changedAttributes().scheduling_requirement;
          }
        }
      }
    }),
    statusObserver: Em.observer('model.status', function () {
      switch (this.get('model.status')) {
        case 'created':
        case 'scheduling_call':
        case 'canceled':
        case 'pilot_accepted':
        case 'flight_complete':
        case 'assets_uploaded':
        case 'assets_classified':
        case 'in_production':
        case 'awaiting_payment':
        case 'assets_classified':
        case 'in_production':
        case 'awaiting_payment':
        case 'invoice_needed':
        case 'invoiced':
          this.set('isHidden', false);
          return this.set('name', 'next step');
        default:
          return this.set('isHidden', true);
      }
    }),
    nextStatus: Ember.computed('model.status', 'model.client.invoiceable', 'goToShedulingCall', 'model.recalculateStatus', 'model.deliverable_work_types.length', function () {
      switch (this.get('model.status')) {
        case 'created':
          if (this.get('model.client_upload')) {
            return 'Upload Required';
          } else if (this.get('goToShedulingCall')) {
            return 'Call to Schedule';
          } else if (this.get('confirmAndAutoDispatchButton')) {
            return 'Confirm and auto-dispatch';
          } else {
            return 'Confirmed';
          }
          break;
        case 'scheduling_call':
          if (this.get('confirmAndAutoDispatchButton')) {
            return 'Confirm and auto-dispatch';
          } else {
            return 'Confirmed';
          }
          break;
        case 'pilot_accepted':
          return 'Flight Complete';
        case 'flight_complete':
        case 'upload_required':
          return 'Assets Uploaded';
        case 'assets_uploaded':
          return 'Processing Shots';
        case 'assets_classified':
          if (this.get('model.skipInProduction')) {
            if (this.get('model.client.invoiceable')) {
              return 'Invoice Needed';
            } else {
              return 'Awaiting Payment';
            }
          } else {
            return 'In Production';
          }
          break;
        case 'in_production':
          if (this.get('model.client.invoiceable')) {
            return 'Invoice Needed';
          } else {
            return 'Awaiting Payment';
          }
          break;
        case 'invoice_needed':
          return 'Invoiced';
        case 'invoiced':
          return 'Complete';
        case 'awaiting_payment':
          return 'Complete';
        case 'canceled':
          return 'confirmed';
      }
    }),
    click: function (e) {
      var _model, _this, confirm, headers, request;
      e.preventDefault();
      e.stopPropagation();
      if (this.get('model').get('isOnHold') || this.get('statusDisabledTooltip')) {
        return;
      }
      confirm = this.get('isSendingRequest') ? false : window.confirm("Are you sure you want to change the status?");
      if (confirm) {
        _this = this;
        _model = this.get('model');
        headers = this.get('sessionAccount.headers');
        _this.set('isSendingRequest', true);
        request = Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + _model.id + "/status",
          type: 'PATCH',
          dataType: 'json',
          headers: headers
        }).then(function (response) {
          return _model.reload().then(function (response) {
            return _this.set('isSendingRequest', false);
          }, function (response) {
            var errors;
            errors = "";
            response.responseJSON.errors.forEach(function (_this) {
              return function (e) {
                return errors = errors + e.detail + "\n";
              };
            }(this));
            alert(errors);
            return _this.set('isSendingRequest', false);
          });
        }, function (response) {
          var errors;
          errors = "";
          response.responseJSON.errors.forEach(function (_this) {
            return function (e) {
              return errors = errors + e.detail + "\n";
            };
          }(this));
          alert(errors);
          return _this.set('isSendingRequest', false);
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      }
    }
  });
  var _default = _exports.default = MissionStatusUpdateButtonComponent;
});