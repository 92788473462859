define("admin/controllers/clients/client", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    failureMsg: "",
    isFailed: false,
    isProcessing: false,
    sessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    showImportMissionModal: false,
    actions: {
      openEditClientModal: function () {
        this.set("isFailed", false);
        this.set("isProcessing", false);
        this.set("copyMissionNumber", "");
        this.set("failureMsg", "");
        const client = this.get('model');
        this.set('model.client', client);
        this.set('model.verticalsToSelect', this.get('verticalsToSelect'));
        this.set('model.actions', this.get('routeActions'));
        this.send('openModal', 'components.clients.modal-client', this.get('model'));
      },
      close: function () {
        this.set("isFailed", false);
        this.set("isProcessing", false);
        this.set("failureMsg", "");
        this.set("missionNumber", "");
        this.set('showImportMissionModal', false);
      },
      waitForMission: function () {
        Ember.run.later(() => {
          return Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/missions/copy_request/${this.get("copyMissionNumber")}`,
            headers: this.get('sessionAccount.headers'),
            type: 'GET',
            dataType: 'json'
          }).done(() => {
            this.send('close');
            this.get("router").transitionTo('missions.edit', this.get("copyMissionNumber"));
          }).fail(error => {
            if (error.status == 404) {
              if (this.get('showImportMissionModal') == true) {
                this.send('waitForMission');
              }
              // XXX: Why is jQuery sending status 200 to the fail block?
            } else if (error.status == 200) {
              this.send('close');
              this.get("router").transitionTo('missions.edit', this.get("copyMissionNumber"));
            } else {
              this.set("failureMsg", error.body || error.statusText);
              this.set("isFailed", true);
              this.set("isProcessing", false);
            }
          });
        }, 5000);
      },
      importMission: function (missionNumber) {
        this.set('isProcessing', true);
        this.set("failureMsg", "");
        this.set("isFailed", false);
        const data = {
          mission_number: missionNumber,
          client_number: this.get('model.id')
        };
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/copy_request`,
          headers: this.get('sessionAccount.headers'),
          type: 'POST',
          data,
          dataType: 'json'
        }).done(response => {
          this.set("copyMissionNumber", response.copy.copy_mission_number);
          this.send('waitForMission');
        }).fail(error => {
          this.set("failureMsg", error.body || error.statusText);
          this.set("isFailed", true);
          this.set("isProcessing", false);
        });
      }
    }
  });
});