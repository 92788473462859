define("admin/models/site", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    number_of_missions: _emberData.default.attr('number'),
    portfolio: _emberData.default.attr('string'),
    formatted_address: _emberData.default.attr('string'),
    last_mission_created: _emberData.default.attr('string'),
    last_mission_scheduled: _emberData.default.attr('string'),
    last_mission_price: _emberData.default.attr('number'),
    last_mission_package_name: _emberData.default.attr('string'),
    last_mission_id: _emberData.default.attr('string'),
    last_mission_currency_id: _emberData.default.attr('string')
  });
});