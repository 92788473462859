define("admin/routes/dropships/new", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    model: function (params) {
      params['perPage'] = 5;
      params['startingPage'] = 1;
      return Ember.RSVP.hash({
        inventoryItems: this.loadIfNotLoaded('inventory-item'),
        droneManufacturers: this.loadIfNotLoaded('drone-manufacturer'),
        matchingPilots: this.infinityModel('pilot', params),
        dropships: this.store.findAll('dropship'),
        groupedItemsList: [Ember.Object.create({
          kind: 'adapters',
          items: [],
          selectedName: 'adapter(s)',
          collapsed: true
        }), Ember.Object.create({
          kind: 'cameras',
          items: [],
          selectedName: 'camera(s)',
          collapsed: true
        }), Ember.Object.create({
          kind: 'drones',
          items: [],
          selectedName: 'drone(s)',
          collapsed: true
        }), Ember.Object.create({
          kind: 'other-equipment',
          items: [],
          selectedName: 'other equipment(s)',
          collapsed: true
        })]
      });
    },
    queryParams: {
      q: {
        refreshModel: true
      }
    },
    resetController(controller, isExiting, transition) {
      if (isExiting) {
        let dropships;
        dropships = controller.get('dropships');
        controller.set('showPilotInput', true);
        controller.set('selectedPilot', null);
        controller.set('queryParams', ['q']);
        controller.set('q', null);
        controller.set('dropships', []);
        controller.set('requireDate', true);
        controller.set('selectedMissions', []);
        controller.set('useDefaultTrackingData', true);
        controller.set('showInventoriesModal', false);
        controller.set('scheduledStartDate', null);
        controller.set('pilotAvailableMissions', []);
        dropships.forEach(function (dropship) {
          if (dropship.get('isNew')) {
            dropship.deleteRecord();
          }
        });
      }
    },
    loadIfNotLoaded(type) {
      var typeIsLoaded = this.get('store').peekAll(type).length > 0;
      var typeItems;
      if (typeIsLoaded) {
        typeItems = this.get('store').peekAll(type);
      } else {
        typeItems = this.get('store').findAll(type);
      }
      return typeItems;
    }
  });
});