define("admin/components/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SubmitButtonComponent;
  SubmitButtonComponent = Ember.Component.extend({
    tagName: 'button',
    type: 'submit',
    classNameBindings: ['enabled::disabled'],
    enabled: true
  });
  var _default = _exports.default = SubmitButtonComponent;
});