define("admin/components/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['slider-container'],
    min: 0,
    max: 100,
    step: 1,
    value: 10,
    valuePercent: Ember.computed('value', function () {
      return parseFloat(((this.value - this.min) / (this.max - this.min) * 100).toFixed(2));
    }),
    unit: '',
    seed: null,
    inputId: null,
    minValueId: null,
    valueId: null,
    maxValueId: null,
    sliderElement: null,
    minValueElement: null,
    valueElement: null,
    maxValueElement: null,
    thumbWidth: 14,
    // should be equal to $thumb-width in slider.scss (in px)

    willRender() {
      this._super(...arguments);
      this.set('seed', Ember.guidFor(this));
      this.set('inputId', `db-slider-${this.seed}`);
      this.set('minValueId', `min-value-${this.seed}`);
      this.set('valueId', `value-${this.seed}`);
      this.set('maxValueId', `max-value-${this.seed}`);
      if (!this.value) {
        this.set('value', this.min);
      }
    },
    didRender() {
      this._super(...arguments);
      this.sliderElement = Ember.$(`#${this.inputId}`)[0];
      this.minValueElement = Ember.$(`#${this.minValueId}`)[0];
      this.valueElement = Ember.$(`#${this.valueId}`)[0];
      this.maxValueElement = Ember.$(`#${this.maxValueId}`)[0];
      this.updateStyles();
      this.sliderElement.oninput = event => {
        this.set('value', parseFloat(event.target.value));
        this.updateStyles();
      };
    },
    updateStyles() {
      // to fill left slider part
      this.element.style.setProperty('--value-thumb-percent', `${this.valuePercent}%`);

      // to arrange current value text
      const textOffset = parseFloat((this.valueElement.clientWidth / 2 / this.element.clientWidth * 100).toFixed(2));
      const thumbOffset = parseFloat((this.thumbWidth / this.element.clientWidth * 100).toFixed(2)) / 100 * this.valuePercent;
      const thumbCorrection = this.thumbWidth / 2;
      this.element.style.setProperty('--value-text-left', `calc(${this.valuePercent - textOffset - thumbOffset}% + ${thumbCorrection}px)`);

      // to avoid intersection with man and max text
      const space = 5; //px
      const minValueRight = this.minValueElement.offsetLeft + this.minValueElement.clientWidth + space;
      if (minValueRight > this.valueElement.offsetLeft) {
        this.element.style.setProperty('--value-text-left', `${minValueRight}px`);
      }
      const valueRight = this.valueElement.offsetLeft + this.valueElement.clientWidth + space;
      if (valueRight > this.maxValueElement.offsetLeft) {
        this.element.style.setProperty('--value-text-left', `${this.maxValueElement.offsetLeft - this.valueElement.clientWidth - space}px`);
      }

      // on first render we don't have width for some reason...
      if (!this.valueElement.clientWidth) {
        this.element.style.setProperty('--value-text-left', `calc(${this.valuePercent}% + ${thumbCorrection}px)`);
      }
    }
  });
});