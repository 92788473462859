define("admin/helpers/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.humanize = humanize;
  // Format the string with mutate underscores to whitespaces and capitalize words
  function humanize([string]) {
    return string.split('_').map(word => word.capitalize()).join(' ');
  }
  var _default = _exports.default = Ember.Helper.helper(humanize);
});