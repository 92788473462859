define("admin/helpers/stored-payout-rates-tooltip-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function storedPayoutRatesTooltipText([pilot]) {
    const currencySymbol = pilot.currency?.symbol || '$';
    const currencyCode = pilot.currency?.code || 'USD';
    const currencyDisplay = currencySymbol + currencyCode;
    const pilotRates = pilot.user?.get('pilot_rates');
    const perAssetOwnRate = pilotRates?.findBy('rate_type', 'per_asset_own')?.payout_amount / 100 || 0;
    const perAssetLeasingRate = pilotRates?.findBy('rate_type', 'per_asset_leasing')?.payout_amount / 100 || 0;
    const perDayRate = pilotRates?.findBy('rate_type', 'per_day')?.payout_amount / 100 || 0;
    const storedPayoutRatesLink = `/pilots/${pilot.user.id}/edit?activeTab=rates`;
    const selectedDisplay = [];
    [perAssetOwnRate, perAssetLeasingRate, perDayRate].forEach(rate => {
      const isSelected = rate && rate === pilot.estimated_labor_payout_amount / 100;
      selectedDisplay.push(isSelected ? 'Selected' : '');
    });
    return Ember.String.htmlSafe(`
    <div class="stored-pilot-rates-tooltip">
      <div class="stored-pilot-rates-tooltip__title">Stored Payout Rates</div>
      <div class="stored-pilot-rates-tooltip__currency">${currencyDisplay}</div>
      <div class="rates-table">
        <div class="rates-table__row">
          <div>Per Asset <span class="light-text">/ Own Equipment</span></div>
          <div class="light-text">${currencySymbol} ${perAssetOwnRate}</div>
          <img src="/assets/images/tick.png" class="${selectedDisplay[0] ? '' : 'hidden'}" />
          <div class="selected-display">${selectedDisplay[0]}</div>
          </div>
        <div class="rates-table__row">
          <div>Per Asset <span class="light-text">/ Leasing Equipment</span></div>
          <div class="light-text">${currencySymbol} ${perAssetLeasingRate}</div>
          <img src="/assets/images/tick.png" class="${selectedDisplay[1] ? '' : 'hidden'}" />
          <div class="selected-display">${selectedDisplay[1]}</div>
          </div>
        <div class="rates-table__row">
          <div>Per Day</div>
          <div class="light-text">${currencySymbol} ${perDayRate}</div>
          <img src="/assets/images/tick.png" class="${selectedDisplay[2] ? '' : 'hidden'}" />
          <div class="selected-display">${selectedDisplay[2]}</div>
        </div>
      </div>
      <a href=${storedPayoutRatesLink} target="_blank">See stored payout rates</a>
    </div>
  `);
  }
  var _default = _exports.default = Ember.Helper.helper(storedPayoutRatesTooltipText);
});