define("admin/helpers/timeframe-format", ["exports", "admin/helpers/timeframe-options"], function (_exports, _timeframeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _timeframeOptions.default.extend({
    compute([start_hour, end_hour, duration, isStartOption, target, format = 'h A']) {
      try {
        return this._super([start_hour, end_hour, duration, isStartOption, format]).find(function (elem) {
          return elem.value === target;
        }).name;
      } catch (e) {
        return "";
      }
    }
  });
});