define("admin/components/site-inspections/modals/bulk-action/add-to-existing-capture-group-modal", ["exports", "admin/data/capture-group/search-field-options"], function (_exports, _searchFieldOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    infinity: Ember.inject.service(),
    store: Ember.inject.service(),
    searchField: 'name',
    searchValue: null,
    searchFieldOptions: _searchFieldOptions.searchFieldOptions,
    selectedCaptureGroup: null,
    init() {
      this._super(...arguments);
      this.searchCaptureGroups();
    },
    async searchCaptureGroups() {
      const captureGroups = await this.store.query('capture-group', this.get('captureGroupsSearchQuery'));
      this.infinity.replace(this.get('captureGroups'), captureGroups);
    },
    assignIsDisabled: Ember.computed('selectedCaptureGroup', function () {
      return !this.get('selectedCaptureGroup.id');
    }),
    captureGroupsSearchQuery: Ember.computed('searchField', 'searchValue', function () {
      return {
        attr: this.get('searchField'),
        q: this.get('searchValue'),
        per_page: 25
      };
    }),
    actions: {
      changeSearchField(value) {
        this.set('searchField', value);
        Ember.run.debounce(this, this.searchCaptureGroups, 500);
      },
      changeSearchValue(event) {
        this.set('searchValue', event.target.value);
        Ember.run.debounce(this, this.searchCaptureGroups, 500);
      },
      changeSelectedCaptureGroup(captureGroup) {
        if (this.get('selectedCaptureGroup.id') === captureGroup.id) {
          return this.set('selectedCaptureGroup', this.get('mission.site_inspection.capture_group'));
        }
        return this.set('selectedCaptureGroup', captureGroup);
      },
      addMissionsToExistingCaptureGroup() {
        this.get('onSubmit')(this.get('selectedCaptureGroup'));
      },
      switchToNewCaptureGroupModal() {
        this.onClose();
        this.showBulkAddToNewCaptureGroupModal();
      },
      closeModal() {
        this.set('searchValue', null);
        this.onClose();
      }
    }
  });
});