define("admin/controllers/inventories-lists/dropships-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    dropshipsByPilots: Ember.computed('model.dropships', function () {
      var result = [];
      this.get('model.dropships').forEach(function (item) {
        var pilot = result.findBy('pilot', item.get('pilot'));
        if (!pilot) {
          result.pushObject(Ember.Object.create({
            pilot: item.get('pilot'),
            contents: []
          }));
        }
        result.findBy('pilot', item.get('pilot')).get('contents').pushObject(item);
      });
      result = this.sortResultsByPilot(result);
      result.forEach(function (pilotResults) {
        let contents = pilotResults.get('contents');
        return pilotResults.set('contents', contents.sortBy('inventory_item.dronebase_uid'));
      });
      return result;
    }),
    sortResultsByPilot(array) {
      return array.sortBy('pilot.fullName');
    }
  });
});