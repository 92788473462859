define("admin/utils/timezone-without-changing-time", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(utcTime, timezone) {
    return (0, _momentTimezone.default)((0, _momentTimezone.default)(utcTime).format('YYYY-MM-DDTHH:mm:ss') + _momentTimezone.default.tz(timezone).format('Z')).tz(timezone);
  }
});