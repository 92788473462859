define("admin/templates/components/reusable/info-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2lNfWmoU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"info-icon\"],[12],[2,\"i\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/info-icon.hbs"
    }
  });
});