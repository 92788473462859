define("admin/templates/clients/client/missions/modal-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "74B7cLYH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"action\",\"animatable\",\"fullScreen\",\"appendedClasses\"],[\"close\",true,\"true\",\"full-screen-modal client-package-modal\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"mode\",\"model\",\"savePackageAction\",\"hide-package-flight-app\",\"closeModalAction\"],[\"Client\",[35,1],[30,[36,0],[[32,0],\"savePackage\"],null],[35,1,[\"hidePackageFlightAppEditor\"]],[30,[36,0],[[32,0],\"close\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"clients/package-form\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/clients/client/missions/modal-package.hbs"
    }
  });
});