define("admin/components/missions/modals/bulk-action/add-to-capture-group-warning-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      submit() {
        this.onSubmit();
      },
      closeModal() {
        this.onClose();
      }
    }
  });
});