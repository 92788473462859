define("admin/mirage/factories/capture-group", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    created_at: _faker.default.date.recent(),
    currency: (0, _emberCliMirage.association)(),
    status: 'created',
    missions_count: 1,
    afterCreate(captureGroup, server) {
      captureGroup.update({
        currency: server.create('currency')
      });
    }
  });
});