define("admin/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "khPUn7hu",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,2]],[2,\"\\n\\n\"],[6,[37,4],[[35,3,[\"isBlankTemplate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container container-main\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row main-row\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,1],[[30,[36,0],[\"modal\"],null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"main-navigation\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/application.hbs"
    }
  });
});