define("admin/helpers/is-greater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let isGreaterHelper = Ember.Helper.helper(function ([leftSide, rightSide]) {
    return leftSide > rightSide;
  });
  var _default = _exports.default = isGreaterHelper;
});