define("admin/components/subscription-offers/offer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    editModalOpen: false,
    regions: Ember.computed('subscriptionOffer.states.[]', function () {
      let offerStates = this.get('subscriptionOffer.states');
      return `(${offerStates.get('length')}) ${offerStates.map(state => state.formattedIsoCode).join(', ')}`;
    }),
    actions: {
      toggleEditModal: function () {
        this.toggleProperty('editModalOpen');
        return null;
      },
      activate: function () {
        this.set('subscriptionOffer.active', true);
        this.get('subscriptionOffer').save();
      },
      deactivate: function () {
        this.set('subscriptionOffer.active', false);
        this.get('subscriptionOffer').save();
      }
    }
  });
});