define("admin/models/payout", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PAYMENT_PROCESSORS = void 0;
  const PAYMENT_PROCESSORS = _exports.PAYMENT_PROCESSORS = {
    stripe: 'Stripe',
    paypal: 'Paypal',
    other: 'Other'
  };
  var _default = _exports.default = _model.default.extend({
    created_on: (0, _model.attr)('string'),
    amount: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),
    payment_processor: (0, _model.attr)('string'),
    payment_id: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    payee_name: (0, _model.attr)('string'),
    payout_to: (0, _model.attr)('string'),
    saved_payoutable_type: (0, _model.attr)('string'),
    saved_payoutable_id: (0, _model.attr)('string'),
    payoutable_type: (0, _model.attr)('string'),
    payoutable_id: (0, _model.attr)('string'),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    payoutable: (0, _model.belongsTo)('payoutable', {
      async: false,
      polymorphic: true,
      inverse: 'payouts'
    }),
    pilot: (0, _model.belongsTo)('pilot', {
      async: false
    }),
    legal_entity: (0, _model.belongsTo)('legal_entity', {
      async: false
    }),
    payout_line_items: (0, _model.hasMany)('payout-line-item', {
      async: false
    }),
    payoutable_link: Ember.computed('payoutable_type', function () {
      if (this.get('payoutable_type') === 'Mission') {
        return 'missions.edit';
      } else {
        // This works for SiteInspection and CaptureGroup, mutate them to kebab-syntax and add s.show,
        // like capture-groups.show
        return this.get('payoutable_type').replace(/([a-z])([A-Z])/g, "$1-$2").concat('s.show').toLowerCase();
      }
    }),
    total_amount_for_labor: Ember.computed('payout_line_items', function () {
      return this.get('payout_line_items').filter(pli => pli.reason === 'labor').reduce((amount, pli) => amount + parseInt(pli.amount), 0);
    }),
    total_amount_for_standby_time: Ember.computed('payout_line_items', function () {
      return this.get('payout_line_items').filter(pli => pli.reason === 'standby_time').reduce((amount, pli) => amount + parseInt(pli.amount), 0);
    }),
    total_amount_for_mobilization: Ember.computed('payout_line_items', function () {
      return this.get('payout_line_items').filter(pli => pli.reason === 'mobilization').reduce((amount, pli) => amount + parseInt(pli.amount), 0);
    }),
    total_amount_for_tax: Ember.computed('payout_line_items', function () {
      return this.get('payout_line_items').filter(pli => pli.reason === 'tax').reduce((amount, pli) => amount + parseInt(pli.amount), 0);
    }),
    total_amount_for_other: Ember.computed('payout_line_items', function () {
      return this.get('payout_line_items').filter(pli => pli.reason === 'other').reduce((amount, pli) => amount + parseInt(pli.amount), 0);
    })
  });
});