define("admin/controllers/waivers/edit", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      save(waiver) {
        if (waiver.validate()) {
          waiver.save().then(() => {
            // save only changed extensions
            const promises = waiver.waiver_extensions.map(extension => {
              if (Object.keys(extension.changedAttributes()).length > 0) {
                return extension.save();
              }
            });
            Ember.RSVP.all(promises).then(() => {
              // nope
            }).catch(err => {
              let message = err.errors[0].detail;
              return alert("FAILED TO SAVE\n" + message);
            });
          }).catch(err => {
            let message = err.errors[0].detail;
            return alert("FAILED TO SAVE\n" + message);
          });
        }
      }
    }
  });
});