define("admin/serializers/serializeable-object", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;
      if (key === 'comments') {
        return;
      } else {
        this._super(...arguments);
      }
    }
  });
});