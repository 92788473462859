define("admin/components/pilot-dispatch-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PilotDispatchRowComponent = Ember.Component.extend({
    classNames: ['pilot-dispatch-row'],
    didReceiveAttrs() {
      const firstSetRate = ['per_asset_own', 'per_asset_leasing', 'per_day'].find(rateType => {
        const rate = this.get('pilot.pilot_rates').findBy('rate_type', rateType);
        return !!rate;
      });
      this.get('pilot').set('laborType', firstSetRate);
    },
    licenseList: Ember.computed('pilot.pilot_licenses.[]', function () {
      const licenses = this.get('pilot.pilot_licenses');
      return licenses.reduce(function (acc, val, i) {
        if (i === licenses.get('length') - 1) {
          return acc += val.get('license.name');
        } else {
          return acc += val.get('license.name') + ', ';
        }
      }, '');
    }),
    droneList: Ember.computed('pilot.drones.[]', function () {
      const drones = this.get('pilot.drones');
      return drones.reduce(function (acc, val, i) {
        if (i === drones.get('length') - 1) {
          return acc += val.get('drone.name');
        } else {
          return acc += val.get('drone.name') + ', ';
        }
      }, '');
    }),
    cameraList: Ember.computed('pilot.drones.[]', function () {
      const cameras = this.get('pilot.camerasArray');
      return cameras.reduce(function (acc, val, i) {
        if (i === cameras.length - 1) {
          return acc += val.get('name');
        } else {
          return acc += val.get('name') + ', ';
        }
      }, '');
    }),
    pilotRates: Ember.computed('pilot.pilot_rates.[]', function () {
      const currency = this.get('pilot.pilot_rates')?.lastObject?.currency;
      const currencyDisplay = currency ? currency.symbol + currency.code : '$USD';
      return ['per_asset_own', 'per_asset_leasing', 'per_day', 'per_standby_hr', 'mobilization'].map(rateType => {
        const rate = this.get('pilot.pilot_rates').findBy('rate_type', rateType);
        return rate ? {
          currencyDisplay,
          rate: rate.get('payout_amount') / 100
        } : {
          currencyDisplay,
          rate: '-'
        };
      });
    }),
    actions: {
      toggleIncludePilot() {
        event.preventDefault();
        if (!this.get('autoDispatch')) {
          this.set('invited', !this.get('invited'));
          return this.toggleIncludePilot(this.get('pilot'), this.get('invited'), event.shiftKey);
        }
      },
      setLaborType(laborType) {
        this.get('pilot').set('laborType', laborType);
      }
    }
  });
  var _default = _exports.default = PilotDispatchRowComponent;
});