define("admin/templates/components/site-inspections/payouts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LXBlY5j4",
    "block": "{\"symbols\":[\"payout\"],\"statements\":[[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Pilot\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Labor\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Standby hours\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Mobilization\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Tax\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Other\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Comment\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Payout date\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Legal entity\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Payout method\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Total payout\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,1,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"6\"],[12],[2,\"No payouts\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"payout\",\"tagName\"],[[32,1],\"\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"site-inspections/payout\",\"payouts\",\"-track-array\",\"each\",\"is-equal\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/site-inspections/payouts.hbs"
    }
  });
});