define("admin/templates/components/select-enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "acSaXzrn",
    "block": "{\"symbols\":[\"error\",\"item\"],\"statements\":[[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"disabled-hint\"],[12],[1,[34,6]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"select\"],[15,\"onchange\",[30,[36,9],[[32,0],[30,[36,8],[[35,0]],null]],[[\"value\"],[\"target.value\"]]]],[15,0,[34,10]],[15,\"disabled\",[34,7]],[12],[2,\"\\n\"],[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[14,2,\"\"],[15,\"disabled\",[30,[36,2],[[35,3],\"disabled\"],null]],[15,\"selected\",[30,[36,4],[[35,0]],null]],[12],[2,\"\\n      \"],[1,[34,5]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,11]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"option\"],[15,2,[32,2,[\"value\"]]],[15,\"selected\",[30,[36,1],[[32,2,[\"value\"]],[35,0]],null]],[15,\"disabled\",[30,[36,2],[[32,2,[\"disabled\"]],\"disabled\"],null]],[12],[2,\"\\n    \"],[1,[32,2,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,14]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selection\",\"is-equal\",\"if\",\"promptDisabled\",\"is-not\",\"prompt\",\"disabledPrompt\",\"disabled\",\"mut\",\"action\",\"selectClass\",\"content\",\"-track-array\",\"each\",\"modelErrors\"]}",
    "meta": {
      "moduleName": "admin/templates/components/select-enum.hbs"
    }
  });
});