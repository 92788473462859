define("admin/components/badge-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      refreshMindflashCourses() {
        var _this = this;
        this.store.query('mindflash_series', {
          'mindflashRefresh': true
        }).then(function (response) {
          return _this.set('mindflashSeries', response);
        });
      },
      changedName(val) {
        this.set('badge.name', val.currentTarget.value);
        Ember.$('input[name="badge-name"]').addClass('changed');
      },
      changedAdminName(val) {
        this.set('badge.internal_name', val.currentTarget.value);
        Ember.$('input[name="badge-internal_name"]').addClass('changed');
      }
    }
  });
});