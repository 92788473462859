define("admin/components/inventory/inventory-items-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    isRelationPresent: Ember.computed('relation', function () {
      return Ember.isPresent(this.get('relation.id'));
    }),
    isRelationExists: Ember.computed('relation', function () {
      return Ember.isBlank(this.get('relation')) || Ember.isPresent(this.get('relation.id'));
    }),
    relationName: Ember.computed('relation', function () {
      return this.get('relation.name');
    }),
    actions: {
      selectRelation(relation) {
        this.selectRelationAction(relation);
      },
      newRelation(name) {
        const newModelName = this.get('newModelName');
        const createdRelation = this.set('relation', this.get('store').createRecord(newModelName, {
          name: name
        }));
        this.get('itemsList').addObject(createdRelation);
        this.selectRelationAction(createdRelation);
      }
    }
  });
});