define("admin/routes/equipment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function (params) {
      return Ember.RSVP.hash({
        drones: this.store.query('drone', {}),
        devices: this.store.query('device', {}),
        pilotEquipment: this.store.query('pilot-equipment', {}),
        cameras: this.store.query('drone-camera', {}),
        droneManufacturers: this.store.query('drone-manufacturer', {})
      });
    }
  });
});