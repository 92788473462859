define("admin/components/missions/modals/bulk-action/status-update-modal", ["exports", "admin/data/mission_statuses", "admin/data/mission_statuses_transitions"], function (_exports, _mission_statuses, _mission_statuses_transitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    missionStatuses: _mission_statuses.default,
    missionAvailableStatusesTransitions: _mission_statuses_transitions.default,
    init() {
      this._super(...arguments);
      this.set('formObject', Ember.Object.create({}));
    },
    statusOptions: Ember.computed('status', function () {
      if (!this.get('selectedOnlyOneStatus')) return [];
      const transitions = this.get('missionAvailableStatusesTransitions').filter(t => t.from == this.get('status')).map(t => t.to);
      return this.get('missionStatuses').filter(status => transitions.includes(status.value));
    }),
    submitDisabled: Ember.computed('formObject.{status,cancellation_reason_id}', 'updating', function () {
      if (!this.get('formObject.status') || this.get('updating')) return true;
      if (this.get('formObject.status') == 'canceled') {
        return !this.get('formObject.cancellation_reason_id');
      } else {
        return false;
      }
    }),
    actions: {
      updateStatus() {
        this.onSubmit(this.get('formObject'));
      },
      closeModal() {
        this.set('formObject', null);
        this.onClose();
      }
    }
  });
});