define("admin/components/expandable-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExpandableTableRowComponent;
  ExpandableTableRowComponent = Ember.Component.extend({
    tagName: 'tbody',
    classNames: ['expandable-tbody'],
    click: function (e) {
      var $indicator, $togglable;
      $togglable = this.$().find('.expandable');
      $indicator = this.$().find('.toggle-indicator');
      if ($togglable.is(':visible')) {
        $togglable.hide();
        return $indicator.removeClass('fa-chevron-down').addClass('fa-chevron-right');
      } else {
        $togglable.show();
        return $indicator.removeClass('fa-chevron-right').addClass('fa-chevron-down');
      }
    },
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        return this.$().find('.expandable').on('click', function (e) {
          return e.stopPropagation();
        });
      });
    },
    willClearRender: function () {
      return this.$().find('.expandable').off('click');
    }
  });
  var _default = _exports.default = ExpandableTableRowComponent;
});