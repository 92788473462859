define("admin/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.JSONAPISerializer.extend({
    alwaysIncludeLinkageData: true,
    keyForAttribute(attribute) {
      return Ember.String.underscore(attribute);
    },
    typeKeyForModel(model) {
      return snakeCase(model.modelName);
    },
    keyForAttribute(key) {
      return snakeCase(key);
    },
    keyForRelationship(key) {
      return snakeCase(key);
    }
  });
  const snakeCase = string => {
    return string.replace(/\W+/g, " ").split(/ |\B(?=[A-Z])/).map(word => word.toLowerCase()).join('_');
  };
});