define("admin/templates/components/client-details-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FsgO/LLq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"client-details-view\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-row\"],[12],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"name\"],[12],[2,\"Client Contact\"],[13],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[\"/clients/\",[34,1,[\"id\"]]]]],[14,0,\"profile-link\"],[12],[2,\"View Profile\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"body-row\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[1,[35,1,[\"fullName\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[12],[1,[35,1,[\"email\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[12],[1,[35,1,[\"phone\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[12],[1,[35,1,[\"company_name\"]]],[13],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"label\",\"params\"],[\"Login to this mission as the customer\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,4],[[35,1,[\"invoiceable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"invoiceable\"],[12],[2,\"\\n          \"],[1,[30,[36,0],null,[[\"type\",\"checked\",\"disabled\",\"name\"],[\"checkbox\",\"true\",\"true\",\"Invoiceable\"]]]],[2,\"\\n          Invoiced client\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"input\",\"client\",\"clientLoginParams\",\"open-client-app\",\"if\",\"showDetails\"]}",
    "meta": {
      "moduleName": "admin/templates/components/client-details-view.hbs"
    }
  });
});