define("admin/models/adapter", ["exports", "ember-data", "admin/models/inventoriable"], function (_exports, _emberData, _inventoriable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _inventoriable.default.extend({
    drone_manufacturer: _emberData.default.belongsTo('drone-manufacturer', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    full_name: Ember.computed('drone_manufacturer', 'name', function () {
      return this.get('drone_manufacturer.name') + ' ' + this.get('name');
    })
  });
});