define("admin/templates/inventories-lists/inventory-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DYwyn57S",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[10,\"div\"],[14,0,\"inventory-list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inventory-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Inventory Items\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"kind\",\"items\",\"model\",\"addSavedItem\",\"displayName\",\"collapsed\",\"toggleCollapsedAction\"],[[32,1,[\"kind\"]],[32,1,[\"items\"]],[35,1],[30,[36,0],[[32,0],\"addSavedItem\"],null],[32,1,[\"displayName\"]],[32,1,[\"collapsed\"]],[30,[36,0],[[32,0],\"toggleCollapsed\",[32,1]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"inventory/inventory-items\",\"groupedItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/inventories-lists/inventory-list.hbs"
    }
  });
});