define("admin/data/shot-type/asset-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Image",
    value: "image"
  }, {
    label: "Video",
    value: "video"
  }, {
    label: "Any",
    value: null
  }];
});