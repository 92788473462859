define("admin/controllers/dropships/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    showPilotInput: true,
    queryParams: ['q'],
    q: null,
    dropships: [],
    requireDate: true,
    selectedMissions: [],
    useDefaultTrackingData: true,
    showInventoriesModal: false,
    isPilotSelected: true,
    datepickerOptions: {
      minDate: (0, _moment.default)().startOf('day')
    },
    showMatchingPilots: Ember.computed('model.matchingPilots', 'q', function () {
      return this.get('q') && this.get('model.matchingPilots.length') > 0;
    }),
    selectedItemsGroups: Ember.computed('dropships.length', function () {
      const items = this.get('dropships');
      const kinds = {
        'drones': 'Drone',
        'cameras': 'DroneCamera',
        'adapters': 'Adapter',
        'other-equipment': 'PilotEquipment'
      };
      let result = [];
      for (let kind in kinds) {
        const filteredItems = items.filterBy('inventory_item.inventory_type', kinds[kind]);
        if (filteredItems.length !== 0) {
          result.push({
            kind: kind,
            items: items.filterBy('inventory_item.inventory_type', kinds[kind])
          });
        }
      }
      return result;
    }),
    groupedItems: Ember.computed('model.inventoryItems.[]', function () {
      const inventoryItems = this.get('model.inventoryItems');
      const groupedItemsList = this.get('model.groupedItemsList');
      const itemTypes = [{
        kind: 'adapters',
        filterType: 'Adapter'
      }, {
        kind: 'cameras',
        filterType: 'DroneCamera'
      }, {
        kind: 'drones',
        filterType: 'Drone'
      }, {
        kind: 'other-equipment',
        filterType: 'PilotEquipment'
      }];
      for (let itemType of itemTypes) {
        let items = inventoryItems.filter(item => {
          return item.inventory_type === itemType.filterType && item.status !== 'archived';
        });
        groupedItemsList.findBy('kind', itemType.kind).set('items', items.sortBy('unit'));
      }
      return groupedItemsList;
    }),
    initializeCalendar: function () {
      Ember.$('.startDatePicker').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        minDate: (0, _moment.default)().startOf('day'),
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      });
    },
    pilotMissions: Ember.observer('selectedPilot', function () {
      var pilot = this.get('selectedPilot');
      var _this = this;
      if (pilot) {
        this.get('store').query('mission', {
          pilot_id: pilot.get('id'),
          status: 'pilot_accepted,flight_complete,assets_uploaded'
        }).then(function (missions) {
          return _this.set('pilotAvailableMissions', missions);
        });
      }
    }),
    actions: {
      toggleEditPilot: function (fillPilot) {
        if (fillPilot) {
          this.set('q', this.get('selectedPilot.fullName'));
        }
        this.set('showPilotInput', !this.get('showPilotInput'));
      },
      selectPilot: function (pilot) {
        this.set('isPilotSelected', true);
        this.set('selectedPilot', pilot);
        this.set('q', '');
        this.send('toggleEditPilot');
      },
      toggleInventoriesModal: function () {
        this.toggleProperty('showInventoriesModal');
      },
      unselectItem: function (item) {
        this.get('dropships').removeObject(item);
      },
      setRequireDate: function (value) {
        this.set('requireDate', value);
        if (value == true) {
          this.initializeCalendar();
        }
      },
      toggleCollapsed: function (group) {
        group.toggleProperty('collapsed');
      },
      save() {
        this.set('isPilotSelected', Ember.isPresent(this.get('selectedPilot')));
        let scheduledDate = null;
        if (this.get('scheduledStartDate')) {
          scheduledDate = new Date(this.get('scheduledStartDate'));
        }

        // Prepare dropships params.
        if (this.get('useDefaultTrackingData')) {
          const firstInList = this.get('selectedItemsGroups.firstObject.items.firstObject');
          const outTrackLink = firstInList.get('outbound_tracking_link');
          const inTrackLink = firstInList.get('inbound_tracking_link');
          const outCost = firstInList.get('outbound_shipping_cost');
          const inCost = firstInList.get('inbound_shipping_cost');
          const notes = firstInList.get('notes');
          this.get('dropships').forEach(dropship => {
            dropship.set('inbound_tracking_link', inTrackLink);
            dropship.set('inbound_shipping_cost', inCost);
            dropship.set('outbound_tracking_link', outTrackLink);
            dropship.set('outbound_shipping_cost', outCost);
            dropship.set('notes', notes);
          });
        }
        this.get('dropships').forEach(dropship => {
          if (this.get('requireDate')) {
            dropship.set('missions', []);
          } else {
            dropship.set('missions', this.get('selectedMissions'));
          }
          dropship.set('pilot', this.get('selectedPilot'));
          dropship.set('return_date', scheduledDate);
        });

        // Make bulk save
        const promises = this.get('dropships').map(dropship => {
          return dropship.save();
        });
        Ember.RSVP.all(promises).then(() => {
          this.transitionToRoute('inventories-lists.dropships-list');
        }, error => {
          // noop
        });
      }
    }
  });
});