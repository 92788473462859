define("admin/templates/components/mission-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RherGX+H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sub-tab-container__no_flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub-tab-container__title title-without-underline\"],[12],[2,\"\\n    Flight Scheduling\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,6],null,[[\"mission\",\"selectedStart\",\"selectedEnd\",\"showReschedule\",\"scheduleError\",\"class\"],[[35,2,[\"mission\"]],[35,5],[35,4],[30,[36,3],[[35,2,[\"mission\",\"isScheduled\"]]],null],[35,1],\"mission-schedule\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"sub-tab-container__action force-margin-top-5\"],[12],[2,\"\\n\"],[6,[37,7],[[35,2,[\"mission\",\"isScheduled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"btn btn-secondary btn-sm\"],[4,[38,0],[[32,0],\"rescheduleFlight\"],null],[12],[2,\"Reschedule\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"btn btn-secondary btn-sm\"],[4,[38,0],[[32,0],\"chooseSchedule\"],null],[12],[2,\"Pilot Availability\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"scheduleError\",\"model\",\"is-not\",\"selectedEnd\",\"selectedStart\",\"schedule-inputs\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-schedule.hbs"
    }
  });
});