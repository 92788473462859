define("admin/data/waiver-status-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Approved",
    value: 'approved'
  }, {
    label: "Denied",
    value: 'denied'
  }, {
    label: "Under Review",
    value: 'under_review'
  }];
});