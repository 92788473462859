define("admin/data/payout-to-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: 'Pilot',
    value: 'pilot'
  }, {
    name: 'Editor',
    value: 'editor'
  }, {
    name: 'Analyst',
    value: 'analyst'
  }];
});