define("admin/helpers/check-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CheckIfHelper;
  CheckIfHelper = Ember.Helper.helper(function (arg) {
    var lvalue, operator, operators, rvalue;
    lvalue = arg[0], operator = arg[1], rvalue = arg[2];
    if (lvalue === void 0 || operator === void 0 || rvalue === void 0 || lvalue.length === 0 || operator.length === 0 || rvalue.length === 0) {
      return console.error('missing arguments [lvalue, operator, rvalue]', [lvalue, operator, rvalue]);
    } else {
      operators = {
        '==': function (l, r) {
          return l === r;
        },
        '===': function (l, r) {
          return l === r;
        },
        '!=': function (l, r) {
          return l !== r;
        },
        '<': function (l, r) {
          return l < r;
        },
        '>': function (l, r) {
          return l > r;
        },
        '<=': function (l, r) {
          return l <= r;
        },
        '>=': function (l, r) {
          return l >= r;
        },
        '||': function (l, r) {
          return l || r;
        },
        'typeof': function (l, r) {
          return typeof l === r;
        }
      };
      if (!operators[operator]) {
        throw new Error('Handlerbars Helper \'checkIf\' doesn\'t know the operator ' + operator);
      }
      return operators[operator](lvalue, rvalue);
    }
  });
  var _default = _exports.default = CheckIfHelper;
});