define("admin/templates/components/busy-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B759uALc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"busy-modal\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Loading...\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"processing\"],[12],[2,\"Processing \"],[1,[34,0]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"progressText\",\"busy\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/busy-modal.hbs"
    }
  });
});