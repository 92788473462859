define("admin/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Rf54i7sD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center four-oh-four\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/dronebase_logo.svg\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"orange-divider\"],[12],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"404 Not Found\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"The page you are looking for does not exist\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/four-oh-four.hbs"
    }
  });
});