define("admin/components/inventory/group-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'selected-items--group',
    actions: {
      unselectItem(item) {
        this.unselectItemAction(item);
      }
    }
  });
});