define("admin/components/collapsible-sidebar-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollapsibleSidebarItemComponent;
  CollapsibleSidebarItemComponent = Ember.Component.extend({
    initPage: Ember.on('init', function () {
      return this.set('expanded', !this.get('collapsed'));
    }),
    actions: {
      toggle: function () {
        return this.set('expanded', !this.get('expanded'));
      }
    }
  });
  var _default = _exports.default = CollapsibleSidebarItemComponent;
});