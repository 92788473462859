define("admin/models/admin", ["exports", "ember-data", "admin/models/rescheduler"], function (_exports, _emberData, _rescheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Admin;
  Admin = _rescheduler.default.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    roles: _emberData.default.attr('array'),
    updated_at: _emberData.default.attr('date'),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + " " + this.get('last_name');
    }),
    initials: Ember.computed('first_name', 'last_name', function () {
      return "" + this.get('first_name').charAt(0) + this.get('last_name').charAt(0);
    }),
    canSeeBulkUpdater: Ember.computed(function () {
      return this.get('roles').indexOf('invoice_manager') >= 0;
    })
  });
  var _default = _exports.default = Admin;
});