define("admin/mirage/models/badge", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    required_drones: (0, _emberCliMirage.hasMany)('drone'),
    required_drone_cameras: (0, _emberCliMirage.hasMany)('drone-camera'),
    required_devices: (0, _emberCliMirage.hasMany)('device'),
    required_pilot_equipments: (0, _emberCliMirage.hasMany)('pilot-equipment'),
    required_pilot_licenses: (0, _emberCliMirage.hasMany)('license'),
    badge_dispatch_zones: (0, _emberCliMirage.hasMany)('dispatch-zone'),
    required_program: (0, _emberCliMirage.belongsTo)('mindflash-series')
  });
});