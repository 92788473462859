define("admin/mirage/factories/shot", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `DBS${i}`;
    },
    instructions: 'lorem ipsum',
    name: 'pano shot',
    afterCreate(shot, server) {
      shot.update({
        shot_type: server.create('shot_type')
      });
    }
  });
});