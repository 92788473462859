define("admin/controllers/waivers/new", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      save(waiver) {
        waiver.save().then(() => {
          // noop
        }).catch(err => {
          let message = err.errors[0].detail;
          return alert("FAILED TO SAVE\n" + message);
        });
      }
    }
  });
});