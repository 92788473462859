define("admin/components/missions/mission-payment-view", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    hasCard: Ember.computed('mission', 'mission.credit_card', 'mission.credit_card.brand', function () {
      return this.get('mission.credit_card.brand') != null;
    }),
    hasHistory: Ember.computed('mission_payments.[]', function () {
      return this.get('mission_payments').length > 0;
    }),
    stripeLink: _environment.default.stripeChargeUrl
  });
});