define("admin/templates/components/missions/plan-map/remove-map-feature-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QB49qhj3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"translucentOverlay\",\"close\",\"containerClassNames\"],[true,\"toggleAction\",\"remove-map-feature-modal\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"remove-modal__content\"],[12],[2,\"\\n    Are you sure you want to remove this map feature from mission with all its related data?\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"remove-modal__buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-primary\"],[4,[38,0],[[32,0],\"tryToRemove\"],null],[12],[2,\"Remove\"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-secondary cancel-button\"],[4,[38,0],[[32,0],\"toggleAction\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "admin/templates/components/missions/plan-map/remove-map-feature-modal.hbs"
    }
  });
});