define("admin/controllers/payouts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PayoutsIndexController;
  PayoutsIndexController = Ember.Controller.extend({
    queryParams: ['status', 'q'],
    sortProperties: ['created_on:desc'],
    sortedPayouts: Ember.computed.sort('model', 'sortProperties'),
    q: null,
    status: null,
    activeCount: Ember.computed('model', 'model.length', function () {
      return this.get('model').length;
    }),
    actions: {
      sortBy: function (sortProperties) {
        return this.set('sortProperties', [sortProperties]);
      },
      clearQuery: function () {
        return this.set('q', null);
      }
    }
  });
  var _default = _exports.default = PayoutsIndexController;
});