define("admin/mirage/factories/drone-camera", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i}`;
    },
    name: 'name',
    mega_pixels: 120,
    thermal: false,
    pilotDrone: (0, _emberCliMirage.association)()
  });
});