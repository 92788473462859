define("admin/components/inventory/inventory-cameras-fields", ["exports", "admin/components/inventory/inventory-items-fields"], function (_exports, _inventoryItemsFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _inventoryItemsFields.default.extend({
    itemsList: Ember.computed(function () {
      return this.get('camerasList').toArray();
    }),
    actions: {
      newRelation(name) {
        const newCamera = this.set('relation', this.get('store').createRecord('drone-camera', {
          name: name
        }));
        this.get('relation').set('drone_manufacturer', this.get('droneManufacturers.firstObject'));
        this.get('itemsList').addObject(newCamera);
        this.selectRelationAction(newCamera);
      }
    }
  });
});