define("admin/mirage/factories/site-inspection", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    external_id(i) {
      return `external${i}`;
    },
    created_at: _faker.default.date.recent(),
    purchase_order_number: 'some_order_number',
    total_inspections: 100,
    client: (0, _emberCliMirage.association)(),
    status: 'in_progress',
    afterCreate(siteInspection, server) {
      siteInspection.update({
        client: server.create('client'),
        site: server.create('location')
      });
    }
  });
});