define("admin/routes/pilots/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    // the query params must be named with [] because rails is picky.
    // this will look like drones[][] in the url parameter, but when sent
    // to the server it'll just look like drones[].
    // the first [] is the actual name, and the second [] is embers way of
    // denoting that its an array
    queryParams: {
      q: {
        refreshModel: true
      },
      lat: {
        refreshModel: true
      },
      lon: {
        refreshModel: true
      },
      distance: {
        refreshModel: true
      },
      'statuses[]': {
        refreshModel: true
      },
      'drone_ids[]': {
        refreshModel: true
      },
      'camera_ids[]': {
        refreshModel: true
      },
      'device_ids[]': {
        refreshModel: true
      },
      'license_ids[]': {
        refreshModel: true
      },
      'pilot_equipment_ids[]': {
        refreshModel: true
      },
      'pilot_badge_badge_ids': {
        refreshModel: true
      },
      'pilot_badge_status_ids': {
        refreshModel: true
      },
      'pilot_badge_include': {
        refreshModel: true
      },
      'pilot_without_badges': {
        refreshModel: true
      }
    },
    model(params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      params['include[]'] = ['drones'];
      params['exept[]'] = ['average_rating', 'payout'];
      this.set('droneIds', params['drone_ids[]']);
      this.set('cameraIds', params['camera_ids[]']);
      this.set('deviceIds', params['device_ids[]']);
      this.set('licenseIds', params['license_ids[]']);
      this.set('pilotEquipmentIds', params['pilot_equipment_ids[]']);
      this.set('pilotBadgeBadgeIds', params['pilot_badge_badge_ids']);
      this.set('pilotBadgeStatusIds', params['pilot_badge_status_ids']);
      this.set('pilotBadgeInclude', params['pilot_badge_include']);
      this.set('pilotWithoutBadges', params['pilot_without_badges']);
      this.set('pilotBadgeStatuses', [Ember.Object.create({
        'id': 'complete',
        'name': 'Complete'
      }), Ember.Object.create({
        'id': 'pending',
        'name': 'Pending'
      })]);
      return this.infinity.model('pilot', params);
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('droneIds', this.get('droneIds'));
      controller.set('cameraIds', this.get('cameraIds'));
      controller.set('deviceIds', this.get('deviceIds'));
      controller.set('licenseIds', this.get('licenseIds'));
      controller.set('pilotEquipmentIds', this.get('pilotEquipmentIds'));
      controller.set('pilotBadgeBadgeIds', this.get('pilotBadgeBadgeIds'));
      controller.set('pilotBadgeStatusIds', this.get('pilotBadgeStatusIds'));
      controller.set('pilotBadgeStatuses', this.get('pilotBadgeStatuses'));
      controller.set('pilotBadgeInclude', this.get('pilotBadgeInclude'));
      controller.set('pilotWithoutBadges', this.get('pilotWithoutBadges'));
    },
    // these methods are here to pretty up the url when using arrays
    // instead of /onboarding?whatever%5D%65"56", it'll look like
    // /onboarding?whatever[][]=56
    serializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    }
  });
});