define("admin/components/site-inspections/standby-hours-line-item-form", ["exports", "ember-changeset", "ember-changeset-validations", "moment-timezone", "admin/validations/standby-hours-line-item", "admin/helpers/humanize-seconds", "admin/utils/timezone-without-changing-time"], function (_exports, _emberChangeset, _emberChangesetValidations, _momentTimezone, _standbyHoursLineItem, _humanizeSeconds, _timezoneWithoutChangingTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    changeset: null,
    standByLineItem: null,
    workOrder: null,
    inputDate: null,
    // to avoid observer on date
    date: null,
    status: 'approved',
    timeSlotsPeriod: [15, 'm'],
    timeSlotsFormat: 'hh:mma',
    startsAtDefault: {
      'hour': 8,
      'minute': 0,
      'seconds': 0
    },
    endsAtDefault: {
      'hour': 8,
      'minute': 30,
      'seconds': 0
    },
    datepickerOptions: Ember.computed('workOrder.created_at', function () {
      return {
        allowInputToggle: true,
        useCurrent: false,
        ignoreReadonly: true,
        format: 'MM/DD/YY',
        maxDate: (0, _momentTimezone.default)().tz(this.get('workOrder.timezone')).format('MM/DD/YY'),
        minDate: (0, _momentTimezone.default)(this.get('workOrder.created_at')).tz(this.get('workOrder.timezone')).startOf('day').format('MM/DD/YY')
      };
    }),
    didReceiveAttrs: function () {
      _momentTimezone.default.tz.setDefault(this.get('workOrder.timezone'));
    },
    didInsertElement: function () {
      this._super();
      this.initStandByHoursLineItem();
    },
    initStandByHoursLineItem: function (snapshot = null) {
      this.set('date', (0, _momentTimezone.default)().format(this.datepickerOptions.format));
      this.set('inputDate', this.get('date'));
      this.set('standByLineItem', this.store.createRecord('standby-hours-line-item'));
      this.set('changeset', new _emberChangeset.default(this.get('standByLineItem'), (0, _emberChangesetValidations.default)(_standbyHoursLineItem.default), _standbyHoursLineItem.default));
      this.get('changeset').set('site_inspection', this.get('workOrder'));
      this.get('changeset').set('pilot', this.get('pilot'));
      this.get('changeset').set('status', this.get('status'));
      if (snapshot) {
        this.changeset.set('standby_hours_reason', snapshot.changes.standby_hours_reason);
      }
      const from = (0, _momentTimezone.default)().set(this.get('startsAtDefault')).format();
      const to = (0, _momentTimezone.default)().set(this.get('endsAtDefault')).format();
      this.setAvailableTimes(from, to);
    },
    removeRequiredCommentError: Ember.observer('changeset.standby_hours_reason', function () {
      delete this.changeset.error.description;
    }),
    dailyPilotLineItems: Ember.computed('date', 'pilot.standby_hours_line_items.length', function () {
      return this.get('pilot.standby_hours_line_items').filter(item => _momentTimezone.default.utc(item.starts_at).isSame(_momentTimezone.default.utc(this.get('date')), 'day')).sortBy('starts_at');
    }),
    setAvailableTimes: function (from, to) {
      const pilotSlots = this.get('dailyPilotLineItems').filter(item => (0, _momentTimezone.default)(item.ends_at).isAfter(from));
      let availableFrom = from;
      let availableTo = to;
      pilotSlots.forEach(item => {
        if ((0, _momentTimezone.default)(availableFrom).isBetween(item.starts_at, item.ends_at, undefined, '[)')) {
          availableFrom = (0, _momentTimezone.default)(item.ends_at).format();
          if ((0, _momentTimezone.default)(availableTo).isSameOrBefore(availableFrom)) {
            availableTo = (0, _momentTimezone.default)(availableFrom).add(...this.timeSlotsPeriod).format();
          }
        } else if ((0, _momentTimezone.default)(availableTo).isBetween(item.starts_at, item.ends_at, undefined, '(]')) {
          availableTo = (0, _momentTimezone.default)(item.starts_at).format();
        }
      });
      this.get('changeset').set('starts_at', availableFrom);
      this.get('changeset').set('ends_at', availableTo);
    },
    wrappedReasons: Ember.computed('standbyHoursReasons', function () {
      return this.get('standbyHoursReasons').map(reason => {
        return Ember.Object.create({
          label: reason.get('formattedName'),
          value: reason
        });
      });
    }),
    isAlreadyReported: function (current, includes) {
      return this.get('dailyPilotLineItems').any(item => current.isBetween(item.starts_at, item.ends_at, undefined, includes));
    },
    timeSlotsFrom: Ember.computed('date', 'dailyPilotLineItems', function () {
      // We check if time in future or 15m before for exclude time, which can't be used for From timeslot
      const isInFutureOrNear = current => current.isAfter((0, _momentTimezone.default)().subtract(15, 'm'), 'minute');
      let timeSlots = [];
      const current = (0, _timezoneWithoutChangingTime.default)(_momentTimezone.default.utc(this.get('date')).startOf('day'), this.get('workOrder.timezone'));
      const end = (0, _timezoneWithoutChangingTime.default)(_momentTimezone.default.utc(this.get('date')).add(1, 'days').startOf('day'), this.get('workOrder.timezone'));
      while (current < end) {
        timeSlots.push(Ember.Object.create({
          label: current.format(this.get('timeSlotsFormat')),
          value: current.format(),
          disabled: this.isAlreadyReported(current, '{)') || isInFutureOrNear(current)
        }));
        current.add(...this.timeSlotsPeriod);
      }
      // Disabled last item
      timeSlots.push(Ember.Object.create({
        label: current.format(this.get('timeSlotsFormat')),
        value: current.format(),
        disabled: true
      }));
      return timeSlots;
    }),
    timeSlotsTo: Ember.computed('date', 'changeset.starts_at', 'dailyPilotLineItems', function () {
      const startsAt = _momentTimezone.default.utc(this.get('changeset.starts_at'));
      const overlapCurrentLineItems = current => this.get('dailyPilotLineItems').any(item => current.isAfter(item.ends_at)) && this.get('dailyPilotLineItems').any(item => startsAt.isBefore(item.starts_at));
      const isInFuture = current => current.isAfter((0, _momentTimezone.default)(), 'minute');
      let timeSlots = [];
      const current = (0, _timezoneWithoutChangingTime.default)(_momentTimezone.default.utc(this.get('date')).startOf('day'), this.get('workOrder.timezone'));
      const end = (0, _timezoneWithoutChangingTime.default)(_momentTimezone.default.utc(this.get('date')).add(1, 'days').startOf('day'), this.get('workOrder.timezone'));

      // Disabled first item
      timeSlots.push(Ember.Object.create({
        label: current.format(this.get('timeSlotsFormat')),
        value: current.format(),
        disabled: true
      }));
      current.add(...this.timeSlotsPeriod);
      while (current <= end) {
        const timeSlot = Ember.Object.create({
          label: current.format(this.get('timeSlotsFormat')),
          value: current.format(),
          disabled: this.isAlreadyReported(current, '(}') || isInFuture(current) || overlapCurrentLineItems(current)
        });
        const diff = current.diff((0, _momentTimezone.default)(startsAt).tz(this.get('workOrder.timezone'))) / 1000;
        if (diff > 0) {
          timeSlot.subLabel = `(${(0, _humanizeSeconds.humanizeSeconds)(diff)})`;
        }
        timeSlots.push(timeSlot);
        current.add(...this.timeSlotsPeriod);
      }
      return timeSlots;
    }),
    validateSlotExclusion: function () {
      if (this.get('dailyPilotLineItems').some(item => (0, _momentTimezone.default)(item.starts_at).isBetween(this.get('changeset.starts_at'), this.get('changeset.ends_at')) || (0, _momentTimezone.default)(item.ends_at).isBetween(this.get('changeset.starts_at'), this.get('changeset.ends_at')))) {
        this.get('changeset').addError('starts_at', 'Overlap with existed record');
        this.get('changeset').addError('ends_at', 'Overlap with existed record');
      }
    },
    validateRequiredComment: function () {
      if (this.get('changeset.standby_hours_reason.comment_required') && (!this.get('changeset.description') || this.get('changeset.description') === '')) {
        this.get('changeset').addError('description', 'Please provide a comment');
      }
    },
    close: function () {
      this.get('standByLineItem').deleteRecord();
      this.closeAction();
    },
    actions: {
      setDateCallback: function ({
        date
      }) {
        if (!date) {
          this.set('date', null);
          this.get('changeset').set('starts_at', null);
          this.get('changeset').set('ends_at', null);
          return;
        }
        this.set('date', date.format(this.datepickerOptions.format));
        const dateObject = {
          year: date.year(),
          month: date.month(),
          date: date.date()
        };
        const startsAt = (0, _momentTimezone.default)(this.get('changeset.starts_at') || this.get('startsAtDefault')).set(dateObject).format();
        const endsAt = (0, _momentTimezone.default)(this.get('changeset.ends_at') || this.get('endsAtDefault')).set(dateObject).format();
        this.setAvailableTimes(startsAt, endsAt);
        this.get('changeset').validate('starts_at', 'ends_at');
      },
      setFromCallback: function (value) {
        if ((0, _momentTimezone.default)(this.get('changeset.ends_at')).isSameOrBefore(value)) {
          this.get('changeset').set('ends_at', (0, _momentTimezone.default)(value).add(...this.timeSlotsPeriod).format());
          this.get('changeset').validate('starts_at', 'ends_at');
        }
      },
      setToCallback: function (value) {
        if ((0, _momentTimezone.default)(this.get('changeset.starts_at')).isSameOrAfter(value)) {
          this.get('changeset').set('starts_at', (0, _momentTimezone.default)(value).subtract(...this.timeSlotsPeriod).format());
          this.get('changeset').validate('ends_at', 'starts_at');
        }
      },
      create: function (logMore = null) {
        // reset errors from backend
        this.get('changeset').validate('base');
        this.get('changeset').validate().then(() => {
          this.validateSlotExclusion();
          this.validateRequiredComment();
          if (this.get('changeset.isValid')) {
            let snapshot = this.get('changeset').snapshot();
            this.get('changeset').save({}).then(() => {
              this.flashMessages.success('Time is logged successfully');
              if (logMore) {
                this.initStandByHoursLineItem();
              } else {
                this.closeAction();
              }
            }, error => {
              error.errors.forEach(error => {
                const key = error.source.pointer.split('/').lastObject;
                const message = error.detail;
                this.get('changeset').addError(key, message);
                this.flashMessages.error(message);
              });
              console.log(error);
              this.get('standByLineItem').deleteRecord();
              this.initStandByHoursLineItem(snapshot);
              this.get('changeset').restore(snapshot);
            });
          }
        });
      },
      close: function () {
        this.close();
      }
    }
  });
});