define("admin/models/organization-available-package", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    organization: (0, _model.belongsTo)('organization', {
      async: false
    }),
    package: (0, _model.belongsTo)('package', {
      async: false
    })
  });
});