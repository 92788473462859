define("admin/components/pilot-payment-information", ["exports", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      deletePayoutAccount(stripeAccountId) {
        if (window.confirm('Are you sure? This will permanently remove the payout account details the ability to cross check payout history.')) {
          this.store.adapterFor('pilot').deletePayoutAccount(this.pilot.id, stripeAccountId, this.get('sessionAccount.headers')).then(() => {
            this.pilot.reload();
          }).catch(response => {
            (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          });
        }
      },
      deleteLegacyPayoutAccount() {
        // For PAYPAL, etc. (which linked to pilot model, not stripe_account)
        if (window.confirm('Are you sure? This will permanently remove the payout account details the ability to cross check payout history.')) {
          this.store.adapterFor('pilot').deleteLegacyPayoutAccount(this.pilot.id, this.get('sessionAccount.headers')).then(() => {
            this.pilot.reload();
          }).catch(response => {
            (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          });
        }
      }
    }
  });
});