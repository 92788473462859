define("admin/mirage-factories/post", ["exports", "ember-infinity/mirage-factories/post"], function (_exports, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _post.default;
    }
  });
});