define("admin/templates/shot_lists/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lH+1YZ8q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"shot-list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"back-link turquoise-link\"],[12],[2,\"\\n      \"],[6,[37,0],null,[[\"bubbles\",\"route\"],[false,\"shot_lists.index\"]],[[\"default\"],[{\"statements\":[[2,\"Back to All Shot Lists\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-title clearfix\"],[12],[2,\"\\n      Edit Shot List\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"shot_list\",\"postProcesses\",\"onBusy\",\"currentRoute\"],[[35,3,[\"shot_list\"]],[35,3,[\"post_processes\"]],[30,[36,2],[[32,0],\"fireBusy\"],null],[35,1]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"currentRoute\",\"action\",\"model\",\"shot-list/shot-list-form\"]}",
    "meta": {
      "moduleName": "admin/templates/shot_lists/edit.hbs"
    }
  });
});