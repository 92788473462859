define("admin/templates/components/tab-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WejUbxFU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"tab-text\",[30,[36,1],[[35,0],\" active\"],null]],null]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"active\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "admin/templates/components/tab-text.hbs"
    }
  });
});