define("admin/components/waiver-fields", ["exports", "admin/data/waiver-status-options", "admin/data/waiver-class-options"], function (_exports, _waiverStatusOptions, _waiverClassOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    waiverStatusOptions: _waiverStatusOptions.default,
    waiverClassOptions: _waiverClassOptions.default,
    store: Ember.inject.service(),
    isExtensionEditing: false,
    extensionForCreation: null,
    didInsertElement() {
      return this.$('.expiresAtDateField').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        date: moment(this.get('waiver.expires_at')).format('MM/DD/YYYY'),
        minDate: moment().startOf('day'),
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      }).on('dp.change', e => {
        return this.set('waiver.expires_at', new Date(e.target.value));
      }).val(moment(this.get('waiver.expires_at')).format('MM/DD/YYYY'));
    },
    actions: {
      addNewExtension() {
        const newExtension = this.store.createRecord('waiver-extension', {
          waiver: this.get('waiver')
        });
        this.get('waiver.waiver_extensions').pushObject(newExtension);
        this.set('extensionForCreation', newExtension);
        this.set('isExtensionEditing', true);
      },
      cancel() {
        const newExtension = this.get('extensionForCreation');
        this.get('waiver.waiver_extensions').removeObject(newExtension);
        this.set('isExtensionEditing', false);
      },
      confirm() {
        const newExtension = this.get('extensionForCreation');
        newExtension.save().then(resp => {
          this.set('extensionForCreation', null);
          this.set('isExtensionEditing', false);
        }).catch(error => {
          // nope
        });
      },
      delete(extension) {
        if (window.confirm('Are you sure? This will permanently remove this extension.')) {
          extension.deleteRecord();
          extension.save().then(() => {
            this.get('waiver.waiver_extensions').removeObject(extension);
          }).catch(err => {
            const message = err.errors[0].detail;
            return alert("FAILED TO SAVE\n" + message);
          });
        }
      }
    }
  });
});