define("admin/helpers/pluralize", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pluralize = pluralize;
  function pluralize(params, options) {
    let [count, word] = params;
    const {
      omitCount
    } = options;
    if (count !== 1) {
      count = count || 0;
      word = (0, _emberInflector.pluralize)(word);
    }
    return (omitCount ? '' : count + ' ') + word;
  }
  var _default = _exports.default = Ember.Helper.helper(pluralize);
});