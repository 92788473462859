define("admin/components/missions/payment-reminder-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      close: function () {
        this.get('close')();
      },
      processReminder: function () {
        const _this = this;
        this.set('loading', true);
        Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${this.get('mission.id')}/mission_payments/reminder`,
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          type: 'POST'
        }).then(function (response) {
          _this.get('close')();
          _this.get('mission').reload();
        }); // ajax
      } // processReminder
    } // actions
  });
});