define("admin/components/inventory/inventory-adapters-fields", ["exports", "admin/components/inventory/inventory-items-fields"], function (_exports, _inventoryItemsFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _inventoryItemsFields.default.extend({
    itemsList: Ember.computed(function () {
      return this.get('adaptersList').toArray();
    }),
    newModelName: 'adapter',
    actions: {
      newRelation(name) {
        const newAdapter = this.set('relation', this.get('store').createRecord('adapter', {
          name: name
        }));
        this.get('relation').set('drone_manufacturer', this.get('droneManufacturers.firstObject'));
        this.get('itemsList').addObject(newAdapter);
        this.selectRelationAction(newAdapter);
      }
    }
  });
});