define("admin/controllers/pilots/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['q', 'distance', 'statuses[]', 'lat', 'lon', 'country_codes'],
    sortProperties: ['distance:asc', 'fullName:asc'],
    sortedPilots: Ember.computed.sort('model.pilots', 'sortProperties'),
    q: null,
    activeTab: 'pilots',
    activeModal: null,
    approvedPilots: false,
    lat: null,
    lon: null,
    distance: null,
    country_codes: Ember.A([]),
    statusSelected: Ember.observer('approvedPilots', function () {
      if (this.get('approvedPilots')) {
        return this.set('statuses[]', 'approved');
      } else {
        return this.set('statuses[]', '');
      }
    }),
    actions: {
      changeCountryCodes: function (countryCodes) {
        return this.set('country_codes[]', countryCodes);
      },
      sortBy: function (sortProperties) {
        return this.set('sortProperties', [sortProperties]);
      },
      clearQuery: function () {
        return this.set('q', null);
      },
      toggleFilter: function () {
        return this.set('hideFilter', false);
      }
    }
  });
});