define("admin/templates/components/inventory/inventory-model-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Uph+LW8m",
    "block": "{\"symbols\":[\"search\"],\"statements\":[[6,[37,8],null,[[\"onClickOutside\"],[[30,[36,0],[[32,0],\"outsideClick\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inventory-model-search\"],[12],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"type\",\"value\",\"key-up\",\"class\",\"autocomplete\"],[\"text\",[35,5],[30,[36,0],[[32,0],\"debouncedUpdateSearchValue\"],null],\"form-control input-md\",false]]]],[2,\"\\n\"],[6,[37,1],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"inventory-model-search-results\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"div\"],[24,0,\"search-result\"],[4,[38,0],[[32,0],\"selectItem\",[32,1,[\"name\"]]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"title\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isNew\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[10,\"span\"],[14,0,\"postfix\"],[12],[2,\"(New)\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[10,\"span\"],[14,0,\"postfix\"],[12],[2,\"(\"],[1,[32,1,[\"inventory_items\",\"length\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"searchResults\",\"-track-array\",\"each\",\"searchValue\",\"input\",\"isSearchResultPresent\",\"click-outside\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inventory/inventory-model-search.hbs"
    }
  });
});