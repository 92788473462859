define("admin/templates/components/inventory/disassociate-missions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LDfPlRyN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"hasOverlay\",\"translucentOverlay\",\"appendClasses\",\"close\"],[true,true,\"dropship-disassociate-missions\",[30,[36,0],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"pe-modal-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-horizontal\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"warning-text\"],[12],[2,\"\\n        Are you sure you want to disassociate this dropshipped item with missions?\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"buttons-block\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"confirm\"],null],[12],[2,\"Proceed\"],[13],[2,\"\\n      \"],[11,\"div\"],[24,0,\"btn btn-secondary cancel-button\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inventory/disassociate-missions-modal.hbs"
    }
  });
});