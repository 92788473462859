define("admin/routes/clients/organizations", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsIndexRoute;
  OrganizationsIndexRoute = Ember.Route.extend(_route.default, {
    model: function (params) {
      params['perPage'] = 30;
      params['startingPage'] = 0;
      return this.infinityModel('organization', params);
    },
    queryParams: {
      q: {
        refreshModel: true
      },
      sort_attribute: {
        refreshModel: true
      },
      sort_order: {
        refreshModel: true
      }
    },
    actions: {
      close: function () {
        this.send('closeModal');
      },
      newOrganization: function (model) {
        model.set('organization', this.get('store').createRecord('organization'));
        this.send('openModal', 'clients.organization.modal', model);
      },
      saveOrganization: function (model) {
        model.organization.save().then(response => {
          this.send('closeModal');
          this.transitionTo('clients.organization', response.id);
        }, response => {});
      }
    }
  });
  var _default = _exports.default = OrganizationsIndexRoute;
});