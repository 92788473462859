define("admin/router", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
      _defineProperty(this, "googleMapsKey", _environment.default.googleMapsKey);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('partner_integration');
    this.route('payouts', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('missions', function () {
      this.route('index', {
        path: '/'
      });
      this.route('map', {
        path: ':mission_id/map'
      });
      this.route('edit', {
        path: ':mission_id/edit'
      });
      this.route('new');
      this.route('new', {
        path: '/new/client/:client_id'
      });
      this.route('training_missions');
    });
    this.route('site-inspections', {
      path: '/site_inspections'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('show', {
        path: ':site_inspection_id/show'
      });
      this.route('duplicate', {
        path: ':site_inspection_id/duplicate'
      });
    });
    this.route('capture-groups', {
      path: '/capture_groups'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('show', {
        path: '/:capture_group_id'
      });
    });
    this.route('shot_lists', function () {
      this.route('index', {
        path: '/'
      });
      this.route('new');
      this.route('edit', {
        path: ':shot_list_id/edit'
      });
      this.route('clone', {
        path: '/clone/:shot_list_id'
      });
    });
    this.route('waivers', function () {
      this.route('index', {
        path: '/'
      });
      this.route('new');
      this.route('edit', {
        path: ':waiver_id/edit'
      });
    });
    this.route('equipment', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('badges', function () {
      this.route('index', {
        path: '/'
      });
      this.route('edit', {
        path: ':badge_id/edit'
      });
    });
    this.route('pilots', function () {
      this.route('index', {
        path: '/'
      });
      this.route('onboarding');
      this.route('pilot', {
        path: '/:pilot_id/edit'
      });
    });
    this.route('organizations', function () {
      this.route('subscription', {
        path: ':organization_id/subscriptions/:subscription_id'
      });
      this.route('comprehensive-report-purchase', {
        path: ':organization_id/comprehensive_report_purchases/:comprehensive_report_purchase_id'
      });
    });
    this.route('clients', function () {
      this.route('index', {
        path: '/'
      });
      this.route('organizations');
      this.route('organization', {
        path: '/organization/:organization_id'
      });
      this.route('client', {
        path: ':client_id'
      }, function () {
        this.route('missions', {
          path: '/'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('new', {
            path: 'missions/new'
          });
          this.route('edit', {
            path: 'missions/:mission_id/edit'
          });
        });
        this.route('locations', function () {
          this.route('modal');
        });
        this.route('packages', function () {
          this.route('modal');
        });
      });
    });
    this.route('inventories-lists', function () {
      this.route('inventory-list');
      this.route('dropships-list');
    });
    this.route('dropships', function () {
      this.route('new');
      this.route('edit', {
        path: '/:pilot_id/edit'
      });
    });
    this.route('admins', function () {
      this.route('index', {
        path: '/'
      });
      this.route('edit', {
        path: ':admin_id/edit'
      });
    });
    this.route('subscription-offers', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('four_oh_four', {
      path: '*path'
    });
    this.route('four_oh_three', {
      path: '*path'
    });
  });
});