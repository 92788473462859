define("admin/components/add-camera-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    currentRecord: null,
    didReceiveAttrs() {
      if (this.get('currentRecord.drone_manufacturer.id')) return;
      this.get('currentRecord').set('drone_manufacturer', this.get('droneManufacturers').firstObject);
    },
    actions: {
      close() {
        return this.get('close')();
      },
      setDroneManufacturer(value) {
        const selectedManufacturer = this.get('droneManufacturers').findBy('id', value);
        return this.get('currentRecord').set('drone_manufacturer', selectedManufacturer);
      },
      submit() {
        const record = this.get('currentRecord');
        if (!record.validate()) return;
        return this.action().then(this.get('close'));
      }
    }
  });
});