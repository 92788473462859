define("admin/data/shot-type/pattern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: 'Crosshatch',
    value: 'crosshatch'
  }, {
    name: 'Perimeter',
    value: 'perimeter'
  }, {
    name: 'Lawn mower',
    value: 'lawn_mower'
  }];
});