define("admin/templates/components/searches/not-matched-queries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M6z0KHlN",
    "block": "{\"symbols\":[\"queryLine\"],\"statements\":[[10,\"div\"],[14,0,\"ver-100 not-matched-queries-block\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hor-200\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hor-100 items-center\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/warning.svg\"],[14,\"alt\",\"warning\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"subtitle-2 color-g400\"],[12],[2,\"\\n        No matches found for \"],[10,\"span\"],[14,0,\"color-g900\"],[12],[1,[35,0,[\"length\"]]],[13],[2,\" / \"],[1,[34,5]],[2,\" item(s)\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"button\",[[4,[38,3],[[32,0],\"toggleNotMatchedQueryLinesVisibile\"],null]],[[\"@type\"],[\"text\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,7],[[35,6],\"Hide\",\"Show\"],null]],[2,\" details\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hor-100 items-start\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"not-matched-queries\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"body-1-light color-g900\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[8,\"button\",[[24,0,\"copy-button\"],[4,[38,3],[[32,0],\"copyNotMatchedLines\"],null]],[[\"@type\"],[\"text\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/copy.svg\"],[14,\"alt\",\"copy\"],[12],[13],[2,\"\\n        \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@side\"],[[34,4],\"bottom\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notMatchedQueryLines\",\"-track-array\",\"each\",\"action\",\"copyButtonText\",\"queryLinesCount\",\"notMatchedQueryLinesVisibile\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/searches/not-matched-queries.hbs"
    }
  });
});