define("admin/data/work-order/search-field-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchFieldOptions = void 0;
  const options = [{
    label: "External ID",
    value: 'external_id'
  }, {
    label: "ID (DBCG)",
    value: 'number'
  }];
  const searchFieldOptions = _exports.searchFieldOptions = options.map(({
    label,
    value
  }) => {
    return Ember.Object.create({
      label,
      value
    });
  });
});