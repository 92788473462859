define("admin/templates/components/dispatch-zone-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vx4lQzxa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[15,2,[34,0,[\"name\"]]],[14,\"placeholder\",\"Enter Location\"],[15,0,[34,1]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"inputClass\"]}",
    "meta": {
      "moduleName": "admin/templates/components/dispatch-zone-autocomplete.hbs"
    }
  });
});