define("admin/models/airbus-briefing", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    external_id: _emberData.default.attr('string'),
    notices: _emberData.default.attr('array'),
    max_altitude: _emberData.default.attr('string'),
    airspace_class: _emberData.default.attr('string'),
    waivers: _emberData.default.attr('array'),
    laanc_enabled: _emberData.default.attr('boolean'),
    auto_authorization: _emberData.default.attr('boolean')
  });
});