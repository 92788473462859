define("admin/adapters/pilot-equipment", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotEquipmentAdapter;
  PilotEquipmentAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    urlTemplate: '{+namespace}/pilot_equipment{/id}'
  });
  var _default = _exports.default = PilotEquipmentAdapter;
});