define("admin/models/post-process", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NOT_SET = void 0;
  const NOT_SET = _exports.NOT_SET = 'not_set';
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    process_type: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    nameLong: Ember.computed('name', function () {
      let name = this.get('name');
      if (['RGB Orthomosaic Stitching', 'Thermal Orthomosaic Stitching'].includes(name)) {
        name += ' (if it’s checked on the mission)';
      }
      return name;
    }),
    nameShort: Ember.computed('name', function () {
      switch (this.get('name')) {
        case 'Panorama Stitching':
          return 'Pano';
        case 'RGB Orthomosaic Stitching':
          return 'RGB Ortho';
        case 'Thermal Orthomosaic Stitching':
          return 'Thermal Ortho';
        case 'Roof Measurements':
          return 'Roof Mrmnts';
        default:
          return this.get('name');
      }
    })
  });
});