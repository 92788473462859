define("admin/data/shot-type/camera-requirements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    value: 'phone_camera',
    name: 'Phone Camera'
  }, {
    value: 'dslr',
    name: 'DSLR'
  }, {
    value: 'drone_camera',
    name: 'Drone Camera'
  }];
});