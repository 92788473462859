define("admin/models/mission_payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    mission: _emberData.default.belongsTo('mission'),
    credit_card: _emberData.default.belongsTo('credit_card'),
    payment_processor: _emberData.default.attr('string'),
    payment_id: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    paid_at: _emberData.default.attr('date')
  });
});