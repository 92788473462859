define("admin/templates/components/missions/activity-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zvt4S1yW",
    "block": "{\"symbols\":[\"activity\"],\"statements\":[[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Date/Time\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Action\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"User\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Role\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Details\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"mission\",\"activity_logs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"created_at\"]],\"MM/DD/YY, h:mm A\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"action\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,1,[\"user_name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,1,[\"role\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,1,[\"details\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"titleize\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/missions/activity-logs.hbs"
    }
  });
});