define("admin/components/pilot-drone-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotDroneViewComponent;
  PilotDroneViewComponent = Ember.Component.extend({
    classNames: 'row pilot-section',
    displayedIndex: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),
    displayedCameras: Ember.computed('drone.cameras.[]', function () {
      var camera, cameraArray, cameraText, i, j, len;
      cameraText = "";
      cameraArray = this.get('drone.cameras').toArray();
      for (i = j = 0, len = cameraArray.length; j < len; i = ++j) {
        camera = cameraArray[i];
        cameraText += camera.get('name');
        if (i !== cameraArray.length - 1) {
          cameraText += ', ';
        }
      }
      return cameraText;
    })
  });
  var _default = _exports.default = PilotDroneViewComponent;
});