define("admin/helpers/empty-or-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emptyOrIncludes = _exports.default = void 0;
  var emptyOrIncludes = function (arg) {
    var el, obj;
    obj = arg[0], el = arg[1];
    return obj.length <= 0 || obj.includes(el);
  };
  _exports.emptyOrIncludes = emptyOrIncludes;
  var _default = _exports.default = Ember.Helper.helper(emptyOrIncludes);
});