define("admin/models/invoice-line-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    created_on: (0, _model.attr)('date'),
    reason: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    display_name: (0, _model.attr)('string'),
    saved_invoiceable_type: (0, _model.attr)('string'),
    saved_invoiceable_id: (0, _model.attr)('string'),
    invoiceable: (0, _model.belongsTo)('invoiceable', {
      polymorphic: true,
      inverse: 'invoice_line_items'
    }),
    work_item: (0, _model.belongsTo)('work-item', {
      async: false
    }),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    })
  });
});