define("admin/mirage/models/standby-hours-reason", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    standby_hours_line_items: (0, _emberCliMirage.hasMany)('standby-hours-line-item')
  });
});