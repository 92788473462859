define("admin/components/missions/plan-map/remove-shot-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    warningShown: false,
    actions: {
      toggleAction: function () {
        this.get('closeAction')();
      },
      tryToRemove: function () {
        if (this.get('shot.images.length') > 0 || this.get('shot.videos.length') > 0) {
          this.set('warningShown', true);
        } else {
          this.get('shot').deleteRecord();
          this.get('shot').save().then(() => {
            this.get('closeAction')();
          });
        }
      }
    }
  });
});