define("admin/templates/admins/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yLKnrLxQ",
    "block": "{\"symbols\":[\"admin\"],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header clearfix waivers\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"pull-left\"],[12],[2,\"Admins\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"pull-right\"],[12],[2,\"Total: \"],[1,[30,[36,2],[[35,1,[\"admins\",\"length\"]],0],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"table\"],[14,0,\"pe-table table waiver-list\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"First Name\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Last Nmae\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Email\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\" \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,1,[\"admins\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[14,0,\"waiver-row\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[1,[32,1,[\"first_name\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[1,[32,1,[\"last_name\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[6,[37,0],null,[[\"route\",\"model\"],[\"admins.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Edit\"]],\"parameters\":[]}]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"either\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/admins/index.hbs"
    }
  });
});