define("admin/helpers/is-not-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.IsNotEqualHelper = void 0;
  const IsNotEqualHelper = ([leftSide, rightSide]) => leftSide !== rightSide;
  _exports.IsNotEqualHelper = IsNotEqualHelper;
  var _default = _exports.default = Ember.Helper.helper(IsNotEqualHelper);
});