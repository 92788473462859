define("admin/components/onboarding/pilot-equipment-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    totalSelectedCount: Ember.computed('selectedPilotEquipment.[]', function () {
      return this.get('selectedPilotEquipment.length');
    }),
    didInsertElement() {
      const _this = this;
      if (this.get('pilotEquipment.length') === undefined) {
        this.store.query('pilot-equipment', {}).then(function (data) {
          _this.set('pilotEquipment', data.toArray());
          _this.cacheObjects('pilotEquipment', data.toArray());
          _this.get('pilotEquipment').forEach(function (item) {
            if (_this.get('pilotEquipmentIds').includes(item.get('id'))) {
              _this.get('selectedPilotEquipment').pushObject(item);
            }
          });
        });
      }
    },
    actions: {
      clear() {
        this.set('selectedPilotEquipment', []);
        this.set('pilotEquipmentIds', []);
      },
      setPanel() {
        this.setPanel(null, null);
      }
    }
  });
});