define("admin/templates/shot_lists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4CTBMCJ3",
    "block": "{\"symbols\":[\"shot_list\"],\"statements\":[[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"All Shot Lists\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[2,\"\\n    \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary btn-sm pull-right header-button\",\"shot_lists.new\"]],[[\"default\"],[{\"statements\":[[2,\"Create New\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n      \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Shots\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"shot_types\",\"length\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n            \"],[6,[37,0],null,[[\"bubbles\",\"route\",\"model\"],[false,\"shot_lists.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Edit\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"sortedShotLists\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/shot_lists/index.hbs"
    }
  });
});