define("admin/models/map-feature", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),
    properties: (0, _model.attr)({
      defaultValue() {
        return {};
      }
    }),
    geometry: (0, _model.attr)({
      defaultValue() {
        return {};
      }
    }),
    mission: (0, _model.belongsTo)(),
    catalog_items: (0, _model.hasMany)()
  });
});