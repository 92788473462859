define("admin/templates/components/input-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zKMCcFpK",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[6,[37,2],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"field-label\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[6,[37,2],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"value\",\"save\"],[[35,1],[30,[36,3],[[32,0],\"save\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"type\",\"value\",\"save\"],[[35,4],[35,1],[30,[36,3],[[32,0],\"save\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inline-input\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[28,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"placeholder\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"value\",\"if\",\"action\",\"type\",\"input-trigger-save\",\"textarea-trigger-save\",\"modelErrors\",\"-track-array\",\"each\",\"typeTextArea\",\"isEditing\"]}",
    "meta": {
      "moduleName": "admin/templates/components/input-inplace-edit.hbs"
    }
  });
});