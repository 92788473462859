define("admin/models/location", ["exports", "@ember-data/model", "moment-timezone"], function (_exports, _model, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    number: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    postal_code: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    formatted_address: (0, _model.attr)('string'),
    properties: (0, _model.attr)(),
    timezone_id: (0, _model.attr)('string'),
    last_package_id: (0, _model.attr)('string'),
    portfolio: (0, _model.attr)('string'),
    capacity_score: (0, _model.attr)('number'),
    airbus_supported_country: (0, _model.attr)('boolean'),
    missions: (0, _model.hasMany)('missions'),
    client: (0, _model.belongsTo)('client'),
    work_orders: (0, _model.hasMany)('site-inspections'),
    currentLocalTime: Ember.computed('timezone_id', function () {
      return _momentTimezone.default.tz((0, _momentTimezone.default)(), this.get('timezone_id')).format('MM/DD/YYYY hh:mm A z');
    })
  });
});