define("admin/routes/four-oh-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    redirect() {
      const url = this.router.location.formatURL('/four-oh-three');
      if (window.location.pathname !== url) {
        return this.transitionTo('/four-oh-three');
      }
    }
  });
});