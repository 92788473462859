define("admin/components/mission-plan-map-feature", ["exports", "admin/data/shot-type/pattern"], function (_exports, _pattern) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['featureStyle'],
    patternOptions: _pattern.default,
    hasSelectedCatalogItem: false,
    supportedGeometry: Ember.computed('feature.object', function () {
      return this.get('feature.object').getGeometry().getType();
    }),
    catalogOptions: Ember.computed('package.catalog_items.[]', function () {
      return this.get('package.catalog_items').filter(item => {
        if (this.supportedGeometry === 'Polygon' && item.has_pano_shot_type) {
          return;
        }
        const supported_geometry = item.supported_geometry;
        return supported_geometry.includes(this.supportedGeometry);
      }).map(item => {
        const exists = this.get('feature.catalog_item_ids').find(catalog_id => catalog_id === item.id);
        return Ember.Object.create({
          item,
          selected: !!exists
        });
      });
    }),
    selectedPanoCatalogItem: Ember.computed('feature.catalog_item_ids.[]', function () {
      const item = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);
      return item ? item.item.panoShotType : null;
    }),
    deliverableOptions: Ember.computed('package.shot_list.shot_types', 'feature.shot_type_ids', function () {
      if (this.get('package.shot_list.shot_types') === undefined) return [];
      return this.get('package.shot_list.shot_types').filter(shot_type => {
        return this.get('feature.object').getGeometry().getType() === shot_type.supported_geometry;
      }).map(item => {
        const exists = this.get('feature.shot_type_ids').find(shot_type_id => shot_type_id === item.id);
        return Ember.Object.create({
          item,
          selected: !!exists
        });
      });
    }),
    enableDeliverableOptions: Ember.computed('feature.object', function () {
      return ['Polygon', 'Point'].indexOf(this.get('feature.object').getGeometry().getType()) >= 0;
    }),
    panoDeliverableAttributes: Ember.computed('package.panoramaShotType.id', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.get('feature.shot_type_attributes')[this.get('package.panoramaShotType.id')];
    }),
    orthoDeliverableAttributes: Ember.computed('package.orthoShotType.id', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.get('feature.shot_type_attributes')[this.get('package.orthoShotType.id')];
    }),
    panoCatalogItemAttributes: Ember.computed('package.orthoShotType.id', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.get('feature.shot_type_attributes')[this.get('selectedPanoCatalogItem.id')];
    }),
    imageSeriesDeliverableAttributes: Ember.computed('package.imageSeriesShotType.id', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.get('feature.shot_type_attributes')[this.get('package.imageSeriesShotType.id')];
    }),
    orbitDeliverableAttributes: Ember.computed('package.orbitShotType.id', 'feature.{shot_type_attributes.[].altitude,shot_type_ids.[]}', function () {
      return this.get('feature.shot_type_attributes')[this.get('package.orbitShotType.id')];
    }),
    formattedArea: Ember.computed('feature.area', function () {
      return this.parentView.formatArea(this.get('feature.area'));
    }),
    formattedLength: Ember.computed('feature.lineLength', function () {
      return this.parentView.formatLength(this.get('feature.lineLength'));
    }),
    formattedAltitude: Ember.computed('panoDeliverableAttributes.altitude', function () {
      return this.parentView.formatAltitude(this.get('panoDeliverableAttributes.altitude'));
    }),
    namingIndexChanged: Ember.observer('feature.namingIndex', function () {
      return this.get('feature.object').setProperty('namingIndex', this.get('feature.namingIndex'));
    }),
    shotTypeAttributesChanged: Ember.observer('feature.shot_type_attributes', function () {
      return this.get('feature.object').setProperty('shot_type_attributes', this.get('feature.shot_type_attributes'));
    }),
    radiusChanged: Ember.observer('orbitDeliverableAttributes.shot_radius', function () {
      if (this.get('orbitDeliverableAttributes.shot_radius')) {
        this.onShotRadiusUpdated(this.get('feature'), this.get('orbitDeliverableAttributes.shot_radius'));
      }
      return false;
    }),
    updateIconName: Ember.computed('feature.{iconName,shot_type_ids}', function () {
      if (this.orbitDeliverableAttributes) {
        return 'orbit';
      } else if (this.panoDeliverableAttributes) {
        return 'panorama-black';
      } else {
        return this.get('feature.iconName');
      }
    }),
    imageBorderStyle: Ember.computed('feature.color', function () {
      return 'border-left: 5px solid' + this.get('feature.color') + ';'.htmlSafe();
    }),
    didInsertElement() {
      return Ember.$("input[type='text']").focus();
    },
    saveMapFeature: function () {
      this.feature.object.toGeoJson(json => {
        this.feature.map_feature.set('geometry', json.geometry);
        this.feature.map_feature.set('properties', json.properties);
        return this.feature.map_feature.save().then(response => {
          this.feature.set('map_feature', response);
        }).catch(error => {
          console.error(error);
          alert("There was an error saving: \n\n" + error.errors.mapBy('detail').join(', '));
        });
      });
    },
    actions: {
      delete(feature) {
        return this.removeMapFeature(feature);
      },
      save() {
        this.toggleProperty('editMode');
        this.saveMapFeature();
      },
      toggleEditMode() {
        this.parentView.disableFeatures();
        if (!this.get('editMode')) {
          return this.parentView.enableFeature(this.get('feature'));
        }
      },
      removeCatalogItem(id) {
        this.get('catalogOptions').find(option => {
          if (option.item.id === id) {
            option.set('selected', false);
            return true;
          }
        });
        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);
        if (!hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPointIcon(this.get('feature'));
          this.set('feature.iconName', 'point');
        }
        this.set('feature.catalog_item_ids', this.get('feature.catalog_item_ids').filter(catalog_id => catalog_id !== id));
        this.set('feature.map_feature.properties.catalog_item_ids', this.get('feature.catalog_item_ids'));
        this.set('feature.map_feature.hasDirtyAttributes', true);
        return this.get('feature.object').setProperty('catalog_item_ids', this.get('feature.catalog_item_ids'));
      },
      addCatalogItem(target) {
        if (!this.get('feature.catalog_item_ids').find(catalog_id => catalog_id === target.item.get('id'))) {
          this.get('feature.catalog_item_ids').pushObject(target.item.get('id'));
          if (target.item.panoShotType) {
            this.get('feature.shot_type_attributes')[target.item.panoShotType.get('id')] = {
              altitude: target.item.panoShotType.get('altitude') || 100
            };
          }
        }
        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);
        if (hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPanoramaIcon(this.get('feature'));
          this.set('feature.iconName', 'panorama');
        }
        this.set('feature.map_feature.properties.catalog_item_ids', this.get('feature.catalog_item_ids'));
        this.set('feature.map_feature.hasDirtyAttributes', true);
        return this.get('feature.object').setProperty('catalog_item_ids', this.get('feature.catalog_item_ids'));
      },
      removeShotType(id) {
        this.get('deliverableOptions').find(option => {
          if (option.item.id === id) {
            option.set('selected', false);
            return true;
          }
        });
        this.set('feature.shot_type_ids', this.get('feature.shot_type_ids').filter(shot_type_id => shot_type_id !== id));
        delete this.get('feature.shot_type_attributes')[id];
        const hasShotTypeSelected = this.get('deliverableOptions').find(option => option.selected);
        if (!hasShotTypeSelected) {
          this.get('feature.object').setProperty('feature_type', 'point_of_interest');
          this.set('feature.iconName', 'point');
          this.changeToPointIcon(this.get('feature'));
        }
        this.get('feature.object').setProperty('shot_type_ids', this.get('feature.shot_type_ids'));
        this.get('feature.object').setProperty('shot_type_attributes', this.get('feature.shot_type_attributes'));
      },
      addShotType(target) {
        let feature_type;
        if (!this.get('feature.shot_type_ids').find(shot_type_id => shot_type_id === target.item.get('id'))) {
          let shotTypeAttrs = {};
          if (target.item.get('id') === this.get('package.orthoShotType.id')) {
            shotTypeAttrs = {
              altitude: this.get('package.orthoShotType.altitude') || 400,
              front_overlap: this.get('package.orthoShotType.front_overlap') || 75,
              side_overlap: this.get('package.orthoShotType.side_overlap') || 70,
              pattern: this.get('package.orthoShotType.pattern') || 'lawn_mower',
              gimbal_angle: this.get('package.orthoShotType.gimbal_angle') || -90
            };
          } else if (target.item.get('id') === this.get('package.imageSeriesShotType.id')) {
            shotTypeAttrs = {
              altitude: this.get('package.imageSeriesShotType.altitude') || 150,
              gimbal_angle: this.get('package.imageSeriesShotType.gimbal_angle') || -30
            };
          } else if (target.item.get('id') === this.get('package.panoramaShotType.id')) {
            shotTypeAttrs = {
              altitude: this.get('package.panoramaShotType.altitude') || 100
            };
            feature_type = 'panorama';
          } else if (target.item.get('id') === this.get('package.orbitShotType.id')) {
            shotTypeAttrs = {
              altitude: this.get('package.orbitShotType.altitude') || 100,
              shot_radius: this.get('package.orbitShotType.shot_radius') || 50,
              gimbal_angle: this.get('package.orbitShotType.gimbal_angle') || -35
            };
            feature_type = 'orbit';
          } else {
            shotTypeAttrs = {};
          }
          this.get('feature.shot_type_attributes')[target.item.get('id')] = shotTypeAttrs;
          this.get('feature.shot_type_ids').pushObject(target.item.get('id'));
        }
        if (feature_type) {
          this.get('feature.object').setProperty('feature_type', feature_type);
          if (feature_type === 'panorama') {
            this.set('feature.iconName', 'panorama-black');
            this.changeToPanoramaIcon(this.get('feature'));
          }
        }
        this.get('feature.object').setProperty('shot_type_ids', this.get('feature.shot_type_ids'));
        this.get('feature.object').setProperty('shot_type_attributes', this.get('feature.shot_type_attributes'));
      }
    }
  });
});