define("admin/components/photo-swipe", ["exports", "ember-photoswipe/components/photo-swipe"], function (_exports, _photoSwipe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _photoSwipe.default.extend({
    // store: Ember.inject.service(),
    itemProperties: ['src', 'msrc', 'w', 'h', 'title', 'record'],
    currentRecord: null,
    classNames: ['photo-swipe'],
    onAfterChange: function (pswp) {
      // Can't seem to get access to ember here
      // Ember.$.get('currentRecord').record == undefined
      var currentRecord = pswp.getItemAt(pswp.getCurrentIndex()).record;
      var metaSection = pswp.template.getElementsByClassName('pswp-meta')[0];
      if (metaSection) {
        metaSection.getElementsByClassName('pswp-meta-taken-at')[0].textContent = currentRecord.get('formattedTakenAt') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-make')[0].textContent = currentRecord.get('camera_make') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-model')[0].textContent = currentRecord.get('camera_model') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-exposure-time')[0].textContent = currentRecord.get('exposure_time') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-exposure-bias')[0].textContent = currentRecord.get('exposure_bias') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-auto-white-balance')[0].textContent = currentRecord.get('autoWhiteBalance') || 'Unknown';
        metaSection.getElementsByClassName('pswp-meta-dimensions')[0].textContent = currentRecord.get('dimensions') || 'Unknown';

        // self._showHideWarning(currentRecord, 'missing_gps_info', 'gps', metaSection)
        if (currentRecord.get('missing_gps_info')) {
          metaSection.getElementsByClassName('gps')[0].classList.remove('hidden');
        } else {
          metaSection.getElementsByClassName('gps')[0].classList.add('hidden');
        }
        if (currentRecord.get('is_low_resolution')) {
          metaSection.getElementsByClassName('resolution')[0].classList.remove('hidden');
        } else {
          metaSection.getElementsByClassName('resolution')[0].classList.add('hidden');
        }
      }
    },
    open(actionOptions) {
      this._super(actionOptions);
      return this.onAfterChange(this.get('pswp'));
    }
  });
});