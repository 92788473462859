define("admin/models/state", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    iso_code: (0, _model.attr)('string'),
    country: (0, _model.belongsTo)('country'),
    subscriptionOffers: (0, _model.hasMany)('subscription-offer'),
    formattedIsoCode: Ember.computed('iso_code', function () {
      return this.get('iso_code').replace(`${this.get('country.iso_code')}-`, '');
    })
  });
});