define("admin/helpers/link-to-react-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkToReactApp = linkToReactApp;
  function linkToReactApp(params) {
    if (window.location.origin.includes('localhost')) {
      // Modifying origin to be 'localhost:4204' which points to React app port on localhost
      return `http://localhost:4204/react${params}`;
    }
    return `${window.location.origin}/react${params}`; // For non localhost - same origin and port for both apps
  }
  var _default = _exports.default = Ember.Helper.helper(linkToReactApp);
});