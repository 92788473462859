define("admin/models/training-package", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    price: _emberData.default.attr('string'),
    // currency not yet returned from API
    currency: _emberData.default.belongsTo('currency'),
    selectOption: Ember.computed('price', 'name', function () {
      return this.get('name') + ' ' + (this.get('currency')?.symbol || '$') + this.get('price') / 100 + ' ' + (this.get('currency')?.code || 'USD');
    })
  });
});