define("admin/templates/components/onboarding/pilot-device-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U6fmuCOF",
    "block": "{\"symbols\":[\"device\"],\"statements\":[[10,\"div\"],[14,0,\"panel-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-back\"],[12],[2,\"\\n    \"],[11,\"a\"],[4,[38,4],[[32,0],\"setPanel\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-chevron-left\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"panel-title\"],[12],[2,\"Back\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-clear\"],[12],[2,\"\\n    \"],[11,\"a\"],[4,[38,4],[[32,0],\"clear\"],null],[12],[2,\"Clear\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-section-title\"],[12],[2,\"\\n    Accepted Devices\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"selections\",\"model\",\"checked\"],[[35,2],[32,1],[30,[36,1],[[35,0],[32,1,[\"id\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"deviceIds\",\"includes\",\"selectedDevices\",\"checkbox-item\",\"action\",\"devices\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/onboarding/pilot-device-filter.hbs"
    }
  });
});