define("admin/components/input-checkbox-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputCheckboxInplaceEditComponent;
  InputCheckboxInplaceEditComponent = Ember.Component.extend({
    classNames: ['inline-info'],
    value: null,
    saveOnChange: function () {
      return this.send('save');
    }.observes('value'),
    actions: {
      save: function () {
        var _this;
        _this = this;
        return this.get('model').save().then(function (response) {}, function (response) {
          return _this.$('input').addClass('error');
        });
      }
    }
  });
  var _default = _exports.default = InputCheckboxInplaceEditComponent;
});