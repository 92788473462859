define("admin/components/filter-missions", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * decaffeinate suggestions:
   * DS101: Remove unnecessary use of Array.from
   * DS102: Remove unnecessary code created because of implicit returns
   * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
   */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['filter-missions-sidebar'],
    sessionAccount: Ember.inject.service(),
    status: null,
    bulkUpdateEnabled: false,
    latitude: null,
    longitude: null,
    distance: null,
    distanceFilter: false,
    assets_late: false,
    on_hold: false,
    reshoot: false,
    include_client_ids: null,
    exclude_client_ids: null,
    selectedClients: Ember.A([]),
    statuses: null,
    countries: null,
    country_codes: Ember.A([]),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    // eslint-disable-next-line ember/no-on-calls-in-components
    initPage: Ember.on('init', function () {
      this.set('include', true);
      return Ember.run.schedule('afterRender', this, function () {
        return this.setupClientFilter();
      });
    }),
    showStatus: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath').includes('missions');
    }),
    showLocation: Ember.computed('router.currentPath', function () {
      if (this.get('router.currentPath') === 'pilots.index') {
        // eslint-disable-next-line ember/no-side-effects
        this.set('locationTitle', 'Pilot Location');
      } else {
        // eslint-disable-next-line ember/no-side-effects
        this.set('locationTitle', 'Mission Location');
      }
      return this.get('router.currentPath').includes('missions') || this.get('router.currentPath') === 'pilots.index';
    }),
    showPilotStatuses: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath') === 'pilots.index';
    }),
    setupClientFilter() {
      let clients;
      if (this.get('exclude_client_ids')) {
        clients = this.get('exclude_client_ids');
        this.set('include', false);
      } else {
        clients = this.get('include_client_ids');
      }
      if (clients) {
        const clientArray = clients.split(',');
        return Array.from(clientArray).map(client => this.requestClients(client).then(response => {
          client = response.data[0];
          const formedClient = {
            id: client.id,
            companyOrFullName: client.attributes.company_name || client.attributes.first_name + ' ' + client.attributes.last_name
          };
          return this.set('selectedClients', this.get('selectedClients').concat([formedClient]));
        }));
      }
    },
    requestClients(client) {
      this.set('clientEmailSearch', client.includes('@'));
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/clients?quick_search=true&q=` + client,
        headers: this.get('sessionAccount.headers'),
        type: 'GET',
        dataType: 'json'
      });
    },
    actions: {
      changeCountryCodesAction(countryCodes) {
        return this.changeCountryCodes(countryCodes);
      },
      updateAssetsLate() {
        this.set('assets_late', !this.get('assets_late'));
      },
      updateReshoot() {
        this.set('reshoot', !this.get('reshoot'));
      },
      updateOnHold() {
        this.set('on_hold', !this.get('on_hold'));
      },
      updateApprovedPilots() {
        this.set('approvedPilots', !this.get('approvedPilots'));
      },
      toggleFilter() {
        return this.set('hideFilter', true);
      },
      toggleInclude() {
        return this.set('include', !this.get('include'));
      },
      updateClientAutocomplete() {
        if (this.get('client')) {
          const requestFunc = function () {
            return this.requestClients(this.get('client')).then(response => {
              const list = [];
              for (let client of Array.from(response.data)) {
                var option;
                const companyOrFullName = client.attributes.company_name || client.attributes.first_name + ' ' + client.attributes.last_name;
                if (this.get('clientEmailSearch')) {
                  option = client.attributes.email;
                } else {
                  option = companyOrFullName;
                }
                const formedClient = {
                  id: client.id,
                  selectableOption: option,
                  companyOrFullName
                };
                list.push(formedClient);
              }
              return this.set('clientAutocompleteList', list);
            });
          };
          return Ember.run.debounce(this, requestFunc, 500);
        }
      },
      selectClient(client) {
        this.set('selectedClients', this.get('selectedClients').concat([client]));
        this.set('clientAutocompleteList', []);
        return this.set('client', null);
      },
      deselectClient(client) {
        return this.set('selectedClients', this.get('selectedClients').filter(selected => client !== selected));
      },
      filterClients() {
        let selected_ids = '';
        for (let client of Array.from(this.get('selectedClients'))) {
          selected_ids += ',';
          selected_ids += client.id;
        }
        selected_ids = selected_ids.substring(1);
        if (this.get('include')) {
          this.set('include_client_ids', selected_ids);
          return this.set('exclude_client_ids', '');
        } else {
          this.set('exclude_client_ids', selected_ids);
          return this.set('include_client_ids', '');
        }
      }
    }
  });
});