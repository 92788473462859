define("admin/adapters/package-flight-app", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PackageFlightAppAdapter;
  PackageFlightAppAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    urlTemplate: '{+namespace}/packages/{packageId}/package_flight_apps{/id}',
    urlSegments: {
      packageId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('package', {
          id: true
        });
      }
    }
  });
  var _default = _exports.default = PackageFlightAppAdapter;
});