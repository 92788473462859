define("admin/models/activity-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('date'),
    action: _emberData.default.attr('string'),
    user_name: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    details: _emberData.default.attr('string'),
    logable: _emberData.default.belongsTo('logable')
  });
});