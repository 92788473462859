define("admin/adapters/ortho-photo-map", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/admin/missions',
    urlTemplate: '{+namespace}/{missionId}/ortho_photo_maps{/id}',
    queryUrlTemplate: '{+namespace}/{missionId}/ortho_photo_maps',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    urlSegments: {
      missionId(_type, _id, snapshot, query) {
        if (query == undefined) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return query.missionId;
        }
      }
    },
    deleteDSMOrtho: (missionId, orthoId, headers) => {
      return (void 0).ajax({
        url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/ortho_photo_maps/${orthoId}/delete_dsm`,
        type: 'POST',
        dataType: 'json',
        headers
      });
    }
  });
});