define("admin/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "admin/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _devise.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/v1/authenticate',
    resourceName: 'admin',
    makeRequest(data, options) {
      if (options == null) {
        options = {};
      }
      Ember.merge(options, {
        data,
        headers: {
          'X-API-TOKEN': _environment.default.api.app_token,
          'accept': 'application/json',
          'content-type': 'application/json'
        }
      });
      return this._super(data, options);
    }
  });
});