define("admin/templates/waivers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nmaPfBXy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-header clearfix edit-waiver\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"pull-left\"],[12],[2,\"Edit Airport Waiver\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"waivers form-group form-group-iconized\"],[12],[2,\"\\n    \"],[11,\"form\"],[24,0,\"form\"],[4,[38,1],[[32,0],\"save\",[35,0,[\"waiver\"]]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"waiver\",\"saveAction\"],[[35,0,[\"waiver\"]],\"save\"]]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"waiver-actions buttons-block\"],[12],[2,\"\\n          \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"class\"],[\"submit\",\"SAVE\",\"btn btn-primary waivers-action\"]]]],[2,\"\\n          \"],[6,[37,4],null,[[\"class\",\"route\"],[\"btn btn-secondary cancel-button waivers-action waivers-action--cancel\",\"waivers.index\"]],[[\"default\"],[{\"statements\":[[2,\"CANCEL\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"waiver-fields\",\"input\",\"link-to\"]}",
    "meta": {
      "moduleName": "admin/templates/waivers/edit.hbs"
    }
  });
});