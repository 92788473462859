define("admin/components/mission-asset-map", ["exports", "admin/data/map_features", "admin/mixins/google-map-init-mixin"], function (_exports, _map_features, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    featureOptions: _map_features.default.featureOptions,
    featureColors: _map_features.default.featureColors,
    disabledFeatureOptions: _map_features.default.disabledFeatureOptions,
    colorIndex: 0,
    mapSelector: 'asset-map',
    didInsertElement(args) {
      this._super(args);
      return this.addObserver('mapImageMarkers', this, 'refreshMap');
    },
    refreshMap() {
      this.set('colorindex', 0);
      this.clearMarkers();
      return this.initMap();
    },
    mapOptions: function () {
      return {
        zoom: 17,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId['HYBRID'],
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        draggable: true,
        disableDoubleClickZoom: false
      };
    },
    initMap() {
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById(this.get('mapSelector')), this.mapOptions());
      }
      if (this.get('mission.location.latitude') && this.get('mission.location.longitude')) {
        this.map.setCenter(this.buildLatLng(this.get('mission.location.latitude'), this.get('mission.location.longitude')));
      } else {
        this.map.setCenter(new google.maps.LatLng(37.2350, -115.8111));
      }
      const disabledOptions = this.get('disabledFeatureOptions');
      delete disabledOptions['icon'];
      this.map.data.addListener('addfeature', event => {
        const options = this.lookUpMapOptions(event.feature);
        Object.assign(options.defaultOptions, disabledOptions);
        this.map.data.overrideStyle(event.feature, options.defaultOptions);
        return this.map.data.overrideStyle(event.feature, {
          fillColor: options.mapColor,
          strokeColor: options.mapColor
        });
      });
      this.loadMissionCenterPin();
      this.loadPropertyPolygon();
      this.loadImagePins();
      return this.fitAllFeatures();
    },
    lookUpMapOptions(feature) {
      let mapColor;
      const geometryType = feature.getGeometry().getType();
      const isNoFlyZone = feature.getProperty('type') === 'No Fly Zone' || feature.getProperty('no_fly_zone');
      const hasCatalogItems = this.hasCatalogItem(feature.getProperty('catalog_item_ids'));
      const options = (() => {
        switch (geometryType) {
          case 'Polygon':
            if (isNoFlyZone) {
              return this.get('featureOptions').NOFLYZONE;
            } else {
              return this.get('featureOptions').PROPERTYAREA;
            }
          case 'LineString':
            return this.get('featureOptions').LINE;
          case 'Point':
            if (feature.getProperty('feature_type') === 'orbit') {
              return this.get('featureOptions').ORBIT;
            } else if (feature.getProperty('feature_type') === 'panorama' || hasCatalogItems) {
              return this.get('featureOptions').PANORAMA;
            } else {
              return this.get('featureOptions').POINTOFINTEREST;
            }
        }
      })();
      if (isNoFlyZone) {
        mapColor = '#F26649';
      } else if (geometryType === 'LineString' || geometryType === 'Polygon') {
        mapColor = this.getNextColor();
      }
      options.mapColor = mapColor;
      return options;
    },
    getNextColor() {
      this.set('colorIndex', this.get('colorIndex') + 1);
      return this.get('featureColors')[this.get('colorIndex') % 5];
    },
    loadMissionCenterPin() {
      if (this.get('mission.location.latitude') && this.get('mission.location.longitude')) {
        const latLng = this.buildLatLng(this.get('mission.location.latitude'), this.get('mission.location.longitude'));
        return new google.maps.Marker({
          map: this.map,
          icon: {
            url: '/assets/images/property_pin.svg',
            scaledSize: new google.maps.Size(50, 80),
            origin: new google.maps.Point(0, 0)
          },
          position: latLng
        });
      }
    },
    loadPropertyPolygon() {
      if (this.get('mission.map_features.length')) {
        try {
          const geoProperties = this.geoJsonConverter.toGeoJson(this.get('mission.map_features'));
          return this.map.data.addGeoJson(geoProperties);
        } catch (error) {
          return console.log('property data malformed, cannot load image map');
        }
      }
    },
    loadImagePins() {
      const markers = [];
      const total = this.get('mapImageMarkers.length');
      if (total && total > 0) {
        this.get('mapImageMarkers').forEach(image => {
          if (image.get('gps_latitude') && image.get('gps_longitude') && image.get('gps_latitude') !== '0' && image.get('gps_longitude') !== '0') {
            return markers.push(new google.maps.Marker({
              icon: this.markerIcon(total),
              map: this.map,
              position: this.buildLatLng(image.get('gps_latitude'), image.get('gps_longitude'))
            }));
          }
        });
      }
      return this.set('markers', markers);
    },
    markerIcon(total) {
      if (total < 250) {
        return {
          url: '/assets/images/gps-map-marker.svg',
          scaledSize: new google.maps.Size(18, 18),
          origin: new google.maps.Point(0, 0)
        };
      } else {
        return {
          url: '/assets/images/gps-map-marker_sml.png'
        };
      }
    },
    clearMarkers() {
      if (this.get('markers')) {
        this.get('markers').forEach(function (marker) {
          marker.setPosition(null);
          marker.setMap(null);
          return marker = null;
        });
        return this.set('markers', []);
      }
    },
    fitAllFeatures() {
      const bounds = new google.maps.LatLngBounds();
      if (this.get('mission.map_features.length')) {
        this.get('mission.map_features').forEach(feature => {
          if (feature.geometry.type === 'Polygon') {
            return feature.geometry.coordinates.forEach(path => {
              return path.forEach(latLng => {
                return bounds.extend(this.buildLatLng(latLng[1], latLng[0]));
              });
            });
          } else if (feature.geometry.type === 'Point') {
            const latLng = feature.geometry.coordinates;
            return bounds.extend(this.buildLatLng(latLng[1], latLng[0]));
          } else if (feature.geometry.type === 'LineString') {
            return feature.geometry.coordinates.forEach(latLng => {
              return bounds.extend(this.buildLatLng(latLng[1], latLng[0]));
            });
          }
        });
      }
      if (this.get('markers')) {
        this.get('markers').forEach(marker => bounds.extend(marker.position));
      }
      if (!bounds.isEmpty()) {
        return this.map.fitBounds(bounds);
      }
    },
    buildLatLng(lat, lng) {
      lat = Number(lat);
      lng = Number(lng);
      return new google.maps.LatLng({
        lat,
        lng
      });
    },
    hasCatalogItem(catalog_item_ids) {
      if (!catalog_item_ids) return false;
      const catalog_item = this.get('mission.package.catalog_items').find(item => catalog_item_ids.includes(item.id));
      return catalog_item?.has_pano_shot_type;
    }
  });
});