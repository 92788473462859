define("admin/models/badge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    internal_name: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    auto_dispatch_batch_size: _emberData.default.attr('number'),
    auto_dispatch_enabled: _emberData.default.attr('boolean'),
    required_drones: _emberData.default.hasMany('drone', {
      async: false
    }),
    required_drone_cameras: _emberData.default.hasMany('drone_camera', {
      async: false
    }),
    required_devices: _emberData.default.hasMany('device', {
      async: false
    }),
    required_pilot_equipments: _emberData.default.hasMany('pilot_equipment', {
      async: false
    }),
    required_pilot_licenses: _emberData.default.hasMany('license', {
      async: false
    }),
    badge_dispatch_zones: _emberData.default.hasMany('dispatch_zone', {
      async: false,
      serialize: 'object'
    }),
    required_program: _emberData.default.belongsTo('mindflash_series', {
      async: false
    }),
    mindflash_series_name: Ember.computed('required_program', function () {
      return this.get('required_program.name');
    }),
    required_training_package: _emberData.default.belongsTo('training_package', {
      async: false
    }),
    training_package_name: Ember.computed('required_training_package', function () {
      return this.get('required_training_package.name');
    }),
    required_checkr_package: _emberData.default.belongsTo('checkr_package', {
      async: false
    }),
    checkr_package_name: Ember.computed('required_checkr_package', function () {
      return this.get('required_checkr_package.name');
    }),
    has_requirements: Ember.computed('training_package_name', 'mindflash_series_name', 'checkr_package_name', function () {
      return !!this.get('training_package_name') || !!this.get('mindflash_series_name') || !!this.get('checkr_package_name');
    }),
    required_drones_and_cameras: Ember.computed('required_drones', 'required_drone_cameras', function () {
      return this.get('required_drones').toArray().concat(this.get('required_drone_cameras').toArray());
    })
  });
});