define("admin/components/modal-dialog-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ModalDialogCustomComponent;
  ModalDialogCustomComponent = Ember.Component.extend({
    didInsertElement: function () {
      window.scrollTo(0, 0);
      return Ember.$('body').css('overflow', 'hidden');
    },
    willDestroyElement: function () {
      return Ember.$('body').css('overflow', 'visible');
    },
    actions: {
      close: function () {
        return this.sendAction();
      }
    }
  });
  var _default = _exports.default = ModalDialogCustomComponent;
});