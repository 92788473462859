define("admin/services/google-init", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    loading: false,
    loaded: false,
    loadMap: function () {
      if (this.loaded || this.loading) {
        return;
      }
      this.set('loading', true);
      this.getScript();
    },
    getScript: function () {
      Ember.$.getScript(`https://maps.googleapis.com/maps/api/js?v=3&key=${_environment.default.googleMapsKey}&signed_in=false&libraries=places,drawing,geometry`, () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.set('loaded', true);
        this.set('loading', false);
      });
    }
  });
});