define("admin/components/reusable/pill-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: [],
    actions: {
      cancel: function () {
        this.get('onCancel')();
      }
    }
  });
});