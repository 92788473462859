define("admin/templates/components/checkbox-aria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fNPpX0AC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[34,6]]]],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],null,[[\"class\",\"checked\",\"disabled\"],[[35,4],true,true]],[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,5],null,[[\"class\",\"checked\"],[[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"wrap\",\"if\",\"checked\",\"formattedLabel\",\"aria-checkbox\",\"containerClasses\",\"allSelected\"]}",
    "meta": {
      "moduleName": "admin/templates/components/checkbox-aria.hbs"
    }
  });
});