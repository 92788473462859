define("admin/components/cancel-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      close() {
        return this.get('close')();
      },
      setReason(value) {
        return this.set('reason_id', value);
      },
      cancel(model) {
        if (!$('.form-horizontal select').val()) {
          $($('.error-message')[0]).show();
          return;
        } else {
          $($('.error-message')[0]).hide();
        }
        const data = {
          'cancellation_reason_id': this.get('reason_id'),
          'notes': this.get('reasonDetails')
        };
        this.get('close')();
        return this.action(model, data);
      }
    }
  });
});