define("admin/routes/shot_lists/clone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShotListsCloneRoute;
  ShotListsCloneRoute = Ember.Route.extend({
    model: function (params) {
      return Ember.RSVP.hash({
        shotListToClone: this.store.findRecord('shot_list', params.shot_list_id),
        shot_types: this.store.query('shot_type', {})
      });
    },
    setupController: function (controller, model) {
      var i, len, ref, results, shot;
      this._super(controller, model);
      model.shot_list = this.store.createRecord('shot_list', model.shot_listToClone.serialize().data.attributes);
      ref = model.shot_listToClone.get('shots').toArray();
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        shot = ref[i];
        results.push(model.shot_list.get('shots').createRecord({
          shot_type: shot.get('shot_type'),
          instructions: shot.get('instructions'),
          origin_type: shot.get('origin_type')
        }));
      }
      return results;
    }
  });
  var _default = _exports.default = ShotListsCloneRoute;
});