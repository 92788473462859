define("admin/components/db-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    inputId: Ember.computed('', function () {
      return `db-datepicker-${this.elementId}`;
    }),
    disabled: false,
    _opened: false,
    _defaultDatepickerOptions: {
      collapse: true,
      focusOnShow: false,
      viewMode: 'days',
      format: 'MM/DD/YY',
      useCurrent: false,
      widgetPositioning: {
        horizontal: 'auto',
        vertical: 'auto'
      },
      icons: {
        date: 'fa fa-calendar',
        previous: 'fa fa-angle-left',
        next: 'fa fa-angle-right',
        close: 'icon-Xmark'
      }
    },
    datepickerOptions: {},
    didInsertElement() {
      const datepickerElement = this.$(`#${this.inputId}`);
      datepickerElement.datetimepicker(Ember.merge(this._defaultDatepickerOptions, this.datepickerOptions));
      datepickerElement.on('dp.change', this.action);
      datepickerElement.on('dp.show', () => {
        this.set('_opened', true);
      });
      datepickerElement.on('dp.hide', () => {
        this.set('_opened', false);
      });
    }
  });
});