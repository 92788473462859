define("admin/models/asset-inspection", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission')
  });
});