define("admin/components/mission-assets-buckets", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MissionShotlistAssetsComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['mission-assets-buckets'],
    allShotsExpanded: false,
    assetReportExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('asset_report') > -1;
    }),
    comprehensiveReportExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('comprehensive_report') > -1;
    }),
    reportBucketExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('composer_roof_report') > -1;
    }),
    zipBucketExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('zip_files') > -1;
    }),
    pointivoBucketsExists: Ember.computed('model.mission.buckets.[]', function () {
      const inBucketExists = this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('pointivo_in_zip') > -1;
      const outBucketExists = this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('pointivo_out_zip') > -1;
      return inBucketExists && outBucketExists;
    }),
    thermalBucketExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('thermal_roof_report') > -1;
    }),
    pavementBucketExists: Ember.computed('model.mission.buckets.[]', function () {
      return this.get('model.mission.buckets').getEach('bucket_type_slug').indexOf('pavement_report') > -1;
    }),
    isThumbnailView: Ember.computed('currentView', function () {
      return this.get('currentView') === 'thumbnail';
    }),
    isFullView: Ember.computed('currentView', function () {
      return this.get('currentView') === 'full';
    }),
    generateZipTitle: Ember.computed('model.mission.archived_at', function () {
      return this.get('model.mission.archived_at') ? 'Regenerate Zip File' : 'Generate Zip File';
    }),
    actions: {
      promoteAllAssets() {
        var request = this.get('model.mission').promoteAssets().then(() => {
          this.get('model.mission.images').setEach('source_type', 'edit');
          this.get('model.mission.videos').setEach('source_type', 'edit');
          this.get('model.mission.attachments').setEach('source_type', 'edit');
          this.get('model.mission.data_files').setEach('source_type', 'edit');
          this.get('model.mission.panoramas').setEach('source_type', 'edit');
          this.get('model.mission.ortho_photo_maps').setEach('source_type', 'edit');
        }, () => {
          alert("Something went wrong");
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      createReportBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'RGB Roof Report',
          bucket_type_slug: 'composer_roof_report'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      createZipBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'Zip Files',
          bucket_type_slug: 'zip_files'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      createAssetReportBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'Asset Report',
          bucket_type_slug: 'asset_report'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      createComprehensiveReportBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'Comprehensive Report',
          bucket_type_slug: 'comprehensive_report'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      createPointivoBuckets() {
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${this.get('model.mission.id')}/create_pointivo_buckets`,
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(Ember.run.bind(this, response => {
          this.get('model.mission').store.pushPayload(response);
        })).catch(() => {
          alert('Failed to create pointivo buckets');
        });
      },
      createPavementReportBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'Pavement Report',
          bucket_type_slug: 'pavement_report'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      createThermalReportBucket() {
        let bucket = this.get('store').createRecord('bucket', {
          name: 'Thermal Roof Report',
          bucket_type_slug: 'thermal_roof_report'
        });
        this.get('model.mission.buckets').addObject(bucket);
        bucket.save();
        return bucket;
      },
      addAsset(file, payload) {
        return this.onfileadd(file, payload);
      },
      removeAsset(file, payload) {
        return this.onfileremove(file, payload);
      },
      startUpload(uploader) {
        return this.onstartupload(uploader);
      },
      shareShareable(shareable, deferred) {
        return this.shareCreateAction(shareable, deferred);
      },
      addMarkersToMap(shot) {
        return this.addMarkersToMap(shot);
      },
      removeMarkersFromMap(shot) {
        return this.removeMarkersFromMap(shot);
      },
      reprocessShot(shot) {
        let url;
        if (shot.get('hasOrthos')) {
          if (!confirm('Delete ortho and restitch?')) {
            return;
          }
        } else {
          if (!confirm('Delete pano and restitch?')) {
            return;
          }
        }
        if (shot.get('hasOrthos')) {
          url = `${_environment.default.api.host}/v1/admin/missions/${shot.get('mission.id')}/shots/${shot.get('id')}/ortho/restart`;
        } else {
          url = `${_environment.default.api.host}/v1/admin/missions/${shot.get('mission.id')}/shots/${shot.get('id')}/panorama/restart`;
        }
        return Ember.$.ajax({
          url,
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function () {
          location.reload();
        }, function () {
          alert('Could not send request');
        });
      },
      toggleFullscreenOrthoModal(ortho) {
        return this.toggleFullscreenOrthoModalAction(ortho);
      },
      regenerateZip() {
        this.model.mission.regenerateZip();
      },
      expandAll() {
        this.set('allShotsExpanded', true);
        this.expandAllAction();
      },
      collapseAll() {
        this.set('allShotsExpanded', false);
        this.collapseAllAction();
      },
      openFullView(shot) {
        this.openFullViewAction(shot);
      },
      goToAsset(asset, index) {
        this.goToAsset(asset, index);
      },
      openSingleAssetView() {
        const asset = this.get('model.mission.shots.firstObject.images.firstObject');
        this.goToAsset(asset, 0);
      },
      publishToArcgis() {
        Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + this.get('model.mission.id') + "/publish_arcgis",
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function () {
          alert('Publishing assets to Esri');
        }).catch(function () {
          alert('Failed to publish images');
        });
      },
      copyArcgisLink() {
        let textArea = document.createElement('textarea');
        textArea.value = this.get('model.mission.arcgisLink');
        document.getElementById('esri_layer').appendChild(textArea);
        textArea.focus();
        textArea.select();
        const successful = document.execCommand('copy');
        if (successful) {
          this.set('linkCopied', true);
          Ember.run.later(() => {
            this.set('linkCopied', false);
          }, 3000);
        }
        document.getElementById('esri_layer').removeChild(textArea);
      }
    }
  });
  var _default = _exports.default = MissionShotlistAssetsComponent;
});