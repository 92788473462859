define("admin/controllers/missions/training-missions", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TrainingMissionsController;
  TrainingMissionsController = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    queryParams: ['status', 'q', 'lat', 'lon', 'distance'],
    sortProperties: ['created_on:desc'],
    sortedMissions: Ember.computed.sort('model.missions', 'sortProperties'),
    q: null,
    status: null,
    lat: null,
    lon: null,
    distance: null,
    activeCount: Ember.computed('model', 'model.missions.length', function () {
      return this.get('model.missions').length;
    }),
    testUserId: Ember.computed('ENV.test_user', function () {
      return "" + _environment.default.test_user;
    }),
    hideRejectedForm: function (id) {
      return Ember.$("#mission_" + id).hide();
    },
    actions: {
      sortBy: function (sortProperties) {
        return this.set('sortProperties', [sortProperties]);
      },
      clearQuery: function () {
        return this.set('q', null);
      },
      toggleFilter: function () {
        return this.set('hideFilter', false);
      }
    }
  });
  var _default = _exports.default = TrainingMissionsController;
});