define("admin/components/mission/pilot-onsite-communications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NOT_NEEDED = 'not_needed';
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['pilot-onsite-communications sub-tab-container__flex_column'],
    showContactNameAndPhone: Ember.computed('model.mission.onsite_contact.call_action', function () {
      const callAction = this.get('model.mission.onsite_contact.call_action');
      return callAction !== NOT_NEEDED;
    }),
    actions: {
      updateCallAction: function (newAction) {
        this.set('model.mission.onsite_contact.call_action', newAction);
      }
    }
  });
});