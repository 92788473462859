define("admin/components/capture-groups/payout-modal", ["exports", "admin/data/line-item-reasons", "ember-changeset", "ember-changeset-validations", "admin/validations/payout"], function (_exports, _lineItemReasons, _emberChangeset, _emberChangesetValidations, _payout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const [STRIPE_PROCESSOR, PAYPAL_PROCESSOR, OTHER_PROCESSOR] = ['Stripe', 'Paypal', 'Other'];
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    changeset: null,
    showLineItemForm: false,
    payout: null,
    showSiteInspectionsWarning: false,
    payoutLineItemReasonOptions: _lineItemReasons.payoutLineItemReasons,
    noAssignedPilots: Ember.computed.equal('captureGroup.pilots.length', 0),
    didReceiveAttrs() {
      this._super();
      this.initNewPayout();
      this.inputValidate();
    },
    defaultCurrency: Ember.computed('currencies.[]', function () {
      return this.get('currencies').findBy('code', 'USD');
    }),
    inputValidate() {
      this.set('payoutLineItemAddDisabled', !(this.get('lineItem.reason') && this.get('lineItem.amount') && this.get('lineItem.amount') > 0));
    },
    payoutLineItemAddDisabled: Ember.computed('lineItem.{reason,amount}', function () {
      return !(this.get('lineItem.reason') && this.get('lineItem.amount') && this.get('lineItem.amount') > 0);
    }),
    siteInspectionsWithoutLegalEntities: Ember.computed('captureGroup.site_inspections.[]', function () {
      return this.get('captureGroup.site_inspections').filter(site_inspection => !site_inspection.get('legal_entity')).map(site_inspection => site_inspection.get('id'));
    }),
    siteInspectionsPerLegalEntities: Ember.computed('captureGroup.site_inspections.[]', function () {
      const siteInspections = this.get('captureGroup.site_inspections');
      return this.get('legalEntities').reduce((hash, legalEntity) => {
        hash[legalEntity.get('name')] = siteInspections.filter(siteInspection => {
          return siteInspection.get('legal_entity.id') == legalEntity.get('id');
        }).length;
        return hash;
      }, {});
    }),
    payoutSaveDisabled: Ember.computed('payout.payout_line_items.length', function () {
      return this.noAssignedPilots || this.get('payout.payout_line_items.length') == 0;
    }),
    selectedPilot: Ember.computed('changeset.pilot.id', 'captureGroup.pilots.[]', function () {
      return this.get('captureGroup.pilots').find(pilot => pilot.id == this.get('changeset.pilot.id'));
    }),
    selectedCGPilot: Ember.computed('selectedPilot', function () {
      if (!this.get('selectedPilot')) return;
      return this.get('captureGroup.capture_group_pilots').find(cgPilot => cgPilot.get('user.id') == this.get('selectedPilot.id'));
    }),
    setPayoutPilot(pilotId) {
      const pilot = this.get('captureGroup.pilots').find(pilot => pilot.id == pilotId);
      this.changeset.set('pilot', pilot);
      this.changeset.set('currency', this.pilotCurrency(pilot));
      this.setPaymentMethod(this.defaultPaymentProcessor);
      if (this.get('lineItem')) {
        this.set('lineItem.amount', this.defaultLineItemAmount(this.get('lineItem.reason')));
      }
    },
    setPaymentMethod(paymentProcessor) {
      if (paymentProcessor === OTHER_PROCESSOR || paymentProcessor === PAYPAL_PROCESSOR) {
        this.changeset.set('payment_processor', paymentProcessor);
        this.changeset.set('legal_entity', null);
      } else {
        this.changeset.set('payment_processor', STRIPE_PROCESSOR);
        this.changeset.set('legal_entity', this.get('legalEntities').find(legal_entity => legal_entity.slug === paymentProcessor));
      }
      this.changeset.set('payment_processor_value', paymentProcessor);
    },
    defaultLineItemAmount(reason) {
      const captureGroupPilot = this.get('captureGroup.capture_group_pilots').find(captureGroupPilot => captureGroupPilot.user.id === this.changeset.get('pilot.id'));
      if (!captureGroupPilot) return 0;
      let value = 0;
      if (reason === 'labor' && captureGroupPilot.estimated_labor_payout_type === 'per_asset') {
        const missionsCount = captureGroupPilot.user.completed_missions_count || 0;
        value = captureGroupPilot.estimated_labor_payout_amount * missionsCount;
      } else if (reason === 'mobilization') {
        value = captureGroupPilot.estimated_mobilization_payout;
      }
      return value;
    },
    initLineItem() {
      const lineItemsCount = this.get('payout.payout_line_items.length');
      const initialReasonIndex = lineItemsCount >= _lineItemReasons.payoutLineItemReasons.length ? 0 : lineItemsCount % _lineItemReasons.payoutLineItemReasons.length;
      const initialReason = _lineItemReasons.payoutLineItemReasons[initialReasonIndex]['value'];
      this.set('lineItem', this.store.createRecord('payout_line_item', {
        saved_payoutable_type: 'capture_groups',
        saved_payoutable_id: this.get('captureGroup.id'),
        reason: initialReason,
        amount: this.defaultLineItemAmount(initialReason)
      }));
      this.inputValidate();
    },
    clearLineItem() {
      this.set('lineItem', null);
      this.set('showLineItemForm', false);
    },
    addLineItem() {
      this.get('payout.payout_line_items').addObject(this.get('lineItem'));
      this.set('payout.amount', (this.get('payout.amount') || 0) + this.get('lineItem.amount'));
      this.clearLineItem();
    },
    paymentProcessorOptions: Ember.computed('changeset.pilot.id', function () {
      const otherOption = {
        value: OTHER_PROCESSOR,
        label: OTHER_PROCESSOR
      };
      if (!this.changeset) return [otherOption];
      const options = this.get('legalEntities').map(legalEntity => ({
        value: legalEntity.slug,
        label: `Stripe (${legalEntity.name})`
      }));
      // Show Paypal option for pilots who had filled `paypal_email` field
      const pilot = this.get('captureGroup.pilots').find(pilot => pilot.id == this.get('changeset.pilot.id'));
      if (pilot.get('paypal_email')) {
        options.push({
          value: PAYPAL_PROCESSOR,
          label: PAYPAL_PROCESSOR
        });
      }
      options.push(otherOption);
      return options;
    }),
    defaultPaymentProcessorOption: Ember.computed('captureGroup.legal_entity', function () {
      const legalEntity = this.get('captureGroup.legal_entity');
      return this.paymentProcessorOptions.find(o => o.value === legalEntity?.slug) || this.paymentProcessorOptions.at(-1);
    }),
    defaultPaymentProcessor: Ember.computed('defaultPaymentProcessorOption', function () {
      return this.defaultPaymentProcessorOption.value === OTHER_PROCESSOR ? OTHER_PROCESSOR : STRIPE_PROCESSOR;
    }),
    initNewPayout() {
      const selectedPilot = this.get('captureGroup.pilots.firstObject');
      this.set('payout', this.store.createRecord('payout', {
        payout_to: 'pilot',
        saved_payoutable_type: 'capture_groups',
        saved_payoutable_id: this.get('captureGroup.id'),
        pilot: selectedPilot,
        currency: this.pilotCurrency(selectedPilot),
        legal_entity: this.get('legalEntities').find(legal_entity => legal_entity.slug === this.defaultPaymentProcessorOption.value),
        payment_processor: this.defaultPaymentProcessor,
        payment_processor_value: this.defaultPaymentProcessorOption.value,
        amount: 0
      }));
      this.set('changeset', new _emberChangeset.default(this.get('payout'), (0, _emberChangesetValidations.default)(_payout.default), _payout.default));
    },
    pilotCurrency(pilot) {
      const captureGroupPilot = this.get('captureGroup.capture_group_pilots').find(captureGroupPilot => captureGroupPilot.user.id === pilot.id);
      if (!captureGroupPilot) {
        return this.get('defaultCurrency');
      }
      return captureGroupPilot.get('currency') || this.get('defaultCurrency');
    },
    actions: {
      inputValidateAction() {
        this.inputValidate();
      },
      clearPayout() {
        this.clearLineItem();
        this.initNewPayout();
      },
      toggleShowLegalEntitiesWarning() {
        this.set('showSiteInspectionsWarning', !this.get('showSiteInspectionsWarning'));
      },
      showLineItemForm() {
        this.set('showLineItemForm', true);
        this.initLineItem();
      },
      updatePilot(pilotId) {
        this.setPayoutPilot(pilotId);
        this.inputValidate();
      },
      save() {
        if (this.get('changeset.payment_processor') !== OTHER_PROCESSOR) {
          // this gets set at actual time of payment
          this.changeset.set('payment_processor', null);
        }
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            const result = this.get('changeset.legal_entity.name') ? window.confirm(`This pilot will be paid through ${this.get("changeset.legal_entity.name")} as legal entity. Proceed?`) : window.confirm(`This pilot will be paid without legal entity. Proceed?`);
            if (result) {
              this.get('changeset').save().then(() => {
                this.get('payout.payout_line_items').save();
                this.togglePayoutsFormModal();
                this.set('payout.newlyAdded', true);
                this.initNewPayout();
              }, error => {
                alert('There was an issue deleting this payout, the status may have changed. Please refresh and try again.');
                return console.log('error while saving: ' + error);
              });
            }
          }
        });
      },
      addLineItemAction() {
        this.addLineItem();
      },
      addAnotherPayoutLineItem() {
        this.addLineItem();
        this.set('showLineItemForm', true);
        return this.initLineItem();
      },
      clearLineItemAction() {
        this.clearLineItem();
      },
      updatePaymentMethod(event) {
        this.setPaymentMethod(event);
      },
      updateLegalEntity(value) {
        this.changeset.set('legal_entity', value);
      },
      updateReason(reason) {
        if (this.get('lineItem')) {
          this.set('lineItem.reason', reason);
          this.set('lineItem.amount', this.defaultLineItemAmount(reason));
        }
        this.inputValidate();
      },
      close() {
        this.togglePayoutsFormModal();
      }
    }
  });
});