define("admin/routes/subscription-offers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        subscriptionOffers: this.store.findAll('subscription-offer'),
        currencies: this.store.findAll('currency'),
        countries: this.store.findAll('country'),
        states: this.store.findAll('state')
      });
    }
  });
});