define("admin/templates/components/checkbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mFoHj1Hu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[34,7]]]],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n\"],[6,[37,3],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,6],null,[[\"type\",\"class\",\"checked\",\"disabled\"],[\"checkbox\",[35,5],true,true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,6],null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",[35,5],[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[18,1,null],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"checkbox-label-addon\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"labelAddon\",\"label\",\"wrap\",\"if\",\"checked\",\"formattedLabel\",\"input\",\"containerClasses\",\"allSelected\",\"showLabel\"]}",
    "meta": {
      "moduleName": "admin/templates/components/checkbox-item.hbs"
    }
  });
});