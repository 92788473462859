define("admin/mirage/config", ["exports", "admin/config/environment", "admin/mirage/factories/subscription"], function (_exports, _environment, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.passthrough('https://cdn.jsdelivr.net/codemirror.spell-checker/latest/**');
    this.passthrough('http://localhost:7357/node_modules/**');
    this.passthrough('/node_modules/typo-js/dictionaries/en_US/en_US.dic');
    this.urlPrefix = _environment.default.api.host;
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */
    this.get('/v1/admin/site_inspections', schema => {
      return schema.siteInspections.all();
    });
    this.get('/v1/admin/cities', schema => {
      return schema.cities.all();
    });
    this.get('/v1/admin/states', schema => {
      return schema.states.all();
    });
    this.get('/v1/admin/countries', schema => {
      return schema.countries.all();
    });
    this.get('/v1/admin/legal_entities', schema => {
      return schema.legalEntities.all();
    });
    this.get('/v1/admin/post_processes', schema => {
      return schema.postProcesses.all();
    });
    this.get('/v1/admin/site_inspections/statuses', () => {
      return {
        statuses: ["created", "quote_requested", "account_manager_review", "quote_approved", "pilot_assigned", "in_progress", "collection_complete", "missions_complete", "invoice_needed", "invoiced", "complete", "cancelled"]
      };
    });
    this.get('/v1/admin/missions/:id', ({
      missions,
      payouts
    }, {
      params
    }) => {
      return {
        data: {
          id: params.id,
          type: 'mission',
          attributes: missions.find(params.id),
          relationships: {
            payouts: {
              data: payouts.where({
                payoutableId: {
                  id: params.id,
                  type: 'mission'
                }
              }).models.map(payout => {
                return {
                  type: 'payout',
                  id: payout.id
                };
              })
            }
          }
        },
        included: payouts.where({
          payoutableId: {
            id: params.id,
            type: 'mission'
          }
        }).models.map(payout => {
          return {
            type: 'payout',
            id: payout.id,
            attributes: payout,
            relationships: {
              payoutable: {
                data: {
                  type: 'mission',
                  id: params.id
                }
              }
            }
          };
        })
      };
    });
    this.get('/v1/admin/pilots/:id', ({
      pilots
    }, {
      params
    }) => {
      return pilots.find(params.id);
    });
    this.patch('/v1/admin/site_inspections/:id/site_inspection_pilots/:site_inspection_pilot_id', (_, {
      requestBody
    }) => {
      const responseBody = JSON.parse(requestBody);
      const laborAmount = responseBody.data.attributes.estimated_labor_payout_amount || 0;
      const id = responseBody.data.id;
      return {
        data: {
          id,
          type: 'site_inspection_pilots',
          attributes: {
            estimated_labor_payout_amount: laborAmount
          }
        }
      };
    });
    this.post('/v1/admin/site_inspections/:id/site_inspection_pilots', ({
      siteInspectionPilots
    }, {
      requestBody
    }) => {
      const requestJson = JSON.parse(requestBody);
      let attrs = requestJson.data.attributes;
      attrs['userId'] = requestJson.data.relationships.user.data.id;
      attrs['siteInspectionId'] = requestJson.data.relationships.site_inspection.data.id;
      if (requestJson.data.relationships.currency?.data?.id) attrs['currencyId'] = requestJson.data.relationships.currency.data.id;
      return siteInspectionPilots.create(attrs);
    });
    this.delete('/v1/admin/site_inspections/:id/site_inspection_pilots/:site_inspection_pilot_id', ({
      siteInspectionPilots
    }, {
      params
    }) => {
      const record = siteInspectionPilots.find(params.site_inspection_pilot_id);
      return {
        data: {
          id: record.id,
          type: 'site_inspection_pilots'
        }
      };
    });
    this.get('/v1/admin/standby_hours_reasons', ({
      standbyHoursReasons
    }) => {
      return standbyHoursReasons.all();
    });
    this.get('/v1/public/standby_hours_rejection_reasons', () => {
      return {
        data: [{
          value: 'accidental_submision',
          label: 'Accidental Submission / Typo'
        }, {
          value: 'data_entry_mismatch',
          label: 'Data entry mismatch'
        }, {
          value: 'incorrect_standby_type',
          label: 'Incorrect Standby type'
        }, {
          value: 'not_billable_equipment_failure',
          label: 'Not billable to Zeitview - Equipment failure'
        }, {
          value: 'not_billable_other',
          label: 'Not billable to Zeitview - Other'
        }, {
          value: 'not_billable_standard_operations',
          label: 'Not billable to Zeitview - Standard Operations'
        }, {
          value: 'not_billable_weather_delay',
          label: 'Not billable to Zeitview - Weather Delay'
        }, {
          value: 'not_within_working_hours',
          label: 'Not within working hours'
        }, {
          value: 'non_working_day_extraneous_circumstances',
          label: 'Non-working day - extraneous circumstances'
        }, {
          value: 'non_working_day_rest',
          label: 'Non-working day - rest'
        }, {
          value: 'site_manager_dispute',
          label: 'Site Manager dispute'
        }, {
          value: 'other',
          label: 'Other'
        }]
      };
    });
    this.get('/v1/admin/site_inspections/:site_inspection_id/standby_hours_line_items', (schema, request) => {
      return schema.standbyHoursLineItems.where({
        site_inspection_id: request.params.site_inspection_id
      });
    });
    this.post('/v1/admin/site_inspections/:site_inspection_id/standby_hours_line_items', (schema, request) => {
      return JSON.parse(request.requestBody);
    });
    this.get('/v1/admin/missions', ({
      missions
    }) => {
      return missions.all();
    });
    this.get('/v1/admin/locations', ({
      locations
    }) => {
      return locations.all();
    });
    this.get('/v1/admin/missions/:id/activity_logs', (schema, request) => {
      return schema.activityLogs.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/mission_payments', (schema, request) => {
      return schema.missionPayments.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/image_markers', (schema, request) => {
      return schema.imageMarkers.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/panoramas', (schema, request) => {
      return schema.panoramas.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/videos', (schema, request) => {
      return schema.videos.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/data_files', (schema, request) => {
      return schema.data_files.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/ortho_photo_maps', (schema, request) => {
      return schema.orthoPhotoMaps.where({
        missionId: request.params.id
      });
    });
    this.get('/v1/admin/missions/:id/invoice_line_items', (schema, request) => {
      return schema.invoiceLineItems.where({
        missionId: request.params.id
      });
    });
    this.post('/v1/admin/missions/:id/regenerate_archives', () => {
      return true;
    });
    this.post('/v1/admin/missions/:id/check_airspace', ({
      missions
    }, request) => {
      let mission = missions.find(request.params.id);
      mission.attrs.airspace_check_status = 'complete';
      return {
        data: {
          id: mission.id,
          attributes: mission.attrs,
          type: 'missions'
        }
      };
    });
    this.get('/v1/admin/dropships', (schema, request) => {
      return schema.dropships.where({
        missionId: request.queryParams.mission_id
      });
    });
    this.get('/v1/admin/admins/:id', (schema, request) => {
      return schema.admins.find(request.params.id);
    });
    this.get('/v1/public/licenses', ({
      licenses
    }) => {
      return licenses.all();
    });
    this.get('/v1/public/verticals', ({
      verticals
    }) => {
      return verticals.all();
    });
    this.get('/v1/admin/site_inspections/:id', (schema, request) => {
      return schema.siteInspections.find(request.params.id);
    });
    this.patch('/v1/admin/site_inspections/:id', (schema, request) => {
      return Ember.Object.create(JSON.parse(request.requestBody));
    });
    this.get('/v1/admin/shot_lists', ({
      shotLists
    }) => {
      return shotLists.all();
    });
    this.get('/v1/admin/shot_lists/:id', ({
      shotLists
    }, request) => {
      return shotLists.find(request.params.id);
    });
    this.get('/v1/admin/preset_search_filters', ({
      presetSearchFilters
    }) => {
      return presetSearchFilters.all();
    });
    this.get('/v1/public/mission_hold_reasons', ({
      missionHoldReasons
    }) => {
      return missionHoldReasons.all();
    });
    this.get('v1/admin/cancellation_reasons', ({
      cancellationReasons
    }) => {
      return cancellationReasons.all();
    });
    this.get('v1/public/mission_rejection_reasons', ({
      missionRejectionReasons
    }) => {
      return missionRejectionReasons.all();
    });
    this.get('v1/admin/reschedule_reasons', ({
      rescheduleReasons
    }) => {
      return rescheduleReasons.all();
    });
    this.get('v1/missions/statuses', () => {
      return [{
        value: "created",
        display_name: "Created"
      }, {
        value: "upload_required",
        display_name: "Upload Required"
      }, {
        value: "scheduling_call",
        display_name: "Scheduling Call"
      }, {
        value: "confirmed",
        display_name: "Confirmed"
      }, {
        value: "pilots_notified",
        display_name: "Pilots Notified"
      }, {
        value: "pilot_accepted",
        display_name: "Pilot Accepted"
      }, {
        value: "flight_complete",
        display_name: "Flight Complete"
      }, {
        value: "assets_uploaded",
        display_name: "Assets Uploaded"
      }, {
        value: "processing_shots",
        display_name: "Processing Shots"
      }, {
        value: "assets_classified",
        display_name: "Assets Classified"
      }, {
        value: "in_production",
        display_name: "In Production"
      }, {
        value: "awaiting_payment",
        display_name: "Awaiting Payment"
      }, {
        value: "invoice_needed",
        display_name: "Invoice Needed"
      }, {
        value: "invoiced",
        display_name: "Invoiced"
      }, {
        value: "complete",
        display_name: "Complete"
      }, {
        value: "canceled",
        display_name: "Canceled"
      }, {
        value: "unfulfilled",
        display_name: "Unfulfilled"
      }, {
        value: "rejected",
        display_name: "Rejected"
      }];
    });
    this.get('v1/admin/packages', ({
      packages
    }) => {
      return packages.all();
    });
    this.get('/v1/admin/drones', ({
      drones
    }) => {
      return drones.all();
    });
    this.get('/v1/admin/devices', ({
      devices
    }) => {
      return devices.all();
    });
    this.get('/v1/admin/flight_apps', ({
      flightApps
    }) => {
      return flightApps.all();
    });
    this.get('/v1/admin/pilot_equipment', ({
      pilotEquipment
    }) => {
      return pilotEquipment.all();
    });
    this.get('/v1/admin/pilots', ({
      pilots,
      pilotRates,
      currencies
    }) => {
      const pilotModels = pilots.all().models;
      return {
        data: pilotModels.map(pilot => {
          const currencyRelationship = pilot.currencyId ? {
            currency: {
              data: {
                type: 'currencies',
                id: currencies.find(pilot.currencyId).id
              }
            }
          } : {};
          return {
            type: 'pilots',
            id: pilot.id,
            attributes: pilot,
            relationships: {
              pilot_rates: {
                data: pilotRates.where({
                  pilotId: pilot.id
                }).models.map(pilotRate => {
                  return {
                    type: 'pilot-rate',
                    id: pilotRate.id
                  };
                })
              },
              ...currencyRelationship
            }
          };
        }),
        meta: {
          total_count: pilotModels.length
        },
        included: [...pilotRates.all().models.map(pilotRate => {
          return {
            type: 'pilot-rate',
            id: pilotRate.id,
            attributes: pilotRate,
            relationships: {
              pilot: {
                data: {
                  type: 'pilots',
                  id: pilotRate.pilotId
                }
              },
              currency: {
                data: {
                  type: 'currencies',
                  id: currencies.find(pilotRate.currencyId).id
                }
              }
            }
          };
        }), ...currencies.all().models.map(currency => {
          return {
            type: 'currencies',
            id: currency.id,
            attributes: currency
          };
        })]
      };
    });
    this.get('/v1/admin/clients', ({
      clients
    }) => {
      const clientModels = clients.all().models;
      return {
        data: clientModels.map(client => {
          return {
            type: 'pilot',
            id: client.id,
            attributes: client
          };
        }),
        meta: {
          total_count: clientModels.length
        }
      };
    });
    this.get('/v1/admin/admins', ({
      admins
    }) => {
      return admins.all();
    });
    this.get('/v1/admin/organizations', ({
      organizations
    }) => {
      const organizationModels = organizations.all().models;
      return {
        data: organizationModels.map(organization => {
          return {
            type: 'organizations',
            id: organization.id,
            attributes: organization
          };
        }),
        meta: {
          page: 0,
          per_page: 30,
          total_pages: 1,
          total_count: organizationModels.length
        }
      };
    });
    this.get('/v1/admin/subscription_offers', schema => {
      const subscriptionOfferModels = schema.subscriptionOffers.all().models;
      const statesModel = schema.states.all().models;
      const subscriptionModels = schema.subscriptions.all().models;
      return {
        data: subscriptionOfferModels.map(subscriptionOffer => {
          const organization = subscriptionOffer.organization;
          const currency = subscriptionOffer.currency;
          return {
            type: 'subscription_offers',
            id: _subscription.default.id,
            attributes: _subscription.default,
            relationships: {
              states: {
                data: [{
                  id: statesModel.firstObject.id,
                  type: 'states'
                }]
              },
              subscriptions: {
                data: [{
                  id: subscriptionModels.firstObject.id,
                  type: 'subscriptions'
                }]
              },
              organization: {
                data: [{
                  id: organization.id,
                  type: 'organizations'
                }]
              },
              currency: {
                data: [{
                  id: currency.id,
                  type: 'currencies'
                }]
              }
            }
          };
        }),
        meta: {
          page: 0,
          per_page: 30,
          total_pages: 1,
          total_count: subscriptionOfferModels.length
        }
      };
    });
    this.get('v1/admin/organizations/:organization_id/subscriptions', schema => {
      const subscriptionModels = schema.subscriptions.all().models;
      const stateModels = schema.states.all().models;
      return {
        data: subscriptionModels.map(subscription => {
          const organization = subscription.organization;
          const currency = subscription.currency;
          const subscriptionOffer = subscription.subscriptionOffer;
          return {
            id: subscription.id,
            type: 'subscriptions',
            attributes: subscription,
            relationships: {
              states: stateModels.map(state => {
                return {
                  id: state.id,
                  type: 'states'
                };
              }),
              organization: {
                data: {
                  id: organization.id,
                  type: 'organizations'
                }
              },
              subscription_offer: {
                data: {
                  id: subscriptionOffer.id,
                  type: 'subscription_offers'
                }
              },
              currency: {
                data: {
                  id: currency.id,
                  type: 'currencies'
                }
              }
            }
          };
        })
      };
    });
    this.get('/v1/admin/estimated_comprehensive_report_purchase_missions', schema => {
      const missionModels = schema.missions.all().models;
      const locationModels = schema.locations.all().models;
      return {
        data: missionModels.map(mission => {
          const location = mission.location;
          return {
            id: mission.id,
            type: 'mission',
            attributes: mission,
            relationships: {
              location: {
                data: {
                  id: location.id,
                  type: 'location'
                }
              }
            }
          };
        }),
        included: locationModels.map(location => {
          return {
            id: location.id,
            type: 'location',
            attributes: location
          };
        }),
        meta: {
          page: 1,
          per_page: 100,
          total_pages: 1,
          total_count: missionModels.length
        }
      };
    });
    this.get('/v1/admin/comprehensive_report_purchases/comprehensive_report_rates', schema => {
      const comprehensiveReportRatesModels = schema.comprehensiveReportRates.all().models;
      const currencies = schema.currencies.all().models;
      return {
        data: comprehensiveReportRatesModels.map(comprehensive_report_rate => {
          const currency = comprehensive_report_rate.currency;
          return {
            id: comprehensive_report_rate.id,
            type: 'comprehensive_report_rates',
            attributes: comprehensive_report_rate,
            relationships: {
              currency: {
                data: {
                  id: currency.id,
                  type: 'currencies'
                }
              }
            }
          };
        }),
        included: currencies.map(currency => {
          return {
            id: currency.id,
            type: 'currencies',
            attributes: currency
          };
        })
      };
    });
    this.get('/v1/admin/organizations/:organization_id', (schema, request) => {
      const organization = schema.organizations.find(request.params.organization_id);
      const packageModels = schema.packages.all().models;
      const clients = schema.clients.all().models;
      const subscriptionOffers = schema.subscriptionOffers.all().models;
      const subscriptions = schema.subscriptions.all().models;
      const comprehensiveReportPurchases = schema.comprehensiveReportPurchases.all().models;
      const organizationAvailablePackages = schema.organizationAvailablePackages.all().models;
      const availableAddons = schema.packageAddons.all().models;
      const verticals = schema.verticals.all().models;
      const badges = schema.badges.all().models;
      const states = schema.states.all().models;
      const missions = schema.missions.all().models;
      return {
        data: {
          id: organization.id,
          type: 'organizations',
          attributes: organization,
          relationships: {
            packages: {
              data: packageModels.map(packageModel => {
                return {
                  id: packageModel.id,
                  type: 'packages'
                };
              })
            },
            clients: {
              data: clients.map(client => {
                return {
                  id: client.id,
                  type: 'clients'
                };
              })
            },
            subscription_offers: {
              data: subscriptionOffers.map(subscriptionOffer => {
                return {
                  id: subscriptionOffer.id,
                  type: 'subscription_offers'
                };
              })
            },
            subscriptions: {
              data: subscriptions.map(subscription => {
                return {
                  id: subscription.id,
                  type: 'subscriptions'
                };
              })
            },
            comprehensive_report_purchases: {
              data: comprehensiveReportPurchases.map(comprehensiveReportPurchase => {
                return {
                  id: comprehensiveReportPurchase.id,
                  type: 'comprehensive_report_purchases'
                };
              })
            },
            organization_available_packages: {
              data: organizationAvailablePackages.map(organizationAvailablePackage => {
                return {
                  id: organizationAvailablePackage,
                  type: 'organization-available-packages'
                };
              })
            },
            available_addons: {
              data: availableAddons.map(availableAddon => {
                return {
                  id: availableAddon.id,
                  type: 'package_addons'
                };
              })
            }
          }
        },
        included: [{
          id: packageModels.firstObject.id,
          type: 'packages',
          attributes: packageModels.firstObject,
          relationships: {
            vertical: {
              data: {
                id: verticals.firstObject.id,
                type: 'verticals'
              }
            },
            shot_list: {
              data: null
            },
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            },
            account_rep: {
              data: null
            },
            badges: {
              data: {
                id: badges.firstObject.id,
                type: 'badges'
              }
            },
            drones: {
              data: []
            },
            devices: {
              data: []
            },
            drone_cameras: {
              data: []
            },
            pilot_equipments: {
              data: []
            },
            deliverable_work_types: {
              data: []
            },
            addons: {
              data: []
            },
            catalog_items: {
              data: []
            },
            package_flight_app: {
              data: null
            }
          }
        }, {
          id: clients.firstObject.id,
          type: 'clients',
          attributes: clients.firstObject,
          relationships: {
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            }
          }
        }, {
          id: subscriptionOffers.firstObject.id,
          type: 'subscription_offers',
          attributes: subscriptionOffers.firstObject,
          relationships: {
            states: {
              data: [{
                id: states.firstObject.id,
                type: 'states'
              }]
            },
            subscriptions: {
              data: subscriptions.map(subscription => {
                return {
                  id: subscription.id,
                  type: 'subscriptions'
                };
              })
            },
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            },
            currency: {
              data: {
                id: subscriptions.firstObject.currency.id,
                type: 'currencies'
              }
            }
          }
        }, {
          id: comprehensiveReportPurchases.firstObject.id,
          type: 'comprehensive_report_purchases',
          attributes: comprehensiveReportPurchases.firstObject,
          relationships: {
            missions: {
              data: missions.map(mission => {
                return {
                  id: mission.id,
                  type: 'mission'
                };
              })
            },
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            },
            currency: {
              data: {
                id: comprehensiveReportPurchases.firstObject.currency.id,
                type: 'currencies'
              }
            }
          }
        }, {
          id: organizationAvailablePackages.firstObject.id,
          type: 'organization-available-packages',
          relationships: {
            package: {
              data: {
                id: packageModels.firstObject.id,
                type: 'packages'
              }
            },
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            }
          }
        }, {
          id: packageModels.firstObject.id,
          type: 'packages',
          attributes: packageModels.firstObject,
          relationships: {
            vertical: {
              data: {
                id: verticals.firstObject.id,
                type: 'verticals'
              }
            },
            shot_list: {
              data: null
            },
            organization: {
              data: {
                id: organization.id,
                type: 'organizations'
              }
            },
            account_rep: {
              data: null
            },
            badges: {
              data: {
                id: badges.firstObject.id,
                type: 'badges'
              }
            },
            drones: {
              data: []
            },
            devices: {
              data: []
            },
            drone_cameras: {
              data: []
            },
            pilot_equipments: {
              data: []
            },
            deliverable_work_types: {
              data: []
            },
            addons: {
              data: []
            },
            catalog_items: {
              data: []
            },
            package_flight_app: {
              data: null
            }
          }
        }, {
          id: availableAddons.firstObject.id,
          type: 'package_addons',
          attributes: availableAddons.firstObject,
          relationships: {
            verticals: {
              data: verticals.map(vertical => {
                return {
                  id: vertical.id,
                  type: 'verticals'
                };
              })
            }
          }
        }]
      };
    });
    this.get('/v1/admin/clients/:client_id', ({
      clients
    }, request) => {
      return clients.find(request.params.client_id);
    });
    this.get('/v1/admin/badges', ({
      badges
    }) => {
      return badges.all();
    });
    this.get('/v1/admin/deliverable_work_types', ({
      deliverableWorkTypes
    }) => {
      return deliverableWorkTypes.all();
    });
    this.get('/v1/admin/package_addons', ({
      packageAddons
    }) => {
      return packageAddons.all();
    });
    this.delete('/v1/admin/site_inspections/:id/assignment', (schema, request) => {
      let siteInspectionPilot = schema.siteInspectionPilots.where({
        "userId": Object.fromEntries(new URLSearchParams(request.requestBody)).pilot_id,
        "siteInspectionId": request.params.id
      }).models.firstObject;
      siteInspectionPilot.destroy();
      return {};
    });
    this.get('/v1/site_inspections/:id/pilots', ({
      pilots
    }) => {
      return pilots.all();
    });
    this.get('/v1/admin/site_inspections/:id/invoice_line_items', (schema, request) => {
      return schema.invoiceLineItems.where({
        id: schema.siteInspections.find(request.params.id).attrs.invoiceLineItemIds
      });
    });
    this.get('/v1/admin/site_inspections/:id/pilots', ({
      pilots
    }) => {
      return pilots.all();
    });
    this.delete('/v1/admin/missions/:id/schedule', () => {
      return {};
    });
    this.delete('/v1/admin/missions/:id/schedule/reset', () => {
      return {};
    });
    this.post('/v1/admin/site_inspections/:id/onsite_contact', ({
      siteInspectionOnsiteContacts
    }, {
      requestBody
    }) => {
      const requestJson = JSON.parse(requestBody);
      return siteInspectionOnsiteContacts.create(requestJson.data.attributes);
    });
    this.get('/v1/admin/currencies', ({
      currencies
    }) => {
      return currencies.all();
    });
    this.get('/v1/admin/pilot_rate_types', ({
      pilotRateTypes
    }) => {
      return pilotRateTypes.all();
    });
    this.post('/v1/admin/site_inspections/:site_inspection_id/payouts/:pilot_id', ({
      payouts,
      siteInspections
    }, {
      params,
      requestBody
    }) => {
      const requestJson = JSON.parse(requestBody);
      let attrs = requestJson.data.attributes;
      attrs['pilotId'] = requestJson.data.relationships.pilot.data.id;
      attrs['payoutable'] = siteInspections.find(params.site_inspection_id);
      attrs['payoutableType'] = 'site_inspection';
      attrs['currencyId'] = requestJson.data.relationships.currency.data.id;
      attrs['legalEntityId'] = requestJson.data.relationships.legal_entity?.data.id;
      return payouts.create(attrs);
    });
    this.get('/v1/admin/capture_groups', ({
      captureGroups
    }) => {
      return captureGroups.all();
    });
    this.get('/v1/admin/capture_groups/:id', ({
      captureGroups
    }, {
      params
    }) => {
      return captureGroups.find(params.id);
    });
    this.get('/v1/admin/capture_groups/statuses', () => {
      return new Promise(resolve => {
        resolve({
          statuses: ["created", "confirmed"]
        });
      });
    });
    this.get('/v1/admin/capture_groups/:capture_group_id/pilots', ({
      pilots
    }) => {
      // TODO: update this to use the capture_group_id
      return new Promise(resolve => {
        resolve(pilots.all());
      });
    });
    this.post('/v1/admin/site_inspections/:site_inspection_id/payouts/:payout_id/payout_line_items', ({
      payoutLineItems
    }, {
      params,
      requestBody
    }) => {
      const requestJson = JSON.parse(requestBody);
      let attrs = requestJson.data.attributes;
      attrs['payoutId'] = params.payout_id;
      attrs['payoutableId'] = params.site_inspection_id;
      attrs['payoutableType'] = 'site_inspection';
      return payoutLineItems.create(attrs);
    });
  }
});