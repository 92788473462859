define("admin/templates/components/reusable/show-hide-next-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JI4dPT+2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"button\"],[15,0,[31,[\"fa fa-chevron-\",[34,0],\" show-hide-next-row-button\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"toggleRow\"],null]],[12],[13]],\"hasEval\":false,\"upvars\":[\"whichDirection\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/show-hide-next-row.hbs"
    }
  });
});