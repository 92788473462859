define("admin/models/pointivo-project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    project_issues_humanize: _emberData.default.attr('array'),
    resource_status: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    project_issues_readable: _emberData.default.attr('array'),
    shot: _emberData.default.belongsTo('shot', {
      async: false
    }),
    inProgress: Ember.computed('status', function () {
      return this.get('status') === 'PROCESSING';
    }),
    isReady: Ember.computed('status', function () {
      return this.get('status') === 'COMPLETE_FINAL';
    }),
    projectIssues: Ember.computed('project_issues_humanize', function () {
      return this.get('project_issues_humanize').join(', ');
    })
  });
});