define("admin/routes/dropships/edit", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    model: function (params) {
      params['perPage'] = 5;
      params['startingPage'] = 1;
      const missionsFindParams = {
        pilot_id: params.pilot_id,
        status: 'available,pilot_accepted,flight_complete,assets_uploaded'
      };
      return Ember.RSVP.hash({
        dropships: this.store.query('dropship', params),
        pilot: this.store.findRecord('pilot', params.pilot_id),
        pilotMissions: this.get('store').query('mission', missionsFindParams)
      });
    },
    queryParams: {
      q: {
        refreshModel: true
      }
    },
    loadIfNotLoaded(type) {
      var typeIsLoaded = this.get('store').peekAll(type).length > 0;
      var typeItems;
      if (typeIsLoaded) {
        typeItems = this.get('store').peekAll(type);
      } else {
        typeItems = this.get('store').findAll(type);
      }
      return typeItems;
    }
  });
});