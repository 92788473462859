define("admin/components/drone-index-list", ["exports", "admin/components/equipment-index-list"], function (_exports, _equipmentIndexList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DroneIndexListComponent;
  DroneIndexListComponent = _equipmentIndexList.default.extend({
    collapsed: true,
    showModal: false,
    currentRecord: null,
    cachedRecords: [],
    recordType: null,
    actions: {
      addRecord: function () {
        this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
        this.get('currentRecord').set('drone_manufacturer', this.get('droneManufacturers.firstObject'));
        return this.set('showModal', true);
      },
      cloneRecord: function (record) {
        var camera, i, len, ref;
        this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
        this.get('currentRecord').setProperties(record.serialize().data.attributes);
        this.get('currentRecord').set('drone_manufacturer', record.get('drone_manufacturer'));
        ref = record.get('optional_cameras').toArray();
        for (i = 0, len = ref.length; i < len; i++) {
          camera = ref[i];
          this.get('currentRecord').get('optional_cameras').pushObject(camera);
        }
        return this.set('showModal', true);
      }
    }
  });
  var _default = _exports.default = DroneIndexListComponent;
});