define("admin/components/dronebase-simple-mde", ["exports", "ember-simplemde/components/simple-mde"], function (_exports, _simpleMde) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _simpleMde.default.extend({
    didInsertElement() {
      this._super();
      this.$(this.get('currentEditor').element).ready(() => {
        this.refreshEditor();
      });
    },
    refreshEditor() {
      Ember.run.later(() => {
        this.get('currentEditor').codemirror.refresh();
      }, 2000);
    }
  });
});