define("admin/controllers/shot_lists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    shotListSortProps: ['name', 'asc'],
    sortedShotLists: Ember.computed.sort('model', 'shotListSortProps')
  });
});