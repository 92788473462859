define("admin/components/organizations/subscription", ["exports", "admin/data/subscription-offer/duration", "admin/data/subscription-offer/valid-for", "ember-changeset-validations", "admin/validations/subscription", "ember-changeset", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _duration, _validFor, _emberChangesetValidations, _subscription, _emberChangeset, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    durationValues: _duration.durationValues,
    validForValues: _validFor.validForValues,
    statuses: Ember.A(['created', 'invoice_needed', 'invoiced', 'complete', 'canceled']),
    possibleStatusesForTransition: Ember.A(['created', 'invoice_needed', 'invoiced', 'complete']),
    init: function () {
      this._super(...arguments);
      this.changeset = new _emberChangeset.default(this.get('model.subscription'), (0, _emberChangesetValidations.default)(_subscription.default), _subscription.default);
    },
    wrappedStatuses: Ember.computed('statuses', function () {
      return this.get('statuses').map(status => {
        return Ember.Object.create({
          label: status.split('_').map(word => word.capitalize()).join(' '),
          value: status
        });
      });
    }),
    currencyOptions: Ember.computed('model.currencies', function () {
      return this.get('model.currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency.code,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    nextStatus: Ember.computed('model.subscription.status', function () {
      const statusIndex = this.get('possibleStatusesForTransition').indexOf(this.get('model.subscription.status'));
      if (statusIndex >= 0) {
        return this.get('possibleStatusesForTransition')[statusIndex + 1];
      }
      return null;
    }),
    activeTab: "details",
    detailsAnchorIds: ["subscription_details", "accounting"],
    actions: {
      changeSubscriptionCurrency: function (currencyCode) {
        this.changeset.set('currency', this.get('model.currencies').findBy('code', currencyCode));
      },
      updateSubscriptionStatus: function (nextStatus) {
        let adapter = this.store.adapterFor('subscription');
        return adapter.updateStatus(this.get('sessionAccount.headers'), {
          status: nextStatus
        }, this.get('model.subscription')).then(() => {
          this.flashMessages.success('Subscription was saved!');
          this.get('model.subscription').reload();
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to save subscription');
        });
      },
      submit: function () {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.changeset.save().then(() => {
              this.flashMessages.success('Subscription was saved!');
            }).catch(response => {
              (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
              this.flashMessages.error('Failed to save subscription');
            });
          }
        });
      },
      setSubTab: function (tabName) {
        this.set("activeSubTab", tabName);
      },
      scrollToElementId: function (elementId, rootElementClass = null) {
        const rootOffset = Ember.$(`.${rootElementClass}`)?.offset()?.top || 0;
        const elementOffset = Ember.$(`#${elementId}`).offset().top;
        Ember.$(document).scrollTop(elementOffset - rootOffset);
        setTimeout(() => this.set('activeSubTab', elementId));
      }
    }
  });
});