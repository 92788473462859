define("admin/adapters/image", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/admin/missions',
    urlTemplate: '{+namespace}/{missionId}/images{/id}',
    queryUrlTemplate: '{+namespace}/{missionId}/shots/{shotId}/images?per_page={perPage}&page={page}',
    urlSegments: {
      shotId: function (type, id, snapshot, query) {
        if (query == undefined) {
          return snapshot.belongsTo('shot', {
            id: true
          });
        } else {
          return query.shotId;
        }
      },
      missionId: function (type, id, snapshot, query) {
        if (query == undefined) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return query.missionId;
        }
      },
      perPage: function (type, id, snapshot, query) {
        if (query == undefined) {
          return 50;
        } else {
          return query.perPage || 50;
        }
      },
      page: function (type, id, snapshot, query) {
        if (query == undefined) {
          return 1;
        } else {
          return query.page;
        }
      }
    }
  });
});