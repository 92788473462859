define("admin/components/input-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["form-group"],
    inputClasses: Ember.computed('modelErrors.length', function () {
      let classes = 'form-control';
      if (this.get('modelErrors.length')) {
        classes = classes + ' error';
      }
      return classes;
    }),
    actions: {
      showErrors: function () {
        return this.set('showError', true);
      }
    }
  });
});