define("admin/routes/partner-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PartnerIntegration;
  PartnerIntegration = Ember.Route.extend({
    model: function (params) {
      return Em.RSVP.hash({
        flight_apps: this.store.findAll('flight_app')
      });
    }
  });
  var _default = _exports.default = PartnerIntegration;
});