define("admin/templates/components/sortable-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pOqiNppJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\" \"],[10,\"i\"],[15,0,[31,[\"fa fa-chevron-\",[34,1]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"direction\"]}",
    "meta": {
      "moduleName": "admin/templates/components/sortable-column.hbs"
    }
  });
});