define("admin/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;
  function capitalize([str], options) {
    if (typeof str !== 'string') return '';
    const {
      omitDash
    } = options;
    if (omitDash) {
      let chunks = str.split('-');
      return chunks.map(chunk => {
        return chunk.charAt(0).toUpperCase() + chunk.slice(1);
      }).join(' ');
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
  var _default = _exports.default = Ember.Helper.helper(capitalize);
});