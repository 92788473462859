define("admin/templates/components/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZdbGt9XA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"values-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,1,[34,0]],[15,0,[31,[\"min-value \",[30,[36,4],[[30,[36,3],[[35,2],[35,1]],null],\"active\"],null]]]],[12],[2,\"\\n    \"],[1,[34,2]],[2,\" \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,1,[34,6]],[15,0,[31,[\"value \",[30,[36,4],[[30,[36,8],[[30,[36,3],[[35,2],[35,1]],null],[30,[36,3],[[35,7],[35,1]],null]],null],\"hide\"],null]]]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\" \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,1,[34,9]],[15,0,[31,[\"max-value \",[30,[36,4],[[30,[36,3],[[35,7],[35,1]],null],\"active\"],null]]]],[12],[2,\"\\n    \"],[1,[34,7]],[2,\" \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"input\"],[15,\"min\",[34,2]],[15,\"max\",[34,7]],[15,\"step\",[34,10]],[15,2,[34,1]],[15,1,[34,11]],[14,4,\"range\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"minValueId\",\"value\",\"min\",\"is-equal\",\"if\",\"unit\",\"valueId\",\"max\",\"either\",\"maxValueId\",\"step\",\"inputId\"]}",
    "meta": {
      "moduleName": "admin/templates/components/slider.hbs"
    }
  });
});