define("admin/models/capture-group", ["exports", "@ember-data/model", "admin/mixins/payoutable"], function (_exports, _model, _payoutable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend(_payoutable.default, {
    name: (0, _model.attr)('string'),
    internal_notes: (0, _model.attr)('string'),
    missions_count: (0, _model.attr)('number'),
    season: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    updated_at: (0, _model.attr)('date'),
    pilots: (0, _model.hasMany)('pilots'),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    site_inspections: (0, _model.hasMany)('site-inspection', {
      async: true
    }),
    capture_group_pilots: (0, _model.hasMany)('capture-group-pilot'),
    missions: (0, _model.hasMany)('mission', {
      async: true
    }),
    organization: (0, _model.belongsTo)('organization', {
      async: false
    }),
    flight_plan: (0, _model.belongsTo)('flight-plan', {
      async: false
    }),
    operation_rep: (0, _model.belongsTo)('admin')
  });
});