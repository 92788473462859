define("admin/components/inputs/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['form-group', 'inputs-price'],
    classNameBindings: ['errorPresent:error'],
    showCurrencyLabel: true,
    observed: false,
    validateInput: function () {},
    errorPresent: Ember.computed('errors.length', function () {
      return this.get('errors.length') > 0;
    }),
    didInsertElement: function () {
      Ember.run.schedule('afterRender', this, function () {
        this.setDisplayValue();
      });
    },
    setDisplayValue: function () {
      this.set('displayValue', this.get('cents') / 100);
    },
    valueObserver: Ember.observer('cents', function () {
      this.setDisplayValue();
    }),
    /* eslint-enable ember/no-observers */

    actions: {
      focusOut: function () {
        this.set('cents', Math.round(this.get('displayValue') * 100));
      },
      observeInput: function () {
        if (this.get('observed')) {
          this.set('cents', Math.round(this.get('displayValue') * 100));
          this.validateInput();
        }
      }
    }
  });
});