define("admin/mirage/factories/comprehensive-report-rate", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `DBM123${i}123`;
    },
    number: _faker.default.random.number({
      min: 100000,
      max: 10000000
    }),
    base_size: 0.3e1,
    subscriber_rate_per_mw: 0.75e4,
    subscriber_rate_per_site: 0.355e5,
    non_subscriber_rate_per_mw: 0.95e4,
    non_subscriber_rate_per_site: 0.455e5,
    currency: (0, _emberCliMirage.association)()
  });
});