define("admin/components/organizations/comprehensive-report-purchases-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    infinity: Ember.inject.service(),
    tagName: '',
    formOpened: false,
    searchValue: null,
    searchTypeValue: 'location_numbers',
    loadingComprehensiveReportPurchases: false,
    filtersObserver: Ember.observer('searchValue', function () {
      this.fetchPurchases();
    }),
    fetchPurchases: async function () {
      const comprehensiveReportPurchases = await this.store.query('comprehensive-report-purchase', this.get('comprehensiveReportPurchasesQuery'));
      this.infinity.replace(this.get('model.comprehensiveReportPurchases'), comprehensiveReportPurchases);
    },
    comprehensiveReportPurchasesQuery: Ember.computed('model.organization.id', 'searchTypeValue', 'searchValue', function () {
      return {
        q: this.get('searchValue'),
        searchField: this.get('searchTypeValue'),
        organization_id: this.get('model.organization.id')
      };
    }),
    searchItemsCount: Ember.computed('searchValue', function () {
      if (!this.get('searchValue')) {
        return 0;
      }
      return this.get('searchValue').split("\n").length;
    }),
    actions: {
      fetchPurchasesAction() {
        this.fetchPurchases();
      },
      toggleForm() {
        this.fetchPurchases();
        this.set('formOpened', !this.get('formOpened'));
      },
      clearValue: function (valuePath) {
        this.set(valuePath, null);
      }
    }
  });
});