define("admin/templates/components/photo-swipe-meta-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7IWUr9iu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"meta\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[34,1]],[12],[2,\"\\n      Unknown\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"meta-class\"]}",
    "meta": {
      "moduleName": "admin/templates/components/photo-swipe-meta-value.hbs"
    }
  });
});