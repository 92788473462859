define("admin/utils/get-error-message-from-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(response) {
    if (response.errors.length > 0) {
      if (response.errors[0].title) {
        return response.errors.map(({
          title
        }) => title).join(' ');
      } else {
        return response.errors.map(({
          detail
        }) => detail).join("\n");
      }
    } else if (response.responseJSON && response.responseJSON.errors.length > 1) {
      return response.responseJSON.errors.map(({
        detail
      }) => detail).join(' ');
    } else {
      return response.message;
    }
  }
});