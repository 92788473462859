define("admin/routes/clients/index", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientsIndexRoute;
  ClientsIndexRoute = Ember.Route.extend(_route.default, {
    model: function (params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      return Ember.RSVP.hash({
        clients: this.infinityModel('client', params),
        verticalsToSelect: this.get('store').findAll('vertical'),
        actions: this.actions
      });
    },
    queryParams: {
      q: {
        refreshModel: true
      }
    },
    actions: {
      createClient: function (model, organization) {
        model.set('organization', organization);
        return model.save().then(function (_this) {
          return function (response) {
            _this.send('closeModal');
            return _this.transitionToRoute('clients.client', model.id);
          };
        }(this), function (response) {});
      },
      closeModalNewClient: function () {
        this.send('closeModal');
        return false;
      }
    }
  });
  var _default = _exports.default = ClientsIndexRoute;
});