define("admin/components/inputs/input-symbol-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      save: function () {
        this.get('saveAction')();
      }
    }
  });
});