define("admin/mirage/factories/pilot", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `DBP${i}`;
    },
    first_name: 'test',
    last_name: 'local',
    email: 'test4@local.com',
    token: 'UkKm02F73okMwBtlhEKb1aZjO6M'
  });
});