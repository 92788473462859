define("admin/components/organizations/comprehensive-report-purchase", ["exports", "ember-changeset-validations", "admin/validations/comprehensive-report-purchase", "ember-changeset", "admin/utils/sort-by", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _emberChangesetValidations, _comprehensiveReportPurchase, _emberChangeset, _sortBy, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    sortAttribute: 'mission.location.name',
    sortDirection: 'desc',
    sortedComprehensiveReportPurchaseMissions: Ember.computed('sortAttribute', 'sortDirection', 'model.comprehensiveReportPurchaseMissions.[]', function () {
      return (0, _sortBy.default)(this.get('model.comprehensiveReportPurchaseMissions'), this.get('sortAttribute'), this.get('sortDirection'));
    }),
    possibleStatusesForTransition: Ember.A(['created', 'invoice_needed', 'invoiced', 'completed']),
    currencyOptions: Ember.computed('model.currencies', function () {
      return this.get('model.currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency.code,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    init: function () {
      this._super(...arguments);
      this.changeset = new _emberChangeset.default(this.get('model.comprehensiveReportPurchase'), (0, _emberChangesetValidations.default)(_comprehensiveReportPurchase.default), _comprehensiveReportPurchase.default);
    },
    wrappedStatuses: Ember.computed('model.statuses', function () {
      return this.get('model.statuses').map(status => {
        return Ember.Object.create({
          label: status.split('_').map(word => word.capitalize()).join(' '),
          value: status
        });
      });
    }),
    nextStatus: Ember.computed('model.comprehensiveReportPurchase.status', function () {
      const statusIndex = this.get('possibleStatusesForTransition').indexOf(this.get('model.comprehensiveReportPurchase.status'));
      if (statusIndex >= 0) {
        return this.get('possibleStatusesForTransition')[statusIndex + 1];
      }
      return null;
    }),
    activeTab: "rate_details",
    detailsAnchorIds: ["rate_details", "accounting"],
    actions: {
      setSort(attribute) {
        if (this.get('sortDirection') === 'desc') {
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortDirection', 'desc');
        }
        this.set('sortAttribute', attribute);
      },
      changeComprehensiveReportPurchaseCurrency: function (currencyCode) {
        this.changeset.set('currency', this.get('model.currencies').findBy('code', currencyCode));
      },
      updateComprehensiveReportPurchaseStatus: function (nextStatus) {
        let adapter = this.store.adapterFor('comprehensive-report-purchase');
        return adapter.updateStatus(this.get('sessionAccount.headers'), {
          status: nextStatus
        }, this.get('model.comprehensiveReportPurchase')).then(() => {
          this.flashMessages.success('Comprehensive Report Purchase was saved!');
          this.get('model.comprehensiveReportPurchase').reload();
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to save Comprehensive Report Purchase');
        });
      },
      submit: function () {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.changeset.save().then(() => {
              this.flashMessages.success('Comprehensive Report Purchase was saved!');
            }).catch(response => {
              (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
              this.flashMessages.error('Failed to save Comprehensive Report Purchase');
            });
          }
        });
      },
      setSubTab: function (tabName) {
        this.set("activeSubTab", tabName);
      },
      scrollToElementId: function (elementId, rootElementClass = null) {
        const rootOffset = Ember.$(`.${rootElementClass}`)?.offset()?.top || 0;
        const elementOffset = Ember.$(`#${elementId}`).offset().top;
        Ember.$(document).scrollTop(elementOffset - rootOffset);
        setTimeout(() => this.set('activeSubTab', elementId));
      }
    }
  });
});