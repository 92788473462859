define("admin/data/comprehensive-report/search-type-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [Ember.Object.create({
    label: 'Site Name',
    value: 'location_names'
  })];
});