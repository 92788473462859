define("admin/routes/organizations/comprehensive-report-purchase", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        currencies: this.store.findAll('currency'),
        statuses: this.store.adapterFor('comprehensive-report-purchase').fetchStatuses(this.get('sessionAccount.headers')).then(response => response.statuses),
        comprehensiveReportPurchase: this.store.queryRecord('comprehensive-report-purchase', {
          organization_id: params.organization_id,
          id: params.comprehensive_report_purchase_id
        }),
        comprehensiveReportPurchaseMissions: this.infinity.model('comprehensive-report-purchase-mission', {
          startingPage: 1,
          perPage: 100,
          comprehensive_report_purchase_id: params.comprehensive_report_purchase_id
        })
      });
    }
  });
});