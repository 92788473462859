define("admin/components/daterange-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      clearDateRange() {
        return this.clearDateRange();
      },
      setDateRange() {
        return this.setDateRange();
      }
    }
  });
});