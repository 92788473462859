define("admin/routes/capture-groups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        captureGroups: this.store.findAll('capture-group')
      });
    }
  });
});