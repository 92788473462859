define("admin/adapters/site-inspection-pilot", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/site_inspections/{siteInspectionId}/site_inspection_pilots{/id}',
    urlSegments: {
      siteInspectionId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('site_inspection', {
          id: true
        });
      }
    }
  });
});