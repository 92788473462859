define("admin/components/mission-map", ["exports", "admin/data/map_features", "admin/mixins/google-map-init-mixin"], function (_exports, _map_features, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    featureOptions: _map_features.default.featureOptions,
    classNames: ['form-group', 'mission-map'],
    DEFAULT_ZOOM: 19,
    buttonDisabled: true,
    typedLatLng: null,
    showInput: true,
    searchValue: Ember.computed('model.location', function () {
      return this.get('model.location.formatted_address');
    }),
    setSearchValue: Ember.observer('model.location.formatted_address', 'typedLatLng', function () {
      if (this.get('typedLatLng')) {
        return this.set('searchValue', this.get('typedLatLng').lat() + ',' + this.get('typedLatLng').lng());
      } else {
        return this.set('searchValue', this.get('model.location.formatted_address'));
      }
    }),
    disableSubmit: Ember.computed('buttonDisabled', function () {
      return this.get('buttonDisabled');
    }),
    initMap() {
      const _this = this;
      const mapOptions = {
        zoom: this.DEFAULT_ZOOM,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId['HYBRID'],
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        zoomControl: true,
        draggable: true,
        scrollwheel: false
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
      }
      this.markers = Ember.A([]);
      this.setupAutocomplete();
      this.map.data.addListener('addfeature', event => {
        return this.map.data.overrideStyle(event.feature, _this.get("featureOptions.PROPERTYAREA.defaultOptions"));
      });
      return this.defaultLocation();
    },
    setupAutocomplete() {
      const _this = this;
      const input = this.$('.search-field')[0];
      const searchBox = new google.maps.places.SearchBox(input);
      this.map.addListener('bounds_changed', () => searchBox.setBounds(_this.map.getBounds()));
      input.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      input.addEventListener('keydown', () => _this.set('buttonDisabled', true));
      return searchBox.addListener('places_changed', function () {
        _this.set('buttonDisabled', true);
        _this.set('message', null);
        if (searchBox.getPlaces().length === 0) {
          alert('We could not find a location based on your search.');
          return;
        }
        const place = searchBox.getPlaces()[0];
        if (!place.address_components) {
          // see if we can get a complete place object with address_components
          const inputString = input.value.replace(/\s/g, '');
          const latLong = inputString.split(',');
          if (latLong.length !== 2) {
            return;
          }
          for (let number of Array.from(latLong)) {
            if (isNaN(number) || number.length === 0) {
              return;
            }
          }
          place.geometry.location = new google.maps.LatLng(latLong[0], latLong[1]);
          return _this.findAddressForPlace(place.geometry.location.lat(), place.geometry.location.lng()).then(response => {
            _this.set('typedLatLng', new google.maps.LatLng(latLong[0], latLong[1]));
            response.geometry.location = _this.get('typedLatLng');
            return _this.dropPin(response);
          }).catch(() => {
            alert('We could not find a location based on your search.');
          });
        } else {
          _this.set('typedLatLng', null);
          return _this.dropPin(place);
        }
      });
    },
    dropPin(place) {
      const lat_lng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
      this.map.setCenter(lat_lng);
      this.map.setZoom(this.DEFAULT_ZOOM);
      this.setAddress(place);
      return this.addMarker(lat_lng, false);
    },
    defaultLocation() {
      this.map.setZoom(1);
      const lat_lng = new google.maps.LatLng(37.2350, -115.8111);
      this.map.setCenter(lat_lng);
      return this.$('.search-field').attr('placeholder', 'Enter Location');
    },
    setAddress(place) {
      let city;
      this.set('buttonDisabled', false);
      this.set('model.location.longitude', place.geometry.location.lng());
      this.set('model.location.latitude', place.geometry.location.lat());
      let name = place.formatted_address;
      if (place.name && !name.toLowerCase().startsWith(place.name.toLowerCase())) {
        name = `${place.name}, ${name}`;
      }
      this.set('model.location.formatted_address', name);
      this.set('showInput', false);
      const address_components = {};
      Ember.$.each(place.address_components, (k, v1) => Ember.$.each(v1.types, function (k2, v2) {
        if (v2 === 'country') {
          return address_components[v2] = v1.short_name;
        } else {
          return address_components[v2] = v1.long_name;
        }
      }));
      if (address_components.street_number && address_components.route) {
        this.set('model.location.address', `${address_components.street_number} ${address_components.route}`);
      } else if (address_components.route) {
        this.set('model.location.address', `${address_components.route}`);
      } else {
        this.set('model.location.address', '');
      }
      const locationName = this.get('model.location.address') || name;
      this.set('model.location.name', locationName);
      if (city = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood) {
        this.set('model.location.city', city);
      } else {
        this.set('model.location.city', '');
      }
      if (address_components.administrative_area_level_1) {
        this.set('model.location.state', address_components.administrative_area_level_1);
      } else {
        this.set('model.location.state', '');
      }
      if (address_components.country) {
        this.set('model.location.country', address_components.country);
      } else {
        this.set('model.location.country', '');
      }
      if (address_components.postal_code) {
        this.set('model.location.postal_code', address_components.postal_code);
      } else {
        this.set('model.location.postal_code', '');
      }
      return this.$('.search-field').attr('placeholder', 'Enter Location');
    },
    addMarker(position, draggable = true) {
      this.markers.forEach(marker => marker.setMap(null));
      this.markers.clear();
      const animation = google.maps.Animation.DROP;
      const marker = new google.maps.Marker({
        map: this.map,
        position,
        draggable,
        animation,
        icon: {
          url: '/assets/images/property_pin.svg',
          scaledSize: new google.maps.Size(50, 80),
          origin: new google.maps.Point(0, 0)
        }
      });
      this.markers.push(marker);
      return marker;
    },
    findAddressForPlace(lat, lng) {
      return new Promise(function (resolve, reject) {
        const lat_lng = new google.maps.LatLng(lat, lng);
        const geocoder = new google.maps.Geocoder();
        return geocoder.geocode({
          'location': lat_lng
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              return resolve(results[0]);
            } else {
              return reject('No results');
            }
          } else {
            return reject('geocoder is not reponding');
          }
        });
      });
    },
    fitAllFeatures() {
      const bounds = new google.maps.LatLngBounds();
      this.map.data.forEach(feature => {
        return feature.getGeometry().forEachLatLng(latLng => {
          return bounds.extend(latLng);
        });
      });
      if (!bounds.isEmpty()) {
        this.map.fitBounds(bounds);
      }
      return bounds;
    },
    removeFeatures() {
      return this.map.data.forEach(feature => {
        return this.map.data.remove(feature);
      });
    },
    loadGeoJson(file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          const xml = Ember.$.parseXML(reader.result);
          const geoJson = toGeoJSON.kml(xml);
          this.removeFeatures();
          // WTF is this?
          geoJson.features[0].properties.type = 'Polygon';
          this.map.data.addGeoJson(geoJson);
          const bounds = this.fitAllFeatures();
          return this.findAddressForPlace(bounds.getCenter().lat(), bounds.getCenter().lng()).then(response => {
            this.addMarker(response.geometry.location, false);
            return this.setAddress(response);
          }).catch(() => {
            alert('We could not find an address based on lat and lng');
          });
        } catch (error) {
          return alert('Failed to load this KML file.');
        }
      };
      if (file) {
        return reader.readAsBinaryString(file);
      }
    },
    actions: {
      create(model) {
        return this.action(model);
      },
      add_geojson() {
        this.loadGeoJson(event.currentTarget.files[0]);
        return event.target.value = null;
      },
      // make sure we can select same file again.
      toggleShowInput() {
        return this.set('showInput', !this.get('showInput'));
      }
    }
  });
});