define("admin/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_FORMAT = 'MM/DD/YY';
  function formatDate([inputDate, format = DEFAULT_FORMAT, placeholder = '-']) {
    const date = (0, _moment.default)(inputDate);
    return date.isValid() ? date.format(format) : placeholder;
  }
  var _default = _exports.default = Ember.Helper.helper(formatDate);
});