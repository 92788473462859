define("admin/serializers/template", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TemplateSerializer;
  TemplateSerializer = _application.default.extend({
    extractAttributes: function (modelClass, resourceHash) {
      var attributes;
      attributes = this._super(modelClass, resourceHash);
      if (resourceHash.relationships.shot_types != null) {
        attributes['shot_type_ids'] = resourceHash.relationships.shot_types.data.mapBy('id');
      }
      return attributes;
    }
  });
  var _default = _exports.default = TemplateSerializer;
});