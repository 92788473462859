define("admin/serializers/pilot", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionSerializer;
  MissionSerializer = _application.default.extend();
  var _default = _exports.default = MissionSerializer;
});