define("admin/templates/components/no-stripe-account-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+Tkoumh2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"no-stripe-account-warning\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"on-hold-warning\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Note:\"],[13],[2,\" This pilot does not yet have a connected Stripe account for this legal entity. You can can still queue up a\\n    payout. The pilot will be automatically notified by email to create their account, but you may want to follow up\\n    with them later so they receive funds properly.\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/no-stripe-account-warning.hbs"
    }
  });
});