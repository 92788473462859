define("admin/data/shot-type/shot-template-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: "Birds Eye",
    value: 'birds_eye'
  }, {
    name: "Cardinal Direction",
    value: "cardinal_direction"
  }, {
    name: "Crane Shot",
    value: "crane_shot"
  }, {
    name: "Creative",
    value: "creative"
  }, {
    name: "Custom",
    value: "custom"
  }, {
    name: "Customer Request",
    value: "customer_request"
  }, {
    name: "Dolly Shot",
    value: "dolly"
  }, {
    name: "Inspection",
    value: "inspection"
  }, {
    name: "Image Series",
    value: "image_series"
  }, {
    name: "Low Level Capture",
    value: "low_level_capture"
  }, {
    name: "Oblique",
    value: "oblique"
  }, {
    name: "Ortho",
    value: 'ortho'
  }, {
    name: "Orbit Shot",
    value: "orbit"
  }, {
    name: "Pano Tiles",
    value: "pano_tiles"
  }, {
    name: "Reverse Dolly",
    value: "reverse_dolly"
  }, {
    name: "Rise & Reveal",
    value: "rise_and_reveal"
  }, {
    name: "Rise & Reverse Away",
    value: "rise_and_reverse_away"
  }, {
    name: "Rise & Descend",
    value: "rise_and_descend"
  }, {
    name: "Sample Funnel",
    value: "sample_funnel"
  }, {
    name: "Showcase",
    value: "showcase"
  }, {
    name: "Trucking",
    value: "trucking"
  }, {
    name: "3D Model",
    value: 'model_3d'
  }, {
    name: "360 Spin",
    value: "spin_360"
  }];
});