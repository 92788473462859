define("admin/validations/capture-group-pilot", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const laborAmountValidator = function () {
    return (key, newValue, oldValue, changes, content) => {
      let laborType = content.get('estimated_labor_payout_type');
      if (changes.hasOwnProperty('estimated_labor_payout_type')) {
        laborType = changes.estimated_labor_payout_type;
      }
      if (!!laborType && newValue <= 0) {
        return 'Please enter the labor value';
      } else {
        return true;
      }
    };
  };
  var _default = _exports.default = {
    'currency': (0, _validators.validatePresence)(true),
    'estimated_labor_payout_type': (0, _validators.validateInclusion)({
      allowBlank: true,
      list: ['per_asset', 'per_day']
    }),
    'estimated_labor_payout_amount': laborAmountValidator(),
    'estimated_standby_payout': (0, _validators.validateNumber)({
      gte: 0
    }),
    'estimated_mobilization_payout': (0, _validators.validateNumber)({
      gte: 0
    })
  };
});