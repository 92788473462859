define("admin/templates/site-inspections/pilots-assign-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5Dw/VmcO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"action\",\"appendedClasses\",\"animatable\",\"fullScreen\"],[\"closeAction\",\"full-screen-modal\",true,\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container-fluid work-order-assign\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"full-screen-modal-toprow\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"\\n        Assign Pilot\\n\"],[6,[37,2],[[30,[36,1],[[35,0,[\"assignmentEntityType\"]],\"work order\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"          to work order \"],[1,[35,0,[\"assignmentEntities\",\"external_id\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"close-modal\"],[12],[2,\"\\n        \"],[11,\"img\"],[24,\"src\",\"/assets/images/X.svg\"],[4,[38,3],[[32,0],\"closeAction\"],null],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"search\",\"results\",\"closeAction\",\"model\",\"assign\",\"placeholder\"],[[30,[36,3],[[32,0],\"searchPilots\"],null],[35,0,[\"pilots\"]],[30,[36,3],[[32,0],\"closeAction\"],null],[35,0],true,\"Pilot First Name, Last Name, City, Zip, Drone type\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"is-equal\",\"if\",\"action\",\"site-inspections/pilot-assign\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/site-inspections/pilots-assign-modal.hbs"
    }
  });
});