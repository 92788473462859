define("admin/mixins/s3-asset-uploads", ["exports", "ember-inflector", "admin/config/environment", "admin/data/allowed-extensions"], function (_exports, _emberInflector, _environment, _allowedExtensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MissionAdapter {
    xhrAPIRequestUrl(file_type, model) {
      return `${_environment.default.api.host}/v1/admin/missions/${model.mission.id}/${file_type}s`;
    }
    saveAsset(file_type, asset, model) {
      return model.mission.get(file_type + 's').unshiftObject(asset);
    }
  }
  class ShotAssetAdapter {
    xhrAPIRequestUrl(file_type, model) {
      return `${_environment.default.api.host}/v1/admin/shot_types/${model.id}/sample_images`;
    }
    saveAsset(file_type, asset, model) {
      return model.get('sample_images').unshiftObject(asset);
    }
  }

  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    getAdapter() {
      if (!this.adapter) {
        if (this.routeName === 'shot_lists.edit' || this.routeName === 'shot_lists.new') {
          this.adapter = new ShotAssetAdapter();
        } else if (this.routeName === 'missions.edit') {
          this.adapter = new MissionAdapter();
        }
      }
      return this.adapter;
    },
    lookupAsset(fileType, file) {
      var asset, store;
      asset = null;
      store = this.get('store');
      store.peekAll(fileType).filter(function (a) {
        if (a.get('id') === file.get('id')) {
          return asset = a;
        }
      });
      return asset;
    },
    session: Ember.inject.service(),
    headers: {},
    s3SignedUrl: {},
    setupController(controller, model) {
      this._super(controller, model);
      return this.setAuthHeaders(model);
    },
    actions: {
      addAsset(file, payload = {}, model = null) {
        const store = this.get('store');
        if (!model) {
          model = this.modelFor(this.routeName);
        }
        if (model.mission) {
          payload['mission_id'] = model.mission.id;
        } else {
          payload['shot_type_id'] = model.id;
        }
        file.set('authHeaders', this.headers);
        const rawType = file.get('type');
        const fileName = file.get('name');
        let fileType = rawType.match(_allowedExtensions.allowedTypeMatcher)?.[0];
        switch (true) {
          // current web APIs assign empty string for uncommon file types
          // https://developer.mozilla.org/en-US/docs/Web/API/File/type
          // thus an iiq file may not have matched on type
          case !fileType && file.get('name').match(/\.iiq/i):
            file.set('type', 'image/tiff');
            fileType = 'image';
            break;
          case ['ats', 'text'].includes(fileType) || !!(!fileType && fileName.match(_allowedExtensions.dataFileExtensionMatcher)):
            file.set('type', rawType || 'application/octet-stream');
            fileType = 'data_file';
            break;
          case ['zip_files', 'composer_roof_report', 'pointivo_out_zip', 'pointivo_in_zip', 'thermal_roof_report', 'pavement_report', 'asset_report', 'comprehensive_report'].includes(payload['bucket_type_slug']):
            fileType = 'attachment';
            break;
          case payload['deliverable_type'] === 'ortho_photo_map':
            fileType = 'ortho_photo_map';
            break;
          case payload['deliverable_type'] === 'ortho_dsm_photo_map':
            fileType = 'ortho_dsm_photo_map';
            break;
          case fileType === 'zip' && fileName.slice(0, 4) === 'aero':
            fileType = 'data_file';
            break;
          // TODO: handle via deliverable_type
          case fileType === 'zip':
            fileType = 'panorama';
            break;
          default:
            // do nothing
            break;
        }
        this.lookupAsset(fileType, file);
        return file.completeUpload(payload, fileType, this.headers).then(response => {
          const mission = store.peekRecord('mission', response.data.relationships.mission.data.id);
          const attributes = {
            id: response.data.id,
            uploaded: true,
            mission
          };
          if (response.data.relationships.shot) {
            attributes["shot"] = store.peekRecord('shot', response.data.relationships.shot.data.id);
          }
          if (response.data.relationships.bucket) {
            attributes["bucket"] = store.peekRecord('bucket', response.data.relationships.bucket.data.id);
          }
          store.createRecord((0, _emberInflector.singularize)(response.data.type), Ember.merge(response.data.attributes, attributes));
        }, function (error) {
          console.error("Error uploading: " + error);
        });
      },
      removeAsset(uploader, file) {
        return uploader.removeFile(file.content);
      },
      startUpload(uploader) {
        return uploader.start();
      },
      willTransition() {
        this.set('headers', {});
        return this.set('s3SignedUrls', {});
      }
    },
    xhrAPIResponse(file_type, response, model) {
      let asset;
      this.store.pushPayload(response);
      if (this.routeName === 'shot_lists.edit' || this.routeName === 'shot_lists.new') {
        asset = this.store.peekRecord('shot_type_sample_image', response.data.id);
      } else {
        asset = this.store.peekRecord(file_type, response.data.id);
      }
      return this.getAdapter().saveAsset(file_type, asset, model);
    },
    xhrAPIRequest(file_type, data, model) {
      const url = this.getAdapter().xhrAPIRequestUrl(file_type, model);
      return Ember.$.ajax({
        url,
        type: 'POST',
        dataType: 'json',
        data,
        headers: this.get('headers')
      });
    },
    setAuthHeaders() {
      let headers = {};
      if (this.get('session.isAuthenticated')) {
        let {
          email,
          token
        } = this.get('session.data.authenticated');
        headers['Authorization'] = `Token token="${token}", email="${email}"`;
      }
      return this.set('headers', headers);
    }
  });
});