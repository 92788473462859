define("admin/mirage/factories/badge", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i}`;
    },
    name: 'name',
    internal_name: 'internal_name',
    created_at: moment(),
    auto_dispatch_batch_size: 100,
    auto_dispatch_enabled: false
  });
});