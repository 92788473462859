define("admin/adapters/mission", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAdapter;
  MissionAdapter = _application.default.extend({
    namespace: _environment.default.api.namespace + '/admin',
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    download: function (data, options) {
      var xhrOptions;
      xhrOptions = {
        url: this.urlForQuery(null, 'mission') + ".csv",
        data: data,
        method: 'GET',
        headers: this.get('sessionAccount.headers')
      };
      if (options['onProgress']) {
        xhrOptions['xhr'] = function (_this) {
          return function () {
            var xhr;
            xhr = Ember.$.ajaxSettings.xhr();
            xhr.onprogress = options['onProgress'];
            return xhr;
          };
        }(this);
      }
      return Ember.$.ajax(xhrOptions).done(function (data, status, xhr) {
        var a, blob, filename, url;
        blob = new Blob([data], {
          type: 'text/csv'
        });
        a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        url = window.URL.createObjectURL(blob);
        a.href = url;
        filename = xhr.getResponseHeader('Content-Disposition').match(/attachment; filename="([^"]+)"/)[1];
        a.download = filename != null ? filename : {
          filename: 'mission-report.csv'
        };
        a.click();
        window.URL.revokeObjectURL(url);
        return a.remove();
      });
    },
    promoteAssets: function (mission) {
      var url;
      url = _environment.default.api.host + "/v1/admin/missions/" + mission.get('id') + "/promote_all_assets";
      return this.ajax(url, 'POST');
    },
    bulkStatusUpdate: function (data) {
      return $.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/bulk_action/status_update",
        method: 'POST',
        headers: this.get('sessionAccount.headers'),
        data: data
      });
    },
    bulkAddToExistingCaptureGroup: function (data) {
      return $.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/bulk_action/assign_to_capture_group",
        method: 'POST',
        headers: this.get('sessionAccount.headers'),
        data: data
      });
    },
    bulkAddToNewCaptureGroup: function (data) {
      return $.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/bulk_action/create_capture_group",
        method: 'POST',
        headers: this.get('sessionAccount.headers'),
        data: data
      });
    },
    moveAssets: function (missionId, data) {
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + missionId + "/images/bulk_update",
        data: data,
        method: 'POST',
        headers: this.get('sessionAccount.headers')
      });
    },
    removeAssets: function (missionId, data) {
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + missionId + "/images/bulk_destroy",
        data: data,
        method: 'POST',
        headers: this.get('sessionAccount.headers')
      });
    }
  });
  var _default = _exports.default = MissionAdapter;
});