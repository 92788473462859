define("admin/controllers/inventories-lists/inventory-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    inventoryItems: Ember.computed('model.inventoryItems', function () {
      return this.get('model.inventoryItems');
    }),
    groupedItems: Ember.computed('model.inventoryItems.@each.status', 'model.inventoryItems.[]', function () {
      const inventoryItems = this.get('model.inventoryItems');
      const groupedItemsList = this.get('model.groupedItemsList');
      const itemTypes = [{
        kind: 'adapters',
        filterType: 'Adapter'
      }, {
        kind: 'cameras',
        filterType: 'DroneCamera'
      }, {
        kind: 'drones',
        filterType: 'Drone'
      }, {
        kind: 'other-equipment',
        filterType: 'PilotEquipment'
      }];
      for (let itemType of itemTypes) {
        let items = inventoryItems.filter(item => {
          return item.inventory_type === itemType.filterType && item.status !== 'archived';
        });
        this.get('model.groupedItemsList').findBy('kind', itemType.kind).set('items', items.sortBy('unit'));
      }
      this.get('model.groupedItemsList').findBy('kind', 'archived').set('items', inventoryItems.filterBy('status', 'archived').sortBy('full_name'));
      return this.get('model.groupedItemsList');
    }),
    actions: {
      addSavedItem: function (item) {
        this.get('model.inventoryItems').unshiftObject(item);
      },
      toggleCollapsed: function (group) {
        group.toggleProperty('collapsed');
      }
    }
  });
});