define("admin/utils/sort-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(objects, fieldName, order) {
    const results = objects.sortBy(fieldName);
    if (order === 'asc') {
      return results;
    } else {
      return results.reverse();
    }
  }
});