define("admin/components/clients/client-profile-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "form",
    showMatchingOrganizations: false,
    init: function () {
      this._super();
      this.set('initialVerticals', this.get('model.client.verticals').toArray());
      this.set('org_q', this.model.client.get('organization.name'));
      this.model.client.store.query('organization', {
        page: 0,
        per_page: 'Infinity'
      }).then(result => {
        this.set('loadedOrganizations', result);
      });
    },
    didInsertElement() {
      this.element.addEventListener('click', this.cancelOrganizationSearch.bind(this));
    },
    saveButtonText: Ember.computed('model.client', function () {
      if (this.get('model.client.id')) {
        return 'Save';
      } else {
        return 'Create';
      }
    }),
    verticalsToSelectOptions: Ember.computed('model.{client.verticals.length,verticalsToSelect}', function () {
      return this.get('model.verticalsToSelect').filter(x => !this.get('model.client.verticals').includes(x));
    }),
    verticalErrors: Ember.computed('model.client.{errors.verticals,verticals.length}', function () {
      if (this.get('model.client.verticals.length')) {
        return [];
      }
      return this.get('model.client.errors').get('verticals');
    }),
    submit: function (e) {
      e.preventDefault();
      this.saveClientAction(this.get('model.client'), this.get('model.client.organization'));
    },
    matchingOrganizations: Ember.computed('org_q', 'loadedOrganizations.length', function () {
      if (this.loadedOrganizations.length > 0) {
        return this.loadedOrganizations.filter(org => {
          if (this.org_q) {
            return org.name.toLowerCase().includes(this.org_q.toLowerCase() || '');
          } else {
            return true;
          }
        });
      } else {
        return [];
      }
    }),
    cancelOrganizationSearch: function (e) {
      if (!this.showMatchingOrganizations) {
        return false;
      }
      const clickOnOrgSelect = e.path[0].className.includes('organizations-select-input') || e.path[0].className.includes('organization-name');
      if (clickOnOrgSelect) {
        return false;
      }
      const queryDoesnotMatch = this.get('org_q') !== this.get('model.client.organization.name');
      if (queryDoesnotMatch) {
        this.set('org_q', this.get('model.client.organization.name'));
      }
      this.send('toggleShowMatchingOrganizations');
    },
    actions: {
      closeModal: function () {
        this.get('model.client').rollbackAttributes();
        this.set('model.client.verticals', this.get('initialVerticals'));
        this.closeModalAction();
      },
      addVertical: function () {
        const verticalInput = this.element.querySelector("input[name='verticals-selection']");
        const vertical = this.get('model.verticalsToSelect').filter(el => el.get('name') == verticalInput.value)[0];
        this.get('model.client.verticals').pushObject(vertical);
        verticalInput.value = '';
      },
      removeVertical: function (vertical) {
        this.get('model.client.verticals').removeObject(vertical);
      },
      selectOrganization: function (organization) {
        this.set('model.client.organization', organization);
        this.set('org_q', organization.get('name'));
        this.send('toggleShowMatchingOrganizations');
      },
      toggleShowMatchingOrganizations: function () {
        this.toggleProperty('showMatchingOrganizations');
      }
    }
  });
});