define("admin/components/missions/full-reshoot", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    copyShots: null,
    priceZero: null,
    cantSetParentToZero: Ember.computed('model.mission.status', function () {
      return ['invoiced', 'complete'].indexOf(this.get('model.mission.status')) >= 0;
    }),
    actions: {
      setRejectionReason(reasonSlug) {
        this.get('setRejectionReasonSlug')(reasonSlug);
        return this.set('rejectionReason', reasonSlug);
      },
      setPriceZero(value) {
        return this.set('priceZero', value);
      },
      setCopyShots(value) {
        return this.set('copyShots', value);
      },
      close() {
        return this.close();
      },
      reshoot(model) {
        let left;
        if (!$('.form-horizontal select').val()) {
          $($('.error-message')[0]).show();
        } else {
          $($('.error-message')[0]).hide();
        }
        if (this.get('priceZero') === null) {
          $($('.error-message')[1]).show();
        } else {
          $($('.error-message')[1]).hide();
        }
        if (this.get('copyShots') === null) {
          $($('.error-message')[2]).show();
        } else {
          $($('.error-message')[2]).hide();
        }
        if (!$('.form-horizontal select').val() || this.get('copyShots') === null || this.get('priceZero') === null) {
          return;
        }
        const planned_date = $('.delivery-requirements .planned-date input').val();
        const promised_date = $('.delivery-requirements .promised-date input').val();
        const data = {
          'planned_delivery_date': planned_date,
          'promised_delivery_date': promised_date,
          'reason_id': this.get('rejectionReason'),
          'reason': this.get('rejectionNotes'),
          'copy_shots': (left = this.get('copyShots')) != null ? left : {
            true: null
          },
          'price_zero': this.get('priceZero')
        };
        this.get('close')();
        return this.reshootAction(model, data);
      }
    }
  });
});