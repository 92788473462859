define("admin/services/pusher-data", ["exports", "admin/config/environment", "pusher-js"], function (_exports, _environment, _pusherJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    subscribeToMissionChannel(mission) {
      this.set('mission', mission);
      _pusherJs.default.logToConsole = _environment.default.environment !== "production";
      var presenceChannel = this.subscribe("presence-mission-" + mission.id);
      presenceChannel.bind('pusher_internal:member_added', data => {
        this._showSubscriber(data);
      });
      presenceChannel.bind('pusher:subscription_succeeded', data => {
        this._showSubscriber(data);
      });
      presenceChannel.bind('pusher:member_added', data => {
        this._showSubscriber(data);
      });
      presenceChannel.bind('pusher:member_removed', data => {
        this._removeSubscriber(data);
      });
      presenceChannel.bind("mission-update", data => {
        this._showUpdated(data);
      });
    },
    subscribe(channelName) {
      var pusher = this.get('pusher');
      if (pusher == undefined) {
        var pusherKey, store;
        pusherKey = _environment.default.pusher_key;
        pusher = new _pusherJs.default(pusherKey, {
          cluster: _environment.default.pusher_cluster,
          authEndpoint: _environment.default.api.host + "/v1/admin/pusher/auth",
          auth: {
            headers: this.get('sessionAccount.headers')
          },
          encrypted: true
        });
        this.set('pusher', pusher);
      }
      return pusher.subscribe(channelName);
    },
    _showUpdated(data) {
      if (this.get('session.data.authenticated.id') != data.updated_by) {
        this.get('mission').set('pusher_updated_at', data.new_update);
      }
    },
    _showSubscriber(data) {
      for (var key in data.members) {
        if (key != undefined) {
          var current_admin = data.members[key].name;
          if (!this.get('mission').pusherSubscribers.includes(current_admin)) {
            this.get('mission').pusherSubscribers.pushObject(current_admin);
          }
        }
      }
      if (data.info != undefined) {
        current_admin = data.info.name;
        if (!this.get('mission').pusherSubscribers.includes(current_admin)) {
          this.get('mission').pusherSubscribers.pushObject(current_admin);
        }
      }
    },
    _removeSubscriber(data) {
      if (data.info != undefined) {
        var leavingAdmin = data.info.name;
        var filteredSubscribers = this.get('mission').pusherSubscribers.filter(function (val) {
          return val != leavingAdmin;
        });
        this.get('mission').set('pusherSubscribers', filteredSubscribers);
      }
    }
  });
});