define("admin/templates/components/onboarding/pilot-equipment-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LJv3nYhp",
    "block": "{\"symbols\":[\"pilotEquipmentItem\"],\"statements\":[[6,[37,8],null,[[\"title\"],[[35,7]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"pilot-equipment-data\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"selections\",\"model\",\"checked\"],[[35,2],[32,1],[30,[36,1],[[35,0],[32,1,[\"id\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pilotEquipmentIds\",\"includes\",\"selectedPilotEquipment\",\"checkbox-item\",\"pilotEquipment\",\"-track-array\",\"each\",\"pilotEquipmentTitle\",\"collapsible-sidebar-item\"]}",
    "meta": {
      "moduleName": "admin/templates/components/onboarding/pilot-equipment-filter.hbs"
    }
  });
});