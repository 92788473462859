define("admin/adapters/application", ["exports", "ember-data", "admin/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    shouldReloadRecord: function () {
      return true;
    },
    namespace: _environment.default.api.namespace,
    host: _environment.default.api.host,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.token', function () {
      let headers = {};
      if (this.get('session.isAuthenticated')) {
        let {
          email,
          token
        } = this.get('session.data.authenticated');
        headers['Authorization'] = `Token token="${token}", email="${email}"`;
        headers['X-API-TOKEN'] = _environment.default.api.app_token;
      }
      return headers;
    }),
    pathForType: function (type) {
      const underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    ajaxError: function (jqXHR) {
      error = this._super(jqXHR);
      if (jqXHR.status == 422) {
        const response = Ember.$.parseJSON(jqXHR.responseText);
        errors = {};
        if (typeof response.errors !== 'undefined') {
          const jsonErrors = response.errors;
          Ember.keys(jsonErrors).forEach(function (key) {
            errors[Ember.String.camelize(key)] = jsonErrors[key];
          });
        }
        if (typeof response.message !== 'undefined') {
          errors['Message'] = response.message;
        }
        return new _emberData.default.InvalidError(errors);
      } else {
        return error;
      }
    }
  });
});