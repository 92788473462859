define("admin/components/mission-assets-sidebar", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['assets-sidebar'],
    timeSortedImages: Ember.computed('mission.images.[]', function () {
      return this.get('mission.images').filterBy('taken_at').sort((a, b) => a.takenAt.toDate() - b.takenAt.toDate());
    }),
    firstImageTakenAt: Ember.computed('mission.first_image_taken_at', function () {
      if (this.get('mission.first_image_taken_at')) {
        return moment(this.get('mission.first_image_taken_at'), "YYYY:MM:DD hh:mm:ss").format("MM/DD/YYYY [at] h:mm a");
      }
    }),
    lastImageTakenAt: Ember.computed('mission.last_image_taken_at', function () {
      if (this.get('mission.last_image_taken_at')) {
        return moment(this.get('mission.last_image_taken_at'), "YYYY:MM:DD hh:mm:ss").format("MM/DD/YYYY [at] h:mm a");
      }
    }),
    cameraMakes: Ember.computed('mission.images.[]', function () {
      return this.get('mission.images').uniqBy('camera_make').map(function (image) {
        return image.camera_make;
      }).compact().join(', ');
    }),
    cameraModels: Ember.computed('mission.images.[]', function () {
      return this.get('mission.images').uniqBy('camera_model').map(function (image) {
        return image.camera_model;
      }).compact().join(', ');
    }),
    pilotPaid: Ember.computed('mission.payouts.[]', function () {
      let createdOn = null;
      this.get('mission.payouts').forEach(function (payout) {
        if (payout.get('payout_to') === 'pilot') {
          createdOn = payout.get('created_on');
          if (!createdOn) {
            createdOn = Date.now();
          }
        }
      });
      return createdOn;
    }),
    roofReportsCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'composer_roof_report';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    zipFilesCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'zip_files';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    assetReportFilesCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'asset_report';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    comprehensiveReportFilesCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'comprehensive_report';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    thermalRoofReportsCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'thermal_roof_report';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    pavementReportsCount: Ember.computed('mission.buckets.@each.attachments.[]', function () {
      const bucket = this.get('mission.buckets').find(function (bucket) {
        return bucket.get('bucket_type.slug') === 'pavement_report';
      });
      if (bucket) {
        return bucket.get('readyAttachmentsCount');
      }
    }),
    actions: {
      toggleRatingModal: function () {
        return this.toggleRatingModalAction();
      },
      togglePilotPayoutModal: function () {
        return this.togglePilotPayoutModal();
      },
      regenerateZip: function (mission) {
        return mission.regenerateZip();
      },
      openFullView: function () {
        this.openFullViewAction();
      }
    }
  });
});