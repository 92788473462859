define("admin/models/inventory-repair", ["exports", "ember-data", "admin/data/inventory-constants"], function (_exports, _emberData, _inventoryConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    outbound_tracking_number: _emberData.default.attr('string'),
    damage_type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    inventory_item: _emberData.default.belongsTo('inventory-item', {
      async: false,
      inverse: 'active_repair'
    }),
    outboundLink: Ember.computed('outbound_tracking_number', function () {
      return `${_inventoryConstants.fedExTrackLink}${this.get('outbound_tracking_number')}`;
    })
  });
});