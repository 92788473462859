define("admin/services/titleizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleize;
  function titleize(string) {
    if (string == null || string == undefined || string == '') {
      return '';
    }
    return string.split('_').map(function (st) {
      if (st == null || st == undefined || st.length < 1) {
        return '';
      }
      return st.charAt(0).toUpperCase() + st.slice(1);
    }).join(' ');
  }
});