define("admin/components/capture-groups/pilot-assign", ["exports", "admin/components/pilot-search-autocomplete"], function (_exports, _pilotSearchAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _pilotSearchAutocomplete.default.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    init() {
      this._super();
      this.get('distances').unshift('any');
      this.set('distance', 'any');
      this.set('pilotRateOptions', ['per_asset_own', 'per_asset_leasing', 'per_day'].map(value => ({
        value
      })));
    },
    willDestroyElement() {
      if (Ember.isPresent(this.get('pilotInvited'))) {
        this.set('pilotInvited', false);
      }
    },
    processSearch() {
      let query = this.get('query');
      let distance = this.get('distance');
      let model = this.get('model');
      let params = {
        q: query,
        distance,
        include: ['drones', 'drones.cameras', 'devices', 'pilot_equipment'],
        drone_ids: this.get('selectedDrones').getEach('id'),
        camera_ids: this.get('selectedCameras').getEach('id'),
        device_ids: this.get('selectedDevices').getEach('id'),
        pilot_equipment_ids: this.get('selectedEquipment').getEach('id'),
        document_ids: this.get('selectedDocuments').getEach('id'),
        pilot_certificate_ids: this.get('selectedCertificates').getEach('id'),
        statuses: this.get('statuses'),
        available_for_relative_mission: this.get('availableForRelativeMission'),
        no_dronebase_emails: this.get('noDronebasePilots'),
        has_drones: this.get('hasDrones'),
        per_page: this.get('maxResults'),
        badge_id: this.get('badgeId'),
        camera_mega_pixels: this.get('selectedMegaPixels'),
        sort_attribute: this.get('sortAttribute')
      };
      if (distance === 'any') {
        delete params.distance;
      }
      if (!query || query.length > this.charLimitForQuery) {
        this.set('results', []);
        this.send('search', params, model);
      }
    },
    // eslint-disable-next-line object-shorthand
    assignCaptureGroup: async function () {
      const pilot = this.get('pilotsList').firstObject;
      this.get('store').adapterFor('capture-group').assignPilot(this.get('sessionAccount.headers'), pilot, this.get('model.captureGroup')).then(() => {
        this.set('pilotInvited', pilot);
        this.get('model.captureGroup').reload();
        this.flashMessages.success(`You've assigned ${pilot.fullName} for capture group!`);
        return this.closeAction();
      }).catch(response => {
        alert(response.responseJSON.error.message);
      });
    },
    allowedLicenses: Ember.computed('model.licenses.[]', function () {
      let countries = this.get('model.missions').map(mission => mission.location.country);
      let licenses = this.get('model.licenses.[]');
      if (licenses) {
        return licenses.filter(license => license.country_codes.some(countryCode => countries.includes(countryCode)));
      } else {
        return [];
      }
    }),
    certificates: Ember.computed('model.licenses.[]', function () {
      if (this.get('allowedLicenses')) {
        return this.get('allowedLicenses').filter(license => license.is_flight_certificate);
      } else {
        return [];
      }
    }),
    actions: {
      toggleIncludePilot(pilot) {
        this.set('pilotsList', [pilot]);
        this.get('pilotList').setEach('invited', false);
        pilot.set('invited', true);
        this.set('numPilotsToInvite', 1);
      },
      assignPilot() {
        this.assignCaptureGroup();
      }
    }
  });
});