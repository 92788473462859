define("admin/models/standby-hours-reason", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    comment_required: (0, _model.attr)('boolean'),
    standby_hours_line_items: (0, _model.hasMany)('standby-hours-line-item', {
      async: false
    }),
    formattedName: Ember.computed('name', 'comment_required', function () {
      let reasonName = this.get('name');
      if (this.get('comment_required')) {
        if (!reasonName.includes(' - ')) {
          reasonName += ' -';
        }
        reasonName += ' (provide comment)';
      }
      return reasonName;
    })
  });
});