define("admin/models/pilot-drone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotDrone;
  PilotDrone = _emberData.default.Model.extend({
    drone: _emberData.default.belongsTo('drone', {
      async: false
    }),
    pilot: _emberData.default.belongsTo('pilot', {
      async: false
    }),
    cameras: _emberData.default.hasMany('drone-camera', {
      async: false
    }),
    hasOptionalCameras: Ember.computed('cameras.[]', function () {
      return this.get('cameras.length') > 0;
    })
  });
  var _default = _exports.default = PilotDrone;
});