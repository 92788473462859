define("admin/data/weekend-filter-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Any Day",
    value: 'any_day'
  }, {
    label: "Weekends Only",
    value: 'weekends_only'
  }, {
    label: "No Weekends",
    value: 'no_weekends'
  }];
});