define("admin/templates/components/info-icon-with-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a9PDx6QP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"info-icon-with-hint\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/info-sign.svg\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hint\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/info-icon-with-hint.hbs"
    }
  });
});