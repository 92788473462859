define("admin/routes/waivers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        waiver: this.store.createRecord('waiver')
      });
    }
  });
});