define("admin/components/search-input-delayed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SearchInputDelayedComponent;
  SearchInputDelayedComponent = Ember.TextField.extend({
    runner: null,
    boundValue: null,
    boundValueListener: Ember.observer('boundValue', function () {
      if (this.get('boundValue') === null) {
        return this.set('value', null);
      }
    }),
    keyUp: function (e) {
      var runner;
      if (runner = this.get('runner')) {
        Ember.run.cancel(runner);
      }
      return this.set('runner', Ember.run.later(this, 'setBoundValue', 500));
    },
    didInsertElement: function (e) {
      return Ember.run.schedule('afterRender', this, function () {
        return this.set('value', this.get('boundValue'));
      });
    },
    setBoundValue: function () {
      return this.set('boundValue', this.get('value'));
    }
  });
  var _default = _exports.default = SearchInputDelayedComponent;
});