define("admin/models/panorama", ["exports", "ember-data", "admin/models/shareable", "admin/config/environment"], function (_exports, _emberData, _shareable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Panorama;
  Panorama = _shareable.default.extend({
    source_type: _emberData.default.attr('string'),
    processing: _emberData.default.attr('boolean'),
    mission: _emberData.default.belongsTo('mission'),
    admin_share: _emberData.default.belongsTo('share'),
    shot: _emberData.default.belongsTo('shot'),
    processing_status: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') === 'edit';
    }),
    accessKey: _emberData.default.attr('string'),
    collabLink: Ember.computed('admin_share', function () {
      return _environment.default.clients.host + "/p/" + this.get('admin_share.id');
    })
  });
  var _default = _exports.default = Panorama;
});