define("admin/adapters/comprehensive-report-purchase", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/organizations/{organizationId}/comprehensive_report_purchases{/id}{?query*}',
    sessionAccount: Ember.inject.service(),
    urlSegments: {
      organizationId: function (type, id, snapshot, query) {
        return query === undefined ? snapshot.belongsTo('organization', {
          id: true
        }) : query.organization_id;
      },
      id: function (type, id, snapshot, query) {
        return query === undefined ? snapshot.id : query.id;
      }
    },
    loadMissions: (headers, data) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/comprehensive_report_purchases/missions`;
      const xhrOptions = {
        url: url,
        method: 'GET',
        headers: headers,
        data: data
      };
      return Ember.$.ajax(xhrOptions);
    },
    fetchStatuses: headers => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/comprehensive_report_purchases/statuses`;
      const xhrOptions = {
        url: url,
        method: 'GET',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    updateStatus: (headers, data, comprehensiveReportPurchase) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/comprehensive_report_purchases/${comprehensiveReportPurchase.get('id')}/status`;
      const xhrOptions = {
        url: url,
        data: data,
        method: 'PATCH',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    submit: (headers, data) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/organizations/${data['organization_id']}/comprehensive_report_purchases`,
        method: 'POST',
        dataType: 'json',
        headers: headers,
        data: data
      });
    }
  });
});