define("admin/utils/uploader/queue", ["exports", "admin/utils/uploader/file_proxy", "admin/utils/uploader/s3", "plupload", "admin/data/allowed-extensions"], function (_exports, _file_proxy, _s, _plupload, _allowedExtensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 10;
  const DEFAULT_URL_BATCH_SIZE = 50; // multiple of 10, see below
  const AWS_MAX_NUM_PARTS = 10_000;
  var _default = _exports.default = Ember.ArrayProxy.extend({
    browsing: false,
    failedUploads: Ember.A([]),
    filteringFiles: false,
    loaded: 0,
    name: null,
    numFilesUploading: 0,
    refreshRate: 750,
    store: Ember.inject.service(),
    uploadCount: 0,
    // going to be uploaded
    uploaded: Ember.A([]),
    // have been uploaded
    uploader: null,
    status: Ember.computed({
      get() {
        return this.get('uploader.state');
      }
    }),
    queueLength: Ember.computed.reads('length'),
    uploadsQueued: Ember.computed('queueLength', 'uploading', function () {
      return this.get('queueLength') && !this.get('uploading');
    }),
    uploading: Ember.computed('status', function () {
      return this.get('status') === _plupload.default.UPLOADING;
    }),
    started: Ember.computed('status', function () {
      return this.get('status') === _plupload.default.STARTED;
    }),
    stopped: Ember.computed('status', function () {
      return this.get('status') === _plupload.default.STOPPED;
    }),
    finished: Ember.computed('status', 'uploadsQueued', function () {
      return this.get('stopped') && !this.get('uploadsQueued');
    }),
    init() {
      this.set('content', Ember.A([]));
      return this._super();
    },
    disableBrowse(disable) {
      if (disable == null) {
        disable = true;
      }
      return this.get('uploader').disableBrowse(disable);
    },
    async uploadFile(uploader, file) {
      if (file.error) {
        file.set("content.status", _plupload.default.FAILED);
        return;
      }
      if (file.size === 0) {
        file.set("content.status", _plupload.default.FILE_SIZE_ERROR);
        return;
      }

      // e.g. 10k 10mb chunks won't allow files > 100gb so...
      // bigger chunks fewer at a time
      const [CHUNK_SIZE, URL_BATCH_SIZE] = file.size > AWS_MAX_NUM_PARTS * DEFAULT_CHUNK_SIZE ? [DEFAULT_CHUNK_SIZE * 10, DEFAULT_URL_BATCH_SIZE / 10] : [DEFAULT_CHUNK_SIZE, DEFAULT_URL_BATCH_SIZE];
      const queue = this;
      const numParts = Math.ceil(file.size / CHUNK_SIZE);
      const s3 = _s.default.create();
      const error_handler = response => {
        console.error(response.error || response.statusText || response);
        file.set("content.status", _plupload.default.FAILED);
        uploader.trigger('Error', {
          code: _plupload.default.HTTP_ERROR,
          message: response.error || response.statusText,
          file,
          status: response.status
        });
      };
      async function retry(fn, retries = 3, delay = 1000) {
        for (let i = 0; i < retries; i++) {
          try {
            return await fn();
          } catch (error) {
            if (i === retries - 1) throw error; // If it's the last attempt, throw the error
            await new Promise(resolve => setTimeout(resolve, delay));
          }
        }
      }

      // called repeatedly for files > CHUNK_SIZE * URL_BATCH_SIZE
      async function uploadChunkBatch({
        file,
        CHUNK_SIZE,
        s3,
        numParts,
        uploader,
        queue,
        error_handler
      }) {
        try {
          const response = await s3.setupMultipartUpload(file);
          const isFirstBatch = file.get("start_part") === 1;
          if (isFirstBatch) {
            file.set("upload_id", response.upload_id);
            file.set("upload_file_name", response.file_name);
            file.set("parts", Ember.A([])); // all the parts
          }

          file.set("part_urls", response.multipart_urls);
          const chunk_size = Math.min(CHUNK_SIZE, file.size);
          const upload_parts = []; // just the parts in this batch

          for (let partNumber = file.get("start_part"); partNumber <= file.get("end_part"); partNumber++) {
            const blob = file.content.getNative();
            const start = (partNumber - 1) * chunk_size;
            const possible_end = Math.min(start + chunk_size, file.size);
            const chunk = blob.slice(start, possible_end);
            const parts = file.get("parts");

            // Check for duplicate parts
            const existingPart = parts.find(part => part.startsWith(`${partNumber}:`));

            // part not found so upload it
            if (existingPart == null) {
              const signedURL = file.get("part_urls")[partNumber];
              upload_parts.push(retry(() => {
                return s3.multipartUpload(signedURL, chunk).then((_response, _textStatus, xhr) => {
                  const etag = xhr.getResponseHeader("etag");
                  const part_name = `${partNumber}:${etag}`;
                  parts.pushObject(part_name);
                  file.loaded = Math.min(file.size, (file.loaded || 0) + chunk.size);
                  uploader.trigger('UploadProgress', file);
                  uploader.trigger('ChunkUploaded', file);
                });
              }, 3, 1000) // 3 retries with a 1-second delay
              .catch(error_handler));
            }
          }
          await Ember.RSVP.all(upload_parts);
          if (file.get("end_part") < numParts) return; // not done yet
          // else let's do final admin and tell Sake we're done

          const rawType = file.get('type');
          const fileName = file.get('name');
          let fileType = rawType.match(_allowedExtensions.allowedTypeMatcher)?.[0];
          switch (true) {
            // current web APIs assign empty string for uncommon file types
            // https://developer.mozilla.org/en-US/docs/Web/API/File/type
            // thus an iiq file may not have matched on type
            case !fileType && file.get('name').match(/\.iiq/i):
              file.set('type', 'image/tiff');
              fileType = 'image';
              break;
            case ['ats', 'text'].includes(fileType) || !!(!fileType && fileName.match(_allowedExtensions.dataFileExtensionMatcher)):
              file.set('type', rawType || 'application/octet-stream');
              fileType = 'data_file';
              break;
            case fileType === 'zip' && fileName.slice(0, 4) === 'aero':
              fileType = 'data_file';
              break;
            // TODO: handle via deliverable_type
            case fileType === 'zip':
              fileType = 'panorama';
              break;
            default:
              // do nothing
              break;
          }
          const upload_name = file.get("upload_file_name");
          // Has already been uploaded. duplicate attampt
          if (queue.get("uploaded").includes(upload_name)) {
            return;
          }
          const completeUpload = file => s3.multipartComplete(file).then(response => {
            queue.get("uploaded").pushObject(upload_name);
            file.set("content.status", _plupload.default.DONE);
            file.loaded = file.size;
            uploader.trigger('UploadProgress', file);
            uploader.trigger('FileUploaded', file, response);
            queue.progressDidChange(uploader);
          }, error_handler);
          // For an image, check the EXIF data for the created date
          if (fileType === 'image') {
            const img = new _plupload.default.moxie.image.Image();
            img.load(file.content.getSource());
            var dateCreated;
            if (img.meta && img.meta.exif && img.meta.exif.DateTimeOriginal) {
              var date = img.meta.exif.DateTimeOriginal;
              date = date.replace(':', '/');
              date = date.replace(':', '/');
              /* eslint-disable no-undef */
              date += " " + moment(file.content.getSource().lastModifiedDate).format('ZZ');
              dateCreated = moment(date).format('YYYY-MM-DD HH:mm:ss ZZ');
            } else {
              dateCreated = moment(file.content.getSource().lastModifiedDate).format('YYYY-MM-DD HH:mm:ss ZZ');
              /* eslint-enable no-undef */
            }

            file.set("date_created", dateCreated);
            img.destroy();
            // complete the upload
            completeUpload(file);
          } else {
            completeUpload(file);
          }
        } catch (error) {
          error_handler(error);
        }
      }
      const numChunkBatches = Math.ceil(numParts / URL_BATCH_SIZE);
      for (let chunkBatch = 1; chunkBatch <= numChunkBatches; chunkBatch++) {
        file.set("start_part", (file.get("end_part") || 0) + 1);
        file.set("end_part", Math.min(file.get("start_part") + URL_BATCH_SIZE - 1, numParts));
        await uploadChunkBatch({
          file,
          CHUNK_SIZE,
          s3,
          numParts,
          uploader,
          queue,
          error_handler
        });
      }
    },
    configureUploader(config) {
      if (config == null) {
        config = {};
      }
      const uploader = new _plupload.default.Uploader(config);
      /* eslint-disable ember/new-module-imports */
      uploader.bind('BeforeUpload', Ember.run.bind(this, 'configureUpload'));
      uploader.bind('Browse', Ember.run.bind(this, 'onBrowse'));
      uploader.bind('ChunkUploaded', Ember.run.bind(this, 'chunkUploaded'));
      uploader.bind('Error', Ember.run.bind(this, 'onError'));
      uploader.bind('FileFiltered', Ember.run.bind(this, 'onFileFiltered'));
      uploader.bind('FilesAdded', Ember.run.bind(this, 'filesAdded'));
      uploader.bind('FilesRemoved', Ember.run.bind(this, 'filesRemoved'));
      uploader.bind('FileUploaded', Ember.run.bind(this, 'fileUploaded'));
      uploader.bind('StateChanged', Ember.run.bind(this, 'onStateChange'));
      uploader.bind('UploadComplete', Ember.run.bind(this, 'uploadComplete'));
      uploader.bind('UploadProgress', Ember.run.bind(this, 'progressDidChange'));
      uploader.settings.browse_button = [config.browse_button];
      const settings = Ember.copy(uploader.settings);
      this.set('uploadCount', settings.uploadCount);
      if (!this.get('uploadCount')) {
        this.set('uploadCount', 0);
      }
      delete settings.uploadCount;
      this.set('settings', settings);
      this.set('uploader', uploader);
      uploader.init();

      // Hijack the plupload upload function to use AWS presigned multipart upload
      const default_upload_listener = uploader.hasEventListener("uploadfile");
      uploader.removeEventListener("uploadfile", default_upload_listener[0].fn);
      uploader.bind("UploadFile", Ember.run.bind(this, 'uploadFile'));
      return uploader;
    },
    onBrowse() {
      return this.set('browsing', true);
    },
    onFileFiltered() {
      return this.set('filteringFiles', true);
    },
    destroy() {
      this._super();
      this.get('uploader').unbindAll();
      this.set('content', Ember.A([]));
      return this.set('uploader', null);
    },
    clearNumUploading() {
      this.set("failedUploads", []);
      this.set("loaded", 0);
      this.set("numFilesUploading", 0);
      this.set("progress", 0);
      this.set("size", 0);
    },
    refresh() {
      return this.get('uploader').refresh();
    },
    size: Ember.computed(function () {
      return this.get('uploader.total.size') || 0;
    }),
    progress: Ember.computed('loaded', 'size', 'queue', function () {
      const uploader = this.get("uploader");
      if (uploader) {
        const loaded = uploader.total.failed + uploader.total.uploaded;
        const total = uploader.total.failed + uploader.total.uploaded + uploader.total.queued;
        return this.percent(loaded, total);
      } else {
        return 0;
      }
    }),
    percent(loaded, size) {
      const percent = loaded / size || 0;
      return Math.min(Math.floor(percent * 100), 100);
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime
      });
    },
    resizeThumbnail(file) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const maxW = 600;
      const maxH = 600;
      const img = document.createElement('img');
      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img, 0, 0, iwScaled, ihScaled);
        return file.set('thumbnail', URL.createObjectURL(this.dataURLtoBlob(canvas.toDataURL())));
      };
      img.src = URL.createObjectURL(file.content.getNative());
    },
    filesAdded(uploader, files) {
      this.set('browsing', false);
      this.set('filteringFiles', false);
      return (() => {
        const result = [];
        for (let file of Array.from(files)) {
          this.uploadCount += 1;
          this.numFilesUploading += 1;
          const filep = _file_proxy.default.create({
            uploadNumber: this.uploadCount,
            content: file,
            shot_id: this.get('shot_id')
          });
          if (this.get('target.showNativeThumbnail')) {
            this.resizeThumbnail(filep);
          }
          this.pushObject(filep);
          this.notifyPropertyChange('queueLength');
          if (!uploader.settings.disableAutoUpload) {
            this.get('target').onfileadd(filep);
            result.push(filep);
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    },
    filesRemoved(uploader, files) {
      for (let file of Array.from(files)) {
        const filep = this.findBy('id', file.id);
        if (filep) {
          this.removeObject(filep);
          this.get("failedUploads").removeObject(filep);
          this.notifyPropertyChange('failedUploads');
          this.notifyPropertyChange('size');
          this.notifyPropertyChange('loaded');
        }
      }
      this.uploadCount -= 1;
    },
    configureUpload(uploader, file) {
      let filep = this.findBy('id', file.id);
      if (filep == null) {
        return false;
      }
      uploader.settings = Ember.copy(this.get('settings'));
      Ember.assign(uploader.settings, filep.settings);
      filep.status = _plupload.default.UPLOADING;
      uploader.trigger("UploadFile", filep);
      uploader.trigger('UploadProgress', filep);
      return false;
    },
    chunkUploaded(_uploader, file) {
      this.set("loaded", (this.get("loaded") || 0) + (file.loaded || 0));
      this.notifyPropertyChange('loaded');
      file.set("content.percent", this.percent(file.loaded, file.size));
      file.notifyPropertyChanges();
    },
    progressDidChange(uploader) {
      this.notifyPropertyChange('size');
      this.notifyPropertyChange('progress');
      return uploader.refresh();
    },
    fileUploaded(_uploader, file, response) {
      this.removeObject(file);
      file._deferred.resolve(response);
    },
    uploadComplete(uploader) {
      Ember.run.later(this.get('uploader'), 'refresh', this.get("refreshRate"));
      this.notifyPropertyChange('loaded');
      uploader.state = _plupload.default.QUEUED;
    },
    onError(_uploader, error) {
      if (error.file) {
        const filep = this.findBy('id', error.file.id) || this.findBy('name', error.file.name);
        if (filep) {
          if (error.code === -602) {//plupload.FILE_DUPLICATE_ERROR
            // noop
            // this.get("dupeUploads").pushObject(filep); if we wanna track - just doing a hot fix for now
          } else {
            filep.set('error', true);
            this.get("failedUploads").pushObject(filep);
            this.notifyPropertyChange('failedUploads');
          }
          if (filep._deferred) {
            filep._deferred.reject(`Queue onError:417, error: ${JSON.stringify(error)}`);
          } else {
            filep.destroy();
            this.get('target').onfileadd(filep);
          }
        }
        this.notifyPropertyChange('length');
        return Ember.run.debounce(this.get('uploader'), 'refresh', this.get("refreshRate"));
        /* eslint-enable ember/new-module-imports */
      } else {
        this.set('error', error);
        return this.get('target').sendAction('onerror', error);
      }
    },
    onStateChange(uploader) {
      if (uploader.state === _plupload.default.STOPPED) {
        uploader.total.reset();
      }
      return this.notifyPropertyChange('status');
    }
  });
});