define("admin/mirage/factories/shot-list", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return i;
    },
    name: 'shot list name',
    afterCreate(shotList, server) {
      shotList.update({
        shotTypes: server.createList('shot-type', 3)
      });
    }
  });
});