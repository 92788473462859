define("admin/helpers/humanize-seconds", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizeSeconds = _exports.default = void 0;
  const LABELS = {
    long: {
      day: 'day',
      hour: 'hour',
      minute: 'minute',
      second: 'second'
    },
    short: {
      day: 'd',
      hour: 'hr',
      minute: 'min',
      second: 'sec'
    }
  };
  const humanizeSeconds = function (...args) {
    let seconds = parseInt(args[0]);
    const {
      labelFormat = 'short',
      minUnit = 'minute'
    } = args[1] || {};
    if (isNaN(seconds)) {
      return zero(labelFormat, minUnit);
    }
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds = seconds - days * 24 * 60 * 60;
    const hours = Math.floor(seconds / (60 * 60));
    seconds = seconds - hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds - minutes * 60;
    let result = [];
    if (days > 0) result.push((0, _emberInflector.pluralize)(days, LABELS[labelFormat]['day']));
    if (hours > 0) result.push((0, _emberInflector.pluralize)(hours, LABELS[labelFormat]['hour']));
    if (minutes > 0) result.push((0, _emberInflector.pluralize)(minutes, LABELS[labelFormat]['minute']));
    if (seconds > 0) result.push((0, _emberInflector.pluralize)(seconds, LABELS[labelFormat]['second']));
    return result.join(' ') || zero(labelFormat, minUnit);
  };
  _exports.humanizeSeconds = humanizeSeconds;
  function zero(labelFormat, minUnit) {
    return [0, LABELS[labelFormat][minUnit]].join(' ');
  }
  var _default = _exports.default = Ember.Helper.helper(humanizeSeconds);
});