define("admin/components/mission/assets/shot-thumbnail", ["exports", "admin/components/mission/assets/shot-base", "admin/data/allowed-extensions", "admin/config/environment"], function (_exports, _shotBase, _allowedExtensions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shotBase.default.extend({
    classNames: ['mission-assets-shot-thumbnail'],
    showAssetsCount: 12,
    assetUploadExtensions: [..._allowedExtensions.extendedList, ..._allowedExtensions.dataFileExtensions, ..._allowedExtensions.logFileExtensions].join(','),
    orthoUploadExtensions: _allowedExtensions.orthoExtensions.join(','),
    isMultiPageImages: Ember.computed('shot.imagePagingMeta', function () {
      return this.get('shot.imagePagingMeta.total_pages') > 1;
    }),
    seeMoreLink: Ember.computed('shot.imagePagingMeta', function () {
      if (this.isMultiPageImages) {
        return `See ${this.shot.imagePagingMeta.per_page - this.showAssetsCount} more of ${this.shot.imagePagingMeta.total_count}`;
      } else {
        return `See ${this.showMoreAssetsCount} more`;
      }
    }),
    showProcessingError: Ember.computed('showProcessingStatus', 'shot.{errorDataFilesCount,errorImagesCount,errorVideosCount,errorOrthosCount}', function () {
      if (this.get('showProcessingStatus')) {
        return false;
      }
      return this.get('shot.errorDataFilesCount') || this.get('shot.errorImagesCount') || this.get('shot.errorVideosCount') || this.get('shot.errorOrthosCount');
    }),
    showAeroProcessing: Ember.computed('shot.data_files.[]', function () {
      if (this.shot.get('name') == 'Turbine Title Cards') {
        return this.shot.get('data_files').filter(data_file => data_file.get('name').includes("aero_")).length > 0;
      }
      return false;
    }),
    actions: {
      goToAsset(asset, index) {
        this.goToAsset(asset, index);
      },
      openFullView() {
        this.get('shot').loadImages();
        this.openFullViewAction(this.get('shot'));
      },
      reprocessAero(missionId) {
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/reprocess_aero`,
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          alert('Reprocess request is successfully pushed');
          return location.reload();
        }, () => {
          return alert('Error reprocessing aero');
        });
      }
    }
  });
});