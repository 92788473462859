define("admin/routes/inventories-lists", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        inventoryItems: this.infinityModel('inventory-item', params),
        drones: this.store.findAll('drone'),
        cameras: this.store.findAll('drone-camera'),
        adapters: this.store.findAll('adapter'),
        otherEquipments: this.store.findAll('pilot-equipment'),
        droneManufacturers: this.store.query('drone-manufacturer', {}),
        dropships: this.store.findAll('dropship')
      });
    }
  });
});