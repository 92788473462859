define("admin/components/inventory/inventory-drones-fields", ["exports", "admin/components/inventory/inventory-items-fields"], function (_exports, _inventoryItemsFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _inventoryItemsFields.default.extend({
    itemsList: Ember.computed(function () {
      return this.get('dronesList').toArray();
    }),
    droneTypes: [{
      value: 'quadcopter',
      name: 'Quadcopter'
    }, {
      value: 'hexacopter',
      name: 'Hexacopter'
    }, {
      value: 'octocopter',
      name: 'Octocopter'
    }, {
      value: 'fixed_wing',
      name: 'Fixed Wing'
    }],
    actions: {
      newRelation(name) {
        const newDrone = this.set('relation', this.get('store').createRecord('drone', {
          name: name
        }));
        this.get('relation').set('drone_manufacturer', this.get('droneManufacturers.firstObject'));
        this.get('itemsList').addObject(newDrone);
        this.selectRelationAction(newDrone);
      },
      addStockCamera(value) {
        this.get('relation.stock_cameras').popObject();
        this.get('relation.stock_cameras').pushObject(value);
      },
      addOptionalCamera(value) {
        this.get('relation.optional_cameras').pushObject(value);
      },
      removeStockCamera(value) {
        this.get('relation.stock_cameras').removeObject(value);
      },
      removeOptionalCamera(value) {
        this.get('relation.optional_cameras').removeObject(value);
      }
    }
  });
});