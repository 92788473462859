define("admin/templates/components/camera-index-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Z01/xrID",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pe-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pe-title\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[[30,[36,0],[\"/equipment\"],null]]]],[14,0,\"unstyled-link\"],[12],[2,\"Cameras\\n        \"],[10,\"i\"],[14,0,\"fa fa-angle-down\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[[30,[36,0],[\"/equipment\"],null]]]],[14,0,\"btn btn-add\"],[12],[2,\"Add Camera\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to-react-app\"]}",
    "meta": {
      "moduleName": "admin/templates/components/camera-index-list.hbs"
    }
  });
});