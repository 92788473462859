define("admin/components/inventory/inventory-repair-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    record: Ember.computed('item', function () {
      return this.get('item');
    }),
    repair: Ember.computed('record', function () {
      return this.get('record').store.createRecord('inventory-repair', {
        inventory_item: this.get('record')
      });
    }),
    actions: {
      close() {
        this.closeAction();
      },
      submit() {
        this.get('repair').save().then(() => {
          this.get('record').reload();
          this.send('close');
        }).catch(() => {
          // handle errors here if needed in future
        });
      }
    }
  });
});