define("admin/serializers/image", ["exports", "admin/serializers/shareable"], function (_exports, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ImageSerializer;
  ImageSerializer = _shareable.default.extend();
  var _default = _exports.default = ImageSerializer;
});