define("admin/components/rating-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RatingModalComponent;
  RatingModalComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      close: function () {
        return this.get('close')();
      },
      setPilotRating: function (value) {
        Ember.$('.star').removeClass('selected');
        Ember.$('.star' + value).addClass('selected');
        return this.set('value', value);
      },
      rate: function (model, redirect) {
        var rating;
        if (!this.get('value')) {
          $($('.error-message')[0]).show();
          return;
        } else {
          $($('.error-message')[0]).hide();
        }
        this.get('close')();
        if (rating = model.mission.pilot_rating) {
          rating.set('value', this.get('value'));
        } else {
          rating = model.mission.store.createRecord('rating', {
            'mission': model.mission,
            'value': this.get('value')
          });
        }
        rating.save().then(function (_this) {
          return function (response) {
            if (response.errors.length > 0) {
              return alert(response.errors[0].detail);
            } else {
              return model.mission.set('pilot_rating', response);
            }
          };
        }(this));
        if (redirect) {
          return $($('.rating-modal-content form')).submit();
        }
      }
    }
  });
  var _default = _exports.default = RatingModalComponent;
});