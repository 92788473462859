define("admin/components/sortable-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SortableColumnComponent;
  SortableColumnComponent = Ember.Component.extend({
    tagName: 'th',
    classNames: ["sortable-column"],
    classNameBindings: ["active:active"],
    sortDirection: null,
    active: Ember.computed('currentSelected', 'column', function () {
      var direction, ref, selected;
      ref = this.get('currentSelected')[0].split(':'), selected = ref[0], direction = ref[1];
      return this.get('column') === selected;
    }),
    direction: Ember.computed('sortDirection', function () {
      if (this.get('sortDirection') === 'asc') {
        return 'up';
      } else {
        return 'down';
      }
    }),
    toggleSortDirection: function () {
      var direction;
      direction = this.get('sortDirection') === 'asc' ? 'desc' : 'asc';
      return this.set('sortDirection', direction);
    },
    click: function () {
      if (this.get('active')) {
        this.toggleSortDirection();
      }
      return this.action(this.get('column') + ":" + this.get('sortDirection'));
    },
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var direction, ref, selected;
        ref = this.get('currentSelected')[0].split(':'), selected = ref[0], direction = ref[1];
        if (this.get('column') === selected) {
          return this.set('sortDirection', direction);
        } else {
          if (!this.get('sortDirection')) {
            return this.set('sortDirection', 'asc');
          }
        }
      });
    }
  });
  var _default = _exports.default = SortableColumnComponent;
});