define("admin/templates/components/inventory/group-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YRxLagja",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"group-name\"],[12],[1,[30,[36,3],[[35,2,[\"kind\"]]],[[\"omitDash\"],[true]]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"group-list\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,2,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"selected-item\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"text\",\"onCancel\"],[[32,1,[\"unit\"]],[30,[36,0],[[32,0],\"unselectItem\",[32,1]],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"reusable/pill-component\",\"group\",\"capitalize\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inventory/group-items.hbs"
    }
  });
});