define("admin/templates/components/email-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RTj4flWo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],[35,2,[\"canInvitePilot\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"button-success\"],[4,[38,1],[[32,0],\"setClicked\"],null],[12],[2,\"Invite\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"button-success\"],[4,[38,1],[[32,0],\"setConfirmed\"],null],[12],[2,\"Do It\"],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"cancel\"],[4,[38,1],[[32,0],\"setIdle\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"success-message\"],[12],[10,\"img\"],[14,\"src\",\"/assets/images/green-check.svg\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"message\",\"action\",\"mission\",\"idle\",\"and\",\"if\",\"clicked\",\"confirmed\",\"error\"]}",
    "meta": {
      "moduleName": "admin/templates/components/email-notify.hbs"
    }
  });
});