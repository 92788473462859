define("admin/components/mission-list-map", ["exports", "admin/data/mission_type", "admin/config/environment", "admin/data/map_features", "admin/mixins/google-map-init-mixin"], function (_exports, _mission_type, _environment, _map_features, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionListMapComponent,
    indexOf = [].indexOf || function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };
  MissionListMapComponent = Ember.Component.extend(_googleMapInitMixin.default, {
    session: Ember.inject.service(),
    needActionStatus: ['created', 'confirmed', 'pilots_notified', 'unfulfilled'],
    missionMarkers: Ember.A([]),
    missionFilters: Ember.A([]),
    missionTypeData: _mission_type.default,
    mapStyles: _map_features.default.mapStylesData,
    didInsertElement: function (args) {
      this._super(args);
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.setupFilters();
      });
    },
    setupFilters: function () {
      var MissionType;
      MissionType = Em.Object.extend({
        type: null,
        color: null,
        fontIcon: null,
        png: null,
        name: null,
        selected: true,
        selected_png: null
      });
      return this.missionTypeData.forEach(function (_this) {
        return function (mission_type) {
          var icon_png, icon_png_array, missionType;
          missionType = MissionType.create(mission_type);
          icon_png_array = missionType.png.split('.');
          icon_png_array[0] = icon_png_array[0] + '_selected';
          icon_png = icon_png_array.join('.');
          return _this.get('missionFilters').pushObject(missionType);
        };
      }(this));
    },
    initMap: function () {
      var mapOptions, zoom;
      zoom = this.smallDevice ? 13 : 15;
      mapOptions = {
        zoom: zoom,
        tilt: 0,
        streetViewControl: false,
        scaleControl: true,
        panControl: true,
        zoomControl: true,
        draggable: true,
        scrollwheel: !this.get('publicsearch'),
        styles: this.mapStyles,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
      }
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-filter-container'));
      this.infoWindow = new google.maps.InfoWindow();
      this.loadMissions(true);
      this.setupInfoWindow();
      return google.maps.event.addListener(this.map, 'click', function (_this) {
        return function () {
          return _this.infoWindow.close();
        };
      }(this));
    },
    loadMissions: function (set_bounds) {
      var bounds;
      if (set_bounds == null) {
        set_bounds = false;
      }
      bounds = new google.maps.LatLngBounds();
      this.clearMissionMarkers();
      this.get('missions').forEach(function (_this) {
        return function (mission) {
          var marker;
          marker = _this.createMarker(mission);
          if (!marker) {
            return;
          }
          bounds.extend(marker.getPosition());
          return _this.missionMarkers.pushObject(marker);
        };
      }(this));
      if (set_bounds) {
        return this.map.fitBounds(bounds);
      }
    },
    setupInfoWindow: function () {
      var _this;
      _this = this;
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = Ember.$('#info-window-node');
      return this.infoWindow.addListener('domready', function (event) {
        var iwBackground, iwCloseBtn, iwOuter;
        iwOuter = Ember.$('.gm-style-iw');
        iwOuter.children().first().css({
          'display': 'block'
        });
        iwOuter.addClass('mission-list');
        iwBackground = iwOuter.prev();
        iwBackground.children(':nth-child(2)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(4)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(3)').find('div').children().css({
          'z-index': 1,
          'box-shadow': '0 1px 6px rgba(178, 178, 178, 0.6)'
        });
        iwCloseBtn = iwOuter.next();
        return iwCloseBtn.css({
          'display': 'none'
        });
      });
    },
    clearMissionMarkers: function () {
      this.missionMarkers.forEach(function (marker) {
        return marker.setMap(null);
      });
      return this.missionMarkers.clear();
    },
    createMarker: function (mission) {
      var _this, marker;
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(mission.get('location.latitude'), mission.get('location.longitude')),
        map: this.map,
        icon: this.getIcon(mission)
      });
      _this = this;
      mission.set('map_marker', marker);
      google.maps.event.addListener(marker, 'click', function (_this) {
        return function () {
          var content;
          _this.selectMission(mission);
          _this.infoWindow.setPosition(marker.position);
          content = _this.generateInfoNodeContent(mission);
          _this.infoWindow.setOptions({
            content: content,
            pixelOffset: new google.maps.Size(0, -37),
            disableAutoPan: false
          });
          _this.infoWindowNode = Ember.$('#info-window-node');
          return _this.infoWindow.open(_this.map);
        };
      }(this));
      return marker;
    },
    getIcon: function (mission, selected) {
      var anchor, icon, icon_png, ref;
      if (selected == null) {
        selected = false;
      }
      icon_png = '/assets/images/client_pin_32x42px.png';
      if (ref = mission.get('status'), indexOf.call(this.needActionStatus, ref) >= 0) {
        icon_png = '/assets/images/available_pin_32x42px.png';
      }
      anchor = new google.maps.Point(16, 37);
      icon = {
        url: icon_png,
        anchor: anchor,
        type: mission.get('missionStatusType')
      };
      return icon;
    },
    selectMission: function (mission, pan_to) {
      var selected_missions;
      if (pan_to == null) {
        pan_to = false;
      }
      selected_missions = this.get('missions').filterBy('selected_on_dashboard', true);
      if (selected_missions) {
        selected_missions.forEach(function (_this) {
          return function (selected_mission) {
            selected_mission.get('map_marker').setIcon(_this.getIcon(selected_mission));
            return selected_mission.set('selected_on_dashboard', false);
          };
        }(this));
      }
      mission.set('selected_on_dashboard', true);
      mission.get('map_marker').setIcon(this.getIcon(mission, true));
      if (pan_to) {
        return this.map.panTo(mission.get('map_marker').getPosition());
      }
    },
    generateInfoNodeContent: function (mission) {
      var addressFirstLine, addressSecondLine, content, link, missionPrice, missionShortName, payout, scheduled_date, scheduled_time, status;
      addressFirstLine = mission.get('location.address');
      addressSecondLine = this.getAddressSecondLine(mission.get('location'));
      link = this.getMissionLink(mission.get('id'));
      missionShortName = mission.get('package').get('vertical.short_name');
      missionPrice = mission.get('package').get('price') / 100;
      scheduled_date = "Unscheduled";
      scheduled_time = "";
      if (mission.get('scheduled_at_start')) {
        scheduled_date = moment.tz(mission.get('scheduled_at_start'), mission.get('location.timezone_id')).format('MM/DD/YYYY');
        scheduled_time = moment.tz(mission.get('scheduled_at_start'), mission.get('location.timezone_id')).format('hh:mmA') + " - " + moment.tz(mission.get('scheduled_at_end'), mission.get('location.timezone_id')).format('hh:mmA');
      }
      payout = "Not set";
      if (mission.get('estimated_pilot_payout') && !(mission.get('estimated_pilot_payout') === "0")) {
        payout = "$" + mission.get('estimated_pilot_payout') / 100;
      }
      status = mission.get('status');
      content = "<div class='info-window-panel'> <div class='left-panel'> <div class='addresses'> <div class='addressFirstLine'> " + addressFirstLine + " </div> <div class='addressSecondLine'> " + addressSecondLine + " </div> </div> <div class='mission-details'> " + missionShortName + " $" + missionPrice + " <br /> <b>Payout: </b> " + payout + " <br /> <b>Status: </b> " + status + " <br /> </div> </div> <div class='right-panel'> " + scheduled_date + " <br /> " + scheduled_time + " <a href='" + link + "' target='_blank' class='btn btn-primary btn-sm'>View</a> </div> </div>";
      return content;
    },
    getMissionLink: function (missionId) {
      return "missions/" + missionId + "/edit";
    },
    getAddressSecondLine: function (location) {
      var addressParts;
      addressParts = [location.get('city'), location.get('state'), location.get('postal_code'), location.get('country')];
      return addressParts = addressParts.filter(function (part) {
        return part;
      }).join(', ');
    }
  });
  var _default = _exports.default = MissionListMapComponent;
});