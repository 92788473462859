define("admin/controllers/clients/organizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsController;
  OrganizationsController = Ember.Controller.extend({
    queryParams: ['q', 'sort_attribute', 'sort_order'],
    sortProperties: ['name:desc'],
    sortAttribute: 'name',
    sortOrder: 'desc',
    q: null,
    actions: {
      clearQuery: function () {
        return this.set('q', null);
      },
      setSort: function (sort) {
        var arrSort;
        this.set('sortProperties', [sort]);
        arrSort = sort.split(':');
        this.set('sort_attribute', arrSort[0]);
        return this.set('sort_order', arrSort[1]);
      }
    }
  });
  var _default = _exports.default = OrganizationsController;
});