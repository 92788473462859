define("admin/models/inventory-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    inventory_type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    dronebase_uid: _emberData.default.attr('string'),
    dropships: _emberData.default.hasMany('dropship', {
      async: true
    }),
    repairs: _emberData.default.hasMany('inventory-repair', {
      async: false
    }),
    active_repair: _emberData.default.belongsTo('inventory-repair', {
      async: false
    }),
    active_dropship: _emberData.default.belongsTo('dropship', {
      async: false
    }),
    inventoriable: _emberData.default.belongsTo('inventoriable', {
      polymorphic: true,
      async: true,
      inverse: 'inventory_items'
    }),
    unit: Ember.computed('inventoriable', function () {
      return this.get('inventoriable.full_name');
    }),
    location: Ember.computed('active_dropship', function () {
      if (Ember.isEmpty(this.get('active_dropship'))) {
        return 'DroneBase HQ';
      } else {
        return this.get('active_dropship.pilot.fullName');
      }
    }),
    pilotId: Ember.computed('active_dropship', 'status', function () {
      if (this.get('status') == 'checked_out') {
        return this.get('active_dropship.pilot.id');
      }
    }),
    inStock: Ember.computed('status', function () {
      return this.get('status') === 'in_stock';
    }),
    underRepair: Ember.computed('status', function () {
      return this.get('status') === 'under_repair';
    }),
    archived: Ember.computed('status', function () {
      return this.get('status') === 'archived';
    }),
    canCheckIn: Ember.computed('status', function () {
      return !this.get('inStock');
    }),
    canCheckOut: Ember.computed('status', function () {
      return this.get('inStock');
    }),
    canSendUnderRepair: Ember.computed('status', function () {
      return this.get('inStock');
    }),
    canArchive: Ember.computed('status', function () {
      return this.get('inStock') || this.get('underRepair');
    }),
    inventoryTypeHumanized: Ember.computed('inventory_type', function () {
      var typesToHumanTypes = {
        'Drone': 'Drone',
        'DroneCamera': 'Camera',
        'Adapter': 'Adapter',
        'PilotEquipment': 'Other Equipment'
      };
      return typesToHumanTypes[this.get('inventory_type')];
    })
  });
});