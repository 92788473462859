define("admin/templates/badges/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ihqflxMC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"action\",\"appendedClasses\",\"animatable\",\"fullScreen\"],[\"close\",\"full-screen-modal\",true,\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container-fluid new-org-modal\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"full-screen-modal-toprow\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"New Badge\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"close-modal\"],[12],[2,\"\\n        \"],[11,\"img\"],[24,\"src\",\"/assets/images/X.svg\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"badges form-group form-group-iconized\"],[12],[2,\"\\n      \"],[11,\"form\"],[24,0,\"form\"],[4,[38,0],[[32,0],\"saveBadge\",[35,1]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"badge\",\"model\"],[[35,1,[\"badge\"]],[35,1]]]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-6 badge-inputs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"buttons-block\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"saveBadge\",[35,1]],null],[12],[2,\"CREATE\"],[13],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-secondary cancel-button\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"CANCEL\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"badge-fields\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/badges/new.hbs"
    }
  });
});