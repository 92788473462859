define("admin/mixins/mission-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    actions: {
      shareShareable(shareable, promise) {
        const share = this.get('store').createRecord('share', {
          shareable
        });
        return share.save().then(response => promise.resolve(response), function (response) {
          share.destroyRecord();
          return promise.reject(response);
        });
      }
    }
  });
});