define("admin/templates/components/clients/modal-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/5o7pYwd",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"action\",\"animatable\",\"fullScreen\",\"appendedClasses\"],[\"close\",true,\"true\",\"full-screen-modal edit-client-profile-modal\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"model\",\"saveClientAction\",\"closeModalAction\"],[[35,1],[30,[36,0],[[32,0],\"createClient\"],null],[30,[36,0],[[32,0],\"closeModalNewClient\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"clients/client-profile-edit\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/clients/modal-client.hbs"
    }
  });
});