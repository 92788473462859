define("admin/controllers/site-inspections/index", ["exports", "admin/data/searches/site_inspections_search_attributes"], function (_exports, _site_inspections_search_attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    NEW_LINE_CHAR: "\n",
    QUERY_LENGTH_LIMIT: 8000,
    queryParams: Ember.A(['sort_attribute', 'sort_order', 'attr', 'q', 'statuses', 'target_start_date_start', 'target_start_date_end', 'target_end_date_start', 'target_end_date_end', 'committed_start_date_start', 'committed_start_date_end', 'legal_entities', 'bulk_update_enabled', 'country_codes']),
    sortProperties: Ember.A(['id:desc']),
    sortAttribute: 'id',
    sortOrder: 'desc',
    attr: 'external_id',
    q: '',
    qInput: '',
    statuses: '',
    inspectionStatuses: Ember.A([]),
    inspectionAvailableStatusTransitions: Ember.A([]),
    selectedInspectionNumbers: Ember.A([]),
    bulk_update_enabled: false,
    bulkUpdateModalVisible: false,
    showActionsDropdown: false,
    //TODO relabel for status
    bulkUpdateErrorsModalVisible: false,
    bulkUpdateErrors: null,
    //END TODO relabel for status
    bulkAddToExistingCaptureGroupModalVisible: false,
    bulkAddToNewCaptureGroupModalVisible: false,
    bulkAddToCaptureGroupWarningModalVisible: false,
    bulkUpdating: false,
    legal_entities: '',
    sessionAccount: Ember.inject.service(),
    countries: null,
    country_codes: Ember.A([]),
    searchAttributesOptions: _site_inspections_search_attributes.default,
    allInspectionsChecked: Ember.computed('selectedInspectionNumbers.length', 'model.workOrders.length', function () {
      return this.get('selectedInspectionNumbers.length') > 0 && this.get('selectedInspectionNumbers.length') >= this.get('model.workOrders.length');
    }),
    selectedOnlyOneStatus: Ember.computed('statuses', function () {
      return !!this.get('statuses') && this.get('statuses').split(',').length == 1;
    }),
    // Bulk update modal dependencies ------------------------------------------------------------------------------------
    bulkUpdateStatusOptions: Ember.computed('statuses', 'inspectionAvailableStatusTransitions', function () {
      const transitions = this.get('inspectionAvailableStatusTransitions').filter(t => t.from == this.get('statuses')).map(t => t.to);
      return this.get('inspectionStatuses').filter(status => transitions.includes(status.value));
    }),
    // Multi-line/attribute search dependencies
    qOneline: Ember.computed('qCompacted', function () {
      return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).join(', ');
    }),
    qLinesCount: Ember.computed('qCompacted', function () {
      if (this.get('qCompacted')) {
        return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).length;
      } else {
        return 0;
      }
    }),
    qCompacted: Ember.computed('qInput', function () {
      return this.get('qInput').trim().replaceAll(/\n+/g, "\n");
    }),
    queryReachedLengthLimit: Ember.computed('qInput', function () {
      return this.get('qInput').length >= this.get('QUERY_LENGTH_LIMIT');
    }),
    notMatchedQueryLines: Ember.computed('model.workOrders.meta.matched_queries', function () {
      const matchedQueries = this.model.workOrders.meta.matched_queries;
      if (!this.qCompacted || !matchedQueries) return [];
      return this.qCompacted.split(this.get('NEW_LINE_CHAR')).reduce((arr, qLine) => {
        if (!matchedQueries.includes(qLine)) arr.push(qLine);
        return arr;
      }, []);
    }),
    submitSearch() {
      this.set('q', this.get('qInput'));
    },
    recalculateTextareaHeight() {
      const textarea = document.querySelector('#searchTextarea');
      const valueNumberOfLineBreaks = (textarea.value.match(/\n/g) || []).length + 2; // +2 for more space
      const borderTop = parseInt(getComputedStyle(textarea).borderTopWidth, 10);
      const borderBottom = parseInt(getComputedStyle(textarea).borderBottomWidth, 10);
      const paddingTop = parseInt(getComputedStyle(textarea).paddingTop, 10);
      const paddingBottom = parseInt(getComputedStyle(textarea).paddingBottom, 10);
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);
      const minHeight = parseInt(getComputedStyle(textarea).minHeight, 10);
      const textHeight = valueNumberOfLineBreaks * lineHeight;
      if (textHeight > minHeight) {
        textarea.style.height = textHeight + borderTop + borderBottom + paddingTop + paddingBottom + 'px';
      } else {
        textarea.style.height = minHeight + 'px';
      }
    },
    // Update button attrs -----------------------------------------------------------------------------------------------
    bulkUpdateAvailable: Ember.computed('model.currentAdmin.canSeeBulkUpdater', function () {
      return this.get('model.currentAdmin.canSeeBulkUpdater');
    }),
    selectedAvailableInspections: Ember.computed('selectedInspectionNumbers', 'model.workOrders.length', function () {
      return this.get('model.workOrders').filter(inspection => this.get('selectedInspectionNumbers').includes(inspection.get('id')));
    }),
    availableInspectionsAssignedToCaptureGroupCount: Ember.computed('selectedInspectionNumbers', 'model.workOrders.length', function () {
      return this.get('selectedAvailableInspections').filter(inspection => inspection.get('capture_group_id')).length;
    }),
    availableInspectionsInProgressCount: Ember.computed('selectedInspectionNumbers', 'model.workOrders.length', function () {
      return this.get('selectedAvailableInspections').filter(inspection => inspection.get('isInProgress')).length;
    }),
    allAvailableInspectionsAreSelected: Ember.computed('selectedInspectionNumbers.length', 'availableInspections.length', function () {
      return this.get('selectedInspectionNumbers.length') > 0 && this.get('selectedInspectionNumbers.length') >= this.get('availableInspections.length');
    }),
    needsToShowWarningBeforeBulkAdditionToCaptureGroup: Ember.computed('availableInspectionsAssignedToCaptureGroupCount', 'availableInspectionsInProgressCount', function () {
      return this.get('availableInspectionsAssignedToCaptureGroupCount') > 0 || this.get('availableInspectionsInProgressCount') > 0;
    }),
    bulkUpdateActionDisabled: Ember.computed('bulkUpdateAvailable', 'selectedInspectionNumbers.length', function () {
      return !this.get('bulkUpdateAvailable') || !this.get('selectedInspectionNumbers.length');
    }),
    bulkUpdateActionTooltipText: Ember.computed('bulkUpdateActionDisabled', 'bulkUpdateAvailable', 'selectedInspectionNumbers.length', 'statuses', function () {
      if (!this.get('bulkUpdateActionDisabled')) return;
      if (!this.get('bulkUpdateAvailable')) {
        return 'Action is not available for your role';
      } else if (!this.get('selectedInspectionNumbers.length')) {
        return 'No selected inspections';
      } else if (!this.get('statuses')) {
        return 'Status not selected (at filter)';
      } else {
        return;
      }
    }),
    bulkUpdateFailedResponseHandler(failedInspections, newStatus = '') {
      const failedInspectionNumbers = failedInspections.map(failedInspection => failedInspection.number);
      if (failedInspections.length) this.send('showBulkUpdateErrorsModal', failedInspections, this.get('selectedInspectionNumbers').length);
      Ember.run(() => {
        this.get('model.workOrders').filter(workOrder => {
          return !failedInspectionNumbers.includes(workOrder.get('id')) && this.get('selectedInspectionNumbers').includes(workOrder.get('id'));
        }).setEach('status', newStatus);
        this.flashMessages.success(`Status updated for ${this.get('selectedInspectionNumbers').length - failedInspectionNumbers.length} inspecitons`);
      });
    },
    // Bulk actions functions
    addInspectionsToExistingCaptureGroup(captureGroup) {
      const inspectionsNumbers = this.get('selectedInspectionNumbers');
      const captureGroupNumber = captureGroup.get('id');
      const data = {
        site_inspection_ids: inspectionsNumbers,
        capture_group_number: captureGroupNumber
      };
      const responseHandler = () => {
        this.set('selectedInspectionNumbers', []);
        this.set('bulkAddToExistingCaptureGroupModalVisible', false);
        this.flashMessages.success(`<div class="ver-25">
          <div class="body-2-semibold">${inspectionsNumbers.length} inspections were added to Capture group</div>
          <div class="caption-1-regular">Capture group: ${captureGroupNumber}</div>
        </div>`, {
          timeout: 5000
        });
        this.set('bulkAddToCGCallback', null);
        this.get('model.workOrders').filter(mission => inspectionsNumbers.includes(mission.get('id'))).setEach('capture_group_id', captureGroupNumber);
      };
      return this.get('store').adapterFor('site-inspection').bulkAddToExistingCaptureGroup(this.get('sessionAccount.headers'), data).then(() => responseHandler()).catch(response => {
        if (response.status == 422 && response.responseJSON?.errors) {
          response.responseJSON.errors.forEach(error => {
            this.flashMessages.error(error.detail);
          });
        } else {
          this.flashMessages.error('Failed to add inspections to existing Capture Group');
        }
      });
    },
    addInspectionsToNewCaptureGroup(captureGroupName) {
      const inspectionsNumbers = this.get('selectedInspectionNumbers');
      const data = {
        site_inspection_ids: inspectionsNumbers,
        capture_group_name: captureGroupName
      };
      const responseHandler = captureGroupNumber => {
        this.set('selectedInspectionNumbers', []);
        this.set('bulkAddToNewCaptureGroupModalVisible', false);
        this.flashMessages.success(`<div class="ver-25">
          <div class="body-2-semibold">${inspectionsNumbers.length} inspections were added to Capture group</div>
          <div class="caption-1-regular">Capture group: ${captureGroupName}</div>
        </div>`, {
          timeout: 5000
        });
        this.set('bulkAddToCGCallback', null);
        this.get('model.workOrders').filter(mission => inspectionsNumbers.includes(mission.get('id'))).setEach('capture_group_id', captureGroupNumber);
      };
      return this.get('store').adapterFor('site-inspection').bulkAddToNewCaptureGroup(this.get('sessionAccount.headers'), data).then(response => responseHandler(response.capture_group_number)).catch(response => {
        if (response.status == 422 && response.responseJSON?.errors) {
          response.responseJSON.errors.forEach(error => {
            this.flashMessages.error(error.detail);
          });
        } else {
          this.flashMessages.error('Failed to add inspections to new Capture Group');
        }
      });
    },
    actions: {
      setSort(sort) {
        // needed for sortable-column to know what column is selected/active
        this.set('sortProperties', [sort]);
        // these values will be passed to BE for sorting options
        var arrSort = sort.split(':');
        this.set('sort_attribute', arrSort[0]);
        this.set('sort_order', arrSort[1]);
      },
      // Multi-line/attribute search dependencies
      openMultiLineSearch() {
        this.set('multiLineSearchOpened', true);
        Ember.run.schedule("afterRender", this, () => {
          this.recalculateTextareaHeight();
          document.querySelector('#searchTextarea').focus();
        });
      },
      closeMultiLineSearch() {
        this.set('multiLineSearchOpened', false);
      },
      textareaKeyUp() {
        this.recalculateTextareaHeight();
        Ember.run.debounce(this, this.submitSearch, 1000);
      },
      clearQuery() {
        this.set('q', '');
        this.set('qInput', '');
      },
      toggleFilter() {
        this.toggleProperty('hideFilter');
      },
      async duplicateWorkOrder(workOrder) {
        this.get('store').adapterFor('site-inspection').duplicate(this.get('sessionAccount.headers'), workOrder).then(() => {
          this.flashMessages.success(`You've duplicated ${workOrder.id}, it will take Admin a few minutes to reflect this, please check back.`);
        }).catch(() => {
          alert(`Unable to duplicate ${workOrder.id} there has been an error`);
        });
      },
      checkAllLoadedInspections() {
        if (this.get('allInspectionsChecked')) return this.set('selectedInspectionNumbers', []);
        const selectedInspectionNumbers = this.get('selectedInspectionNumbers');
        const inspectionsNumbers = this.get('model.workOrders').getEach('id');
        this.set('selectedInspectionNumbers', Array.from(new Set([...selectedInspectionNumbers, ...inspectionsNumbers])));
      },
      checkInspection(inspection, event) {
        const selectedInspectionNumbers = this.get('selectedInspectionNumbers');
        const inspectionNumber = inspection.get('id');
        const newSelectedInspectionNumbers = event.target.checked ? [...selectedInspectionNumbers, inspectionNumber] : selectedInspectionNumbers.filter(number => number !== inspectionNumber);
        this.set('selectedInspectionNumbers', Array.from(new Set(newSelectedInspectionNumbers)));
      },
      updateSelectedSiteInspections(newStatus) {
        const inspectionNumbers = this.get('selectedInspectionNumbers');
        const data = {
          inspection_ids: inspectionNumbers,
          new_status: newStatus
        };
        this.set('bulkUpdating', true);
        this.get('store').adapterFor('site-inspection').bulkUpdate(this.get('sessionAccount.headers'), data).then(() => {
          this.get('model.workOrders').filter(workOrder => {
            return this.get('selectedInspectionNumbers').includes(workOrder.get('id'));
          }).setEach('status', newStatus);
          this.flashMessages.success(`Status updated for ${this.get('selectedInspectionNumbers').length} inspections`);
        }).catch(response => {
          if (response.status == 422 && response.responseJSON?.failed_inspections) {
            this.bulkUpdateFailedResponseHandler(response.responseJSON.failed_inspections);
          } else {
            alert('Error while inspections were updating');
          }
        }).always(() => {
          this.set('bulkUpdateModalVisible', false);
          this.set('bulkUpdating', false);
          this.set('selectedInspectionNumbers', []);
        });
      },
      showBulkUpdateModal() {
        this.set('bulkUpdateModalVisible', true);
        this.set('bulkUpdateModalFormObject', Ember.Object.create({}));
        this.set('showActionsDropdown', false);
      },
      closeBulkUpdateModal() {
        this.set('bulkUpdateModalVisible', false);
        this.set('bulkUpdateModalFormObject', null);
      },
      showBulkAddToExistingCaptureGroupModal() {
        this.set('bulkAddToExistingCaptureGroupModalVisible', true);
        this.set('showActionsDropdown', false);
      },
      closeBulkAddToExistingCaptureGroupModal() {
        this.set('bulkAddToExistingCaptureGroupModalVisible', false);
      },
      showBulkAddToNewCaptureGroupModal() {
        this.set('bulkAddToNewCaptureGroupModalVisible', true);
        this.set('showActionsDropdown', false);
      },
      closeBulkAddToNewCaptureGroupModal() {
        this.set('bulkAddToNewCaptureGroupModalVisible', false);
      },
      closeBulkAddToCaptureGroupWarningModal() {
        this.set('bulkAddToCaptureGroupWarningModalVisible', false);
      },
      showBulkUpdateErrorsModal(failedInspections, bulkUpdateInspectionsCount) {
        console.log(failedInspections);
        console.log(bulkUpdateInspectionsCount);
        this.set('bulkUpdateErrorsModalVisible', true);
        this.set('bulkUpdateErrors', failedInspections);
        this.set('bulkUpdateInspectionsCount', bulkUpdateInspectionsCount);
      },
      closeBulkUpdateErrorsModal() {
        this.set('bulkUpdateErrorsModalVisible', false);
        this.set('bulkUpdateErrors', null);
        this.set('bulkUpdateMissionsCount', null);
      },
      switchBulkUpdateMode() {
        this.toggleProperty('bulk_update_enabled');
        this.set('statuses', this.get('bulk_update_enabled') ? 'invoice_needed' : null);
      },
      toggleActionsDropdown() {
        this.toggleProperty('showActionsDropdown');
      },
      changeCountryCodes(countryCodes) {
        return this.set('country_codes[]', countryCodes);
      },
      addInspectionsToExistingCaptureGroup(captureGroup) {
        if (this.get('needsToShowWarningBeforeBulkAdditionToCaptureGroup')) {
          this.set('bulkAddToExistingCaptureGroupModalVisible', false);
          this.set('bulkAddToCaptureGroupWarningModalVisible', true);
          this.set('bulkAddToCGCallback', () => this.addInspectionsToExistingCaptureGroup(captureGroup));
          return;
        }
        this.addInspectionsToExistingCaptureGroup(captureGroup);
      },
      addInspectionsToNewCaptureGroup(captureGroupName) {
        if (this.get('needsToShowWarningBeforeBulkAdditionToCaptureGroup')) {
          this.set('bulkAddToNewCaptureGroupModalVisible', false);
          this.set('bulkAddToCaptureGroupWarningModalVisible', true);
          this.set('bulkAddToCGCallback', () => this.addInspectionsToNewCaptureGroup(captureGroupName));
          return;
        }
        this.addInspectionsToNewCaptureGroup(captureGroupName);
      },
      confirmSiteInspectionsAdditionToCaptureGroup() {
        this.set('bulkAddToCaptureGroupWarningModalVisible', false);
        this.get('bulkAddToCGCallback')();
      }
    }
  });
});