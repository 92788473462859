define("admin/models/rescheduler", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Rescheduler;
  Rescheduler = _emberData.default.Model.extend({
    mission_reschedules: _emberData.default.hasMany('mission-reschedule', {
      async: false
    })
  });
  var _default = _exports.default = Rescheduler;
});