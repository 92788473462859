define("admin/data/duration-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: '1 hour',
    value: '60'
  }, {
    name: '2 hours',
    value: '120'
  }, {
    name: '4 hours',
    value: '240'
  }, {
    name: '8 hours',
    value: '480'
  }];
});