define("admin/models/organization", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    insight_access: (0, _model.attr)('string', {
      defaultValue: 'None'
    }),
    insights_url: (0, _model.attr)('string'),
    subscription_level: (0, _model.attr)('string'),
    packages: (0, _model.hasMany)('package', {
      async: false
    }),
    organization_available_packages: (0, _model.hasMany)('organization-available-package', {
      async: true
    }),
    clients: (0, _model.hasMany)('client', {
      async: false
    }),
    subscriptions: (0, _model.hasMany)('subscription', {
      async: true
    }),
    subscriptionOffers: (0, _model.hasMany)('subscription-offer', {
      async: false
    }),
    available_addons: (0, _model.hasMany)('package-addon', {
      async: false
    }),
    organization_portfolios: (0, _model.hasMany)('organization_portfolio'),
    comprehensive_report_purchases: (0, _model.hasMany)('comprehensive-report-purchase', {
      async: false
    }),
    insight_access_editable: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    insightAccessNamesMapping: {
      None: 'None',
      Solar: 'Solar',
      Wind: 'Wind',
      Buildings: 'Property',
      AEC: 'AEC'
    },
    insightAccessName: Ember.computed('insight_access', function () {
      return this.insightAccessNamesMapping[this.get('insight_access')];
    }),
    insightAccessIsNotNone: Ember.computed('insight_access', function () {
      return this.get('insight_access') !== 'None';
    })
  });
});