define("admin/models/inventoriable", ["exports", "ember-data", "admin/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    inventory_items: _emberData.default.hasMany('inventory-item', {
      async: true,
      inverse: 'inventoriable'
    })
  });
});