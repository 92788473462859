define("admin/models/esri-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    organization_name: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    token_expiry_date: _emberData.default.attr('date'),
    client: _emberData.default.belongsTo('client'),
    baseArcgisUrl: Ember.computed('organization_name', function () {
      return `https://${this.get('organization_name')}.maps.arcgis.com`;
    })
  });
});