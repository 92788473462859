define("admin/helpers/filtered-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function filteredLength([collection, field, value]) {
    return collection.filter(function (item) {
      return item.get(field) === value;
    }).length;
  }
  var _default = _exports.default = Ember.Helper.helper(filteredLength);
});