define("admin/templates/components/collapsible-sidebar-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Jq3jsjpz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dropdown-refine \",[34,0]]]],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"refine-header\"],[4,[38,1],[[32,0],\"toggle\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"header-title\"],[12],[1,[34,2]],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"fa fa-chevron-up\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"fa fa-chevron-down\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"refine-content \",[30,[36,5],[[35,3],\"hidden\"],null]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"headerClass\",\"action\",\"title\",\"expanded\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "admin/templates/components/collapsible-sidebar-item.hbs"
    }
  });
});