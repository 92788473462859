define("admin/components/pilot-payout-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PilotPayoutModalComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    missionPayoutsReverse: Ember.computed('model.mission.payouts.[]', function () {
      return this.get('model.mission.payouts').toArray().reverse();
    }),
    arrayOfTotalPayoutByCurrency: Ember.computed('model.mission.payouts.[]', function () {
      const result = [];
      const uniqueCurrencies = this.get('model.mission.payouts').getEach('currency').uniq();
      uniqueCurrencies.forEach(currency => {
        result.push({
          currency,
          amount: this.get('model.mission.payouts').filterBy('currency', currency).getEach('amount').reduce((m, n) => parseInt(m) + parseInt(n), 0)
        });
      });
      return result;
    }),
    actions: {
      openPayoutLineItemForm() {
        return this.get('openPayoutLineItemForm')();
      },
      addPayoutLineItem() {
        return this.get('addPayoutLineItem').apply(this);
      },
      addAnotherPayoutLineItem() {
        return this.get('addAnotherPayoutLineItem').apply(this);
      },
      clearPayoutLineItem() {
        return this.get('clearPayoutLineItem').apply(this);
      },
      clearPayoutLineItems() {
        return this.get('clearPayoutLineItems').apply(this);
      },
      createPayout() {
        return this.get('createPayout')(this.get('model'));
      },
      close() {
        return this.get('close')();
      },
      deletePayout(payout) {
        payout.set('saved_payoutable_id', payout.payoutable.id);
        payout.set('saved_payoutable_type', 'missions');
        payout.deleteRecord();
        return payout.save().then(function () {},
        // w00t
        function (error) {
          alert('There was an issue deleting this payout');
          return console.log('error while saving: ' + error);
        });
      }
    }
  });
  var _default = _exports.default = PilotPayoutModalComponent;
});