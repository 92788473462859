define("admin/components/input-validated-dollars", ["exports", "admin/components/input-validated", "accounting/format-money", "accounting/unformat"], function (_exports, _inputValidated, _formatMoney, _unformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputValidatedDollarsComponent;
  InputValidatedDollarsComponent = _inputValidated.default.extend({
    formatMoney: _formatMoney.default,
    unformatMoney: _unformat.default,
    valueChanged: Ember.observer('value', function () {
      return this.set('displayValue', this.formatMoney(this.get('value') / 100));
    }),
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        return this.set('displayValue', this.formatMoney(this.get('value') / 100));
      });
    },
    actions: {
      focus: function () {
        var displayValue, price;
        displayValue = this.get('displayValue');
        price = this.unformatMoney(displayValue);
        this.set('displayValue', price);
        return this.$('input').select();
      },
      showErrors: function () {
        var priceInCents;
        this._super();
        priceInCents = this.unformatMoney(this.get('displayValue')) * 100;
        this.set('value', priceInCents);
        return this.set('displayValue', this.formatMoney(priceInCents / 100));
      }
    }
  });
  var _default = _exports.default = InputValidatedDollarsComponent;
});