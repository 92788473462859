define("admin/controllers/clients/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientsIndexController;
  ClientsIndexController = Ember.Controller.extend({
    queryParams: ['q'],
    q: null,
    actions: {
      clearQuery: function () {
        return this.set('q', null);
      },
      openNewClientModal: function () {
        this.set('model.client', this.store.createRecord('client'));
        return this.send('openModal', 'components.clients.modal-client', this.get('model'));
      }
    }
  });
  var _default = _exports.default = ClientsIndexController;
});