define("admin/models/legal-entity", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    stripe_enabled: (0, _model.attr)('boolean'),
    site_inspections: (0, _model.hasMany)('site-inspection'),
    missions: (0, _model.hasMany)('mission')
  });
});