define("admin/components/missions/plan-map/shotlist", ["exports", "admin/data/shot-type/shot-template-types"], function (_exports, _shotTemplateTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    saveShotListButton: 'Save As Shot List',
    shotListLink: null,
    shotListForm: null,
    shotTypeTemplates: _shotTemplateTypes.default,
    reverseList: Ember.computed('model.mission.shots.[]', function () {
      return this.get('model.mission.shots').filter(function (shot) {
        return shot.origin_type != 'catalog';
      }).toArray().reverse();
    }),
    newShotTypeObserver: Ember.observer('newShot', function () {
      if (!this.get('newShot')) {
        return false;
      }
      this.addShot(this.get('newShot'));
      this.set('newShot', null);
    }),
    addShot: function (template) {
      let name = template.replace(/_/g, ' ');
      name = name.charAt(0).toUpperCase() + name.slice(1);
      let newShot = this.get('model.mission.shots').createRecord({
        template: template,
        origin_type: 'ad_hoc',
        name: name
      });
      this.set('activeShot', newShot);
    },
    didInsertElement: function () {
      this.shotListLink = Em.$('.shot-list-link');
      this.shotListForm = Em.$('.shot-list-form');
      this.shotListLink.on('click', e => {
        e.preventDefault();
        this.shotListLink.fadeOut(200);
        this.shotListForm.delay(200).fadeIn(200);
      });
    },
    actions: {
      choose_shot_list(shot_list) {
        if (this.get('model.mission.package.catalog_items.length')) {
          return;
        }
        if (window.confirm('Are you sure you want to clear current shot list?')) {
          const _this = this;
          this.get('reverseList').forEach(shot => shot.deleteRecord());
          return this.get('model.mission.shots').save().then(function (result) {
            let i = 0;
            var saveShots = function (shot_list) {
              const _shot_type = shot_list.get('shot_types').objectAt(i);
              const _shot = _this.get('model.mission.shots').createRecord({
                name: _shot_type.get('name'),
                shot_type: _shot_type,
                instructions: _shot_type.get('instructions'),
                origin_type: 'ad_hoc'
              });
              return _shot.save().then(function (response) {
                // TODO: find a way to push response data into store
                // _this.store.pushPayload(shot);
                i++;
                if (shot_list.get('shot_types').objectAt(i)) {
                  return saveShots(shot_list);
                }
              });
            };
            saveShots(shot_list);
            return _this.set('saveShotListButton', 'Save As Shot List');
          });
        }
      },
      save_shot_list() {
        const _this = this;
        this.set('saveShotListButton', 'Saving...');
        this.get('model.shot_list').set('shot_ids', this.get('model.mission.shots').mapBy('id'));
        return this.get('model.shot_list').save().then(function (response) {
          _this.set('saveShotListButton', 'Saved!');
          return _this.shotListForm.delay(2000).fadeOut(1000);
        });
      },
      add_shot: function (shot) {
        this.addShot(shot);
      }
    }
  });
});