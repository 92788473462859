define("admin/helpers/format-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function formatArray([array, valuePath, withCount]) {
    if (array === undefined || array.length === 0) {
      return '-';
    }
    const values = array.map(associationObj => associationObj.get(valuePath));
    if (withCount) {
      return `(${values.length}) ${values.join(', ')}`;
    } else {
      return values.join(', ');
    }
  }
  var _default = _exports.default = Ember.Helper.helper(formatArray);
});