define("admin/models/hold", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Hold;
  Hold = _emberData.default.Model.extend({
    reason: _emberData.default.attr('string'),
    reason_notes: _emberData.default.attr('string'),
    initiated_by: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    resolved: _emberData.default.attr('boolean'),
    mission: _emberData.default.belongsTo('mission'),
    mission_hold_reason: _emberData.default.belongsTo('mission_hold_reason')
  });
  var _default = _exports.default = Hold;
});