define("admin/models/pilot-license", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotLicense;
  PilotLicense = _emberData.default.Model.extend({
    license_number: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    license: _emberData.default.belongsTo('organization', {
      async: false
    }),
    full_name: Ember.computed('name', function () {
      return this.get('name');
    })
  });
  var _default = _exports.default = PilotLicense;
});