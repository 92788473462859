define("admin/templates/clients/client/images/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "q9q2rOVy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[15,\"src\",[31,[[34,0]]]],[14,\"alt\",\"test\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"url\"]}",
    "meta": {
      "moduleName": "admin/templates/clients/client/images/image.hbs"
    }
  });
});