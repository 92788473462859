define("admin/utils/ember-changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addToHasMany = addToHasMany;
  _exports.removeFromHasMany = removeFromHasMany;
  function addToHasMany(changeset, relationship, item) {
    let hasMany = changeset.get(relationship).pushObjects([item]);
    changeset.set(relationship, hasMany.toArray());
  }
  function removeFromHasMany(changeset, relationship, item) {
    let hasMany = changeset.get(relationship).removeObject(item);
    changeset.set(relationship, hasMany.toArray());
  }
});