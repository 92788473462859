define("admin/routes/waivers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function (params) {
      return Ember.RSVP.hash({
        waivers: this.store.query('waiver', {}),
        waiver: this.store.createRecord('waiver')
      });
    },
    actions: {
      close() {
        return this.send('closeModal');
      },
      newWaiver(model) {
        return this.send('openModal', 'waivers.new', model);
      },
      saveWaiver(model) {
        if (model.waiver.validate()) {
          var _this = this;
          return model.waiver.save().then(function (response) {
            _this.send('closeModal');
            _this.refresh();
          }).catch(function (response) {
            let message = response.errors[0].detail;
            _this.set('errors', message);
            // return alert("FAILED TO SAVE\n" + message);
          });
        }
      }
    }
  });
});