define("admin/services/common-dictionaries", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CommonDictionaries;
  CommonDictionaries = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    verticals: Ember.computed(function () {
      return this.get('store').findAll('vertical');
    })
  });
  var _default = _exports.default = CommonDictionaries;
});