define("admin/serializers/shot", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShotSerializer;
  ShotSerializer = _application.default.extend({
    attrs: {
      shot_type: {
        deserialize: 'records'
      },
      mission: {
        deserialize: 'records'
      }
    }
  });
  var _default = _exports.default = ShotSerializer;
});