define("admin/models/pilot-rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    rate_type: _emberData.default.attr('string'),
    payout_amount: _emberData.default.attr('number'),
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),
    pilot: _emberData.default.belongsTo('pilot', {
      async: false
    })
  });
});