define("admin/templates/components/missions/line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4/yHB41T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"reason\"],[12],[1,[35,0,[\"display_name\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"date\"],[12],[1,[30,[36,1],[[35,0,[\"created_on\"]],\"MM/DD/YY h:mm a\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"amount\"],[12],[1,[30,[36,2],[[35,0,[\"amount\"]],[35,0,[\"currency\"]]],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lineItem\",\"moment-format\",\"format-price\"]}",
    "meta": {
      "moduleName": "admin/templates/components/missions/line-item.hbs"
    }
  });
});