define("admin/templates/components/collapsible-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xIzUooa9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"collapsible-panel accordion \",[34,0]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row equipment-header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[14,\"data-toggle\",\"collapse\"],[15,\"data-target\",[31,[\"#\",[34,1]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-inline-block search-field\"],[12],[2,\"\\n          \"],[1,[34,2]],[2,\"\\n          \"],[10,\"span\"],[14,0,\"counter\"],[12],[2,\"(\"],[1,[34,3]],[2,\")\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"d-inline-block pull-right arrow\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[14,\"src\",\"/assets/images/up-arrow.svg\"],[12],[13],[2,\" \"]],\"parameters\":[]},{\"statements\":[[2,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/assets/images/down-arrow.svg\"],[12],[13],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"body \",[34,6]]]],[15,1,[31,[[34,1]]]],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"container-classes\",\"body-id\",\"title\",\"counter\",\"open\",\"if\",\"body-classes\"]}",
    "meta": {
      "moduleName": "admin/templates/components/collapsible-panel.hbs"
    }
  });
});