define("admin/templates/components/organization-package-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6EXzXeEp",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[\"organization-checkbox \",[34,0]]]],[4,[38,1],[[32,0],\"toggleChecked\"],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"checkboxClass\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/organization-package-checkbox.hbs"
    }
  });
});