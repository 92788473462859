define("admin/components/missions/edit-package-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      close: function () {
        this.get('close')();
      },
      newPackageModal: function (model) {
        this.get('close')();
        this.newPackage(model);
      },
      editPackage: function () {
        const promise = Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${this.model.mission.id}/update_package/${this.model.mission.package.id}`,
          type: 'PATCH',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        });
        promise.then(() => {
          this.get('model.mission').reload();
          this.get('model.invoiceLineItems').reload();
          this.get('close')();
        }).catch(response => {
          alert(response.errors[0].detail);
        });
      }
    }
  });
});