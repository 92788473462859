define("admin/models/drone-manufacturer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DroneManufacturer;
  DroneManufacturer = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    drones: _emberData.default.hasMany('drone')
  });
  var _default = _exports.default = DroneManufacturer;
});