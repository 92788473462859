define("admin/components/site-inspections/payout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    displayDetails: false,
    newlyAdded: Ember.computed('payout.newlyAdded', function () {
      return this.get('payout.newlyAdded');
    }),
    isPayoutDeletable: Ember.computed('payout.status', function () {
      const status = this.get('payout.status');
      return ['created', 'autopay_onboarding', 'awaiting_top_up', 'queued_for_payment'].includes(status);
    }),
    actions: {
      toggleDetailsVisibility: function () {
        if (this.get('payout.payout_line_items').length) this.toggleProperty('displayDetails');
      },
      deletePayoutItem(payout) {
        const deletableStatuses = ['created', 'autopay_onboarding', 'awaiting_top_up', 'queued_for_payment'];
        if (deletableStatuses.includes(this.get("payout.status")) && this.get('isPayoutDeletable')) {
          // Add confirmation alert here
          if (window.confirm("Are you sure you want to delete this payout? This cannot be undone.")) {
            payout.deleteRecord();
            return payout.save().then(function () {},
            // w00t
            function (error) {
              alert('There was an issue deleting this payout, the status may have changed. Please refresh and try again.');
              return console.log('error while saving: ' + error);
            });
          }
        }
      }
    }
  });
});