define("admin/mirage/models/mission", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    pilot: (0, _emberCliMirage.belongsTo)('pilot'),
    location: (0, _emberCliMirage.belongsTo)('location'),
    activity_logs: (0, _emberCliMirage.hasMany)('activity_logs'),
    images_markers: (0, _emberCliMirage.hasMany)('image_markers'),
    ortho_photo_maps: (0, _emberCliMirage.hasMany)('ortho_photo_maps'),
    deliverable_work_types: (0, _emberCliMirage.hasMany)('deliverable_work_types'),
    buckets: (0, _emberCliMirage.hasMany)('buckets'),
    dropships: (0, _emberCliMirage.hasMany)('dropships'),
    point_of_interest: (0, _emberCliMirage.belongsTo)('point-of-interest'),
    client: (0, _emberCliMirage.belongsTo)('client'),
    currency: (0, _emberCliMirage.belongsTo)('currency'),
    payouts: (0, _emberCliMirage.hasMany)()
  });
});