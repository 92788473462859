define("admin/helpers/is-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(([leftSide, rightSide]) => {
    let exp = 'model:' + rightSide + '::';
    return leftSide.toString().match(new RegExp(exp));
  });
});