define("admin/components/inventory/inventory-item-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    record: Ember.computed('item', function () {
      return this.get('item') || this.get('store').createRecord('inventory-item');
    }),
    fieldsComponent: Ember.computed('kind', function () {
      const componentName = this.get('kind').replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase();
      return `inventory/inventory-${componentName}-fields`;
    }),
    relation: Ember.computed('item', function () {
      const currentItem = this.get('item');
      if (Ember.isBlank(currentItem)) {
        return null;
      }
      return currentItem.inventoriable;
    }),
    relationModelError: false,
    saveItemWithRelation(item, relation) {
      const isNewItem = item.get('isNew');
      item.set('inventoriable', relation);
      item.save().then(createdItem => {
        if (isNewItem) {
          this.send('close', createdItem);
        } else {
          this.send('close');
        }
      });
    },
    actions: {
      close: function (item = null) {
        this.get('record').rollbackAttributes();
        this.closeAction(item);
      },
      submit() {
        let item = this.get('record');
        let relatedItem = this.get('relation');
        if (Ember.isBlank(relatedItem)) {
          this.set('relationModelError', true);
          return;
        }
        if (relatedItem.get('id')) {
          this.saveItemWithRelation(item, relatedItem);
        } else {
          relatedItem.save().then(createdRelation => {
            this.saveItemWithRelation(item, createdRelation);
          });
        }
      },
      selectRelation(record) {
        this.set('relation', record);
        this.set('relationModelError', false);
      }
    }
  });
});