define("admin/data/shot-type/level-of-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: 'Context',
    value: 'context'
  }, {
    name: 'Detailed',
    value: 'detailed'
  }, {
    name: 'Extreme detailed',
    value: 'extreme_detailed'
  }];
});