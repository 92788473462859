define("admin/components/mission-assets-share", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    buttonText: null,
    classNames: ["asset-share"],
    isShareable: Ember.computed('shareable.isShareable', function () {
      return this.get('shareable').get('isShareable');
    }),
    actions: {
      shareShareable() {
        if (this.get('shareable.shareLink')) {
          return this.set('showShareModal', true);
        } else {
          const deferred = Ember.RSVP.defer();
          deferred.promise.then(response => {
            const shareLink = `${_environment.default.clients.host}/${response.get('shareable.constructor.modelName').charAt(0)}/${response.get('id')}`;
            this.set('shareable.shareLink', shareLink);
            this.set('shareable.share_token', response.get('id'));
            return this.set('showShareModal', true);
          }, response => {
            let error;
            if (error = response.errors[0]) {
              return alert(error.detail);
            } else {
              return alert('There is a problem sharing...');
            }
          });
          return this.shareCreateAction(this.get('shareable'), deferred);
        }
      },
      hideShareModal() {
        return this.set('showShareModal', false);
      }
    }
  });
});