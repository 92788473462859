define("admin/components/organizations/client-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'table-responsive organization-clients',
    actions: {
      goToClient: function (clientId) {
        this.goToClientPage(clientId);
      },
      toggleOrgOwner: function (client) {
        client.set('is_organization_owner', !client.get('is_organization_owner'));
        const _this = this;
        client.save().then(function (success) {}, function (failure) {
          client.set('is_organization_owner', !client.get('is_organization_owner'));
          window.alert(`Cannot save the client: ${failure.errors.getEach('detail').join('; ')}`);
        });
      }
    }
  });
});