define("admin/data/scheduling-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: "Fly as soon as possible",
    value: 'not_needed'
  }, {
    name: "Fly at specific date and time",
    value: 'scheduled'
  }, {
    name: "DroneBase call to schedule",
    value: 'call_to_schedule'
  }];
});