define("admin/components/onboarding/filter-pilots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    panelMode: null,
    panelTitle: null,
    status: null,
    lat: null,
    lon: null,
    distance: null,
    selectedDrones: null,
    selectedCameras: null,
    selectedDevices: null,
    selectedLicenses: null,
    selectedPilotEquipment: null,
    selectedBadges: null,
    selectPilotBadgeStatuses: null,
    statuses: null,
    locationTitle: Ember.computed('', function () {
      return 'Location';
    }),
    licenseTitle: Ember.computed('selectedLicenses.[]', function () {
      return 'Licenses (' + this.get('selectedLicenses.length') + ')';
    }),
    pilotEquipmentTitle: Ember.computed('selectedPilotEquipment.[]', function () {
      return 'Pilot Equipment (' + this.get('selectedPilotEquipment.length') + ')';
    }),
    setPanel(mode, title) {
      this.set('panelMode', mode);
      this.set('panelTitle', title);
    },
    actions: {
      changeCountryCodesAction(countryCodes) {
        return this.changeCountryCodes(countryCodes);
      },
      setPanel(mode, title) {
        this.setPanel(mode, title);
      },
      toggleFilter() {
        this.get('toggleFilter')();
      },
      cacheObjects(key, value) {
        this.set(key, value);
      }
    }
  });
});