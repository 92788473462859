define("admin/models/pilot-equipment", ["exports", "ember-data", "ember-model-validator/mixins/model-validator", "admin/models/inventoriable"], function (_exports, _emberData, _modelValidator, _inventoriable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotEquipment;
  PilotEquipment = _inventoriable.default.extend(_modelValidator.default, {
    name: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),
    full_name: Ember.computed('brand', 'name', function () {
      return [this.get('brand'), this.get('name')].filter(Boolean).join(' ');
    }),
    validations: {
      name: {
        presence: true
      }
    }
  });
  var _default = _exports.default = PilotEquipment;
});