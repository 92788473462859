define("admin/components/site-inspections/forms/accounting-form", ["exports", "ember-changeset", "ember-changeset-validations", "admin/validations/site-inspection"], function (_exports, _emberChangeset, _emberChangesetValidations, _siteInspection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    legalEntities: Ember.A([]),
    workOrder: null,
    changeset: null,
    wrappedLegalEntities: Ember.computed('legalEntities', function () {
      return this.get('legalEntities').map(reason => Ember.Object.create({
        label: reason.get('name'),
        value: reason
      }));
    }),
    didReceiveAttrs: function () {
      this._super();
      this.set('changeset', new _emberChangeset.default(this.get('workOrder'), (0, _emberChangesetValidations.default)(_siteInspection.default), _siteInspection.default));
    },
    actions: {
      savePurchaseOrderNumberUpdate: function () {
        this.get("changeset").save();
      },
      clearPurchaseOrderNumberUpdate: function () {
        this.get("changeset").rollback();
      }
    }
  });
});