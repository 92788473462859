define("admin/models/site-inspection", ["exports", "ember-data", "@ember-data/model", "admin/mixins/payoutable", "admin/mixins/invoiceable", "admin/mixins/logable"], function (_exports, _emberData, _model, _payoutable, _invoiceable, _logable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend(_payoutable.default, _invoiceable.default, _logable.default, {
    created_at: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    // asset inspections only
    total_inspections: _emberData.default.attr('string'),
    // all missions, with or without assets
    total_missions: _emberData.default.attr('string'),
    operations_pending_missions_count: _emberData.default.attr('string'),
    operations_in_progress_missions_count: _emberData.default.attr('string'),
    operations_complete_missions_count: _emberData.default.attr('string'),
    purchase_order_number: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('string'),
    salesforce_opportunity_id: _emberData.default.attr('string'),
    origin_salesforce_opportunity_id: _emberData.default.attr('string'),
    estimated_labor_payout_amount: _emberData.default.attr('string', {
      defaultValue: '0'
    }),
    estimated_labor_payout_type: _emberData.default.attr('string'),
    estimated_standby_payout: _emberData.default.attr('string', {
      defaultValue: '0'
    }),
    estimated_mobilization_payout: _emberData.default.attr('string', {
      defaultValue: '0'
    }),
    internal_notes: _emberData.default.attr('string'),
    target_start_date: _emberData.default.attr('string'),
    target_end_date: _emberData.default.attr('string'),
    committed_start_date: _emberData.default.attr('string'),
    operation_rep_id: _emberData.default.attr('string'),
    services: _emberData.default.attr('array'),
    capture_group_id: _emberData.default.attr('string'),
    mission_location_id: _emberData.default.attr('string'),
    capture_group: _emberData.default.belongsTo('capture-group', {
      async: false
    }),
    stripe_accounts: _emberData.default.hasMany('stripe-account'),
    client: _emberData.default.belongsTo('client', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    legal_entity: _emberData.default.belongsTo('legal-entity', {
      async: false
    }),
    site: _emberData.default.belongsTo('location', {
      async: false
    }),
    missions: _emberData.default.hasMany('mission'),
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),
    site_inspection_pilots: _emberData.default.hasMany('site-inspection-pilot'),
    operationRep: _emberData.default.belongsTo('admin', {
      async: false
    }),
    onsite_contact: _emberData.default.belongsTo('site_inspection_onsite_contact', {
      async: false
    }),
    standby_hours_line_items: _emberData.default.hasMany('standby-hours-line-item', {
      async: false
    }),
    timezone: Ember.computed('site.timezone_id', function () {
      return this.get('site.timezone_id');
    }),
    humanizedStatus: Ember.computed('status', function () {
      return this.get('status').split('_').map(word => word.capitalize()).join(' ');
    }),
    // not sure if returning a default value is a good idea here?
    // eslint-disable-next-line ember/require-return-from-computed
    price: Ember.computed('invoice_line_items.@each.amount', function () {
      if (this.get('invoice_line_items')) {
        return this.get('invoice_line_items').getEach('amount').reduce((partial_sum, a) => partial_sum + a, 0);
      }
    }),
    pilots: Ember.computed('site_inspection_pilots.@each.user', function () {
      return this.get('site_inspection_pilots').getEach('user');
    }),
    numPilotsWithoutPayouts: Ember.computed('payouts.[]', 'pilots.[]', function () {
      const pilotIds = this.get('pilots').map(({
        id
      }) => id).uniq();
      const payoutPilotIds = this.get('payouts').map(payout => payout.get('pilot.id'));
      const pilotWithPayoutIds = pilotIds.filter(id => payoutPilotIds.includes(id));
      return pilotIds.concat(pilotWithPayoutIds).filter(item => !pilotIds.includes(item) || !pilotWithPayoutIds.includes(item)).length;
    }),
    missionStatuses: Ember.computed('missions.@each.status', function () {
      const missionStatuses = this.get('missions').map(mission => {
        mission.get('status');
      });
      return missionStatuses;
    }),
    isInProgress: Ember.computed('operations_in_progress_missions_count', function () {
      return this.get('operations_in_progress_missions_count') > 0;
    }),
    loadActivityLogs() {
      this.get('store').query('activity-log', {
        siteInspectionId: this.get('id')
      }).then(activityLogs => {
        this.set('activity_logs', activityLogs);
      }, error => {
        alert(error);
      });
    },
    loadPilots() {
      this.get('store').query('pilot', {
        siteInspectionId: this.get('id')
      }).then(items => {
        this.set('pilots', items);
      });
    },
    loadOnsiteContact() {
      if (!this.get('onsite_contact')) {
        this.get('store').createRecord('site-inspection-onsite-contact', {
          site_inspection: this,
          call_action: 'not_needed'
        });
      }
    }
  });
});