define("admin/components/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    showDropdown: false,
    disableOnSelect: false,
    replacePromptOnSelect: true,
    optionLabelPath: 'displayName',
    actions: {
      toggleShowDropdown() {
        return this.set('showDropdown', !this.get('showDropdown'));
      },
      selectItem(item) {
        if (this.get('disableOnSelect')) {
          item.set('selected', true);
        }
        if (this.get('replacePromptOnSelect')) {
          this.set('selectedItem', item);
        }
        if (this.get('action')) {
          this.action(item);
        }
      },
      dropdownBlurred() {
        //click onto hovered element (the inner one)
        $(this.$(':hover')[this.$(':hover').length - 1]).click();
        if (this.get('showDropdown')) {
          this.$('button.dropbtn').click();
          if (this && this.get('refresh')) {
            return this.set('showDropdown', false);
          }
        }
      }
    }
  });
});