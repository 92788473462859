define("admin/components/inventory/inventory-other-equipment-fields", ["exports", "admin/components/inventory/inventory-items-fields"], function (_exports, _inventoryItemsFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _inventoryItemsFields.default.extend({
    itemsList: Ember.computed(function () {
      return this.get('otherEquipmentList').toArray();
    }),
    newModelName: 'pilot-equipment'
  });
});