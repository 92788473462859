define("admin/components/open-client-app", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OpenClientAppComponent;
  OpenClientAppComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    clientLoginUrl: Ember.computed('params', function () {
      var adminEmail, adminToken, name, nameEncoded, prop, propEncoded, ref, url;
      adminToken = this.get('session.data.authenticated.token');
      adminEmail = this.get('session.data.authenticated.email');
      url = _environment.default.clients.host + "/?admin_token=" + adminToken + "&admin_email=" + adminEmail;
      ref = this.get('params');
      for (name in ref) {
        prop = ref[name];
        nameEncoded = encodeURIComponent(name);
        propEncoded = encodeURIComponent(prop);
        url = url + ("&" + nameEncoded + "=" + propEncoded);
      }
      return url;
    }),
    actions: {
      openClientApp: function () {
        return window.open(this.get('clientLoginUrl'));
      }
    }
  });
  var _default = _exports.default = OpenClientAppComponent;
});