define("admin/helpers/pilot-has-standby-line-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pilotHasStandbyLineItems = pilotHasStandbyLineItems;
  function pilotHasStandbyLineItems([standbyHoursLineItems, siteInspectionId]) {
    const pilotLineItems = standbyHoursLineItems.filter(({
      site_inspection
    }) => site_inspection.get('id') === siteInspectionId);
    return pilotLineItems && pilotLineItems.length > 0;
  }
  var _default = _exports.default = Ember.Helper.helper(pilotHasStandbyLineItems);
});