define("admin/services/upload-queue-manager", ["exports", "admin/utils/uploader/queue"], function (_exports, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    init() {
      this._super();
      return this.set('queues', new Map());
    },
    create(name, component, config) {
      const queue = _queue.default.create({
        name,
        target: component
      });
      this.get('queues').set(name, queue);
      queue.configureUploader(config);
      return queue;
    },
    findOrCreate(name, component, config) {
      let queue;
      if (this.get('queues').has(name)) {
        queue = this.get('queues').get(name);
        if (config !== null) {
          queue.set('target', component);
          queue.configureUploader(config);
        }
      } else {
        queue = this.create(name, component, config);
      }
      return queue;
    }
  });
});