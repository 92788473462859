define("admin/components/pilot-dispatch", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotDispatchComponent;
  PilotDispatchComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    classNames: ['pilot-dispatch'],
    isSendingRequest: false,
    drones: [],
    notifiedPilotsSort: ['status:asc', 'created_on:desc'],
    orderedNotifiedPilots: Ember.computed.sort('model.notified_pilots', 'notifiedPilotsSort'),
    canReInvite: Ember.computed('model.notified_pilots.[]', 'model.status', function () {
      return !this.get('model.notified_pilots').findBy('status', 'accepted') && this.get('model.status') !== 'created' && this.get('model.status') !== 'scheduling_call';
    }),
    collapsedAfterFive: true,
    actions: {
      pilotCancelled: function (model) {
        var _model, _this, confirm, headers;
        confirm = this.get('isSendingRequest') ? false : window.confirm("Are you sure you want to cancel this pilot?");
        if (confirm) {
          _this = this;
          _model = this.get('model');
          headers = this.get('sessionAccount.headers');
          _this.set('isSendingRequest', true);
          return Ember.$.ajax({
            url: _environment.default.api.host + "/v1/admin/missions/" + _model.id + "/pilot",
            type: 'DELETE',
            dataType: 'json',
            headers: headers
          }).then(function (response) {
            _model.reload();
            return _this.set('isSendingRequest', false);
          }, function (response) {
            return _this.set('isSendingRequest', false);
          });
        }
      },
      reInvite: function (pilot_notification) {
        var confirm;
        confirm = this.get('isSendingRequest') ? false : window.confirm("Are you sure you want re-invite " + pilot_notification.get('pilot.fullName') + "?");
        if (confirm) {
          this.set('isSendingRequest', true);
          return Ember.$.ajax({
            url: _environment.default.api.host + "/v1/admin/missions/" + pilot_notification.get('mission.id') + "/notifications",
            headers: this.get('sessionAccount.headers'),
            type: 'POST',
            dataType: 'json',
            data: {
              pilot: pilot_notification.get('pilot.id')
            }
          }).then(function (_this) {
            return function (response) {
              _this.get('model').reload();
              return _this.set('isSendingRequest', false);
            };
          }(this), function (_this) {
            return function (response) {
              return _this.set('isSendingRequest', false);
            };
          }(this));
        }
      },
      toggleCollapse: function () {
        return this.toggleProperty('collapsedAfterFive');
      }
    }
  });
  var _default = _exports.default = PilotDispatchComponent;
});