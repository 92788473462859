define("admin/helpers/format-year-and-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pluralize = (word, count) => {
    return count > 1 ? word + 's' : word;
  };
  var _default = _exports.default = Ember.Helper.extend({
    compute([months]) {
      let output = '';
      let monthsCount = months % 12;
      let yearsCount = (months - monthsCount) / 12;
      if (yearsCount > 0) {
        output += yearsCount + ' ' + pluralize('year', yearsCount) + ' ';
      }
      if (monthsCount > 0) {
        output += monthsCount + ' ' + pluralize('month', monthsCount);
      }
      return output;
    }
  });
});