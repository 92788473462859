define("admin/data/searches/mission_search_attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Synced with attributes hash at MissionsSearch at Sake
  var _default = _exports.default = [{
    label: 'Inspection ID (DBM)',
    value: 'number'
  }, {
    label: 'Site ID (DBML)',
    value: 'dbml'
  }, {
    label: 'Site Name',
    value: 'site_name'
  }, {
    label: 'Formatted Address',
    value: 'formatted_address'
  }, {
    label: 'Address',
    value: 'address'
  }, {
    label: 'City',
    value: 'city'
  }, {
    label: 'State',
    value: 'state'
  }, {
    label: 'Postal Code',
    value: 'postal_code'
  }, {
    label: 'Country',
    value: 'country'
  }, {
    label: 'Capture Group Name',
    value: 'cg_name'
  }, {
    label: 'Capture Group ID (DBCG)',
    value: 'dbcg'
  }, {
    label: 'Client Email',
    value: 'client_email'
  }, {
    label: 'Client Name',
    value: 'client_name'
  }, {
    label: 'Company',
    value: 'company'
  }, {
    label: 'Package Name',
    value: 'package'
  }, {
    label: 'Portfolio Name',
    value: 'portfolio_name'
  }, {
    label: 'Ops Coordinator',
    value: 'rep'
  }, {
    label: 'Reference ID',
    value: 'reference_id'
  }];
});