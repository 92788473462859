define("admin/components/mission-status-filter", ["exports", "admin/data/mission_statuses"], function (_exports, _mission_statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionStatusFilterComponent;
  MissionStatusFilterComponent = Ember.Component.extend({
    classNames: ["mission-status-filter"],
    missionStatuses: _mission_statuses.default,
    selectedStatus: null,
    sidebar: false,
    showDaterange: false,
    selectedStatuses: Ember.computed('selectedStatus', function () {
      return (this.get('selectedStatus') || '').split(',');
    }),
    didInsertElement: function () {
      var endDate, localEndTime, localStartTime, startDate;
      this.syncCheckboxesWithStatusFilterValue();
      if (this.get('showDaterange')) {
        if (this.get('startDaterange')) {
          localStartTime = moment.utc(this.get('startDaterange')).toDate();
          startDate = moment(localStartTime).format('MM/DD/YYYY');
          this.set('startDate', startDate);
        }
        if (this.get('endDaterange')) {
          localEndTime = moment.utc(this.get('endDaterange')).toDate();
          endDate = moment(localEndTime).format('MM/DD/YYYY');
          return this.set('endDate', endDate);
        }
      }
    },
    didRender: function () {
      this.syncCheckboxesWithStatusFilterValue();
      if (this.get('showDaterange')) {
        Ember.$('.start-day-filter').datetimepicker({
          collapse: true,
          focusOnShow: false,
          viewMode: 'days',
          format: 'MM/DD/YYYY',
          useCurrent: false,
          maxDate: moment().endOf('day'),
          icons: {
            date: "fa fa-calendar",
            previous: "fa fa-angle-left",
            next: "fa fa-angle-right",
            close: "icon-Xmark"
          }
        });
        return Ember.$('.end-day-filter').datetimepicker({
          collapse: true,
          focusOnShow: false,
          viewMode: 'days',
          format: 'MM/DD/YYYY',
          useCurrent: false,
          maxDate: moment().endOf('day'),
          icons: {
            date: "fa fa-calendar",
            previous: "fa fa-angle-left",
            next: "fa fa-angle-right",
            close: "icon-Xmark"
          }
        });
      }
    },
    syncCheckboxesWithStatusFilterValue: function () {
      var i, len, ref, results, status, statusCode;
      ref = this.get('missionStatuses');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        status = ref[i];
        statusCode = status.value;
        results.push(this.$("input:checkbox[data-status='" + statusCode + "']").prop('checked', this.get('selectedStatuses').includes(statusCode)));
      }
      return results;
    },
    setStatuses: function () {
      var statuses;
      statuses = [];
      this.$("input:checkbox").each(function (index, checkbox) {
        if (checkbox.checked) {
          return statuses.push(checkbox.value);
        }
      });
      return this.set('selectedStatus', statuses.join());
    },
    actions: {
      updateMissionStatuses: function () {
        return Ember.run.debounce(this, this.setStatuses, 1500);
      },
      clearMissionStatuses: function () {
        this.set('selectedStatus', null);
        return this.$("input:checkbox").prop('checked', false);
      },
      setDateRange: function () {
        var localEndDate, localFormat, localStartDate, utcEndDate, utcStartDate;
        localStartDate = this.get('startDate');
        localEndDate = this.get('endDate');
        localFormat = 'MM/DD/YYYY';
        if (localStartDate) {
          utcStartDate = moment.utc(moment(localStartDate, localFormat).utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('startDaterange', utcStartDate.format(moment.defaultFormat));
        } else {
          this.set('startDaterange', null);
        }
        if (localEndDate) {
          utcEndDate = moment.utc(moment(localEndDate, localFormat).endOf('day').utc().format('YYYY-MM-DD HH:mm:ssZ'));
          return this.set('endDaterange', utcEndDate.format(moment.defaultFormat));
        } else {
          return this.set('endDaterange', null);
        }
      },
      clearDateRange: function () {
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('startDaterange', null);
        return this.set('endDaterange', null);
      }
    }
  });
  var _default = _exports.default = MissionStatusFilterComponent;
});