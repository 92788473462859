define("admin/helpers/is-equal-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsEqualIdHelper;
  IsEqualIdHelper = Ember.Helper.helper(function (arg) {
    var leftId, leftSide, rightId, rightSide;
    leftSide = arg[0], rightSide = arg[1];
    leftId = function () {
      try {
        return leftSide.get('id');
      } catch (error) {}
    }();
    rightId = function () {
      try {
        return rightSide.get('id');
      } catch (error) {}
    }();
    if (rightId !== void 0 && leftId !== void 0) {
      return leftId === rightId;
    } else {
      return leftSide === rightSide;
    }
  });
  var _default = _exports.default = IsEqualIdHelper;
});