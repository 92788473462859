define("admin/adapters/onsite-contact", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OnsiteContactAdapter;
  OnsiteContactAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    urlTemplate: '{+namespace}/missions/{missionId}/onsite_contact/{id}',
    urlSegments: {
      missionId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('mission', {
          id: true
        });
      }
    }
  });
  var _default = _exports.default = OnsiteContactAdapter;
});