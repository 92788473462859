define("admin/models/pilot-badge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotBadge;
  PilotBadge = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    pilot: _emberData.default.belongsTo('pilot', {
      async: false
    }),
    badge: _emberData.default.belongsTo('badge', {
      async: false
    }),
    complete: Ember.computed('status', function () {
      return this.get('status') === 'complete';
    })
  });
  var _default = _exports.default = PilotBadge;
});