define("admin/components/site-inspections/capture-group-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    workOrder: null,
    numMissionsOutOfCaptureGroup: Ember.computed('workOrder.{total_missions,capture_group.missions_count}', function () {
      let workOrderMissions = this.get('workOrder.total_missions') || 0;
      let captureGroupMissions = this.get('workOrder.capture_group.missions_count') || 0;
      return workOrderMissions - captureGroupMissions;
    }),
    showMissionsOutOfCaptureGroupWarning: Ember.computed('numMissionsOutOfCaptureGroup', 'workOrder.capture_group', function () {
      return this.numMissionsOutOfCaptureGroup > 0 && this.get('workOrder.capture_group');
    }),
    actions: {
      scrollToInspections() {
        /* eslint-disable ember/no-global-jquery */
        /* eslint-disable no-undef */
        const rootOffset = jQuery('.content-with-sidebar__content')?.offset()?.top || 0;
        const elementOffset = jQuery('#inspections').offset().top;
        jQuery(document).scrollTop(elementOffset - rootOffset);
        /* eslint-enable ember/no-global-jquery */
        /* eslint-enable no-undef */
      }
    }
  });
});