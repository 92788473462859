define("admin/components/missions/add-mission-to-work-order-modal", ["exports", "admin/data/work-order/search-field-options", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _searchFieldOptions, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    searchField: 'external_id',
    searchValue: null,
    infinity: Ember.inject.service(),
    store: Ember.inject.service(),
    searchFieldOptions: _searchFieldOptions.searchFieldOptions,
    selectedWorkOrder: null,
    init() {
      this._super(...arguments);
      this.searchWorkOrders();
      if (this.get('mission.site_inspection_id')) {
        this.set('selectedWorkOrder', this.get('mission.site_inspection'));
      }
    },
    async searchWorkOrders() {
      const workOrders = await this.store.query('site-inspection', this.get('workOrdersSearchQuery'));
      this.infinity.replace(this.get('workOrders'), workOrders);
    },
    workOrdersSearchQuery: Ember.computed('searchField', 'searchValue', function () {
      return {
        attr: this.get('searchField'),
        q: this.get('searchValue'),
        per_page: 100
      };
    }),
    actions: {
      changeSearchField(value) {
        this.set('searchField', value);
        this.searchWorkOrders();
      },
      changeSearchValue(event) {
        this.set('searchValue', event.target.value);
        this.searchWorkOrders();
      },
      changeSelectedWorkOrder(workOrder) {
        if (this.get('selectedWorkOrder.id') === workOrder.id) {
          return this.set('selectedWorkOrder', this.get('mission.site_inspection'));
        }
        return this.set('selectedWorkOrder', workOrder);
      },
      submit() {
        this.set('mission.site_inspection', this.get('selectedWorkOrder'));
        this.get('mission').save().then(() => {
          this.get('mission').reload();
          this.flashMessages.success(`You've assigned mission to Work order!`);
          this.close();
        }, response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error((0, _getErrorMessageFromResponse.default)(response));
        });
      },
      close() {
        this.set('searchValue', null);
        return this.get('close')();
      }
    }
  });
});