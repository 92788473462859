define("admin/helpers/timeframe-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([start_hour, end_hour, duration, isStartOption, format = 'h A']) {
      try {
        if (isStartOption) {
          end_hour = end_hour - duration;
        } else {
          start_hour = start_hour + duration;
        }
        return new Array(end_hour - start_hour).fill().map((d, i) => this.generateOption(i + start_hour, format));
      } catch (e) {
        return "";
      }
    },
    generateOption(hour, format) {
      return {
        name: moment(new Date().setHours(hour)).format(format),
        value: (hour * 60).toString()
      };
    }
  });
});