define("admin/components/mission-flight-app", ["exports", "admin/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionFlightAppComponent;
  MissionFlightAppComponent = Ember.Component.extend(_fileSaver.default, {
    classNames: ["mission-flight-app row panel-group"],
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    workflowModeOverride: null,
    hasCyberduckCredentials: true,
    didInsertElement: function () {
      if (!(this.get('missionFlightApp.flight_app.deliver_to_app') === 'cyberduck' && this.get('missionFlightApp.value.access_id') != null && this.get('missionFlightApp.value.secret_key') != null)) {
        return this.set('hasCyberduckCredentials', false);
      }
    },
    groupedFlightApps: Ember.computed('model.flightApps', function () {
      var output, values;
      output = {};
      this.get('model.flightApps').forEach(function (flightApp) {
        var key;
        key = flightApp.get('name') + '-' + flightApp.get('data_type');
        if (output[key]) {
          return output[key]['apps'].push(flightApp);
        } else {
          return output[key] = {
            name: flightApp.get('name'),
            dataType: flightApp.get('data_type'),
            apps: [flightApp]
          };
        }
      });
      values = Object.values(output);
      if (!this.get('activeFlightAppGroup')) {
        this.set('activeFlightAppGroup', values[0]);
      }
      return values.sort(function (a, b) {
        var textA, textB;
        textA = a.name;
        textB = b.name;
        return textA.localeCompare(textB);
      });
    }),
    workflowMode: Ember.computed('missionFlightApp', function () {
      if (this.get('missionFlightApp')) {
        return 'create';
      } else {
        return 'none';
      }
    }),
    requireCyberduck: Ember.computed('missionFlightApp', 'missionFlightApp.flight_app', function () {
      return this.get('missionFlightApp.flight_app.deliver_to_app') === 'cyberduck';
    }),
    requireExternalUrl: Ember.computed('missionFlightApp', 'missionFlightApp.flight_app', function () {
      return this.get('missionFlightApp.flight_app.deliver_to_app') === 'external_url';
    }),
    missionFlightApp: Ember.computed('model.mission.mission_flight_app', 'transientMissionFlightApp', function () {
      if (this.get('model.mission.mission_flight_app')) {
        return this.get('model.mission.mission_flight_app');
      } else {
        return this.get('transientMissionFlightApp');
      }
    }),
    valueErrorObserver: Ember.observer('valueError', 'missionFlightApp.value.android', 'missionFlightApp.value.ios', 'missionFlightApp.value.external_id', function () {
      var valuePresent;
      valuePresent = this.get('missionFlightApp.value.android') || this.get('missionFlightApp.value.ios') || this.get('missionFlightApp.value.external_id');
      if (this.get('valueError') && valuePresent) {
        return this.set('valueError', !valuePresent);
      }
    }),
    deliveryErrorObserver: Ember.observer('deliveryError', 'missionFlightApp.delivery_to_url', function () {
      var deliveryPresent;
      deliveryPresent = this.get('missionFlightApp.delivery_to_url');
      if (this.get('deliveryError') && deliveryPresent) {
        return this.set('deliveryError', !deliveryPresent);
      }
    }),
    actions: {
      setEditorMode: function (value) {
        var mfa;
        if (value === 'none') {
          if ((mfa = this.get('missionFlightApp')) && mfa.id) {
            if (confirm("Are you sure you want to delete the workflow for this mission? " + mfa.get('mission.id'))) {
              mfa.set('mission', this.get('model.mission'));
              this.get('model.mission').set('mission_flight_app', mfa);
              return mfa.destroyRecord().then(function (_this) {
                return function () {
                  _this.get('model.mission').set('mission_flight_app', null);
                  _this.set('transientMissionFlightApp', null);
                  return alert("Workflow has been deleted");
                };
              }(this), function () {
                return alert("Failed to delete Workflow");
              });
            } else {
              return Ember.$(this.element).find('[name=workflow-mode][value=create]').click();
            }
          } else {
            return this.set('transientMissionFlightApp', null);
          }
        } else {
          if (!this.get('model.mission.mission_flight_app')) {
            console.log('creating a transient mission flight app');
            mfa = this.get('store').createRecord('mission_flight_app');
            mfa.set('value', {});
            mfa.set('flight_app', this.get('model.flightApps.firstObject'));
            return this.set('transientMissionFlightApp', mfa);
          }
        }
      },
      setAppGroup: function (elem) {
        var currentFlightAppUpload, equivalentUploadMethod, selection, value;
        console.clear();
        value = parseInt(elem.target.value);
        selection = this.get('groupedFlightApps').objectAt(value);
        this.set('activeFlightAppGroup', selection);
        console.log('app group items', selection.apps);
        currentFlightAppUpload = this.get('missionFlightApp.flight_app.deliver_to_app');
        equivalentUploadMethod = selection.apps.filter(function (app) {
          console.log(app.get('deliver_to_app'), currentFlightAppUpload);
          return app.get('deliver_to_app') === currentFlightAppUpload;
        })[0];
        console.log('equivalent upload method', equivalentUploadMethod);
        return this.get('missionFlightApp').set('flight_app', equivalentUploadMethod || selection.apps.objectAt(0));
      },
      setFlightApp: function (elem) {
        var selection, value;
        value = parseInt(elem.target.value);
        selection = this.get('model.flightApps').filter(function (app) {
          return parseInt(app.get('id')) === value;
        })[0];
        console.log('value', value);
        console.log('setFlightApp', selection);
        if (!selection) {
          selection = this.get('model.flightApps')[0];
        }
        return this.get('missionFlightApp').set('flight_app', selection);
      },
      submit: function () {
        var mfa;
        mfa = this.get('missionFlightApp');
        mfa.set('mission', this.get('model.mission'));
        if (mfa.get('flight_app.deliver_to_app') !== 'external_url') {
          mfa.set('delivery_to_url', null);
        }
        return mfa.save().then(function () {
          return alert("Workflow Saved");
        }, function (_this) {
          return function (response) {
            console.log(response.errors);
            return response.errors[0].detail.split(', ').forEach(function (error) {
              var field;
              field = error.split(' ')[0].toLowerCase();
              return _this.set(field + 'Error', error);
            });
          };
        }(this));
      },
      generateCredentials: function (mission) {
        $('.cyberduck-credentials .error').hide();
        return $.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/cyberduck",
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (data) {
            _this.get('model.mission').reload();
            return _this.set('hasCyberduckCredentials', true);
          };
        }(this), function (_this) {
          return function (error) {
            return $('.cyberduck-credentials .error').show();
          };
        }(this));
      },
      resetCyberduck: function (mission) {
        var really_delete;
        really_delete = confirm('Delete all assets and set mission to pilot_accepted?');
        if (!really_delete) {
          return;
        }
        $('.cyberduck-reset .error').hide();
        return $.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/cyberduck/reset",
          type: 'DELETE',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (data) {
            return window.location.reload(true);
          };
        }(this), function (_this) {
          return function (error) {
            return $('.cyberduck-reset .error').show();
          };
        }(this));
      },
      getDuckFile: function (mission) {
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/cyberduck",
          type: 'GET',
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            var bb, blob, error, filename;
            try {
              blob = new Blob([response], {
                type: 'application/octet-stream'
              });
            } catch (error1) {
              error = error1;
              window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
              if (error.name === 'TypeError' && window.BlobBuilder) {
                bb = new BlobBuilder();
                bb.append(response);
                blob = bb.getBlob("application/octet-stream");
              } else if (e.name === 'InvalidStateError') {
                blob = new Blob([response], {
                  type: 'application/octet-stream'
                });
              } else {
                alert('Downloading is not supported on your device.');
              }
            }
            filename = mission.id + '.duck';
            return _this.saveFileAs(filename, blob, 'application/octet-stream');
          };
        }(this));
      }
    }
  });
  var _default = _exports.default = MissionFlightAppComponent;
});