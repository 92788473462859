define("admin/utils/send-error-to-sentry", ["exports", "@sentry/ember"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(errorMessage) {
    Sentry.withScope(function (scope) {
      scope.setLevel("error");
      Sentry.captureException(new Error(errorMessage));
    });
  }
});