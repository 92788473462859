define("admin/controllers/site-inspections/show", ["exports", "admin/config/environment", "moment-timezone"], function (_exports, _environment, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'MM/DD/YY';
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    missionStatusMap: Ember.computed(function () {
      return {
        'In Progress': ['created', 'scheduling_call', 'confirmed', 'pilots_notified', 'pilot_accepted', 'flight_complete', 'assets_uploaded', 'processing_shots'],
        Complete: ['assets_classified', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete']
      };
    }),
    nextStatus: null,
    activeTab: 'details',
    queryParams: ['activeTab'],
    activeSubTab: 'inspections',
    detailsAnchorIds: Ember.computed(function () {
      return ['pilots', 'payouts', 'estimated_rates', 'add_payouts', 'pricing', 'internal_notes', 'scheduling', 'on-site_contact', 'po_number', 'inspections'];
    }),
    standByHoursAnchorIds: Ember.computed('model.workOrder.pilots.@each.id', function () {
      return this.get('model.workOrder.pilots').map(pilot => `standby-hours-${pilot.id}`);
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    laborTypeValues: [{
      name: 'Per Asset',
      value: 'per_asset'
    }, {
      name: 'Per Day',
      value: 'per_day'
    }],
    insightsLink: Ember.computed('model.workOrder.{id,client.organization.insights_url}', function () {
      if (this.get('model.workOrder.organization.insights_url') && this.get('model.workOrder.id')) {
        return this.get('model.workOrder.organization.insights_url') + `/inspections/site/${this.get('model.workOrder.id')}`;
      }
      return null;
    }),
    showPilotEditModal: false,
    editingPilot: null,
    selectedPilot: Ember.computed('firstSelectedPilot', function () {
      return this.get('firstSelectedPilot');
    }),
    firstSelectedPilot: Ember.computed('model.workOrder.missions.length', function () {
      return Ember.Object.create({
        value: '',
        name: `All Missions (${this.get('model.workOrder.missions.length')})`
      });
    }),
    selectedStatus: Ember.computed('firstSelectedStatus', function () {
      return this.get('firstSelectedStatus');
    }),
    firstSelectedStatus: Ember.computed('model.workOrder.missions.length', function () {
      return Ember.Object.create({
        value: '',
        name: `All (${this.get('model.workOrder.missions.length')})`
      });
    }),
    pilotsForSelect: Ember.computed('model.workOrder.missions.@each.pilot', function () {
      var pilotsArray = [this.get('firstSelectedPilot')];
      var unassignedMissions = this.get('model.workOrder.missions').filterBy('pilot.fullName', undefined).length;
      pilotsArray.pushObject(Ember.Object.create({
        value: 'unassigned',
        name: `Unassigned (${unassignedMissions})`
      }));
      let pilots = this.get('model.workOrder.missions').getEach('pilot.fullName');
      pilots = [...new Set(pilots)];
      pilots.forEach(pilot => {
        if (Ember.isPresent(pilot)) {
          let missions = this.get('model.workOrder.missions').filterBy('pilot.fullName', pilot);
          let pilotHash = {
            value: pilot,
            name: `${pilot} (${missions.length})`
          };
          pilotsArray.pushObject(Ember.Object.create(pilotHash));
        }
      });
      return pilotsArray;
    }),
    selectedPilotObserver: Ember.observer('pilotsForSelect.@each', function () {
      const pilotsArray = this.get('pilotsForSelect');
      this.set('selectedPilot', pilotsArray.firstObject);
    }),
    statusesForSelect: Ember.computed('model.workOrder.missions.length', function () {
      var statusesArray = [this.get('firstSelectedStatus')];
      var statusesMap = this.get('missionStatusMap');
      Object.keys(statusesMap).forEach(statusName => {
        let statusHash = {
          value: statusName
        };
        let missions = this.get('model.workOrder.missions').filter(function (mission) {
          return statusesMap[statusName].indexOf(mission.get('status')) >= 0;
        });
        statusHash.name = statusName + ` (${missions.length})`;
        statusesArray.pushObject(Ember.Object.create(statusHash));
      });
      return statusesArray;
    }),
    updateNextStatus: Ember.observer('model.workOrder.status', function () {
      const headers = this.get('sessionAccount.headers');
      const currentStatus = this.get('model.workOrder.status').split(' ').map(word => word.toLowerCase()).join('_');
      Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/site_inspections/statuses`,
        type: 'GET',
        dataType: 'json',
        headers
      }).then(response => {
        const nextStatus = response['statuses'][response['statuses'].indexOf(currentStatus) + 1];
        // canceling from Admin is not enabled yet
        if (!nextStatus || nextStatus === 'cancelled') {
          return this.set('nextStatus', null);
        }
        this.set('nextStatus', nextStatus.split('_').map(word => word.capitalize()).join(' '));
      }, response => {
        console.error(response.responseJSON.errors.map(e => e.details));
      });
    }),
    // display values
    opsCoordinatorString: Ember.computed('model.workOrder.operationRep.fullName', function () {
      return this.get('model.workOrder.operationRep.fullName') || '-';
    }),
    locationString: Ember.computed('model.workOrder.location', function () {
      const site = this.get('model.workOrder.site');
      return [site.get('city'), site.get('state'), site.get('country')].compact().join(', ');
    }),
    shownMissions: Ember.computed('model.workOrder.missions.[]', 'selectedPilot', 'selectedStatus', function () {
      let missions = this.get('model.workOrder.missions');
      if (Ember.isPresent(this.get('selectedPilot.value'))) {
        if (this.get('selectedPilot.value') === 'unassigned') {
          missions = missions.filter(mission => Ember.isEmpty(mission.get('pilot.fullName')));
        } else {
          missions = missions.filterBy('pilot.fullName', this.get('selectedPilot.value'));
        }
      }
      if (Ember.isPresent(this.get('selectedStatus.value'))) {
        let statuses = this.get('missionStatusMap')[this.get('selectedStatus.value')];
        missions = missions.filter(function (mission) {
          return statuses.indexOf(mission.get('status')) >= 0;
        });
      }
      return missions;
    }),
    onsiteContactLine1: Ember.computed('model.workOrder.onsite_contact.name', function () {
      return this.get('model.workOrder.onsite_contact.name') || '-';
    }),
    onsiteContactLine2: Ember.computed('model.workOrder.onsite_contact.{phone,email}', function () {
      return this.get('model.workOrder.onsite_contact.phone') || this.get('model.workOrder.onsite_contact.email') || '';
    }),
    purchaseOrderNumberString: Ember.computed('model.workOrder.purchase_order_number', function () {
      return this.get('model.workOrder.purchase_order_number') || '-';
    }),
    DATE_FORMAT: 'MM/DD/YY',
    formatDate(date) {
      const isValid = (0, _momentTimezone.default)(date).isValid();
      return isValid ? (0, _momentTimezone.default)(date).format(this.DATE_FORMAT) : null;
    },
    datepickerOptions: Ember.computed(function () {
      return {
        minDate: (0, _momentTimezone.default)().startOf('day')
      };
    }),
    targetDateString: Ember.computed('model.workOrder.{target_start_date,target_end_date}', function () {
      let startDate = this.get('model.workOrder.target_start_date');
      let endDate = this.get('model.workOrder.target_end_date');
      if (startDate) startDate = this.formatDate(startDate);
      if (endDate) endDate = this.formatDate(endDate);
      if (startDate && endDate) return `${startDate} - ${endDate}`;
      if (startDate || endDate) return `${startDate || '???'} - ${endDate || '???'}`;
      return '-';
    }),
    committedDateString: Ember.computed('model.workOrder.committed_start_date', function () {
      let committedDate = this.get('model.workOrder.committed_start_date');
      if (committedDate) committedDate = this.formatDate(committedDate);
      return committedDate || '-';
    }),
    servicesString: Ember.computed('model.workOrder.services', function () {
      const services = [...this.services];
      const firstService = services.shift();
      const addlServices = services.map(service => service.toLowerCase());
      return [firstService, ...addlServices].join(', ');
    }),
    selectedPilotAndStatusObserver: Ember.observer('selectedStatus', 'selectedPilot', function () {
      this.set('checkAllMissions', false);
      this.get('model.workOrder.missions').setEach('isChecked', false);
    }),
    checkAllMissionsObserver: Ember.observer('checkAllMissions', function () {
      if (this.get('checkAllMissions')) {
        this.get('shownMissions').setEach('isChecked', true);
      } else {
        this.get('shownMissions').setEach('isChecked', false);
      }
    }),
    // model.workOrder.pilots.@each.standby_hours_line_items.length - for a new line item
    // model.standbyHoursLineItemsObserve.length - for removed unsaved line item
    standByHoursLineItemsByPilot: Ember.computed('model.workOrder.pilots.@each.{standby_hours_line_items}', 'model.workOrder.id', function () {
      return this.get('model.workOrder.pilots').reduce((hash, pilot) => {
        hash[pilot.get('id')] = pilot.get('standby_hours_line_items').filter(({
          site_inspection
        }) => site_inspection.get('id') === this.get('model.workOrder.id')).sortBy('starts_at').reverse();
        return hash;
      }, {});
    }),
    totalTimeStandByHoursLineItemsByPilot: Ember.computed('standByHoursLineItemsByPilot', function () {
      const hash = {};
      for (const pilotId in this.get('standByHoursLineItemsByPilot')) {
        hash[pilotId] = this.get('standByHoursLineItemsByPilot')[pilotId].reduce((sum, item) => sum + item.get('periodInSeconds'), 0);
      }
      return hash;
    }),
    workOrderTimeZone: Ember.computed('model.workOrder.site.timezone_id', function () {
      const gtmHours = (0, _momentTimezone.default)().tz(this.get('model.workOrder.site.timezone_id')).format('Z');
      const zoneName = (0, _momentTimezone.default)().tz(this.get('model.workOrder.site.timezone_id')).zoneName();
      return `(GTM${gtmHours}) ${zoneName}`;
    }),
    payoutsSort: Ember.computed(function () {
      return ['created_on'];
    }),
    payouts: Ember.computed.sort('model.workOrder.payouts', 'payoutsSort'),
    // input field values
    new_estimated_labor_payout_amount: Ember.computed('model.workOrder.estimated_labor_payout_amount', function () {
      return this.get('model.workOrder.estimated_labor_payout_amount');
    }),
    new_estimated_standby_payout: Ember.computed('model.workOrder.estimated_standby_payout', function () {
      return this.get('model.workOrder.estimated_standby_payout');
    }),
    new_estimated_mobilization_payout: Ember.computed('model.workOrder.estimated_mobilization_payout', function () {
      return this.get('model.workOrder.estimated_mobilization_payout');
    }),
    internalNotes: Ember.computed('model.workOrder.internal_notes', function () {
      return this.get('model.workOrder.internal_notes');
    }),
    targetStartDate: Ember.computed('model.workOrder.target_start_date', function () {
      const startDate = this.get('model.workOrder.target_start_date');
      return startDate && (0, _momentTimezone.default)(startDate).format(DATE_FORMAT) || null;
    }),
    targetEndDate: Ember.computed('model.workOrder.target_end_date', function () {
      const endDate = this.get('model.workOrder.target_end_date');
      return endDate && (0, _momentTimezone.default)(endDate).format(DATE_FORMAT) || null;
    }),
    committedStartDate: Ember.computed('model.workOrder.committed_start_date', function () {
      const committedDate = this.get('model.workOrder.committed_start_date');
      return committedDate && (0, _momentTimezone.default)(committedDate).format(DATE_FORMAT) || null;
    }),
    onsiteContactName: Ember.computed('model.workOrder.onsite_contact.name', function () {
      return this.get('model.workOrder.onsite_contact.name');
    }),
    onsiteContactPhone: Ember.computed('model.workOrder.onsite_contact.phone', function () {
      return this.get('model.workOrder.onsite_contact.phone');
    }),
    onsiteContactEmail: Ember.computed('model.workOrder.onsite_contact.email', function () {
      return this.get('model.workOrder.onsite_contact.email');
    }),
    onsiteContactNotes: Ember.computed('model.workOrder.onsite_contact.notes', function () {
      return this.get('model.workOrder.onsite_contact.notes');
    }),
    purchaseOrderNumber: Ember.computed('model.workOrder.purchase_order_number', function () {
      return this.get('model.workOrder.purchase_order_number');
    }),
    defaultCurrency: Ember.computed('model.{currencies.@each.code,defaultCurrencyCode}', function () {
      return this.get('model.currencies').findBy('code', this.get('model.defaultCurrencyCode'));
    }),
    approvedPayoutLineItemsHours: Ember.computed('workOrder.standby_hours_line_items.length', function () {
      const hash = {};
      for (const pilotId in this.get('standByHoursLineItemsByPilot')) {
        let sum = 0;
        this.get('standByHoursLineItemsByPilot')[pilotId].filter(lineItem => {
          if (lineItem.status === 'approved') {
            sum = sum + lineItem.get('periodInSeconds');
          }
        });
        hash[pilotId] = sum / 3600;
      }
      return hash;
    }),
    init() {
      this._super();
      Ember.run.schedule('afterRender', this, () => {
        this.notifyPropertyChange('model.status');
      });
    },
    actions: {
      setTab(tab) {
        this.set('activeTab', tab);
        window.scrollTo({
          top: 0
        });
        if (tab === 'activityLogs') {
          this.get('model.workOrder').loadActivityLogs();
        }
      },
      showPilotStandbyHours(pilotId) {
        this.set('activeTab', 'standbyHours');
        Ember.run.later(() => {
          return window.scrollTo({
            top: document.getElementById(`standby-hours-${pilotId}`).offsetTop
          });
        }, 100);
      },
      updateWorkOrderStatus(nextStatus) {
        let confirm;
        if (nextStatus === 'Complete' && this.get('model.workOrder.numPilotsWithoutPayouts') > 0) {
          confirm = window.confirm(`${this.get('model.workOrder.numPilotsWithoutPayouts')} pilots do not yet have payouts for this work order. Are you sure you want to move this work order to Complete?`);
        } else {
          confirm = window.confirm('Are you sure you want to change the status?');
        }
        if (confirm) {
          const model = this.get('model');
          this.set('isSendingRequest', true);
          return Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/site_inspections/${model.workOrder.id}/status`,
            type: 'PATCH',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(() => {
            // eslint-disable-next-line ember/jquery-ember-run
            this.model.workOrder.reload();
            if (this.get('activeTab') === 'activityLogs') location.reload();
          }, response => {
            let errors = '';
            // eslint-disable-next-line ember/jquery-ember-run
            response.responseJSON.errors.forEach(error => {
              errors = errors + error.detail + '\n';
            });
            alert(errors);
            // eslint-disable-next-line ember/jquery-ember-run
            this.set('isSendingRequest', false);
          });
        }
      },
      openPilotAssignmentModal() {
        let selectedMissions = this.get('shownMissions').filterBy('isChecked', true);
        let pilotsPresent = selectedMissions.filter(mission => {
          return Ember.isPresent(mission.get('pilot.id'));
        }).length > 0;
        if (selectedMissions.length > 0 && !pilotsPresent) {
          this.set('model.assignmentEntityType', 'mission');
          this.set('model.assignmentEntities', selectedMissions);
          this.send('openModal', 'site-inspections.pilots-assign-modal', this.get('model'));
        } else {
          alert('Some of selected missions belong to pilots');
        }
      },
      openPilotAssignmentWorkOrderModal() {
        this.set('model.assignmentEntityType', 'work order');
        this.set('model.assignmentEntities', this.get('model.workOrder'));
        this.send('openModal', 'site-inspections.pilots-assign-modal', this.get('model'));
      },
      unassignPilot() {
        let selectedMissions = this.get('shownMissions').filterBy('isChecked', true).filter(mission => Ember.isPresent(mission.get('pilot.id')));
        if (selectedMissions.length > 0) {
          const confirmUnassign = window.confirm(`Are you sure you want to unassign ${selectedMissions.length} missions from their pilot?`);
          if (confirmUnassign) {
            Ember.$.ajax({
              url: `${_environment.default.api.host}/v1/admin/site_inspections/${this.get('model.workOrder.id')}/assignment`,
              headers: this.get('headers'),
              type: 'DELETE',
              dataType: 'json',
              data: {
                missions_ids: selectedMissions.getEach('id')
              }
            }).then(() => {
              /* eslint-disable ember/jquery-ember-run */
              selectedMissions.setEach('isChecked', false);
              this.set('checkAllMissions', false);
              this.get('model.workOrder').reload();
              /* eslint-enable ember/jquery-ember-run */
            }, () => {
              alert('Failed to unassign missions');
            });
          }
        } else {
          alert('Select some missions to unassign');
        }
      },
      unassignWorkOrderPilot(si_pilot) {
        const pilot = si_pilot.get('user');
        const confirmUnassign = window.confirm(`Are you sure you want to unassign ${pilot.get('fullName')} from work order?`);
        if (confirmUnassign) {
          si_pilot.destroyRecord().then(() => {
            // noop
          }, error => {
            alert('Failed to unassign pilot');
            console.log(error);
          });
        }
      },
      saveSiteInspection() {
        this.get('model.workOrder').setProperties({
          estimated_labor_payout_amount: this.new_estimated_labor_payout_amount,
          estimated_standby_payout: this.new_estimated_standby_payout,
          estimated_mobilization_payout: this.new_estimated_mobilization_payout
        });
        this.get('model.workOrder').save().then(() => {
          // noop
        }, err => {
          window.alert(err);
        });
      },
      clearEstimatedPayouts() {
        this.set('new_estimated_labor_payout_amount', this.get('model.workOrder.estimated_labor_payout_amount'));
        this.set('new_estimated_standby_payout', this.get('model.workOrder.estimated_standby_payout'));
        this.set('new_estimated_mobilization_payout', this.get('model.workOrder.estimated_mobilization_payout'));
      },
      togglePilotEditModal(pilot = null) {
        this.set('selectedSIPilot', pilot);
        this.toggleProperty('showPilotEditModal');
      },
      updateOpsCoordinator(newCoordinator) {
        this.set('model.workOrder.operation_rep', newCoordinator);
      },
      updateWorkOrderNotes(newNotes) {
        this.set('model.workOrder.internal_notes', newNotes);
      },
      saveNotesUpdates() {
        this.set('model.workOrder.internal_notes', this.get('internalNotes'));
        this.get('model.workOrder').save();
      },
      clearNotesUpdates() {
        this.set('internalNotes', this.get('model.workOrder.internal_notes'));
      },
      saveSchedulingUpdates() {
        let newTargetStartDate = this.get('targetStartDate');
        newTargetStartDate = newTargetStartDate && new Date(newTargetStartDate) || null;
        let newTargetEndDate = this.get('targetEndDate');
        newTargetEndDate = newTargetEndDate && new Date(newTargetEndDate) || null;
        let newCommittedStartDate = this.get('committedStartDate');
        newCommittedStartDate = newCommittedStartDate && new Date(newCommittedStartDate) || null;
        this.set('model.workOrder.target_start_date', newTargetStartDate);
        this.set('model.workOrder.target_end_date', newTargetEndDate);
        this.set('model.workOrder.committed_start_date', newCommittedStartDate);
        this.get('model.workOrder').save();
      },
      clearSchedulingUpdates() {
        [['targetStartDate', 'target_start_date'], ['targetEndDate', 'target_end_date'], ['committedStartDate', 'committed_start_date']].forEach(([date, modelKey]) => {
          const modelDate = this.get('model.workOrder.' + modelKey);
          this.set(date, modelDate && (0, _momentTimezone.default)(modelDate).format(DATE_FORMAT) || null);
        });
      },
      saveOnsiteContactUpdates() {
        this.set('model.workOrder.onsite_contact.name', this.get('onsiteContactName'));
        this.set('model.workOrder.onsite_contact.phone', this.get('onsiteContactPhone'));
        this.set('model.workOrder.onsite_contact.email', this.get('onsiteContactEmail'));
        this.set('model.workOrder.onsite_contact.notes', this.get('onsiteContactNotes'));
        this.get('model.workOrder').get('onsite_contact').save();
      },
      clearOnsiteContactUpdates() {
        this.set('onsiteContactName', this.get('model.workOrder.onsite_contact.name'));
        this.set('onsiteContactPhone', this.get('model.workOrder.onsite_contact.phone'));
        this.set('onsiteContactEmail', this.get('model.workOrder.onsite_contact.email'));
        this.set('onsiteContactNotes', this.get('model.workOrder.onsite_contact.notes'));
      },
      setSubTab(tabName) {
        this.set('activeSubTab', tabName);
      },
      scrollToElementId(elementId, rootElementClass = null) {
        const rootOffset = Ember.$(`.${rootElementClass}`)?.offset()?.top || 0;
        const elementOffset = Ember.$(`#${elementId}`).offset().top;
        Ember.$(document).scrollTop(elementOffset - rootOffset);
        setTimeout(() => this.set('activeSubTab', elementId));
      }
    }
  });
});