define("admin/mirage/models/pilot", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    siteInspectionPilots: (0, _emberCliMirage.hasMany)('site-inspection-pilot'),
    standby_hours_line_items: (0, _emberCliMirage.hasMany)('standby-hours-line-item'),
    currency: (0, _emberCliMirage.belongsTo)('currency'),
    pilot_rates: (0, _emberCliMirage.hasMany)('pilot-rate'),
    stripe_accounts: (0, _emberCliMirage.hasMany)('stripe-account')
  });
});