define("admin/routes/missions/training-missions", ["exports", "ember-infinity/mixins/route", "admin/mixins/mission-share", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _missionShare, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TrainingMissionsRoute;
  TrainingMissionsRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, _missionShare.default, {
    model: function (params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      params['mission_types'] = 'training';
      return Ember.RSVP.hash({
        missions: this.infinityModel('mission', params)
      });
    },
    queryParams: {
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      lat: {
        refreshModel: true
      },
      lon: {
        refreshModel: true
      },
      distance: {
        refreshModel: true
      }
    }
  });
  var _default = _exports.default = TrainingMissionsRoute;
});