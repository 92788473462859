define("admin/routes/payouts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PayoutsIndexRoute;
  PayoutsIndexRoute = Ember.Route.extend({
    infinity: Ember.inject.service(),
    model: function (params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      return this.infinity.model('payout', params);
    },
    queryParams: {
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    }
  });
  var _default = _exports.default = PayoutsIndexRoute;
});