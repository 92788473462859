define("admin/models/rating", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    value: _emberData.default.attr('number'),
    mission: _emberData.default.belongsTo('mission'),
    async: false
  });
});