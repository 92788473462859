define("admin/validations/subscription", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    price: [(0, _validators.validatePresence)({
      presence: true,
      message: "This field is required"
    }), (0, _validators.validateNumber)({
      gte: 0,
      message: 'This field must be a number'
    })],
    currency: (0, _validators.validatePresence)({
      presence: true,
      message: "This field is required"
    }),
    duration: [(0, _validators.validatePresence)({
      presence: true,
      message: "This field is required"
    })],
    valid_for: [(0, _validators.validatePresence)({
      presence: true,
      message: "This field is required"
    })]
  };
});