define("admin/data/shot_list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    "id": 1,
    "name": "Dolly Shot",
    "notes": "",
    "video": "https://youtu.be/dRhhQi7izjY?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 2,
    "name": "Trucking Shot",
    "notes": "",
    "video": "https://youtu.be/fN9IQiw0nJ8?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 3,
    "name": "Pan Shot",
    "notes": "",
    "video": "https://youtu.be/-9yHN8MJ2GM?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 4,
    "name": "Crane Shot",
    "notes": "",
    "video": "https://youtu.be/jDrgGN7PMeE?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 5,
    "name": "Tilt Shot",
    "notes": "",
    "video": "https://youtu.be/Sl7dA8yj4hY?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 6,
    "name": "Reveal Shot",
    "notes": "",
    "video": "https://youtu.be/ZRurLFqWiJQ?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 7,
    "name": "360 Spin Shot",
    "notes": "",
    "video": "https://youtu.be/tu5Bf3sadeY?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 8,
    "name": "Orbit Shot",
    "notes": "",
    "video": "https://youtu.be/6CuXIrVn4uQ?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 9,
    "name": "Birds Eye Shot",
    "notes": "",
    "video": "https://youtu.be/3svMcAKIEGI?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 10,
    "name": "Rise + Reverse Away Shot",
    "notes": "",
    "video": "https://youtu.be/Qp1fncXodFQ?list=PLxn2u8qdjXsOBU1XsZPTI1JkgzJcdJb1F"
  }, {
    "id": 99,
    "name": "Custom",
    "notes": "",
    "video": ""
  }];
});