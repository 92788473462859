define("admin/components/aria-checkbox", ["exports", "ember-aria-checkbox/components/aria-checkbox"], function (_exports, _ariaCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ariaCheckbox.default;
    }
  });
});