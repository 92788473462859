define("admin/templates/components/site-inspections/capture-group-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wGvbYFcp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"work-order-capture-group-warning\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"title\"],[12],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[2,\" \"],[1,[34,1]],[2,\" inspections are out of Capture Groups \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"You may want to move these inspections to a Capture group for Pilot assignment and Pilot payout.\"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"label\"],[4,[38,0],[[32,0],\"scrollToInspections\",\"inspections\"],null],[12],[2,\"See inspections\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"numMissionsOutOfCaptureGroup\",\"showLink\",\"if\",\"showMissionsOutOfCaptureGroupWarning\"]}",
    "meta": {
      "moduleName": "admin/templates/components/site-inspections/capture-group-warning.hbs"
    }
  });
});