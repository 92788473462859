define("admin/templates/components/photo-swipe-meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MrpplDXW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pswp-meta\"],[12],[2,\"\\n\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-taken-at\",\"Date Taken\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-make\",\"Make\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-model\",\"Model\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-exposure-time\",\"Exposure Time\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-exposure-bias\",\"Exposure Bias Value\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-auto-white-balance\",\"White Balance\"]]]],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"meta-class\",\"label\"],[\"pswp-meta-dimensions\",\"Dimensions\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"warnings\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"asset-warning gps hidden\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[10,\"span\"],[14,0,\"meta-warning\"],[12],[2,\"Missing GPS Meta Data\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"asset-warning resolution hidden\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[10,\"span\"],[14,0,\"meta-warning\"],[12],[2,\"Low resolution\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"photo-swipe-meta-value\"]}",
    "meta": {
      "moduleName": "admin/templates/components/photo-swipe-meta.hbs"
    }
  });
});