define("admin/templates/components/mission-status-rewind-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LWWQTL36",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[35,0,[\"status\"]],\"confirmed\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[14,0,\"btn btn-secondary btn-sm\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-arrow-left\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Created\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"is-equal\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-status-rewind-button.hbs"
    }
  });
});