define("admin/components/reshoot-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    fullReshoot: true,
    rejectionReasonSlug: null,
    plannedDate: Ember.computed('', function () {
      // so it doesn't change with mission date
      this.get('formattedPlannedDate');
    }),
    promisedDate: Ember.computed('', function () {
      // so it doesn't change with mission date
      this.get('formattedPromisedDate');
    }),
    formattedPlannedDate: null,
    promiseDateDisabled: Ember.computed('rejectionReasonSlug', function () {
      const rejectionReason = this.model.rejectionReasons.findBy('slug', this.get('rejectionReasonSlug'));
      return (rejectionReason != null ? rejectionReason.promised_date_flexibility : undefined) !== true;
    }),
    actions: {
      setRejectionReasonSlug(slug) {
        return this.set('rejectionReasonSlug', slug);
      },
      toggleMode(value) {
        if (value === "full-reshoot") {
          this.set("fullReshoot", true);
        } else {
          this.set("fullReshoot", false);
        }
        return true;
      },
      close() {
        return this.get('close')();
      },
      reshoot(a, b) {
        return this.fullReshootAction(a, b);
      }
    }
  });
});