define("admin/templates/components/site-inspections/autocomplete-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dIkyFHkq",
    "block": "{\"symbols\":[\"object\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"selected-pilot\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"selected-pilot-details\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"selected-pilot-name\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"selected-pilot-email\"],[12],[2,\"\\n      \"],[1,[32,1,[\"email\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"span\"],[24,0,\"fa fa-times-circle gray-icon\"],[4,[38,2],[[32,0],[35,1],[32,1],[35,0]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"objectType\",\"deselectAutocomplete\",\"action\",\"selectedIds\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/site-inspections/autocomplete-selected.hbs"
    }
  });
});