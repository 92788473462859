define("admin/components/add-device-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddDeviceModalComponent;
  AddDeviceModalComponent = Ember.Component.extend({
    currentDevice: null,
    operatingSystems: [{
      value: 'ios',
      name: 'iOS'
    }, {
      value: 'android',
      name: 'Android'
    }],
    deviceTypes: [{
      value: 'phone',
      name: 'Phone'
    }, {
      value: 'tablet',
      name: 'Tablet'
    }],
    actions: {
      close: function () {
        return this.get('close')();
      },
      submit: function () {
        var device;
        device = this.get('currentRecord');
        if (!device.validate()) {
          return;
        }
        return this.action();
      }
    }
  });
  var _default = _exports.default = AddDeviceModalComponent;
});