define("admin/components/missions/process-payment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    status: null,
    actions: {
      close: function () {
        this.get('close')();
      },
      processPayment: function () {
        const _this = this;
        this.set('status', 'processing');
        const data = {
          credit_card: this.get('mission.credit_card'),
          status: 'not paid',
          price: this.get('mission.price')
        };
        const payment = this.get('mission.mission_payments').createRecord(data);
        payment.save().then(function (response) {
          if (_this.get('mission.isFree')) {
            _this.get('close')();
          } else {
            _this.set('status', 'success');
          }
          _this.get('mission').reload();
        }).catch(function (response) {
          payment.set('status', 'failed');
          _this.set('errors', response.errors);
          _this.set('status', 'error');
        });
      } // processPayment
    } // actions
  });
});