define("admin/components/camera-index-list", ["exports", "admin/components/equipment-index-list"], function (_exports, _equipmentIndexList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CameraIndexListComponent;
  CameraIndexListComponent = _equipmentIndexList.default.extend({
    collapsed: true,
    showModal: false,
    currentRecord: null,
    cachedRecords: [],
    recordType: null,
    actions: {
      cloneRecord: function (record) {
        this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
        this.get('currentRecord').setProperties(record.serialize().data.attributes);
        this.get('currentRecord').set('drone_manufacturer', record.get('drone_manufacturer'));
        return this.set('showModal', true);
      }
    }
  });
  var _default = _exports.default = CameraIndexListComponent;
});