define("admin/components/mission-weather-forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionWeatherForecastComponent;
  MissionWeatherForecastComponent = Ember.Component.extend({
    classNames: 'd-inline-block forecast',
    time: null,
    icon: null,
    temperature: null,
    precipProbability: null,
    cloudCover: null,
    windSpeed: null
  });
  var _default = _exports.default = MissionWeatherForecastComponent;
});