define("admin/templates/components/pilot-license-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2G20jI4v",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"license-col pilot-section-col\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"License \"],[1,[34,1]],[2,\":\"],[13],[2,\"\\n    \"],[1,[35,0,[\"license\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"license-col pilot-section-col\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Number:\"],[13],[2,\" \"],[1,[35,0,[\"license_number\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"license-col pilot-section-col\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-inline-block mg-right-sm\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Image:\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-inline-block\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"image\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"a\"],[15,6,[34,0,[\"image\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n          \"],[10,\"img\"],[15,\"src\",[34,0,[\"image\"]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/images/image_placeholder.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"license\",\"displayedIndex\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-license-view.hbs"
    }
  });
});