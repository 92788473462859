define("admin/components/polygon-notes", ["exports", "admin/data/map_features"], function (_exports, _map_features) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    featureColors: _map_features.default.featureColors,
    colorIndex: 0,
    hidePolygons: true,
    polygonNotes: Ember.computed('map_features.[]', 'hidePolygons', function () {
      if (this.get('map_features.length')) {
        if (this.get('hidePolygons')) {
          return this.get('map_features').filter(this.findArea).map(this.parseNote, this).slice(0, 10);
        } else {
          return this.get('map_features').filter(this.findArea).map(this.parseNote, this);
        }
      } else {
        return [];
      }
    }),
    polygonAreaOutsideOfRange: Ember.computed('map_features.[]', function () {
      if (this.get('map_features.length')) {
        return this.get('map_features').filter(feature => feature.properties.area_outside_of_defined_range).length;
      } else {
        return 0;
      }
    }),
    getNextColor(item) {
      if (item.properties.no_fly_zone) {
        return '#F26649';
      } else {
        this.set('colorIndex', this.get('colorIndex') + 1);
        return this.get('featureColors')[this.get('colorIndex') % 5];
      }
    },
    parseNote(item) {
      return {
        color: this.getNextColor(item),
        name: item.name,
        notes: item.notes,
        acres: (this.findArea(item) * 0.0002471).toFixed(2),
        sqrFeet: (this.findArea(item) * 10.76391).toLocaleString('en', {
          maximumFractionDigits: 1
        }),
        catalog_items: item.catalog_items
      };
    },
    findArea(item) {
      return item.properties.area;
    },
    actions: {
      moreOrLessPolygons() {
        this.toggleProperty('hidePolygons');
      }
    }
  });
});