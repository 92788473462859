define("admin/templates/components/pilot-equipment-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uU2/4HhK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pilot-section-col\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"EQUIPMENT \"],[1,[34,0]],[2,\":\"],[13],[2,\"\\n    \"],[1,[35,1,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"displayedIndex\",\"pilotEquipment\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-equipment-view.hbs"
    }
  });
});