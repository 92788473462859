define("admin/components/inventory/dropship-inventory-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    groupedItems: Ember.computed('inventoryItems', function () {
      return this.get('inventoryItems');
    }),
    preselectedInventoriesGroups: Ember.computed('preselectedInventories.[]', function () {
      return this.groupItems('preselectedInventories');
    }),
    preselectedInventories: Ember.computed('dropships', function () {
      return this.get('dropships').getEach('inventory_item');
    }),
    groupItems(itemsProperty) {
      const items = this.get(itemsProperty);
      const kinds = {
        drones: 'Drone',
        cameras: 'DroneCamera',
        adapters: 'Adapter',
        'other-equipment': 'PilotEquipment'
      };
      let result = [];
      for (let kind in kinds) {
        result.push({
          kind: kind,
          items: items.filterBy('inventory_type', kinds[kind])
        });
      }
      return result;
    },
    removeUnselectedItems() {
      const _this = this;
      var itemsToBeDeleted = [];
      this.get('dropships').forEach(function (dropshipItem) {
        var dronebaseUid = dropshipItem.get('inventory_item').get('dronebase_uid');
        var dropshipMissing = _this.get('preselectedInventories').filterBy('dronebase_uid', dronebaseUid).length === 0;
        if (dropshipMissing) {
          itemsToBeDeleted.push(dropshipItem);
        }
      });
      this.get('dropships').removeObjects(itemsToBeDeleted);
    },
    addSelectedItems() {
      const _this = this;
      this.get('preselectedInventories').forEach(function (inventoryItem) {
        var dropshippedItems = _this.get('dropships').getEach('inventory_item');
        var dropshipExists = dropshippedItems.filterBy('dronebase_uid', inventoryItem.get('dronebase_uid')).length > 0;
        if (!dropshipExists) {
          var newDropship = _this.get('store').createRecord('dropship', {
            pilot: _this.get('pilot'),
            inventory_item: inventoryItem
          });
          _this.get('dropships').pushObject(newDropship);
        }
      });
    },
    actions: {
      unselectItem: function (item) {
        this.get('preselectedInventories').removeObject(item);
      },
      saveAndClose: function () {
        this.removeUnselectedItems();
        this.addSelectedItems();
        this.send('close');
      },
      close: function () {
        this.closeModal();
      },
      toggleCollapsedAction: function (group) {
        this.toggleCollapsedAction(group);
      }
    }
  });
});