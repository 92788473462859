define("admin/mirage/factories/admin", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    first_name: 'hey',
    last_name: 'you',
    email: 'local_admin@mail.com',
    roles: ['ops_manager', 'account_rep'],
    invoiceManager: (0, _emberCliMirage.trait)({
      roles: ['ops_manager', 'account_rep', 'invoice_manager']
    })
  });
});