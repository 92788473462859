define("admin/templates/components/inventory/inventory-adapters-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fogvSL3r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inventory-modal-fields\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-fields\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        Model\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"modelsList\",\"searchValue\",\"selectRelationAction\",\"newRelationAction\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"newRelation\"],null]]]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"error-message\"],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"            Model can't be blank\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        Brand\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        \"],[1,[30,[36,11],null,[[\"optionLabelPath\",\"content\",\"selection\",\"action\",\"prompt\",\"selectClass\",\"disabled\"],[\"name\",[35,10],[35,8,[\"drone_manufacturer\"]],[30,[36,0],[[32,0],[30,[36,9],[[35,8,[\"drone_manufacturer\"]]],null]],null],\"Brand\",\"form-control input-md\",[35,7]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"selectRelationAction\",\"relationName\",\"itemsList\",\"inventory/inventory-model-search\",\"relationModelError\",\"if\",\"isRelationExists\",\"relation\",\"mut\",\"droneManufacturers\",\"select-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inventory/inventory-adapters-fields.hbs"
    }
  });
});