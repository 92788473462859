define("admin/components/pilot-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotBadgeComponent;
  PilotBadgeComponent = Ember.Component.extend({
    actions: {
      removeBadge: function (pilotBadge) {
        return $(this.element).animate({
          opacity: 0
        }, 500, "linear", function () {
          return pilotBadge.destroyRecord();
        });
      }
    }
  });
  var _default = _exports.default = PilotBadgeComponent;
});