define("admin/components/onboarding/pilot-location-filter", ["exports", "admin/mixins/google-map-init-mixin"], function (_exports, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    store: Ember.inject.service(),
    countries: null,
    changeCountryCodes: null,
    country_codes: [],
    countryOptions: Ember.computed('countries.[]', function () {
      return this.get('countries').map(country => {
        return Ember.Object.create({
          label: country.name,
          value: country.iso_code
        });
      });
    }),
    didInsertElement() {
      if (!this.get('testEnvironment')) {
        this._super(...arguments);
      }
    },
    clearLocationSearch() {
      this.setProperties({
        distanceFilter: false,
        lat: null,
        lon: null,
        distance: null
      });
    },
    initMap() {
      const input = document.getElementById('locationSearch');
      if (input) {
        input.addEventListener('change', event => {
          if (event.target.value === '') {
            this.clearLocationSearch();
          }
        });
        const autocomplete = new google.maps.places.SearchBox(input);
        autocomplete.addListener('places_changed', () => {
          const place = autocomplete.getPlaces()[0];
          if (place && place.geometry) {
            this.setProperties({
              distanceFilter: true,
              lat: place.geometry.location.lat(),
              lon: place.geometry.location.lng(),
              distance: this.get('distance') || 25
            });
          } else {
            this.clearLocationSearch();
          }
        });
      }
    },
    actions: {
      changeCountryCodesAction(countryCodes) {
        this.changeCountryCodes(countryCodes);
      },
      clear() {
        this.set('selectedDevices', []);
        this.set('deviceIds', []);
      },
      setPanel() {
        this.setPanel(null, null);
      }
    }
  });
});