define("admin/adapters/capture-group", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: `${_environment.default.api.namespace}/admin`,
    updateStatus: (headers, data, captureGroup) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/capture_groups/${captureGroup.get('id')}/status`;
      const xhrOptions = {
        url: url,
        data: data,
        method: 'PATCH',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    fetchStatuses: headers => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/capture_groups/statuses`;
      const xhrOptions = {
        url: url,
        method: 'GET',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    unassignPilot: (headers, pilot, captureGroup) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/capture_groups/${captureGroup.get('id')}/pilots/${pilot.get('id')}`;
      const xhrOptions = {
        url: url,
        method: 'DELETE',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    assignPilot: (headers, pilot, captureGroup) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/capture_groups/${captureGroup.get('id')}/pilots/${pilot.get('id')}/assign`;
      const laborType = pilot.laborType;
      const currency = pilot.pilot_rates.firstObject?.currency;
      const data = {
        currency_id: currency?.id,
        estimated_labor_payout_amount: pilot.get('pilot_rates').findBy('rate_type', laborType)?.get('payout_amount'),
        estimated_labor_payout_type: laborType === 'per_day' ? 'per_day' : 'per_asset',
        estimated_standby_payout: pilot.get('pilot_rates').findBy('rate_type', 'per_standby_hr')?.get('payout_amount'),
        estimated_mobilization_payout: pilot.get('pilot_rates').findBy('rate_type', 'mobilization')?.get('payout_amount')
      };
      const xhrOptions = {
        url: url,
        data: data,
        method: 'POST',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    fetchPilots: (headers, captureGroupId) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/capture_groups/${captureGroupId}/pilots`;
      const xhrOptions = {
        url: url,
        method: 'GET',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    }
  });
});