define("admin/models/partial-reshoot", ["exports", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    created_at: _emberData.default.attr('date'),
    reason: _emberData.default.belongsTo('mission-rejection-reason'),
    reason_name: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission'),
    shots: _emberData.default.hasMany('shot', {
      async: false
    }),
    clear_shots: _emberData.default.attr('boolean'),
    validations: {
      reason: {
        presence: true
      },
      mission: {
        presence: true
      },
      shots: {
        presence: true
      }
    }
  });
});