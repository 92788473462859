define("admin/components/onboarding/pilot-badge-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedBadges: null,
    badgeTitle: null,
    badges: [],
    badgeStatuses: [],
    pilotBadgeBadgeIds: [],
    pilotBadgeStatusIds: [],
    selectedPilotBadgeStatuses: [],
    pilotBadgeStatuses: [],
    selectPilotBadgeStatuses: [],
    didInsertElement() {
      const _this = this;
      if (this.get('badges.length') === undefined || this.get('badges.length') == 0) {
        this.store.query('badge', {}).then(function (data) {
          _this.set('badges', data.toArray());
          _this.cacheObjects('badges', data.toArray());
          _this.get('badges').forEach(function (badge) {
            if (_this.get('pilotBadgeBadgeIds').includes(badge.get('id'))) {
              _this.get('selectedBadges').pushObject(badge);
            }
          });
        });
      }
    },
    disableBadgeStatuses: Ember.computed('selectedBadges.length', function () {
      return this.get('selectedBadges.length') <= 0 || this.get('pilotWithoutBadges');
    }),
    badgeTitle: Ember.computed('selectedBadges.length', function () {
      return 'Badges (' + this.get('selectedBadges.length') + ')';
    }),
    isNoBadgeChecked: Ember.computed('pilotWithoutBadges', function () {
      this.get('pilotWithoutBadges');
    }),
    selectedBadgesObserver: Ember.observer('selectedBadges', function () {
      if (this.get('selectedBadges.length') > 0) {
        this.set('pilotWithoutBadges', false);
      } else {
        this.set('selectPilotBadgeStatuses', []);
      }
    }),
    actions: {
      toggleNoBadge() {
        this.set('pilotWithoutBadges', !this.get('pilotWithoutBadges'));
        if (this.get('pilotWithoutBadges')) {
          this.set('selectedBadges', []);
          this.set('selectPilotBadgeStatuses', []);
        }
      },
      toggleInclude() {
        this.set('pilotBadgeInclude', !this.get('pilotBadgeInclude'));
      }
    }
  });
});