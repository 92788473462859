define("admin/templates/components/mission/assets/full-view-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qrSTSfpo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"full-view-map\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/mission/assets/full-view-map.hbs"
    }
  });
});