define("admin/templates/components/db-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eqiufBhU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"db-datepicker-container \",[30,[36,2],[[35,1],\"opened\"],null],\" \",[34,0]]]],[12],[2,\"\\n  \"],[1,[30,[36,7],null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\",\"disabled\"],[\"text\",\"form-control\",[35,6],[35,5],[35,4],[35,3]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"db-datepicker-icon\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"calendar-icon\"],[14,\"src\",\"/assets/images/calendar_icon.svg\"],[12],[13],[2,\"\\n    \"],[10,\"img\"],[14,0,\"calendar-icon--hover\"],[14,\"src\",\"/assets/images/calendar_icon_white.svg\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"wrapperClass\",\"_opened\",\"if\",\"disabled\",\"value\",\"placeholder\",\"inputId\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/db-datepicker.hbs"
    }
  });
});