define("admin/mirage/serializers/mission", ["exports", "admin/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    include: ['client', 'location'],
    keyForAttribute(attribute) {
      return attribute.replace('-', '_');
    }
  });
});