define("admin/components/add-pilot-equipment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddPilotEquipmentModalComponent;
  AddPilotEquipmentModalComponent = Ember.Component.extend({
    currentRecord: null,
    actions: {
      close: function () {
        return this.get('close')();
      },
      submit: function () {
        var record;
        record = this.get('currentRecord');
        if (!record.validate()) {
          return;
        }
        return this.action();
      }
    }
  });
  var _default = _exports.default = AddPilotEquipmentModalComponent;
});