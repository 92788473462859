define("admin/templates/components/ortho-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DuMN70yc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,1,[31,[\"ortho\",[34,1,[\"shot\",\"id\"]],\"-\",[34,0]]]],[15,0,[31,[\"ortho \",[30,[36,2],[[35,1,[\"final\"]],\"asset-final\"],null]]]],[14,5,\"width:100%;\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"idSuffix\",\"ortho\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/ortho-viewer.hbs"
    }
  });
});