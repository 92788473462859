define("admin/models/drone-camera", ["exports", "ember-data", "ember-model-validator/mixins/model-validator", "admin/models/inventoriable"], function (_exports, _emberData, _modelValidator, _inventoriable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DroneCamera;
  DroneCamera = _inventoriable.default.extend(_modelValidator.default, {
    name: _emberData.default.attr('string'),
    drone_manufacturer: _emberData.default.belongsTo('drone-manufacturer', {
      async: true
    }),
    mega_pixels: _emberData.default.attr('number'),
    thermal: _emberData.default.attr('boolean'),
    pilotDrone: _emberData.default.belongsTo('pilot-drone'),
    full_name: Ember.computed('name', 'drone_manufacturer', function () {
      return this.get('drone_manufacturer.name') + ' ' + this.get('name');
    }),
    validations: {
      name: {
        presence: true
      }
    }
  });
  var _default = _exports.default = DroneCamera;
});