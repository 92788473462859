define("admin/components/video-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoJsComponent;
  VideoJsComponent = Ember.Component.extend({
    tagName: 'video',
    classNames: ['video-js vjs-default-skin'],
    concatenatedProperties: ['playerAttributeBindings'],
    playerEvents: {
      durationchange: 'durationChange',
      loadedmetadata: 'loadedMetadata',
      play: 'play',
      resize: 'resize',
      seeked: 'seeked',
      timeupdate: 'timeUpdate',
      volumechange: 'volumeChange'
    },
    playerAttributeBindings: ['autoplay', 'controls', 'currentHeight:height', 'currentWidth:width', 'loop', 'muted', 'playbackRate', 'poster', 'preload', 'volume', 'fluid'],
    autoresize: false,
    controls: true,
    fluid: true,
    currentTimeDidChange: Ember.on('seeked', 'timeUpdate', function (player) {
      return this.set('currentTime', player.currentTime());
    }),
    dimensionsDidChange: Ember.on('resize', function (player) {
      return this.setProperties({
        currentHeight: player.height(),
        currentWidth: player.width()
      });
    }),
    durationDidChange: Ember.on('durationChange', function (player) {
      return this.set('duration', player.duration());
    }),
    naturalAspectRatio: Ember.computed('naturalHeight', 'naturalWidth', function () {
      return this.get('naturalHeight') / this.get('naturalWidth');
    }),
    volumeDidChange: Ember.on('volumeChange', function (player) {
      this.set('muted', player.muted());
      return this.set('volume', player.volume());
    }),
    _applyPlayerAttribute: function (player, attrName, newValue) {
      var method, oldValue;
      method = player[attrName];
      if (method) {
        oldValue = method.call(player);
        if (oldValue !== newValue) {
          return method.call(player, newValue);
        }
      }
    },
    _autoresizePlayer: function (player) {
      var naturalAspectRatio, parentWidth;
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (!this.get('autoresize')) {
        return;
      }
      naturalAspectRatio = this.get('naturalAspectRatio');
      parentWidth = Ember.$(player.el().parentNode).width();
      return this.setProperties({
        currentHeight: parentWidth * naturalAspectRatio,
        currentWidth: parentWidth
      });
    },
    _didInitPlayer: function (player) {
      this._setupPlayerAttributes(player);
      this._setupPlayerEvents(player);
      this._setupAutoresize(player);
      return this.on('willDestroyElement', function () {
        return player.dispose();
      });
    },
    _initPlayer: Ember.on('didInsertElement', function () {
      var _this, element, options;
      _this = this;
      element = this.get('element');
      options = this.get('setup') || {};
      return videojs(element, options, function () {
        return _this._didInitPlayer(this);
      });
    }),
    _registerPlayerObserver: function (property, target, observer) {
      var scheduledObserver;
      scheduledObserver = function () {
        return Ember.run.scheduleOnce('render', this, observer);
      };
      this.addObserver(property, target, scheduledObserver);
      return this.on('willClearRender', this, function () {
        return this.removeObserver(property, target, scheduledObserver);
      });
    },
    _setupAutoresize: function (player) {
      var observer;
      this._setupResizeListener(player);
      observer = function () {
        return this._autoresizePlayer(player);
      };
      this._registerPlayerObserver('autoresize', this, observer);
      this._registerPlayerObserver('naturalAspectRatio', this, observer);
      return Ember.run(this, function () {
        return Ember.run.scheduleOnce('render', this, observer);
      });
    },
    _setupPlayerAttributeBindingObservation: function (player, property, attrName) {
      var observer;
      observer = function () {
        var propertyValue;
        propertyValue = this.get(property);
        return this._applyPlayerAttribute(player, attrName, propertyValue);
      };
      this._registerPlayerObserver(property, this, observer);
      return Ember.run(this, function () {
        var propertyValue;
        if (this.isDestroyed) {
          return;
        }
        propertyValue = this.get(property);
        if (Ember.isNone(propertyValue)) {
          propertyValue = player[attrName].call(player);
          this.set(property, propertyValue);
        }
        return this._applyPlayerAttribute(player, attrName, propertyValue);
      });
    },
    _setupPlayerAttributes: function (player) {
      var attrName, binding, colonIndex, i, len, property, ref, results;
      ref = this.playerAttributeBindings;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        binding = ref[i];
        colonIndex = binding.indexOf(':');
        if (colonIndex === -1) {
          property = binding;
          attrName = binding;
        } else {
          property = binding.substring(0, colonIndex);
          attrName = binding.substring(colonIndex + 1);
        }
        results.push(this._setupPlayerAttributeBindingObservation(player, property, attrName));
      }
      return results;
    },
    _setupPlayerEventHandler: function (player, event, eventName) {
      var handlerFunction;
      handlerFunction = Ember.run.bind(this, function (e) {
        return this.trigger(eventName, player, e);
      });
      return player.on(event, handlerFunction);
    },
    _setupPlayerEvents: function (player) {
      var event, events, i, len, results;
      events = this.get('playerEvents');
      results = [];
      for (i = 0, len = events.length; i < len; i++) {
        event = events[i];
        if (events.hasOwnProperty(event)) {
          results.push(this._setupPlayerEventHandler(player, event, events[event]));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    _setupResizeListener: function (player) {
      var debouncedFunction, handlerFunction;
      handlerFunction = Ember.run.bind(this, function () {
        return this._autoresizePlayer(player);
      });
      debouncedFunction = function () {
        return Ember.run.debounce(this, handlerFunction, 150);
      };
      Ember.$(window).on('resize', debouncedFunction);
      return this.on('willClearRender', function () {
        return Ember.$(window).off('resize', debouncedFunction);
      });
    }
  });
  var _default = _exports.default = VideoJsComponent;
});