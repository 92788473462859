define("admin/components/mission-sidebar-map", ["exports", "admin/components/mission-asset-map"], function (_exports, _missionAssetMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _missionAssetMap.default.extend({
    mapSelector: 'sidebar-map',
    initMap: function () {
      this._super();
      this.map.addListener('click', function () {
        this.onClick();
      }.bind(this));
    }
  });
});