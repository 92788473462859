define("admin/models/client", ["exports", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  var Client;
  Client = _emberData.default.Model.extend(_modelValidator.default, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    requires_invoice_report: _emberData.default.attr('boolean'),
    invoiceable: _emberData.default.attr('boolean'),
    company_name: _emberData.default.attr('string'),
    is_organization_owner: _emberData.default.attr('boolean'),
    welcome_email_sent: _emberData.default.attr('date'),
    welcome_email_type: _emberData.default.attr('string'),
    pilot_number: _emberData.default.attr('string'),
    signup_source: _emberData.default.attr('string', {
      defaultValue: 'admin_registered'
    }),
    collaborators: _emberData.default.hasMany('collaborators', {
      async: false
    }),
    client_mission_csvs: _emberData.default.hasMany('client_mission_csvs', {
      async: false
    }),
    packages: _emberData.default.hasMany('package', {
      async: false
    }),
    client_available_packages: _emberData.default.hasMany('client-available-package', {
      async: true
    }),
    verticals: _emberData.default.hasMany('vertical', {
      async: false
    }),
    available_addons: _emberData.default.hasMany('package-addon', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    esri_account: _emberData.default.belongsTo('esri_account', {
      async: false
    }),
    validations: {
      'verticals': {
        presence: true
      },
      'first_name': {
        presence: true
      },
      'last_name': {
        presence: true
      },
      'email': {
        presence: true
      }
    },
    fullName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + " " + this.get('last_name');
    }),
    companyOrFullName: Ember.computed('company_name', 'fullName', function () {
      if (this.get('company_name')) {
        return this.get('company_name');
      }
      return this.get('fullName');
    }),
    welcomeEsriEmailSent: Ember.computed('welcome_email_type', function () {
      return this.get('welcome_email_type') === 'esri';
    })
  });
  var _default = _exports.default = Client;
});