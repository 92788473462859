define("admin/data/cloud-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: "Up to 10% cloudy",
    value: 'less_than_10'
  }, {
    name: "Up to 50% cloudy",
    value: 'less_than_50'
  }, {
    name: "Up to 100% cloudy",
    value: 'less_than_100'
  }];
});