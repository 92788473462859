define("admin/mirage/models/site-inspection", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    client: (0, _emberCliMirage.belongsTo)('client'),
    site: (0, _emberCliMirage.belongsTo)('location'),
    missions: (0, _emberCliMirage.hasMany)('mission'),
    siteInspectionPilots: (0, _emberCliMirage.hasMany)('site-inspection-pilot'),
    standby_hours_line_items: (0, _emberCliMirage.hasMany)('standby-hours-line-item', {
      async: true
    }),
    payouts: (0, _emberCliMirage.hasMany)()
  });
});