define("admin/components/onboarding/badge-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    selectedPilots: [],
    badges: [],
    selectedAllPilots: false,
    totalCount: null,
    lat: null,
    lon: null,
    distance: null,
    droneIds: null,
    cameraIds: null,
    deviceIds: null,
    licenseIds: null,
    sendInvites: function () {
      var _this = this;
      if (this.get('selectedAllPilots')) {
        var requestData = {
          lat: this.get('lat'),
          lon: this.get('lon'),
          distance: this.get('distance'),
          drone_ids: this.get('droneIds'),
          camera_ids: this.get('cameraIds'),
          device_ids: this.get('deviceIds'),
          license_ids: this.get('licenseIds'),
          onboarding: this.get('selectedBadge.has_requirements')
        };
      } else {
        var requestData = {
          pilot_ids: this.get('selectedPilots').map(function (pilot) {
            return pilot.get('id');
          }),
          onboarding: this.get('selectedBadge.has_requirements')
        };
      }
      Ember.$.ajax({
        url: _environment.default.api.host + '/v1/admin/badges/' + this.get('selectedBadge').id + '/pilot_badges',
        headers: this.get('sessionAccount.headers'),
        type: 'POST',
        dataType: 'json',
        data: requestData
      }).then(function (response) {
        if (_this.get('selectedAllPilots')) {
          _this.set('successMessage', _this.get('totalCount') + ' pilot(s) will be invited.');
        } else {
          _this.set('successMessage', "You've invited " + response.data.length + ' pilot(s)');
          if (response.data.length != _this.get('selectedPilots').length) {
            let alreadyHaveCount = _this.get('selectedPilots').length - response.data.length;
            _this.set('successSubMessage', alreadyHaveCount + " pilots were previously invited to earn this badge.");
          }
        }
        _this.get('resetPilotSelection')();
      });
    },
    actions: {
      close() {
        this.get('close')();
      },
      invitePilots() {
        this.set('inviteConfirmation', true);
      },
      confirmInvitePilots() {
        this.sendInvites();
      },
      cancelInvitePilots() {
        if (this.get('inviteConfirmation')) {
          this.set('inviteConfirmation', false);
        } else {
          this.get('close')();
        }
      }
    }
  });
});