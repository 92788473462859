define("admin/mirage/factories/site-inspection-onsite-contact", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    name: 'joe',
    email: 'joe@joe.com',
    phone: '1234567890',
    notes: 'some notes',
    call_action: 'call'
  });
});