define("admin/app", ["exports", "admin/resolver", "ember-load-initializers", "admin/config/environment", "@sentry/ember", "@chamaeleonidae/chmln"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry, _chmln) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  if (_environment.default.sentryAdminUrl) {
    Sentry.init({
      dsn: _environment.default.sentryAdminUrl,
      environment: _environment.default.sentryEnvironment,
      tracesSampleRate: 1.0
    });
  }
  if (_environment.default.chameleonProjectKey) {
    _chmln.default.init(_environment.default.chameleonProjectKey, {
      fastUrl: "https://fast.chameleon.io/"
    });
  }
  Ember.MODEL_FACTORY_INJECTIONS = true;
  Ember.$.ajaxSetup({
    headers: {
      'X-API-TOKEN': _environment.default.api.app_token
    },
    beforeSend: xhr => {
      xhr.setRequestHeader('X-API-TOKEN', _environment.default.api.app_token);
    }
  });
  Ember.Router.reopen({
    renderTemplate: () => {
      const toolTips = () => {
        Ember.$('[data-toggle="tooltip"]').tooltip();
      };
      Ember.run.scheduleOnce('afterRender', toolTips);
      (void 0)._super();
    }
  });
  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);
      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);
      _defineProperty(this, "Resolver", _resolver.default);
      _defineProperty(this, "ready", () => {
        Ember.$(document).ajaxStart(() => {
          // eslint-disable-next-line
          NProgress.start();
        });
        Ember.$(document).ajaxStop(() => {
          // eslint-disable-next-line
          NProgress.done();
        });
      });
    }
  }
  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});