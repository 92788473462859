define("admin/data/reschedule-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Clear scheduled date/time",
    value: 'clear'
  }, {
    label: "Set a new date/time",
    value: 'reschedule'
  }, {
    label: "Set a date/time from pilot availability",
    value: 'pilotAvailability'
  }];
});