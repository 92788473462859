define("admin/components/db-multiselect", ["exports", "admin/utils/ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: Ember.A(['db-multiselect']),
    classNameBindings: ['selectOpened:opened'],
    searchEnabled: true,
    allButtonEnabled: true,
    selectedHolder: '',
    _wrappedOptions: Ember.A([]),
    selectedItems: Ember.A([]),
    selectOptions: Ember.A([]),
    valuePath: 'value',
    labelPath: 'label',
    value: null,
    changeset: null,
    relationship: null,
    withPills: false,
    withClearAllButton: false,
    isChangeset: Ember.computed('changeset', function () {
      return !!this.get('changeset');
    }),
    onChangeAction: null,
    action: null,
    selectOpened: false,
    disabled: false,
    placeholder: null,
    allSelected: Ember.computed('_wrappedOptions.[]', 'selectedItems.[]', function () {
      return this.get('_wrappedOptions.length') === this.get('selectedItems.length');
    }),
    itemSelected: function (item) {
      return this.get('selectedItems').filter(selectedItem => selectedItem.get(this.get('valuePath')) === item.value);
    },
    anyAndNotAllSelected: Ember.computed('_wrappedOptions.[]', 'selectedItems.[]', function () {
      return this.get('_wrappedOptions.length') !== this.get('selectedItems.length') && this.get('selectedItems.length') > 0;
    }),
    options: Ember.computed('_wrappedOptions.[]', 'searchOptions.[]', function (targetValue) {
      if (targetValue) {
        return this.get('_wrappedOptions');
      } else {
        return this.get('searchOptions');
      }
    }),
    didReceiveAttrs: function () {
      this._super();
      if (['string', 'number'].includes(typeof this.get('options')[0])) {
        // array of strings or numbers
        const _wrappedOptions = this.get('options').map(option => Ember.Object.create({
          value: option,
          label: option
        }));
        this.set('_wrappedOptions', _wrappedOptions);
      } else {
        // array of Ember objects
        this.set('_wrappedOptions', this.get('options'));
      }
      this.set('selectOptions', this.get('_wrappedOptions'));
      if (this.get('value')) {
        this.set('selectedItems', this.get('_wrappedOptions').filter(wrappedOption => this.get('value').includes(wrappedOption.get(this.get('valuePath')))));
      }
      if (this.get('isChangeset')) {
        this.set('selectedItems', this.get('_wrappedOptions').filter(wrappedOption => this.get('changeset').get(this.get('relation')).includes(wrappedOption.get('value'))));
      }
    },
    clearSearch: function () {
      this.element.querySelector("#searchInput").value = '';
    },
    actions: {
      toggleSelectOpened: function () {
        this.toggleProperty('selectOpened');
      },
      handleSearchChange: function (e) {
        const targetValue = e.target.value.toLowerCase();
        const filteredSearchList = this.get('_wrappedOptions').filter(({
          value,
          label
        }) => label.toLowerCase().includes(targetValue) || value.toLowerCase().includes(targetValue));
        if (targetValue) {
          this.set('selectOptions', filteredSearchList);
        } else {
          this.set('selectOptions', this.get('_wrappedOptions'));
        }
      },
      toggleValue: function (value) {
        let toggledOption = this.get('_wrappedOptions').find(option => option.get(this.get('valuePath')) === value);
        if (this.get('selectedItems').findBy(this.get('valuePath'), value)) {
          if (this.get('isChangeset')) {
            (0, _emberChangeset.removeFromHasMany)(this.get('changeset'), this.get('relation'), toggledOption.get(this.get('valuePath')));
          }
          this.set('selectedItems', this.get('selectedItems').filter(selectedItem => {
            return selectedItem.get(this.get('valuePath')) !== value;
          }));
        } else {
          if (this.get('isChangeset')) {
            (0, _emberChangeset.addToHasMany)(this.get('changeset'), this.get('relation'), toggledOption.get(this.get('valuePath')));
          }
          this.set('selectedItems', this.get('selectedItems').concat(toggledOption));
        }
        this.set('value', this.get('selectedItems').map(selectedItem => selectedItem.get(this.get('valuePath'))));
        if (this.get('action')) {
          this.action(value);
        }
        if (this.get('onChangeAction')) {
          this.onChangeAction();
        }
        this.clearSearch();
      },
      toggleAll: function () {
        if (this.get('selectedItems.length') === this.get('_wrappedOptions.length')) {
          this.clearAll();
        } else {
          this.set('selectedItems', this.get('_wrappedOptions'));
          if (this.get('isChangeset')) {
            this.get('selectedItems').map(item => (0, _emberChangeset.addToHasMany)(this.get('changeset'), this.get('relation'), item.get(this.get('valuePath'))));
          }
        }
        this.clearSearch();
      },
      clearAll: function () {
        if (this.get('isChangeset')) {
          this.get('selectedItems').map(item => (0, _emberChangeset.removeFromHasMany)(this.get('changeset'), this.get('relation'), item.get(this.get('valuePath'))));
        }
        this.set('selectedItems', []);
        this.set('value', []);
        this.clearSearch();
      },
      clickOutside: function () {
        this.set('selectOpened', false);
      }
    }
  });
});