define("admin/adapters/invoice-line-item", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/admin',
    urlTemplate: '{+namespace}/{invoiceableType}/{invoiceableId}/invoice_line_items{/id}',
    urlSegments: {
      invoiceableId: function (type, id, snapshot, query) {
        if (query === undefined) {
          return snapshot.attr('saved_invoiceable_id');
        } else {
          if (query.siteInspectionId) {
            return query.siteInspectionId;
          } else {
            return query.missionId;
          }
        }
      },
      invoiceableType: function (type, id, snapshot, query) {
        if (query === undefined) {
          return snapshot.attr('saved_invoiceable_type');
        } else {
          if (query.siteInspectionId) {
            return 'site_inspections';
          } else {
            return 'missions';
          }
        }
      }
    }
  });
});