define("admin/mirage/factories/mission", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `DBM123${i}123`;
    },
    price: 19900.0,
    status: 'pilot_accepted',
    scheduled_at_start: _faker.default.date.past(2),
    estimated_comprehensive_report_purchase_price: 4800,
    comprehensive_report_purchase_subscriber: false,
    afterCreate(mission, server) {
      mission.update({
        location: server.create('location')
      });
    }
  });
});