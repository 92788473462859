define("admin/components/missions/add-mission-to-capture-group-modal", ["exports", "admin/data/capture-group/search-field-options", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _searchFieldOptions, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    searchField: 'name',
    searchValue: null,
    infinity: Ember.inject.service(),
    store: Ember.inject.service(),
    searchFieldOptions: _searchFieldOptions.searchFieldOptions,
    selectedCaptureGroup: null,
    init() {
      this._super(...arguments);
      this.searchCaptureGroups();
      if (this.get('mission.site_inspection.capture_group_id')) {
        this.set('selectedCaptureGroup', this.get('mission.site_inspection.capture_group'));
      }
    },
    async searchCaptureGroups() {
      const captureGroups = await this.store.query('capture-group', this.get('captureGroupsSearchQuery'));
      this.infinity.replace(this.get('captureGroups'), captureGroups);
    },
    captureGroupsSearchQuery: Ember.computed('searchField', 'searchValue', function () {
      return {
        attr: this.get('searchField'),
        q: this.get('searchValue'),
        per_page: 25
      };
    }),
    actions: {
      changeSearchField(value) {
        this.set('searchField', value);
        Ember.run.debounce(this, this.searchCaptureGroups, 500);
      },
      changeSearchValue(event) {
        this.set('searchValue', event.target.value);
        Ember.run.debounce(this, this.searchCaptureGroups, 500);
      },
      changeSelectedCaptureGroup(captureGroup) {
        if (this.get('selectedCaptureGroup.id') === captureGroup.id) {
          return this.set('selectedCaptureGroup', this.get('mission.site_inspection.capture_group'));
        }
        return this.set('selectedCaptureGroup', captureGroup);
      },
      submit() {
        this.set('mission.site_inspection.capture_group', this.get('selectedCaptureGroup'));
        this.get('mission.site_inspection').save().then(() => {
          this.get('mission').reload();
          this.flashMessages.success(`You've assigned mission to Capture group!`);
          this.close();
        }, response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error((0, _getErrorMessageFromResponse.default)(response));
        });
      },
      close() {
        this.set('searchValue', null);
        return this.get('close')();
      }
    }
  });
});