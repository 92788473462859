define("admin/helpers/is-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsCheckedHelper;
  IsCheckedHelper = Ember.Helper.helper(function (arg) {
    var leftSide, ref, rightSide;
    leftSide = arg[0], rightSide = arg[1];
    return (ref = leftSide === rightSide) != null ? ref : {
      'checked': ''
    };
  });
  var _default = _exports.default = IsCheckedHelper;
});