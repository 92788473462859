define("admin/helpers/is-not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsNotHelper;
  IsNotHelper = Ember.Helper.helper(function (arg) {
    var value;
    value = arg[0];
    return !value;
  });
  var _default = _exports.default = IsNotHelper;
});