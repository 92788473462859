define("admin/mixins/css-class-namespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    setupController(controller, model) {
      this._super(controller, model);
      return this.addCssNamespace();
    },
    deactivate() {
      this._super();
      return this.removeCssNamespace();
    },
    addCssNamespace() {
      return Ember.$('body').addClass(this.routeNameToCssClass());
    },
    removeCssNamespace() {
      return Ember.$('body').removeClass(this.routeNameToCssClass());
    },
    routeNameToCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });
});