define("admin/templates/components/pilot-drone-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H8j273cJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1,[\"hasOptionalCameras\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pilot-section-col\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Drone \"],[1,[34,0]],[2,\":\"],[13],[2,\"\\n      \"],[1,[35,1,[\"drone\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pilot-section-col\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Camera(s):\"],[13],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pilot-section-col\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Drone \"],[1,[34,0]],[2,\":\"],[13],[2,\"\\n      \"],[1,[35,1,[\"drone\",\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"displayedIndex\",\"drone\",\"displayedCameras\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-drone-view.hbs"
    }
  });
});