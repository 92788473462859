define("admin/templates/components/automatic-stitching-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FO9I/V6d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"deliverables auto-ortho-stitching\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"deliverables-block\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"deliverables-block-label\"],[12],[2,\"Automatic Processing\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"deliverables-part\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ember-view\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0,[\"auto_ortho_stitching\"]]]]]],[2,\"\\n          Ortho Stitching\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ember-view\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",true,true]]]],[2,\"\\n          Pano Stitching\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/automatic-stitching-toggle.hbs"
    }
  });
});