define("admin/templates/components/capacity-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fUdIHDEa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"close\",\"appendedClasses\",\"translucentOverlay\",\"hasOverlay\",\"fullScreen\"],[[30,[36,0],[[32,0],\"close\"],null],\"capacity-modal full-screen-modal\",true,true,\"true\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container-fluid capacity-modal-content\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"close-modal pull-right\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/X.svg\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"model\",\"selectAction\"],[[35,1],[30,[36,0],[[32,0],\"setSelected\"],null]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group row buttons-block\"],[12],[2,\"\\n      \"],[11,\"a\"],[16,0,[31,[\"btn btn-primary \",[30,[36,5],[[30,[36,4],[[35,3]],null],\"disabled\"],null]]]],[16,\"disabled\",[30,[36,4],[[35,3]],null]],[4,[38,0],[[32,0],\"saveMission\"],null],[12],[2,\"\\n        CONFIRM\\n      \"],[13],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn btn-secondary cancel-button\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"\\n        CANCEL\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"available-timeslot-selector\",\"selectedTimeslotData\",\"is-blank\",\"if\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/capacity-modal.hbs"
    }
  });
});