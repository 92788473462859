define("admin/models/dropship", ["exports", "ember-data", "admin/data/inventory-constants"], function (_exports, _emberData, _inventoryConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    outbound_tracking_link: _emberData.default.attr('string'),
    inbound_tracking_link: _emberData.default.attr('string'),
    outbound_shipping_cost: _emberData.default.attr('number'),
    inbound_shipping_cost: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    return_date: _emberData.default.attr('date'),
    created_at: _emberData.default.attr('date'),
    inventory_item: _emberData.default.belongsTo('inventory-item', {
      async: false,
      inverse: 'dropships'
    }),
    pilot: _emberData.default.belongsTo('pilot', {
      async: false
    }),
    missions: _emberData.default.hasMany('mission', {
      async: false
    }),
    requireDate: Ember.computed('return_date', function () {
      return Ember.isPresent(this.get('return_date'));
    }),
    formatedReturnDate: Ember.computed('return_date', function () {
      const current_return_date = this.get('return_date');
      if (!current_return_date) {
        return '';
      }
      return moment(current_return_date).utc().format('MM/DD/YY');
    }),
    missionsArray: Ember.computed('missions.[]', function () {
      return this.get('missions').toArray();
    }),
    inboundLink: Ember.computed('inbound_tracking_link', function () {
      return `${_inventoryConstants.fedExTrackLink}${this.get('inbound_tracking_link')}`;
    }),
    outboundLink: Ember.computed('outbound_tracking_link', function () {
      return `${_inventoryConstants.fedExTrackLink}${this.get('outbound_tracking_link')}`;
    })
  });
});