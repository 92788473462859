define("admin/models/attachment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission'),
    bucket: _emberData.default.belongsTo('bucket'),
    version_urls: _emberData.default.attr(),
    source_type: _emberData.default.attr('string'),
    processing_status: _emberData.default.attr('string'),
    downloadUrl: Ember.computed('version_urls.{}', function () {
      return this.get('version_urls.download');
    }),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') == 'edit';
    }),
    isProcessing: Ember.computed('processing_status', function () {
      return this.get('processing_status') == 'processing';
    })
  });
});