define("admin/components/site-inspections/pricing", ["exports", "ember-changeset", "ember-changeset-validations", "admin/validations/invoice-line-item"], function (_exports, _emberChangeset, _emberChangesetValidations, _invoiceLineItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['work-order-section', 'pricing'],
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    changeset: null,
    invoiceLineItem: null,
    workOrder: null,
    showForm: false,
    totalMissions: null,
    completedMissions: null,
    approvedStandbyHours: null,
    lineItemReasons: [{
      label: "Mobilization",
      value: "mobilization"
    }, {
      label: "Standby Time",
      value: "stand_by_time"
    }, {
      label: "Base Price",
      value: "base_price"
    }],
    didReceiveAttrs: function () {
      this._super();
      this.initNewInvoiceLineItem();
    },
    initNewInvoiceLineItem: function () {
      this.set('invoiceLineItem', this.store.createRecord('invoice-line-item', {
        saved_invoiceable_type: "site_inspections",
        saved_invoiceable_id: this.get("workOrder.id")
      }));
      this.set('changeset', new _emberChangeset.default(this.get('invoiceLineItem'), (0, _emberChangesetValidations.default)(_invoiceLineItem.default), _invoiceLineItem.default));
      this.set('totalMissions', this.get('workOrder.missions').length);
      this.set('completedMissions', this.get('completedTurbinesByStatus'));
      this.set('approvedStandbyHours', this.get('approvedStandbyHoursLineItem'));
    },
    wrappedCurrencies: Ember.computed('currencies', function () {
      return this.get('currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    completedTurbinesByStatus: Ember.computed('workOrder.missions', function () {
      return this.get('workOrder.missions').filter(mission => {
        return mission.get("isShareable");
      }).length;
    }),
    approvedStandbyHoursLineItem: Ember.computed('workOrder.standby_hours_line_items.length', function () {
      return this.get('workOrder.standby_hours_line_items').filter(lineItem => lineItem.status === 'approved' && lineItem.billable_to_customer === true).reduce((sum, item) => sum + item.get('periodInSeconds'), 0);
    }),
    actions: {
      setCurrency: function (currency) {
        if (!confirm(`Change the currency for all the line items to ${currency.code}?`)) {
          return false;
        }
        this.set('workOrder.currency', currency);
        this.get('workOrder').save().then(() => {
          this.get('workOrder.invoice_line_items').forEach(invoiceLineItem => invoiceLineItem.set('currency', currency));
        }, error => {
          const message = `Can't update work order!\n${error.errors.map(error => error.detail).join("\n")}`;
          this.flashMessages.error(message);
        });
      },
      toggleForm: function () {
        this.toggleProperty("showForm");
      },
      save: function () {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.get('changeset').save().then(() => {
              this.set('invoiceLineItem.newlyAdded', true);
              this.set("showForm", false);
              this.initNewInvoiceLineItem();
            });
          }
        });
      }
    }
  });
});