define("admin/data/waiver-class-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: 'A',
    value: 'A'
  }, {
    name: 'B',
    value: 'B'
  }, {
    name: 'C',
    value: 'C'
  }, {
    name: 'D',
    value: 'D'
  }, {
    name: 'F',
    value: 'F'
  }];
});