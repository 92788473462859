define("admin/routes/admins/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        admins: this.store.query('admin', {})
      });
    }
  });
});