define("admin/components/missions/plan-map", ["exports", "admin/config/environment", "admin/data/map_features", "admin/mixins/google-map-init-mixin"], function (_exports, _environment, _map_features, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    sessionAccount: Ember.inject.service(),
    classNames: ['google-map'],
    undoObject: null,
    DEFAULT_ZOOM: 19,
    currentlySaving: false,
    selectedFeatureId: null,
    colorIndex: 0,
    activeTab: 'map_list_container',
    mapStyles: _map_features.default.mapStylesData,
    featureColors: _map_features.default.featureColors,
    featureOptions: _map_features.default.featureOptions,
    selectedFeatureOptions: _map_features.default.selectedFeatureOptions,
    disabledFeatureOptions: _map_features.default.disabledFeatureOptions,
    showPoi: true,
    location: Ember.computed('model.mission.location.formatted_address', function () {
      return this.get('model.mission.location.formatted_address');
    }),
    noLocation: Ember.computed('model.mission.location.formatted_address', function () {
      return !this.get('model.mission.location.formatted_address') || this.get('model.mission.location.formatted_address').length < 1;
    }),
    noProperty: Ember.computed('model.features.[]', function () {
      return this.get('model.features').length < 1;
    }),
    clientMapUrl: Ember.computed('model.mission.id', function () {
      return `${_environment.default.clients.host}/orders/${this.get('model.mission.id')}/edit`;
    }),
    orbitShotTypeId: Ember.computed('model.mission.package', function () {
      return this.get('model.mission.package.orbitShotType.id');
    }),
    didInsertElement() {
      this._super(...arguments);
      this.smallScreen = Ember.$(window).width() <= 768;
      if (this.smallScreen) {
        Ember.$('nav').hide();
        Ember.$('.container-main').css('padding-top', '0');
      }
    },
    willDestroyElement() {
      Ember.$('nav').show();
      return Ember.$('.container-main').css('padding-top', '50px');
    },
    initMap() {
      const _this = this;
      let options = {
        zoom: this.DEFAULT_ZOOM,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId['HYBRID'],
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        draggable: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        disableDoubleClickZoom: false
      };
      if (this.smallScreen) {
        options.zoomControl = false;
        options.disableDoubleClickZoom = true;
      }
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_canvas'), options);
      }
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.$('#map_ui_container')[0]);
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.$('#small_device_controls_container')[0]);
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.$('#control_boundry_zoom')[0]);
      this.map.addListener('tilesloaded', function () {
        _this.$('#map_ui_container').show();
        _this.$('#control_boundry_zoom').show();
        return _this.$('#map_shot_list_container').show();
      });
      this.map.addListener('click', function () {
        _this.infoWindow.close();
        return _this.disableFeatures();
      });
      this.Feature = Ember.Object.extend({
        name: '',
        notes: ''
      });
      this.map.data.addListener('addfeature', event => {
        this.setGeometryData(event.feature);
        options = this.lookUpMapOptions(event.feature);
        const feature_type = event.feature.getProperty('feature_type');
        this.map.data.overrideStyle(event.feature, options.defaultOptions);
        this.map.data.overrideStyle(event.feature, {
          fillColor: options.mapColor,
          strokeColor: options.mapColor
        });
        let shotTypeIds = event.feature.getProperty('shot_type_ids') || Ember.A([]);
        let shotTypeAttributes = event.feature.getProperty('shot_type_attributes') || {};

        // Panorama altitude was saved directly in the feature in old missions.
        // Now we saving altitude in the shot_type_attributes.
        // Moving panorama altitude into shot_type_attributes to maintain consistency
        const panoramaShotType = this.model.mission.package.panoramaShotType;
        if (event.feature.getProperty('altitude') && panoramaShotType) {
          if (!shotTypeIds.includes(panoramaShotType.id)) {
            shotTypeIds.pushObject(panoramaShotType.id);
          }
          shotTypeAttributes[panoramaShotType.id] = {
            altitude: event.feature.getProperty('altitude')
          };
          event.feature.removeProperty('altitude');
          event.feature.setProperty('shot_type_ids', shotTypeIds);
          event.feature.setProperty('shot_type_attributes', shotTypeAttributes);
        }
        const featureIndex = this.getNextIndex(feature_type);
        const map_feature = this.findOrCreateMapFeature(event.feature, `${options.defaultName} #${featureIndex}`);
        const feature = this.Feature.create({
          id: event.feature.getId(),
          object: event.feature,
          map_feature,
          feature_type,
          feature_index: featureIndex,
          area: event.feature.getProperty('area'),
          lineLength: event.feature.getProperty('lineLength'),
          no_fly_zone: event.feature.getProperty('no_fly_zone'),
          site_asset_id: event.feature.getProperty('site_asset_id'),
          color: options.keyColor,
          iconName: options.iconName,
          catalogEnabled: options.catalogEnabled,
          catalog_item_ids: event.feature.getProperty('catalog_item_ids') || Ember.A([]),
          shot_type_ids: shotTypeIds,
          shot_type_attributes: shotTypeAttributes
        });
        this.enableFeature(feature);
        return this.get('model.features').pushObject(feature);
      });
      this.map.data.addListener('setproperty', event => {
        const polygon = this.get('model.features').findBy('object', event.feature);
        if (polygon) {
          return polygon.set(event.name, event.newValue);
        }
      });
      this.map.data.addListener('setgeometry', event => {
        return this.setGeometryData(event.feature);
      });
      this.map.data.addListener('click', event => {
        return this.enableFeature(this.get('model.features').findBy('object', event.feature));
      });
      this.map.data.addListener('mousedown', () => {
        return this.infoWindow.close();
      });
      this.map.data.addListener('mouseup', event => {
        const point = this.get('model.features').findBy('object', event.feature);
        if (point && point.circle && point.get('geometry_changed')) {
          this.send('drawCircleAroundFeature', point, point.shot_type_attributes[this.orbitShotTypeId].shot_radius);
          point.toggleProperty('geometry_changed');
        }
        return this.infoWindow.close();
      });
      this.markers = Ember.A([]);
      this.setupInfoWindow();
      this.setupAutocomplete();
      return this.loadData();
    },
    findOrCreateMapFeature(feature_object, defaultName) {
      return this.get('model.mission.map_features').findBy('id', feature_object.getId()) || this.model.mission.store.createRecord('map_feature', {
        mission: this.model.mission,
        name: defaultName,
        notes: feature_object.getProperty('notes')
      });
    },
    lookUpMapOptions(feature) {
      let keyColor, mapColor;
      const geometryType = feature.getGeometry().getType();
      const isNoFlyZone = feature.getProperty('type') === 'No Fly Zone' || feature.getProperty('no_fly_zone');
      const feature_type = feature.getProperty('feature_type');
      const hasCatalogItems = this.hasCatalogItem(feature.getProperty('catalog_item_ids'));
      const options = (() => {
        switch (geometryType) {
          case 'Polygon':
            if (isNoFlyZone) {
              return this.get('featureOptions').NOFLYZONE;
            } else {
              return this.get('featureOptions').PROPERTYAREA;
            }
          case 'LineString':
            return this.get('featureOptions').LINE;
          case 'Point':
            if (feature_type === 'panorama' || hasCatalogItems) {
              return this.featureOptions.PANORAMA;
            } else {
              return this.featureOptions.POINTOFINTEREST;
            }
        }
      })();
      if (isNoFlyZone) {
        mapColor = '#F26649';
        keyColor = 'transparent';
      } else if (geometryType === 'LineString' || geometryType === 'Polygon') {
        mapColor = this.getNextColor();
      } else {
        // Point
        mapColor = 'transparent';
      }
      options.mapColor = mapColor;
      options.keyColor = keyColor || mapColor;
      return options;
    },
    setupInfoWindow() {
      const _this = this;
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = Ember.$('#info-window-node');
      return this.infoWindow.addListener('domready', function () {
        return _this.populateInfoWindow(this);
      });
    },
    setupAutocomplete() {
      const input = this.$('.search-field')[0];
      const searchBox = new google.maps.places.SearchBox(input);
      this.map.addListener('bounds_changed', () => searchBox.setBounds(this.map.getBounds()));
      input.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      return searchBox.addListener('places_changed', () => {
        this.set('message', null);
        if (searchBox.getPlaces().length === 0) {
          alert('We could not find a location based on your search.');
          return;
        }
        const place = searchBox.getPlaces()[0];
        if (!place.address_components) {
          // see if we can get a complete place object with address_components
          return this.findAddressForPlace(place).then(response => this.dropPin(response), function () {
            alert('We could not find a location based on your search.');
          });
        } else {
          return this.dropPin(place);
        }
      });
    },
    dropPin(place) {
      return this.saveUndo().then(() => {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(this.DEFAULT_ZOOM);
        this.clearMap();
        this.setAddress(place);
        if (this.get('model.mission.map_features.length')) {
          const geoProperties = this.geoJsonConverter.toGeoJson(this.get('model.mission.map_features'));
          this.addGeoJson(geoProperties);
        }
        return this.addMarker(place.geometry.location, false);
      });
    },
    loadData() {
      if (this.get('model.mission.location.latitude') && this.get('model.mission.location.longitude')) {
        const latLng = new google.maps.LatLng({
          lat: Number(this.get('model.mission.location.latitude')),
          lng: Number(this.get('model.mission.location.longitude'))
        });
        this.map.setCenter(latLng);
        this.addMarker(this.map.getCenter(), false);
        if (this.get('model.mission.map_features.length')) {
          const geoProperties = this.geoJsonConverter.toGeoJson(this.get('model.mission.map_features'));
          return this.map.data.addGeoJson(geoProperties);
        }
      } else {
        return this.defaultLocation();
      }
    },
    clearMap() {
      const _this = this;
      this.get('model.features').forEach(f => {
        if (f.get('circle')) {
          f.get('circle').setMap(null);
        }
        _this.map.data.remove(f.object);
      });
      this.get('model.features').clear();
      this.markers.forEach(marker => marker.setMap(null));
      this.markers.clear();
      return this.infoWindow.close();
    },
    defaultLocation() {
      this.clearMap();
      this.map.setZoom(3);
      const lat_lng = new google.maps.LatLng(37.2350, -115.8111);
      this.map.setCenter(lat_lng);
      this.$('.search-field').attr('placeholder', 'Enter Location');
      return this.$('.search-field')[0].focus();
    },
    setAddress(place) {
      let city;
      this.set('model.mission.location.longitude', place.geometry.location.lng());
      this.set('model.mission.location.latitude', place.geometry.location.lat());
      let name = place.formatted_address;
      if (place.name && !name.toLowerCase().startsWith(place.name.toLowerCase())) {
        name = `${place.name}, ${name}`;
      }
      this.set('location', name);
      this.set('model.mission.location.name', name);
      this.set('model.mission.location.formatted_address', name);
      const address_components = {};
      Ember.$.each(place.address_components, function (k, v1) {
        Ember.$.each(v1.types, function (k2, v2) {
          if (v2 === 'country') {
            return address_components[v2] = v1.short_name;
          } else {
            return address_components[v2] = v1.long_name;
          }
        });
      });
      if (address_components.street_number && address_components.route) {
        this.set('model.mission.location.address', `${address_components.street_number} ${address_components.route}`);
      } else if (address_components.route) {
        this.set('model.mission.location.address', `${address_components.route}`);
      } else {
        this.set('model.mission.location.address', '');
      }
      if (city = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood) {
        this.set('model.mission.location.city', city);
      } else {
        this.set('model.mission.location.city', '');
      }
      if (address_components.administrative_area_level_1) {
        this.set('model.mission.location.state', address_components.administrative_area_level_1);
      } else {
        this.set('model.mission.location.state', '');
      }
      if (address_components.country) {
        this.set('model.mission.location.country', address_components.country);
      } else {
        this.set('model.mission.location.country', '');
      }
      if (address_components.postal_code) {
        this.set('model.mission.location.postal_code', address_components.postal_code);
      } else {
        this.set('model.mission.location.postal_code', '');
      }
      this.$('.search-field').attr('placeholder', 'Enter Location');
      return this.$('.search-field')[0].select();
    },
    findAddressForPlace(place) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        const lat_lng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        const geocoder = new google.maps.Geocoder();
        return geocoder.geocode({
          'location': lat_lng
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              return resolve(results[0]);
            } else {
              return reject('No results');
            }
          } else {
            return reject('geocoder is not reponding');
          }
        });
      });
    },
    addGeoJson(json) {
      this.map.data.addGeoJson(json);
      return this.fitAllFeatures();
    },
    hasDynamicColor(feature) {
      return feature.geometry.type === 'LineString' || feature.geometry.type === 'Polygon' && !feature.no_fly_zone;
    },
    getNextColor() {
      this.set('colorIndex', this.get('colorIndex') + 1);
      return this.get('featureColors')[this.get('colorIndex') % 5];
    },
    getNextId() {
      if (this.model.features.length) {
        const featureIds = this.model.features.map(feature => parseInt(feature.id));
        return Math.max(...featureIds) + 1;
      } else {
        return 1;
      }
    },
    getNextIndex(feature_type) {
      let filteredFeatures;
      if (feature_type == 'point_of_interest') {
        filteredFeatures = this.model.features.filter(feature => {
          return ['point_of_interest', 'panorama', 'orbit'].includes(feature.get('feature_type'));
        });
      } else {
        filteredFeatures = this.model.features.filterBy('feature_type', feature_type);
      }
      if (filteredFeatures.length) {
        const featureIds = filteredFeatures.map(feature => parseInt(feature.feature_index));
        return Math.max(...featureIds) + 1;
      } else {
        return 1;
      }
    },
    addFeature(feature) {
      let feature_object, lat, lng;
      const id = this.getNextId();
      const featureProperties = {
        feature_type: feature.feature_type,
        catalog_item_ids: Ember.A([]),
        shot_type_ids: Ember.A([]),
        shot_type_attributes: {}
      };
      if (feature.geometry_type === 'Polygon') {
        lat = this.map.center.lat();
        lng = this.map.center.lng();
        const polygonCoordinates = [new google.maps.LatLng(lat + 0.0001, lng + 0.00012), new google.maps.LatLng(lat + 0.0001, lng - 0.00012), new google.maps.LatLng(lat - 0.0001, lng - 0.00012), new google.maps.LatLng(lat - 0.0001, lng + 0.00012)];
        const polygon_object = new google.maps.Data.Polygon([polygonCoordinates]);
        featureProperties.no_fly_zone = feature.no_fly_zone;
        featureProperties.area = feature.area;
        feature_object = new google.maps.Data.Feature({
          id,
          geometry: polygon_object,
          properties: featureProperties
        });
      } else if (feature.geometry_type === 'LineString') {
        lat = this.map.center.lat();
        lng = this.map.center.lng();
        const lineCoordinates = [new google.maps.LatLng(lat + 0.00007, lng + 0.00015), new google.maps.LatLng(lat - 0.00007, lng - 0.00015)];
        const line_object = new google.maps.Data.LineString(lineCoordinates);
        featureProperties.lineLength = feature.lineLength;
        feature_object = new google.maps.Data.Feature({
          id,
          geometry: line_object,
          properties: featureProperties
        });
      } else if (feature.geometry_type === 'Point') {
        const point_object = new google.maps.Data.Point(this.map.getCenter());
        featureProperties.no_fly_zone = feature.no_fly_zone;
        feature_object = new google.maps.Data.Feature({
          id,
          geometry: point_object,
          properties: featureProperties
        });
      }
      this.map.data.add(feature_object);
    },
    addKlmFeature(feature) {
      let geometry, coordinates;
      const id = this.getNextId();
      const properties = {
        catalog_item_ids: Ember.A([]),
        shot_type_ids: Ember.A([]),
        shot_type_attributes: {}
      };
      if (feature.Polygon) {
        coordinates = feature.Polygon[0].outerBoundaryIs[0].coordinates;
        geometry = new google.maps.Data.Polygon([coordinates]);
        properties.feature_type = 'polygon';
        properties.no_fly_zone = false;
        properties.area = google.maps.geometry.spherical.computeArea(coordinates);
      } else if (feature.LineString) {
        coordinates = feature.LineString[0].coordinates;
        geometry = new google.maps.Data.LineString(coordinates);
        properties.feature_type = 'line';
        properties.lineLength = google.maps.geometry.spherical.computeLength(coordinates).toFixed(1);
      } else if (feature.Point) {
        geometry = new google.maps.Data.Point(feature.Point.coordinates[0]);
        properties.feature_type = 'point';
      }
      const obj = new google.maps.Data.Feature({
        id: id,
        geometry: geometry,
        properties: properties
      });
      this.map.data.add(obj);
    },
    removeFeature(feature) {
      this.get('model.features').removeObject(feature);
      this.map.data.remove(feature.object);
      this.model.mission.reload().then(() => {
        return this.infoWindow.close();
      }, response => {
        console.log(response);
        return this.infoWindow.close();
      });
    },
    fitAllFeatures() {
      const bounds = new google.maps.LatLngBounds();
      this.get('model.features').forEach(feature => {
        if (feature.object.getGeometry().getType() === 'Polygon') {
          return feature.object.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
        } else if (feature.object.getGeometry().getType() === 'Point') {
          if (feature.shot_type_ids.includes(this.orbitShotTypeId)) {
            this.send('drawCircleAroundFeature', feature, feature.shot_type_attributes[this.orbitShotTypeId].shot_radius);
          }
          return bounds.extend(feature.object.getGeometry().get());
        } else if (feature.object.getGeometry().getType() === 'LineString') {
          return feature.object.getGeometry().getArray().forEach(latLng => bounds.extend(latLng));
        }
      });
      this.disableFeatures();
      return this.map.fitBounds(bounds);
    },
    setGeometryData(feature) {
      let ft = feature.getGeometry().getType();
      const bounds = new google.maps.LatLngBounds();
      if (ft === 'Polygon') {
        feature.setProperty('area', google.maps.geometry.spherical.computeArea(feature.getGeometry().getArray()[0].getArray()).toFixed(1));
        feature.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
      } else if (ft === 'LineString') {
        const coords = feature.getGeometry().getArray();
        feature.setProperty('lineLength', google.maps.geometry.spherical.computeLength(coords).toFixed(1));
        coords.forEach(latLng => bounds.extend(latLng));
      } else if (ft === 'Point') {
        const point = this.get('model.features').findBy('object', feature);
        if (point && point.circle) {
          point.set('geometry_changed', true);
        }
      }
      feature.setProperty('bounds', bounds);
      return feature.setProperty('center', feature.getProperty('bounds').getCenter());
    },
    enableFeature(feature) {
      this.disableFeatures();
      this.set('selectedFeatureId', feature.id);
      const options = this.selectedFeatureOptions;
      const feature_type = feature.object.getProperty('feature_type');
      if (feature_type === 'orbit') {
        options.icon = '/assets/images/map/orbit_pin.png';
      } else if (feature_type === 'panorama' || this.hasCatalogItem(feature.catalog_item_ids)) {
        options.icon = '/assets/images/map/pano_pin.png';
      } else {
        options.icon = '/assets/images/map/point_of_interest_pin.svg';
      }
      this.map.data.overrideStyle(feature.object, options);
      this.enableInfoWindow(feature);
    },
    disableFeatures() {
      this.infoWindow.close();
      this.set('selectedFeatureId', null);
      this.model.features.forEach(feature => {
        const options = this.disabledFeatureOptions;
        if (feature.object.getProperty('feature_type') === 'orbit') {
          options.icon = '/assets/images/map/orbit_pin.png';
        } else if (feature.object.getProperty('feature_type') === 'panorama' || this.hasCatalogItem(feature.catalog_item_ids)) {
          options.icon = '/assets/images/map/pano_pin.png';
        } else {
          options.icon = '/assets/images/map/point_of_interest_pin.svg';
        }
        this.map.data.overrideStyle(feature.object, options);
      });
    },
    hasCatalogItem(catalog_item_ids) {
      if (!catalog_item_ids) return false;
      const catalog_item = this.model.mission.package.get('catalog_items').find(item => catalog_item_ids.includes(item.id));
      return catalog_item?.has_pano_shot_type;
    },
    highlightFeatureImage(activeFeatureType) {
      return Ember.$('#controls_container .image').toArray().forEach(img => {
        switch (img.id) {
          case 'polygon':
            return this.toggleImage(activeFeatureType === 'Polygon', img);
          case 'line':
            return this.toggleImage(activeFeatureType === 'Line', img);
          case 'point':
            return this.toggleImage(activeFeatureType === 'Point of Interest', img);
          case 'nfz':
            return this.toggleImage(activeFeatureType === 'No Fly Zone', img);
        }
      });
    },
    toggleImage(active, elem) {
      if (active) {
        Ember.$(elem).addClass('active');
        return Ember.$(elem).parent('.feature').addClass('selected');
      } else {
        Ember.$(elem).removeClass('active');
        return Ember.$(elem).parent('.feature').removeClass('selected');
      }
    },
    addMarker(position, draggable = true) {
      this.markers.forEach(marker => marker.setMap(null));
      this.markers.clear();
      const animation = google.maps.Animation.DROP;
      const marker = new google.maps.Marker({
        map: this.map,
        position,
        draggable,
        animation,
        icon: {
          url: '/assets/images/property_pin.svg',
          scaledSize: new google.maps.Size(50, 80),
          origin: new google.maps.Point(0, 0)
        }
      });
      this.markers.push(marker);
      return marker;
    },
    populateInfoWindow(target) {
      const contentArr = target.content.split('||');
      let content = `<strong>${contentArr[1]}</strong><br>`;
      if (Ember.isPresent(contentArr[2])) {
        content += `<p class='small pull-left'>${contentArr[2]}</p><br/>`;
      }
      if (contentArr[3]) {
        content += `<p class='small pull-left'>${contentArr[3]}</p>`;
      }
      if (contentArr[4]) {
        content += `<p class='shots small pull-left'>${contentArr[4]}</p>`;
      }
      if (contentArr[5]) {
        content += `<p class='small pull-left'>Altitude: ${contentArr[5]}</p>`;
      }
      this.infoWindowNode.html(content);
      const feature = this.model.features.findBy('id', contentArr[0]);
      if (feature && feature.get('map_feature.geometry.type') === 'Point') {
        target.set('pixelOffset', new google.maps.Size(0, -65));
      }
      target.set('content', this.infoWindowNode[0]);
    },
    enableInfoWindow(feature) {
      let position;
      const feature_object = feature.object;
      const shotTypeAttributes = feature_object.getProperty('shot_type_attributes');
      const panoAttributes = shotTypeAttributes && shotTypeAttributes[this.model.mission.package.get('panoramaShotType.id')];
      const altitude = panoAttributes && panoAttributes['altitude'];
      const content = `${feature.id}||${feature.map_feature.name}||${feature.map_feature.notes || ''}||${this.formatLength(feature_object.getProperty('lineLength'))}||${this.formatArea(feature_object.getProperty('area'))}||${this.formatAltitude(altitude)}`;
      if (feature_object.getGeometry().getType() === 'Polygon') {
        position = feature_object.getGeometry().getAt(0).getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature_object.getGeometry().getType() === 'LineString') {
        position = feature_object.getGeometry().getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature_object.getGeometry().getType() === 'Point') {
        position = feature_object.getGeometry().get();
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, -30)
        });
      }
      return this.infoWindow.open(this.map);
    },
    saveUndo() {
      return new Ember.RSVP.Promise(resolve => {
        this.set('undoObject', this.geoJsonConverter.toGeoJson(this.get('model.mission.map_features')));
        return this.map.data.toGeoJson(() => {
          const elem = this.$('.map-search-undo');
          elem.stop().data('timer');
          elem.show(() => {
            Ember.$.data(this, 'timer', setTimeout(function () {
              elem.fadeOut(1000);
            }, 10000));
          });
          return resolve(true);
        });
      });
    },
    formatLength(length) {
      if (!length) {
        return '';
      }
      let formatted = `${length} meter`;
      if (length !== 1.0) {
        formatted += 's';
      }
      return `${formatted} (${this.metersToFeet(length)})`;
    },
    formatArea(area) {
      if (!area) {
        return '';
      }
      return `${this.metersToAcres(area)} (${this.metersToFeetSqr(area)})`;
    },
    formatAltitude(altitude) {
      if (!altitude) {
        return '';
      }
      return `${altitude} ft`;
    },
    metersToAcres(area) {
      const val = (area * 0.0002471).toFixed(2);
      let acres = `${val} acre`;
      if (val !== 1) {
        acres += 's';
      }
      return acres;
    },
    metersToFeet(area) {
      const val = Math.round(area * 3.2808).toLocaleString();
      return `${val} feet`;
    },
    metersToFeetSqr(area) {
      const val = Math.round(area * 10.76391).toLocaleString();
      return `${val} sqr feet`;
    },
    actions: {
      redirectToMission() {
        this.redirectAction(this.get('model.mission.id'));
      },
      add_polygon() {
        this.addFeature(this.get('featureOptions.PROPERTYAREA'));
      },
      add_no_fly_zone() {
        this.addFeature(this.get('featureOptions.NOFLYZONE'));
      },
      add_point_of_interest() {
        this.addFeature(this.get('featureOptions.POINTOFINTEREST'));
      },
      add_panorama() {
        this.addFeature(this.get('featureOptions.PANORAMA'));
      },
      add_line() {
        this.addFeature(this.get('featureOptions.LINE'));
      },
      removeFeature(feature) {
        if (Ember.isPresent(feature.map_feature)) {
          this.set('featureToDelete', feature);
          this.set('showDeleteMapFeatureModal', true);
        } else {
          this.removeFeature(feature);
          if (feature.get('circle')) {
            feature.get('circle').setMap(null);
          }
        }
      },
      changeToPanoramaIcon(selectedFeature) {
        const feature = this.map.data.getFeatureById(selectedFeature.id);
        this.map.data.overrideStyle(feature, {
          icon: {
            url: "/assets/images/map/pano_pin.png"
          }
        });
      },
      changeToPointIcon(selectedFeature) {
        const feature = this.map.data.getFeatureById(selectedFeature.id);
        this.map.data.overrideStyle(feature, {
          icon: {
            url: "/assets/images/map/point_of_interest_pin.svg"
          }
        });
      },
      boundry_zoom() {
        this.fitAllFeatures();
      },
      copyToClipboard() {
        Ember.$('.copy-link').addClass('copied');
        Ember.$('.copy-link span').text('Copied!');
        Ember.$(".map-client-url input[type='text']").select();
        document.execCommand("copy");
        Ember.$(".map-client-url input[type='text']").blur();
      },
      toggle_tabs(activeTab) {
        this.set('activeTab', activeTab);
      },
      updateAndReturn() {
        this.set('currentlySaving', true);
        this.infoWindow.close();
        let reloadMission = false;
        if (this.model.mission.location.hasDirtyAttributes) {
          reloadMission = true;
          const data = this.model.mission.location.toJSON();
          data['mission_id'] = this.model.mission.id;
          data['client_id'] = this.model.mission.client.id;
          Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/locations/${this.model.mission.location.id}`,
            headers: this.get('sessionAccount.headers'),
            type: 'PUT',
            data,
            dataType: 'json'
          }).then(() => {}, error_response => {
            let error = error_response.responseJSON.errors.firstObject;
            if (error) {
              alert("There was an issue saving this location: \n" + error.detail);
            }
            return console.log('something w wrong...', error_response);
          });
        }
        const update_map_data = this.model.features.map(feature => {
          return new Ember.RSVP.Promise((resolve, reject) => {
            feature.object.toGeoJson(json => {
              const map_feature = feature.map_feature;
              const geometryChanged = JSON.stringify(map_feature.geometry) !== JSON.stringify(json.geometry);
              const propertiesChanged = JSON.stringify(map_feature.properties) !== JSON.stringify(json.properties);
              if (geometryChanged) {
                map_feature.set('geometry', json.geometry);
              }
              if (propertiesChanged) {
                map_feature.set('properties', json.properties);
              }
              if (map_feature.hasDirtyAttributes) {
                reloadMission = true;
                map_feature.save().then(response => {
                  feature.set('map_feature', response);
                  resolve();
                }).catch(error => {
                  console.error('Something went wrong when updating map feature:', error);
                  alert("There was an error updating map feature: \n\n" + error.errors.mapBy('detail').join(', '));
                  reject(error);
                });
              } else {
                resolve();
              }
            });
          });
        });
        Ember.RSVP.Promise.all(update_map_data).then(() => {
          if (reloadMission || this.model.mission.hasDirtyAttributes) {
            this.model.mission.reload().then(() => {
              return this.redirectAction(this.model.mission.id);
            }).catch(() => {
              return this.redirectAction(this.model.mission.id);
            });
          } else {
            return this.redirectAction(this.model.mission.id);
          }
        }).catch(error => {
          console.error("Something went wrong when saving this map:", error);
          this.set('currentlySaving', false);
        });
      },
      closeRemoveShotModal() {
        this.set('showDeleteShotModal', false);
      },
      closeRemoveMapFeatureModal(deletedFeature) {
        this.set('featureToDelete', null);
        if (Ember.isPresent(deletedFeature)) {
          this.removeFeature(deletedFeature);
        }
        this.set('showDeleteMapFeatureModal', false);
      },
      togglePoiVisibility() {
        this.toggleProperty('showPoi');
        this.get('model.features').forEach(f => {
          if (f.object.getGeometry().getType() == 'Point') {
            let feature = this.map.data.getFeatureById(f.id);
            this.map.data.overrideStyle(feature, {
              visible: this.showPoi
            });
          }
        });
      },
      importKML(event) {
        if (window.File && window.FileList && window.FileReader && geoXML3) {
          const files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onload = () => {
              const handlePlacemark = placemark => this.addKlmFeature(placemark);
              const geoXml = new geoXML3.parser({
                map: this.map,
                createPolygon: handlePlacemark,
                createMarker: handlePlacemark,
                createLineString: handlePlacemark,
                afterParse: () => this.fitAllFeatures()
              });
              geoXml.parse(reader.result);
            };
            reader.readAsDataURL(files[i]);
          }
        } else {
          console.log('Your browser does not support File API!');
        }
      },
      drawCircleAroundFeature(feature, radius) {
        if (radius !== undefined) {
          if (feature.get('circle')) {
            feature.get('circle').setMap(null);
          }
          const map = this.map;
          const shotCircle = new google.maps.Circle({
            strokeColor: "#FFFFFF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#3FC0C2",
            fillOpacity: 0.6,
            map,
            center: feature.object.getGeometry().get(),
            radius: radius * 0.3048,
            visible: true
          });
          feature.set('circle', shotCircle);
        }
        return true;
      }
    }
  });
});