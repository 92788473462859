define("admin/components/subscriptions/form-modal", ["exports", "admin/utils/sort-by", "admin/config/environment", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _sortBy, _environment, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    selectedSubscriptionOffers: Ember.A([]),
    sessionAccount: Ember.inject.service(),
    hidePurchasedOffers: false,
    sortAttribute: 'name',
    sortDirection: 'desc',
    organizationSubscriptionIds: Ember.computed('model.organization.subscriptionOffers.[]', function () {
      return this.get('model.organization.subscriptionOffers').map(({
        id
      }) => id);
    }),
    possibleSubscriptionOffers: Ember.computed('model.{subscriptionOffers.[],organization.subscriptionOffers.[]}', function () {
      return this.get('model.subscriptionOffers').filter(subscriptionOffer => {
        return !this.get('organizationSubscriptionIds').includes(subscriptionOffer.id) && subscriptionOffer.active;
      });
    }),
    wrappedSubscriptionOffers: Ember.computed('sortAttribute', 'sortDirection', 'hidePurchasedOffers', 'model.subscriptionOffers.[]', 'possibleSubscriptionOffers.[]', function () {
      let subscriptionOffers = [];
      if (this.get('hidePurchasedOffers')) {
        subscriptionOffers = this.get('possibleSubscriptionOffers');
      } else {
        subscriptionOffers = this.get('model.subscriptionOffers');
      }
      return (0, _sortBy.default)(subscriptionOffers.filter(({
        active
      }) => active), this.get('sortAttribute'), this.get('sortDirection'));
    }),
    removeItem: function (value) {
      this.set('selectedSubscriptionOffers', this.get('selectedSubscriptionOffers').filter(selectedOption => selectedOption !== value));
    },
    addItem: function (value) {
      this.set('selectedSubscriptionOffers', this.get('selectedSubscriptionOffers').concat(value));
    },
    anyButNotAllSelected: Ember.computed('selectedSubscriptionOffers.length,model.subscriptionOffers.length', function () {
      const selectedOffersLength = this.get('selectedSubscriptionOffers.length');
      return selectedOffersLength !== this.get('possibleSubscriptionOffers.length') && selectedOffersLength > 0;
    }),
    allSelected: Ember.computed('selectedSubscriptionOffers.length,possibleSubscriptionOffers.length', function () {
      return this.get('selectedSubscriptionOffers.length') > 0 && this.get('selectedSubscriptionOffers.length') === this.get('possibleSubscriptionOffers.length');
    }),
    actions: {
      close() {
        return this.closeAction();
      },
      async setSort(attribute) {
        if (this.get('sortDirection') === 'desc') {
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortDirection', 'desc');
        }
        this.set('sortAttribute', attribute);
      },
      toggleHidePurchasedOffers: function () {
        this.set('hidePurchasedOffers', !this.get('hidePurchasedOffers'));
      },
      toggleSubscriptionOfferOption: function (value) {
        if (this.get('selectedSubscriptionOffers').indexOf(value) === -1) {
          this.addItem(value);
        } else {
          this.removeItem(value);
        }
      },
      toggleAllOffers: function () {
        if (this.get('allSelected')) {
          this.set('selectedSubscriptionOffers', []);
        } else {
          this.set('selectedSubscriptionOffers', this.get('possibleSubscriptionOffers'));
        }
      },
      submit: function (event) {
        event.stopPropagation();
        Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/organizations/${this.get('model.organization.id')}/subscriptions`,
          headers: this.get('sessionAccount.headers'),
          type: 'POST',
          data: {
            subscription_offer_ids: this.get('selectedSubscriptionOffers').map(({
              id
            }) => id)
          },
          dataType: 'json'
        }).then(() => {
          this.get('model.organization').reload();
          this.closeAction();
          this.flashMessages.success('Subscription offers was purchased!');
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
        });
      }
    }
  });
});