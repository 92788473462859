define("admin/components/drones-devices-equipment-selection", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DronesDevicesEquipmentSelectionComponent;
  DronesDevicesEquipmentSelectionComponent = Ember.Component.extend({
    classNames: ['drones-devices-equipment'],
    includeManufacturer: true,
    droneCameraCounter: Ember.computed('selectedDrones.[]', 'selectedCameras.[]', function () {
      return this.get('selectedDrones.length') + this.get('selectedCameras.length');
    }),
    droneLength: Ember.computed('model.drones', function () {
      return this.get('model.drones.length');
    }),
    dronesHalf: Ember.computed('model.drones', function () {
      return Math.floor(this.get('droneLength') / 2) - 1;
    }),
    dronesOne: Ember.computed('model.drones', function () {
      return this.get('drones').toArray().slice(0, +this.get('dronesHalf') + 1 || 9e9);
    }),
    dronesTwo: Ember.computed('model.drones', function () {
      return this.get('drones').toArray().slice(this.get('dronesHalf') + 1, +(this.get('droneLength') - 1) + 1 || 9e9);
    }),
    devicesCounter: Ember.computed('selectedPhones.[]', 'selectedTablets.[]', function () {
      return this.get('selectedPhones.length') + this.get('selectedTablets.length');
    }),
    equipmentCounter: Ember.computed('selectedEquipment.[]', function () {
      return this.get('selectedEquipment.length');
    }),
    documentCounter: Ember.computed('selectedDocuments.[]', function () {
      return this.get('selectedDocuments.length');
    }),
    selectedDronesObjects: Ember.computed('model.drones', 'selectedDrones.[]', function () {
      var selection;
      selection = this.get('selectedDrones');
      return this.get('model.drones').filter(function (drone) {
        return selection.indexOf("" + drone.id) !== -1;
      });
    }),
    selectedPhones: Ember.computed('selectedDevices', 'selectedDevices.@each.type', function () {
      return this.get('selectedDevices').filter(function (device) {
        return device.get('device_type') === 'phone';
      });
    }),
    selectedTablets: Ember.computed('selectedDevices', 'selectedDevices.@each.type', function () {
      return this.get('selectedDevices').filter(function (device) {
        return device.get('device_type') === 'tablet';
      });
    }),
    cameras: Ember.computed('model.drones', function () {
      var availableCameraIds, cameras, seen;
      cameras = [];
      seen = {};
      availableCameraIds = [];
      this.get('model.drones').toArray().forEach(function (drone) {
        return drone.get('optional_cameras').sortBy('full_name').forEach(function (camera) {
          if (!seen[camera.id]) {
            seen[camera.id] = true;
            cameras.push(camera);
            return availableCameraIds.push(camera.get('id'));
          }
        });
      });
      return cameras;
    }),
    phones: Ember.computed('model.devices', function () {
      return this.get('model.devices').sortBy('full_name').filter(function (device) {
        return device.get('device_type') === 'phone';
      });
    }),
    tablets: Ember.computed('model.devices', function () {
      return this.get('model.devices').sortBy('full_name').filter(function (device) {
        return device.get('device_type') === 'tablet';
      });
    }),
    equipments: Ember.computed('model.equipment', function () {
      return this.get('model.equipment').sortBy('full_name');
    }),
    documents: Ember.computed('model.licenses.[]', function () {
      return this.get('model.licenses').sortBy('name').filter(function (license) {
        return !license.get('is_flight_certificate');
      });
    }),
    actions: {
      clearDrones: function () {
        this.set('selectedDrones', this.get('selectedDrones').clear());
        return this.set('selectedCameras', this.get('selectedCameras').clear());
      },
      toggleDroneList: function (event) {
        $($('#drone-header-toggle').data('target')).toggle();
        return this.set('droneCameraOpen', !this.get('droneCameraOpen'));
      },
      toggleDeviceList: function (event) {
        $($('#devices-header-toggle').data('target')).toggle();
        return this.set('devicesOpen', !this.get('devicesOpen'));
      },
      toggleEquipmentList: function (event) {
        $($('#other-equipment-header-toggle').data('target')).toggle();
        return this.set('equipmentOpen', !this.get('equipmentOpen'));
      },
      toggleDocumentList: function (event) {
        $($('#document-header-toggle').data('target')).toggle();
        return this.toggleProperty('documentOpen');
      }
    }
  });
  var _default = _exports.default = DronesDevicesEquipmentSelectionComponent;
});