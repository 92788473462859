define("admin/adapters/collaborator", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollaboratorAdapter;
  CollaboratorAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.namespace + "/admin",
    urlTemplate: "{+host}/{+namespace}/missions/{missionId}/collaborators",
    urlSegments: {
      missionId: function (type, id, collaborator, query) {
        return collaborator.belongsTo('mission', {
          id: true
        });
      }
    },
    searchUrl: function () {
      return this.urlPrefix() + "/clients/search";
    },
    query: function (store, type, query) {
      return this.ajax(this.searchUrl(), 'POST', {
        data: query
      });
    },
    deleteRecordUrlTemplate: '{+host}/{+namespace}/missions/{missionId}/collaborators/{id}'
  });
  var _default = _exports.default = CollaboratorAdapter;
});