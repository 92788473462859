define("admin/components/mission/shots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    hideShots: true,
    shots: Ember.computed('model.[]', 'hideShots', function () {
      if (this.get('model.length')) {
        if (this.get('hideShots') && this.get('model.length') > 10) {
          return this.get('model').slice(0, 10);
        } else {
          return this.get('model');
        }
      } else {
        return [];
      }
    }),
    actions: {
      moreOrLessShots() {
        this.toggleProperty('hideShots');
      }
    }
  });
});