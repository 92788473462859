define("admin/models/pilot-invitations-dispatch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotInvitationsDispatch;
  PilotInvitationsDispatch = _emberData.default.Model.extend({
    dispatch_status: _emberData.default.attr('string'),
    status: Ember.computed('dispatch_status', function () {
      if (this.get('dispatch_status') === 'in_progress') {
        return "(Pilot invitations are being sent automatically)";
      } else if (this.get('dispatch_status') === 'completed') {
        return "Pilot invitation auto-dispatch has completed";
      } else if (this.get('dispatch_status') === 'no_pilots_found') {
        return "Auto dispatch did not find any suitable available pilots";
      }
    })
  });
  var _default = _exports.default = PilotInvitationsDispatch;
});