define("admin/templates/components/missions/map-feature-line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pN/p/gKa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"name\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"amount\"],[12],[1,[30,[36,2],[[35,1,[\"totalAmount\"]],[35,1,[\"currency\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"feature-details\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[6,[37,4],[[35,1,[\"feature_details\",\"value_outside_of_defined_range\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"area-out-of-range\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/error-solid2x.png\"],[14,\"alt\",\"error icon\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"This property area exceeds the package agreement\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"displayName\",\"lineItem\",\"format-price\",\"featureDetails\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/missions/map-feature-line-item.hbs"
    }
  });
});