define("admin/templates/organizations/comprehensive-report-purchase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GfTlTDqs",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"organizations/comprehensive-report-purchase\"]}",
    "meta": {
      "moduleName": "admin/templates/organizations/comprehensive-report-purchase.hbs"
    }
  });
});