define("admin/templates/components/subscription-offers/gear-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5qYrtLMK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"action-gear-block-wrapper\"],[12],[2,\"\\n \"],[10,\"div\"],[15,0,[30,[36,3],[\"gear\",[30,[36,2],[[35,1],\" active\"],null]],null]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,3],[\"dropdown\",[30,[36,2],[[35,1],\" active\"],null]],null]],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"toggleEditModal\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"dropdown-item-text\"],[12],[2,\" Edit \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,4,[\"active\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"deactivate\"],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"dropdown-item-text deactivate\"],[12],[2,\"Deactivate\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"activate\"],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"dropdown-item-text activate\"],[12],[2,\"Activate\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"showActions\",\"if\",\"concat\",\"subscriptionOffer\"]}",
    "meta": {
      "moduleName": "admin/templates/components/subscription-offers/gear-icon.hbs"
    }
  });
});