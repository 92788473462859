define("admin/validations/invoice-line-item", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    amount: (0, _validators.validateNumber)({
      gt: 0
    }),
    reason: (0, _validators.validatePresence)({
      presence: true,
      message: "Additional Pricing can't be blank"
    }),
    saved_invoiceable_id: (0, _validators.validatePresence)(true),
    saved_invoiceable_type: (0, _validators.validateInclusion)({
      list: ['site_inspections', 'missions']
    })
  };
});