define("admin/models/pilot-search-metum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotSearchMetum;
  PilotSearchMetum = _emberData.default.Model.extend({
    active_missions: _emberData.default.attr('number'),
    invitations: _emberData.default.attr('number'),
    invitation_payout: _emberData.default.attr('number'),
    invitation_date: _emberData.default.attr('string'),
    pilot: _emberData.default.belongsTo('pilot')
  });
  var _default = _exports.default = PilotSearchMetum;
});