define("admin/templates/components/pilot-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "x+G74JO8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pilot-badge bottom-buffer\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[35,0,[\"badge\",\"internal_name\"]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Status - \"],[1,[35,0,[\"status\"]]],[2,\" \"],[1,[30,[36,3],[[30,[36,2],[[35,0,[\"status\"]],\"complete\"],null],[30,[36,1],[[35,0,[\"updated_at\"]],\"MM/DD/YY\"],null]],null]],[2,\" \"],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Invited on \"],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"MM/DD/YY\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[11,\"a\"],[4,[38,4],[[32,0],\"removeBadge\",[35,0]],null],[12],[10,\"i\"],[14,0,\"fa fa-close\"],[12],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pb\",\"moment-format\",\"is-equal\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-badge.hbs"
    }
  });
});