define("admin/components/pilot-equipment-index-list", ["exports", "admin/components/equipment-index-list"], function (_exports, _equipmentIndexList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotEquipmentIndexListComponent;
  PilotEquipmentIndexListComponent = _equipmentIndexList.default.extend({
    collapsed: true,
    showModal: false,
    currentRecord: null,
    cachedRecords: [],
    recordType: null
  });
  var _default = _exports.default = PilotEquipmentIndexListComponent;
});