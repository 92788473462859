define("admin/components/comprehensive-report-purchases/form", ["exports", "admin/utils/sort-by", "admin/data/comprehensive-report/search-type-options", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _sortBy, _searchTypeOptions, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    selectedMissions: Ember.A([]),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    infinity: Ember.inject.service(),
    onlySelected: false,
    stateFilterValue: null,
    searchTypeValue: 'location_names',
    searchValue: null,
    sortAttribute: 'location.name',
    sortDirection: 'desc',
    searchTypes: _searchTypeOptions.default,
    totalPrice: Ember.computed('selectedMissions.[]', function () {
      const selectedMissions = this.get('selectedMissions');
      if (selectedMissions.length === 0) {
        return 0;
      }
      return selectedMissions.map(({
        estimated_comprehensive_report_purchase_price
      }) => estimated_comprehensive_report_purchase_price).reduce((a, b) => a + b);
    }),
    stateOptions: Ember.computed('model.states', function () {
      return this.get('model.states').map(state => {
        return Ember.Object.create({
          label: state.name,
          value: state.iso_code
        });
      });
    }),
    didReceiveAttrs: async function () {
      this._super();
      const missions = await this.store.query('estimated-comprehensive-report-purchase-mission', {
        organization_id: this.get('model.organization.id')
      });
      this.infinity.replace(this.get('comprehensiveReportMissionOptions'), missions);
    },
    filtersObserver: Ember.observer('stateFilterValue', 'searchValue', async function () {
      const missions = await this.store.query('estimated-comprehensive-report-purchase-mission', this.get('missionsQuery'));
      this.infinity.replace(this.get('comprehensiveReportMissionOptions'), missions);
    }),
    missionsQuery: Ember.computed('stateFilterValue', 'searchTypeValue', 'searchValue', function () {
      return {
        state: this.get('stateFilterValue'),
        searchField: this.get('searchTypeValue'),
        q: this.get('searchValue'),
        organization_id: this.get('model.organization.id')
      };
    }),
    searchItemsCount: Ember.computed('searchValue', function () {
      if (!this.get('searchValue')) {
        return 0;
      }
      return this.get('searchValue').split("\n").length;
    }),
    selectedMissionIds: Ember.computed('selectedMissions.[]', function () {
      return this.get('selectedMissions').map(({
        id
      }) => id);
    }),
    selectedMissionPrice: Ember.computed('selectedMissions.[]', function () {
      return this.get('selectedMissions').map(mission => {
        return {
          id: mission.id,
          price: mission.estimated_comprehensive_report_purchase_price
        };
      });
    }),
    comprehensiveReportMissionOptions: Ember.computed('onlySelected', 'selectedMissions.length', function () {
      return this.get('onlySelected') ? this.get('selectedMissions') : this.get('model.comprehensiveReportMissions');
    }),
    sortedComprehensiveReportMissionOptions: Ember.computed('sortAttribute', 'sortDirection', 'comprehensiveReportMissionOptions.[]', function () {
      return (0, _sortBy.default)(this.get('comprehensiveReportMissionOptions'), this.get('sortAttribute'), this.get('sortDirection'));
    }),
    removeItem: function (value) {
      this.set('selectedMissions', this.get('selectedMissions').filter(selectedOption => selectedOption !== value));
    },
    addItem: function (value) {
      this.set('selectedMissions', this.get('selectedMissions').concat(value));
    },
    anyButNotAllSelected: Ember.computed('selectedMissions.length', 'model.comprehensiveReportMissions.length', function () {
      const selectedMissionsLength = this.get('selectedMissions.length');
      return selectedMissionsLength !== this.get('model.comprehensiveReportMissions.length') && selectedMissionsLength > 0;
    }),
    allSelected: Ember.computed('selectedMissions.length', 'model.comprehensiveReportMissions.length', function () {
      return this.get('selectedMissions.length') > 0 && this.get('selectedMissions.length') === this.get('model.comprehensiveReportMissions.length');
    }),
    defaultCurrency: Ember.computed('model.currencies', function () {
      return this.get('model.currencies').find(currency => currency.code === 'USD');
    }),
    comprehensiveReportRate: Ember.computed('model.comprehensiveReportRates', function () {
      return this.get('model.comprehensiveReportRates').find(({
        currency
      }) => currency.code === this.get('defaultCurrency').code);
    }),
    actions: {
      searchAction: async function () {
        const missions = await this.store.query('estimated-comprehensive-report-purchase-mission', this.get('missionsQuery'));
        this.infinity.replace(this.get('comprehensiveReportMissionOptions'), missions);
      },
      setSort(attribute) {
        if (this.get('sortDirection') === 'desc') {
          this.set('sortDirection', 'asc');
        } else {
          this.set('sortDirection', 'desc');
        }
        this.set('sortAttribute', attribute);
      },
      loadMoreMissions(missions) {
        missions.extraParams = this.get('missionsQuery');
        this.infinity.infinityLoad(missions);
      },
      toggleWithReset() {
        this.toggleForm();
      },
      setOnlySelected(value) {
        this.set('onlySelected', value);
      },
      removeAllMissions: function () {
        if (!confirm('Do you want remove all selected inspections?')) {
          return null;
        }
        return this.set('selectedMissions', []);
      },
      clearValue: function (valuePath) {
        this.set(valuePath, null);
      },
      toggleMissionOption: function (value) {
        if (this.get('selectedMissions').indexOf(value) === -1) {
          this.addItem(value);
        } else {
          this.removeItem(value);
        }
      },
      toggleAllMissions: function () {
        if (this.get('allSelected')) {
          this.set('selectedMissions', []);
        } else {
          this.set('selectedMissions', this.get('model.comprehensiveReportMissions'));
        }
      },
      submit: function (purchaseType) {
        const requestBody = {
          mission_ids: this.get('selectedMissionIds'),
          organization_id: this.model.organization.id,
          currency: this.get('defaultCurrency').id,
          price: purchaseType === 'single-purchase' ? JSON.stringify(this.get('selectedMissionPrice')) : this.get('totalPrice'),
          purchase_type: purchaseType
        };
        this.store.adapterFor('comprehensive-report-purchase').submit(this.get('sessionAccount.headers'), requestBody).then(response => {
          this.store.pushPayload(response);
          const totalReportsCount = requestBody['mission_ids'].length;
          var message = '<div class="row comprehensive-report-message"><div class="col-sm-12">' + totalReportsCount + ' purchases were added successfully!</div><div class="col-sm-12">Each purchase includes 1 comprehensive report</div></div>';
          if (purchaseType != 'single-purchase') {
            message = '<div class="row comprehensive-report-message"><div class="col-sm-12">Comprehensive Report Purchase was added</div><div class="col-sm-12">Purchase includes ' + totalReportsCount + ' comprehensive reports</div></div>';
          }
          this.flashMessages.success(message);
          this.toggleForm();
        }).catch(response => {
          this.flashMessages.error(response.responseJSON.errors.map(({
            detail
          }) => detail).join(','));
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
        });
      }
    }
  });
});