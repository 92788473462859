define("admin/templates/components/pilot-payout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PMRsP0Qt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n  \"],[11,\"form\"],[24,0,\"form\"],[4,[38,1],[[32,0],\"createPayout\",[35,0]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n          \"],[1,[30,[36,2],null,[[\"value\",\"placeholder\",\"modelErrors\"],[[35,0,[\"payout\",\"amount\"]],\"Amount Paid\",[35,0,[\"payout\",\"errors\",\"amount\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n          \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"class\"],[\"submit\",\"Create\",\"btn btn-success btn-lg\"]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"input-validated-dollars\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-payout.hbs"
    }
  });
});