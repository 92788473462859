define("admin/models/device", ["exports", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Device;
  Device = _emberData.default.Model.extend(_modelValidator.default, {
    operating_system: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    device_type: _emberData.default.attr('string'),
    validations: {
      name: {
        presence: true
      },
      operating_system: {
        presence: true
      },
      device_type: {
        presence: true
      }
    }
  });
  var _default = _exports.default = Device;
});