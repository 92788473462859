define("admin/templates/components/pilot-compliance-documents-index-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CAFPJ8xe",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"close\",\"action\",\"currentRecord\"],[[30,[36,1],[[32,0],\"toggleModal\"],null],[30,[36,1],[[32,0],\"saveRecord\"],null],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"pe-header\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"javascript:void(0);\"],[24,0,\"pe-title\"],[4,[38,1],[[32,0],\"toggleCollapsed\"],null],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n      Compliance Documents\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[30,[36,5],[\"/equipment?selectedTab=compliance_documents\"],null]],[14,0,\"btn btn-add\"],[12],[2,\"Open\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentRecord\",\"action\",\"add-pilot-equipment-modal\",\"showModal\",\"if\",\"link-to-react-app\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-compliance-documents-index-list.hbs"
    }
  });
});