define("admin/components/onboarding/pilot-device-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    totalSelectedCount: Ember.computed('selectedDevices.[]', function () {
      return this.get('selectedDevices.length');
    }),
    didInsertElement() {
      if (this.get('devices.length') === undefined) {
        this.store.query('device', {}).then(data => {
          this.set('devices', data.toArray());
          this.cacheObjects('devices', data.toArray());
          this.get('devices').forEach(device => {
            if (this.get('deviceIds').includes(device.get('id'))) {
              this.get('selectedDevices').pushObject(device);
            }
          });
        });
      }
    },
    actions: {
      clear() {
        this.set('selectedDevices', []);
        this.set('deviceIds', []);
      },
      setPanel() {
        this.setPanel(null, null);
      }
    }
  });
});