define("admin/components/package-flight-app", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PackageFlightAppComponent;
  PackageFlightAppComponent = Ember.Component.extend({
    classNames: ["package-flight-app row panel-group"],
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    "package": null,
    flightApps: null,
    workflowModeOverride: null,
    hasCyberduckCredentials: true,
    groupedFlightApps: Ember.computed('flightApps', function () {
      var output, values;
      output = {};
      this.get('model.flightApps').forEach(function (flightApp) {
        var key;
        key = flightApp.get('name') + '-' + flightApp.get('data_type');
        if (output[key]) {
          return output[key]['apps'].push(flightApp);
        } else {
          return output[key] = {
            name: flightApp.get('name'),
            dataType: flightApp.get('data_type'),
            apps: [flightApp]
          };
        }
      });
      values = Object.values(output);
      return values.sort(function (a, b) {
        var textA, textB;
        textA = a.name;
        textB = b.name;
        return textA.localeCompare(textB);
      });
    }),
    workflowMode: Ember.computed('packageFlightApp', function () {
      if (this.get('packageFlightApp')) {
        return 'create';
      } else {
        return 'none';
      }
    }),
    requireExternalUrl: Ember.computed('packageFlightApp', 'packageFlightApp.flight_app', function () {
      return this.get('packageFlightApp.flight_app.deliver_to_app') === 'external_url';
    }),
    packageFlightApp: Ember.computed('model.package.package_flight_app', 'transientPackageFlightApp', 'editMode', function () {
      if (this.get('model.package.package_flight_app') && this.get('editMode') !== 'none') {
        console.log('using persisted');
        return this.get('model.package.package_flight_app');
      } else {
        return this.get('transientPackageFlightApp');
      }
    }),
    flightApp: Ember.computed('packageFlightApp', function () {
      return this.get('packageFlightApp.flight_app');
    }),
    valueErrorObserver: Ember.observer('valueError', 'packageFlightApp.value.android', 'packageFlightApp.value.ios', 'packageFlightApp.value.external_id', function () {
      var valuePresent;
      valuePresent = this.get('packageFlightApp.value.android') || this.get('packageFlightApp.value.ios') || this.get('packageFlightApp.value.external_id');
      if (this.get('valueError') && valuePresent) {
        return this.set('valueError', !valuePresent);
      }
    }),
    deliveryErrorObserver: Ember.observer('deliveryError', 'packageFlightApp.delivery_to_url', function () {
      var deliveryPresent;
      deliveryPresent = this.get('packageFlightApp.delivery_to_url');
      if (this.get('deliveryError') && deliveryPresent) {
        return this.set('deliveryError', !deliveryPresent);
      }
    }),
    activeFlightAppGroup: Ember.computed('appGroupId', 'groupedFlightApps', function () {
      var fa, groupId;
      groupId = this.get('appGroupId');
      if (groupId) {
        return this.get('groupedFlightApps').objectAt(groupId);
      } else {
        fa = this.get('flightApp');
        return this.get('groupedFlightApps').find(function (_this) {
          return function (x) {
            return x.name === fa.name && x.dataType === fa.data_type;
          };
        }(this));
      }
    }),
    actions: {
      setEditorMode: function (value) {
        var mfa;
        this.set('editMode', value);
        if (value === 'none') {
          if ((mfa = this.get('model.package.package_flight_app')) && mfa.id) {
            if (confirm("Are you sure you want to delete the workflow for this package? " + mfa.get('package.id'))) {
              mfa.set('package', this.get('model.package'));
              this.get('package').set('package_flight_app', mfa);
              return mfa.destroyRecord().then(function (_this) {
                return function () {
                  _this.get('model.package').set('package_flight_app', null);
                  _this.set('transientPackageFlightApp', null);
                  return alert("Workflow has been deleted");
                };
              }(this), function () {
                return alert("Failed to delete Workflow");
              });
            } else {
              return Ember.$(this.element).find('[name=workflow-mode][value=create]').click();
            }
          } else {
            return this.set('transientPackageFlightApp', null);
          }
        } else {
          if (!this.get('model.package.package_flight_app')) {
            mfa = this.get('store').createRecord('package_flight_app');
            mfa.set('value', {});
            mfa.set('flight_app', this.get('model.flightApps.firstObject'));
            this.set('transientPackageFlightApp', mfa);
            return this.set('model.package.package_flight_app', this.get('transientPackageFlightApp'));
          }
        }
      },
      setAppGroup: function (elem) {
        var currentFlightAppUpload, equivalentUploadMethod, selection, value;
        value = parseInt(elem.target.value);
        selection = this.get('groupedFlightApps').objectAt(value);
        this.set('appGroupId', value);
        console.log('app group items', selection.apps);
        currentFlightAppUpload = this.get('packageFlightApp.flight_app.deliver_to_app');
        equivalentUploadMethod = selection.apps.filter(function (app) {
          console.log(app.get('deliver_to_app'), currentFlightAppUpload);
          return app.get('deliver_to_app') === currentFlightAppUpload;
        })[0];
        console.log('equivalent upload method', equivalentUploadMethod);
        return this.get('packageFlightApp').set('flight_app', equivalentUploadMethod || selection.apps.objectAt(0));
      },
      setFlightApp: function (elem) {
        var selection, value;
        value = parseInt(elem.target.value);
        selection = this.get('flightApps').filter(function (app) {
          return parseInt(app.get('id')) === value;
        })[0];
        console.log('value', value);
        console.log('setFlightApp', selection);
        if (!selection) {
          selection = this.get('flightApps')[0];
        }
        return this.get('packageFlightApp').set('flight_app', selection);
      }
    }
  });
  var _default = _exports.default = PackageFlightAppComponent;
});