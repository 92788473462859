define("admin/routes/capture-groups/show", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        captureGroup: this.store.findRecord("capture-group", params.capture_group_id),
        statuses: this.store.adapterFor('capture-group').fetchStatuses(this.get('sessionAccount.headers')).then(response => response.statuses),
        operationReps: this.store.query('admin', {
          role: 'operation_rep'
        }),
        missions: this.infinityModel('mission', {
          perPage: 30,
          startingPage: 1,
          capture_group_number: params.capture_group_id,
          include: ['payouts', 'payouts.payout_line_items']
        }),
        siteInspections: this.infinityModel('site-inspection', {
          perPage: 30,
          startingPage: 1,
          capture_group_id: params.capture_group_id
        }),
        presetSearchFilters: this.store.query('preset-search-filter', {}),
        drones: this.store.findAll('drone'),
        devices: this.store.findAll('device'),
        licenses: this.store.findAll('license'),
        equipment: this.store.findAll('pilot_equipment'),
        badges: this.store.query('badge', {}),
        legalEntities: this.store.findAll('legal-entity'),
        currencies: this.store.query('currency', {
          all: true
        }),
        defaultCurrencyCode: 'USD',
        pilotsWithCompletedMissions: this.store.adapterFor('capture-group').fetchPilots(this.get('sessionAccount.headers'), params.capture_group_id).then(response => response.data)
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      model.stripeLegalEntities = model.legalEntities.filter(legalEntity => legalEntity.get('stripe_enabled'));
      model.captureGroup.pilots.forEach(pilot => {
        pilot.set('completed_missions_count', model.pilotsWithCompletedMissions.find(({
          id
        }) => pilot.id === id).attributes.completed_missions_count);
      });
    }
  });
});