define("admin/components/inventory/disassociate-missions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'dropship-disassociate-missions',
    actions: {
      close: function () {
        this.get('closeModal')();
      },
      confirm: function () {
        this.get('confirmDisassociate')();
      }
    }
  });
});