define("admin/models/shot-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    camera_requirement: _emberData.default.attr('string'),
    camera_sensor: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    video: _emberData.default.attr('string'),
    shots: _emberData.default.hasMany('shot', {
      async: false
    }),
    post_processes: _emberData.default.hasMany('post-process'),
    sample_images: _emberData.default.hasMany('shot_type_sample_image'),
    min: _emberData.default.attr('number'),
    max: _emberData.default.attr('number'),
    asset_type: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    area_of_interest: _emberData.default.attr('string'),
    level_of_detail: _emberData.default.attr('string'),
    gimbal_angle: _emberData.default.attr('number'),
    front_overlap: _emberData.default.attr('number'),
    side_overlap: _emberData.default.attr('number'),
    pattern: _emberData.default.attr('string'),
    altitude: _emberData.default.attr('number'),
    time_of_day: _emberData.default.attr('string'),
    file_type: _emberData.default.attr('string'),
    shot_list: _emberData.default.belongsTo('shot-list'),
    rtk_required: _emberData.default.attr('boolean'),
    supported_geometry: Ember.computed('template', function () {
      // May send this from the backedn
      return this.get('template') == 'pano_tiles' || this.get('template') == 'orbit' ? 'Point' : 'Polygon';
    })
  });
});