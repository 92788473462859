define("admin/templates/components/site-inspections/pilots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fHf/j8Jz",
    "block": "{\"symbols\":[\"si_pilot\"],\"statements\":[[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table work-order-section__table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Pilot\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Turbines Completed\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Payout Rates\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Standby Hours\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Action\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[35,8,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"6\"],[12],[2,\"No pilots\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,4,[\"site_inspection_pilots\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,5],null,[[\"pilot\",\"workOrder\",\"onEdit\",\"onUnassign\",\"showStandbyHours\",\"tagName\",\"approvedPayoutLineItemsHours\"],[[32,1],[35,4],[35,3],[35,2],[35,1],\"\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"approvedPayoutLineItemsHours\",\"showStandbyHours\",\"onUnassign\",\"onEdit\",\"workOrder\",\"site-inspections/pilot\",\"-track-array\",\"each\",\"pilots\",\"is-equal\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/site-inspections/pilots.hbs"
    }
  });
});