define("admin/components/missions/ortho-modal-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      toggleFullscreenOrthoModal: function (ortho) {
        this.toggleFullscreenOrthoModalAction(ortho);
      },
      fakeAction: function () {
        // Just do nothing, this fuction is used to prevent ortho lightbox closing
        // when you click on map view
      },
      downloadOrtho: function (ortho, type) {
        window.location = ortho.get('downloadable' + type);
      }
    }
  });
});