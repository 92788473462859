define("admin/routes/clients/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    messageBus: Ember.inject.service(),
    infinity: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        flightApps: this.store.findAll('flight_app'),
        organization: this.store.findRecord('organization', params.organization_id),
        verticals: this.store.findAll('vertical'),
        shot_lists: this.store.findAll('shot_list'),
        accountReps: this.store.query('admin', {
          role: 'account_rep'
        }),
        badges: this.store.query('badge', {}),
        organization_packages: this.store.query('package', {
          organization_id: params.organization_id
        }),
        drones: this.store.findAll('drone'),
        devices: this.store.findAll('device'),
        equipment: this.store.findAll('pilot_equipment'),
        dronebase_packages: this.get('store').query('package', {
          available: true
        }),
        work_types: this.store.findAll('deliverable_work_type'),
        package_addons: this.store.findAll('package_addon'),
        subscriptions: this.store.query('subscription', {
          organization_id: params.organization_id
        }),
        subscriptionOffers: this.infinity.model('subscription-offer', {
          startingPage: 1,
          perPage: 100,
          q: {
            active: true
          }
        }),
        states: this.store.findAll('state'),
        countries: this.store.findAll('country'),
        currencies: this.store.findAll('currency'),
        comprehensiveReportPurchases: this.infinity.model('comprehensive-report-purchase', {
          startingPage: 1,
          perPage: 10,
          searchField: null,
          q: null,
          organization_id: params.organization_id
        }),
        comprehensiveReportMissions: this.infinity.model('estimated-comprehensive-report-purchase-mission', {
          startingPage: 1,
          perPage: 100,
          state: null,
          searchField: null,
          q: null,
          inspectionYear: null,
          organization_id: params.organization_id
        }),
        comprehensiveReportRates: this.store.findAll('comprehensive-report-rate'),
        actions: this.actions
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      model.shotListsForSelect = model.shot_lists.toArray().sortBy('name');
      model.verticalsForSelect = model.verticals.toArray().sortBy('name');
      model.sortedOrganizationPackages = model.organization_packages.toArray().sortBy('vertical.short_name', 'position', 'price', 'name');
      model.sortedDronebasePackages = model.dronebase_packages.toArray().sortBy('vertical.short_name', 'position', 'price', 'name');
      const newName = model.organization.get('name');
      controller.set('newName', newName);
    },
    actions: {
      saveOrganization: function (model) {
        model.organization.save().then(response => {
          this.send('closeModal');
          this.transitionTo('clients.organization', response.id);
        }, response => {});
      },
      savePackage: function (model) {
        this.transitionToRoute('clients.organization', model.organization.id);
        this.send('closeModal');
      },
      editPackage: function (model, edit_package) {
        delete model.package;
        model.package = edit_package;
        this.send('openModal', 'clients.organization.modal-package', model);
      },
      clonePackage: function (model, clone_package) {
        delete model.package;
        model.package = model.organization.get('packages').createRecord({
          name: clone_package.get('name'),
          vertical: clone_package.get('vertical'),
          shot_list: clone_package.get('shot_list'),
          price: clone_package.get('price'),
          accept_restricted_airspace: clone_package.get('accept_restricted_airspace'),
          skip_asset_verification: clone_package.get('skip_asset_verification'),
          skip_instructions_confirmation: clone_package.get('skip_instructions_confirmation'),
          mission_instructions: clone_package.get('mission_instructions'),
          mission_internal_notes: clone_package.get('mission_internal_notes'),
          mission_production_notes: clone_package.get('mission_production_notes'),
          default_pilot_script: clone_package.get('default_pilot_script'),
          estimated_pilot_payout: clone_package.get('estimated_pilot_payout'),
          estimated_analyst_payout: clone_package.get('estimated_analyst_payout'),
          pilot_instructions: clone_package.get('pilot_instructions'),
          badge: clone_package.get('badge'),
          badge_required: clone_package.get('badge_required'),
          auto_dispatch_enabled: clone_package.get('auto_dispatch_enabled'),
          auto_ortho_stitching: clone_package.get('auto_ortho_stitching'),
          deliverableWorkTypes: clone_package.get('deliverableWorkTypes'),
          require_raw_images: clone_package.get('require_raw_images'),
          description: clone_package.get('description'),
          description_bullet_points: clone_package.get('description_bullet_points'),
          pointivo_package_id: clone_package.get('pointivo_package_id'),
          estimated_roof_measurement_payout: clone_package.get('estimated_roof_measurement_payout'),
          data_capture_within: clone_package.get('data_capture_within'),
          data_capture_offset: clone_package.get('data_capture_offset'),
          catalog_items: clone_package.get('catalog_items'),
          currency: clone_package.get('currency')
        });
        this.send('openModal', 'clients.organization.modal-package', model);
      },
      close: function () {
        if (this.get("currentModel.organization")) {
          this.get("currentModel.organization").rollbackAttributes();
        }
        this.send('closeModal');
      },
      closePackageModal: function () {
        if (this.get('model.package.hasDirtyAttributes')) {
          if (this.get('model.package.hasDirtyAttributes')) {
            this.get('model.package').rollbackAttributes();
          } else {
            this.get('model.package').destroy();
          }
        }
        this.send('closeModal');
      }
    }
  });
});