define("admin/components/select-enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectEnumComponent;
  SelectEnumComponent = Ember.Component.extend({
    selection: null,
    prompt: null,
    content: null,
    selectClass: 'form-control input-md',
    disabled: false,
    classNames: ['select-enum-container']
  });
  var _default = _exports.default = SelectEnumComponent;
});