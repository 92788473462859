define("admin/helpers/read-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ReadPathHelper;
  ReadPathHelper = Ember.Helper.helper(function (arg) {
    var object, path;
    object = arg[0], path = arg[1];
    return Ember.get(object, path);
  });
  var _default = _exports.default = ReadPathHelper;
});