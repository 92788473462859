define("admin/models/bucket", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    bucket_type_slug: _emberData.default.attr('string'),
    attachments: _emberData.default.hasMany('attachment', {
      async: false
    }),
    mission: _emberData.default.belongsTo('mission'),
    bucket_type: _emberData.default.belongsTo('bucket_type'),
    readyAttachmentsCount: Ember.computed('attachments.length', function () {
      return this.get('attachments').filter(function (item) {
        return item.get('processing_status') === 'ready';
      }).length;
    }),
    errorAttachmentsCount: Ember.computed('attachments.length', function () {
      return this.get('attachments').filter(function (item) {
        return item.get('processing_status') === 'error';
      }).length;
    }),
    promotedAttachmentsCount: Ember.computed('attachments.length', 'attachments.@each.final', function () {
      return this.get('attachments').filter(function (item) {
        return item.get('processing_status') === 'ready' && item.get('final');
      }).length;
    }),
    promoteAssets: function () {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.promoteAssets(this);
    }
  });
});