define("admin/templates/components/site-inspections/capture-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TTLXLT4H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"capture-group-detail\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"capture-group-detail-header\"],[12],[2,\"\\n    Capture Group:\\n    \"],[10,\"span\"],[14,0,\"capture-group-name\"],[12],[2,\"\\n      \"],[1,[35,0,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"capture-groups.show\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[35,0,[\"id\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"capture-group-assigned-pilots\"],[12],[2,\"\\n      Assigned pilots: \"],[10,\"span\"],[12],[1,[35,0,[\"capture_group_pilots\",\"length\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[18,1,null],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"capture_group\",\"link-to\"]}",
    "meta": {
      "moduleName": "admin/templates/components/site-inspections/capture-groups.hbs"
    }
  });
});