define("admin/components/organization-package-checkbox", ["exports", "admin/components/client-package-checkbox"], function (_exports, _clientPackageCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _clientPackageCheckbox.default.extend({
    packageAvailable: Ember.computed('available_package', 'model.organization.organization_available_packages.[]', function () {
      if (this.get('model.organization.organization_available_packages').findBy('package', this.get('available_package'))) {
        return true;
      }
    }),
    actions: {
      create: function () {
        const available_package = this.store.createRecord('organization-available-package', {
          organization: this.get('model.organization'),
          package: this.get('available_package')
        });
        available_package.save();
      },
      destroy: function () {
        const available_package = this.get('model.organization.organization_available_packages').findBy('package', this.get('available_package'));
        available_package.deleteRecord();
        available_package.save();
      }
    }
  });
});