define("admin/templates/components/pano-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2ibn5NED",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,1,[31,[\"pano\",[34,1,[\"accessKey\"]],\"-\",[34,0]]]],[15,0,[31,[\"panorama \",[30,[36,2],[[35,1,[\"final\"]],\"asset-final\"],null]]]],[12],[2,\"\\n  \"],[10,\"noscript\"],[12],[2,\"ERROR: Javascript not activated\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"idSuffix\",\"panorama\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pano-viewer.hbs"
    }
  });
});