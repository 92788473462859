define("admin/adapters/payout-line-item", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    createRecordUrlTemplate: '{+namespace}/{payoutableType}/{payoutableId}/payouts/{payoutId}/payout_line_items',
    deleteRecordUrlTemplate: '{+namespace}/{payoutableType}/{payoutableId}/payouts{/id}',
    queryUrlTemplate: '{+namespace}/payouts{?query*}',
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    urlSegments: {
      payoutableType: function (_type, _id, snapshot) {
        return snapshot.attr('saved_payoutable_type');
      },
      payoutableId: function (_type, _id, snapshot) {
        return snapshot.attr('saved_payoutable_id');
      },
      payoutId: function (_type, _id, snapshot) {
        return snapshot.belongsTo('payout', {
          id: true
        });
      }
    }
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
  });
});