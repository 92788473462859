define("admin/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KFonUapX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n\\t  \"],[10,\"h2\"],[12],[2,\"Login to Account\"],[13],[2,\"\\n\\t  \"],[10,\"div\"],[14,0,\"error text-center\"],[12],[1,[35,0,[\"credentials\",\"base\"]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,0,\"sso google-sso\"],[15,6,[31,[[34,1,[\"baseUrl\"]],\"/google\"]]],[12],[2,\"\\n    Sign in with Google\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"model\"]}",
    "meta": {
      "moduleName": "admin/templates/login.hbs"
    }
  });
});