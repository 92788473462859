define("admin/mirage/models/package", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    client: (0, _emberCliMirage.belongsTo)('client'),
    vertical: (0, _emberCliMirage.belongsTo)('vertical'),
    organization: (0, _emberCliMirage.belongsTo)('organization'),
    shot_list: (0, _emberCliMirage.belongsTo)('shot_list'),
    accountRep: (0, _emberCliMirage.belongsTo)('admin'),
    devices: (0, _emberCliMirage.hasMany)("device"),
    droneCameras: (0, _emberCliMirage.hasMany)("drone_camera"),
    drones: (0, _emberCliMirage.hasMany)("drone"),
    pilotEquipments: (0, _emberCliMirage.hasMany)("pilot_equipment"),
    deliverableWorkTypes: (0, _emberCliMirage.hasMany)("deliverable_work_type"),
    badge: (0, _emberCliMirage.belongsTo)('badge'),
    ackage_flight_app: (0, _emberCliMirage.belongsTo)('package_flight_app'),
    catalog_items: (0, _emberCliMirage.hasMany)('catalog_items')
  });
});