define("admin/helpers/is-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsInHelper;
  IsInHelper = Ember.Helper.helper(function (arg) {
    var array, element;
    element = arg[0], array = arg[1];
    return array.indexOf(element) > -1;
  });
  var _default = _exports.default = IsInHelper;
});