define("admin/components/mission-status-rewind-button", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionStatusRewindButtonComponent;
  MissionStatusRewindButtonComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    headers: {},
    type: 'submit',
    classNameBindings: ['isHidden:hide'],
    isHidden: false,
    isSendingRequest: false,
    statusObserver: Em.observer('model.status', function () {
      switch (this.get('model.status')) {
        case 'confirmed':
          this.set('isHidden', false);
          return this.set('name', 'previous step');
        default:
          return this.set('isHidden', true);
      }
    }),
    click: function (e) {
      var _model, _this, confirm, headers, request;
      e.preventDefault();
      e.stopPropagation();
      if (this.get('model').get('isOnHold')) {
        return;
      }
      confirm = this.get('isSendingRequest') ? false : window.confirm("Are you sure you want to change the status?");
      if (confirm) {
        _this = this;
        _model = this.get('model');
        headers = this.get('sessionAccount.headers');
        _this.set('isSendingRequest', true);
        request = Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + _model.id + "/status?rewind=true",
          type: 'PATCH',
          dataType: 'json',
          headers: headers
        });
        request.then(function (response) {
          _model.reload();
          return _this.set('isSendingRequest', false);
        }, function (response) {
          return _this.set('isSendingRequest', false);
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      }
    }
  });
  var _default = _exports.default = MissionStatusRewindButtonComponent;
});