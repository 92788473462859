define("admin/routes/login", ["exports", "ember-simple-auth/authenticators/devise", "ember-simple-auth/mixins/unauthenticated-route-mixin", "admin/config/environment"], function (_exports, _devise, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    routeIfAlreadyAuthenticated: 'search',
    queryParams: {
      email: false,
      token: false
    },
    baseUrl: _environment.default.api.host,
    model(params) {
      if (params['email'] && params['token']) {
        this.get('session').authenticate('authenticator:devise', params['email'], `token:${params['token']}`).then(() => {
          return null;
        }, () => {
          return alert("Login Failed. This shouldn't happen unless someone's messing with the system");
        });
      }
      return {
        "baseUrl": `${_environment.default.api.host}/auth`
      };
    }
  });
});