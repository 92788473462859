define("admin/templates/components/mission/mission-is-being-copied-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hybuVSQ3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sub-tab-container mission-status-label reshoot_or_on_hold\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub-tab-container__title\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"copyingIsInProgress\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        This mission is being COPIED\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        This is a COPIED mission\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"pull-right\"],[12],[1,[30,[36,2],[[35,0,[\"created_on\"]],\"MM/DD/YY hh:mm A\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub-tab-container__flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sub-tab-container__flex__30\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"subcontainer-line\"],[12],[2,\"\\n        Original \"],[1,[35,0,[\"copiedMissionSrc\"]]],[2,\" mission: \"],[10,\"a\"],[15,6,[31,[[34,0,[\"copiedMissionUrl\"]]]]],[12],[1,[35,0,[\"copiedMissionNumber\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"subcontainer-line\"],[12],[2,\"\\n        \"],[1,[35,0,[\"percent_copying_completed\"]]],[2,\"% completed\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[35,0,[\"copyingIsInProgress\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"sub-tab-container__flex sub-tab-container__flex__60\"],[12],[2,\"\\n        Details: This could take several minutes based on the large size of deliverables.\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mission\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission/mission-is-being-copied-box.hbs"
    }
  });
});