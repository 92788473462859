define("admin/components/missions/map-feature-line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['map-feature-line-item'],
    displayName: Ember.computed('lineItem.map_feature', function () {
      return this.get('lineItem.map_feature.name') + ' - ' + this.get('lineItem.map_feature.geometry.type');
    }),
    featureDetails: Ember.computed('lineItem.feature_details', function () {
      let area = this.get('lineItem.feature_details')['area_in_meters'];
      if (area) {
        return this.formatArea(area);
      } else {
        return this.formatLength(this.get('lineItem.feature_details')['length_in_meters']);
      }
    }),
    valueOutsideOfRange: Ember.computed('lineItem.feature_details', function () {
      return this.get('lineItem.feature_details')['value_outside_of_defined_range'];
    }),
    formatLength(length) {
      if (!length) {
        return '';
      }
      let formatted = `${length.toLocaleString()} meter`;
      if (length !== 1.0) {
        formatted += 's';
      }
      return `${formatted} (${this.metersToFeet(length)})`;
    },
    formatArea(area) {
      if (!area) {
        return '';
      }
      return `${this.metersToAcres(area)} (${this.metersToFeetSqr(area)})`;
    },
    metersToAcres(area) {
      const val = (area * 0.0002471).toFixed(2);
      let acres = `${val} acre`;
      if (val !== 1) {
        acres += 's';
      }
      return acres;
    },
    metersToFeet(area) {
      const val = Math.round(area * 3.2808).toLocaleString();
      return `${val} feet`;
    },
    metersToFeetSqr(area) {
      const val = Math.round(area * 10.76391).toLocaleString();
      return `${val} sqr feet`;
    }
  });
});