define("admin/data/capture-group/seasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'spring',
    value: 'spring'
  }, {
    label: 'summer',
    value: 'summer'
  }, {
    label: 'fall',
    value: 'fall'
  }, {
    label: 'winter',
    value: 'winter'
  }];
});