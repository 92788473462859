define("admin/components/client-package-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    packageAvailable: Ember.computed('available_package', 'model.client.client_available_packages.[]', function () {
      if (this.get('model.client.client_available_packages').findBy('package', this.get('available_package'))) {
        return true;
      }
    }),
    checkboxClass: Ember.computed('disabled', 'packageAvailable', function () {
      if (this.get('packageAvailable') && this.get('disabled')) {
        return 'available disabled';
      } else if (this.get('packageAvailable')) {
        return 'available';
      } else if (this.get('disabled')) {
        return 'not-available disabled';
      } else {
        return 'not-available';
      }
    }),
    actions: {
      toggleChecked: function () {
        if (this.get('disabled')) {
          return false;
        }
        if (this.get('packageAvailable')) {
          return this.send('destroy');
        } else {
          return this.send('create');
        }
      },
      create: function () {
        const available_package = this.store.createRecord('client-available-package', {
          client: this.get('model.client'),
          package: this.get('available_package')
        });
        available_package.save();
      },
      destroy: function () {
        const available_package = this.get('model.client.client_available_packages').findBy('package', this.get('available_package'));
        available_package.deleteRecord();
        available_package.save();
      }
    }
  });
});