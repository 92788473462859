define("admin/models/client-mission-csv", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientMissionCsv;
  ClientMissionCsv = _emberData.default.Model.extend({
    total_rows: _emberData.default.attr('number'),
    mission_created: _emberData.default.attr('number'),
    errors: _emberData.default.attr('number'),
    attachment_csv_url: _emberData.default.attr('string'),
    completed_csv_url: _emberData.default.attr('string'),
    errored_csv_url: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    client: _emberData.default.belongsTo('client', {
      async: false
    }),
    admin: _emberData.default.belongsTo('admin', {
      async: false
    }),
    default_mission_package: _emberData.default.belongsTo('package', {
      async: false
    }),
    pending: Ember.computed('status', function () {
      return this.get('status') === 'pending';
    }),
    error: Ember.computed('status', function () {
      var errorRegex, status;
      status = this.get('status');
      errorRegex = /error/;
      return errorRegex.exec(status);
    }),
    displayStatus: Ember.computed('status', function () {
      var status;
      status = this.get('status');
      if (status === 'pending' || status === 'processing' || status === 'importing') {
        return 'generating_missions';
      } else if (status === 'complete') {
        return 'generating_reports';
      } else if (status === 'exported') {
        return 'completed';
      } else {
        return status;
      }
    })
  });
  var _default = _exports.default = ClientMissionCsv;
});