define("admin/templates/missions/pilots-assign-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FD0hmx/x",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"action\",\"appendedClasses\",\"animatable\",\"fullScreen\"],[\"close\",\"full-screen-modal\",true,\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"full-screen-modal-toprow\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"Assign Pilot to Mission \"],[1,[35,0,[\"mission\",\"id\"]]],[13],[2,\"\\n      \"],[11,\"a\"],[24,6,\"javascript:void(0);\"],[24,0,\"close-modal\"],[4,[38,1],[[32,0],\"close\"],null],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"alt\",\"close\"],[14,\"src\",\"/assets/images/X.svg\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"isAssignOperation\",\"search\",\"results\",\"model\",\"placeholder\"],[true,[30,[36,1],[[32,0],\"searchPilots\"],null],[35,0,[\"pilots\"]],[35,0],\"Pilot First Name, Last Name, City, Zip, Drone type\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"pilot-search-autocomplete\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/missions/pilots-assign-modal.hbs"
    }
  });
});