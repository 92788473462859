define("admin/models/comprehensive-report-purchase-mission", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    price: (0, _model.attr)('number'),
    capacity: (0, _model.attr)('number'),
    active_subscription: (0, _model.attr)('number'),
    created_at: (0, _model.attr)('date'),
    updated_at: (0, _model.attr)('date'),
    mission: (0, _model.belongsTo)('mission', {
      async: false
    }),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    comprehensive_report_purchase: (0, _model.belongsTo)('comprehensive-report-purchase', {
      async: false
    })
  });
});