define("admin/helpers/is-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([val]) {
      return val != undefined && val != null;
    }
  });
});