define("admin/components/site-inspections/pilot-assign", ["exports", "admin/config/environment", "admin/components/pilot-search-autocomplete"], function (_exports, _environment, _pilotSearchAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _pilotSearchAutocomplete.default.extend({
    store: Ember.inject.service(),
    init() {
      this._super();
      this.get('distances').unshift('any');
      this.set('distance', 'any');
      this.set('pilotRateOptions', ['per_asset_own', 'per_asset_leasing', 'per_day'].map(value => ({
        value
      })));
    },
    willDestroyElement() {
      if (Ember.isPresent(this.get('pilotInvited'))) {
        this.set('pilotInvited', false);
      }
    },
    allowedLicenses: Ember.computed('model.licenses.[]', function () {
      let siteCountry = this.get('model.workOrder.site.country');
      let licenses = this.get('model.licenses.[]');
      if (licenses) {
        return licenses.filter(license => license.country_codes.includes(siteCountry));
      } else {
        return [];
      }
    }),
    certificates: Ember.computed('model.licenses.[]', function () {
      if (this.get('allowedLicenses')) {
        return this.get('allowedLicenses').filter(license => license.is_flight_certificate);
      } else {
        return [];
      }
    }),
    processSearch() {
      let query = this.get('query');
      let distance = this.get('distance');
      let model = this.get('model');
      let params = {
        q: query,
        distance,
        include: ['drones', 'drones.cameras', 'devices', 'pilot_equipment'],
        drone_ids: this.get('selectedDrones').getEach('id'),
        camera_ids: this.get('selectedCameras').getEach('id'),
        device_ids: this.get('selectedDevices').getEach('id'),
        document_ids: this.get('selectedDocuments').getEach('id'),
        pilot_certificate_ids: this.get('selectedCertificates').getEach('id'),
        pilot_equipment_ids: this.get('selectedEquipment').getEach('id'),
        statuses: this.get('statuses'),
        available_for_relative_mission: this.get('availableForRelativeMission'),
        no_dronebase_emails: this.get('noDronebasePilots'),
        has_drones: this.get('hasDrones'),
        per_page: this.get('maxResults'),
        badge_id: this.get('badgeId'),
        camera_mega_pixels: this.get('selectedMegaPixels'),
        sort_attribute: this.get('sortAttribute')
      };
      if (distance === 'any') {
        delete params.distance;
      }
      if (!query || query.length > this.charLimitForQuery) {
        this.set('results', []);
        this.send('search', params, model);
      }
    },
    assignmentMessage: Ember.computed('model.{assignmentEntityType,assignmentEntities}', function () {
      let message = '';
      if (this.get('model.assignmentEntityType') === 'mission') {
        message += `${this.get('model.assignmentEntities').length} `;
      }
      message += this.get('model.assignmentEntityType');
      if (this.get('model.assignmentEntityType') === 'mission' && this.get('model.assignmentEntities').length > 1) {
        message += 's';
      }
      return message;
    }),
    assignMissions() {
      let pilot = this.get('pilotsList').firstObject;
      let missionsToAssign = this.get('model.assignmentEntities');
      Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/site_inspections/${this.get('model.workOrder.id')}/assignment`,
        headers: this.get('sessionAccount.headers'),
        type: 'POST',
        dataType: 'json',
        data: {
          pilot_id: pilot.get('id'),
          missions_ids: missionsToAssign.getEach('id')
        }
      }).then(Ember.run.bind(this, () => {
        this.set('pilotInvited', pilot);
        this.set('missionsAssigned', missionsToAssign.length);
        missionsToAssign.setEach('isChecked', false);
        this.get('model.workOrder').reload();
      })).catch(() => {
        alert('failed to assign missions');
      });
    },
    // eslint-disable-next-line object-shorthand
    assignWorkOrder: async function () {
      const pilot = this.get('pilotsList').firstObject;
      const laborType = pilot.get('laborType');
      const siteInspectionPilot = this.store.createRecord('site-inspection-pilot', {
        site_inspection: this.get('model.assignmentEntities'),
        user: pilot,
        currency: pilot.get('pilot_rates').firstObject?.get('currency'),
        estimated_labor_payout_amount: pilot.get('pilot_rates').findBy('rate_type', laborType)?.get('payout_amount'),
        // per asset own vs. leasing are simply 'per asset' at this point (adding to WO pilot rates table)
        estimated_labor_payout_type: laborType === 'per_day' ? 'per_day' : 'per_asset',
        estimated_standby_payout: pilot.get('pilot_rates').findBy('rate_type', 'per_standby_hr')?.get('payout_amount'),
        estimated_mobilization_payout: pilot.get('pilot_rates').findBy('rate_type', 'mobilization')?.get('payout_amount')
      });
      await siteInspectionPilot.save().then(async () => {
        this.set('pilotInvited', pilot);
        await this.get("model.workOrder").reload();
      }).catch(error => {
        siteInspectionPilot.deleteRecord();
        alert(error.errors.map(e => e.detail).join('. '));
        console.log(error);
      });
    },
    actions: {
      toggleIncludePilot(pilot) {
        this.set('pilotsList', [pilot]);
        this.get('pilotList').setEach('invited', false);
        pilot.set('invited', true);
        this.set('numPilotsToInvite', 1);
      },
      assignPilot() {
        switch (this.get('model.assignmentEntityType')) {
          case 'mission':
            this.assignMissions();
            break;
          case 'work order':
            this.assignWorkOrder();
            break;
        }
      }
    }
  });
});