define("admin/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;
  // Format the number to the given number of decimal places
  function includes([obj, el]) {
    return obj.includes(el);
  }
  var _default = _exports.default = Ember.Helper.helper(includes);
});