define("admin/templates/inventories-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FbmU/sYH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inventory-index\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n    \"],[10,\"li\"],[15,0,[30,[36,2],[[30,[36,1],[[35,0],\"inventory-list\"],null],\"active\"],null]],[12],[6,[37,3],null,[[\"route\"],[\"inventories-lists.inventory-list\"]],[[\"default\"],[{\"statements\":[[2,\"Inventory\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[15,0,[30,[36,2],[[30,[36,1],[[35,0],\"dropships-list\"],null],\"active\"],null]],[12],[6,[37,3],null,[[\"route\"],[\"inventories-lists.dropships-list\"]],[[\"default\"],[{\"statements\":[[2,\"Dropship\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"activeTab\",\"is-equal\",\"if\",\"link-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/inventories-lists.hbs"
    }
  });
});