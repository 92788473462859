define("admin/helpers/some", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.some = some;
  // check if array contains any of the values in another array
  function some([haystack, arr]) {
    return arr.some(v => haystack.includes(v));
  }
  var _default = _exports.default = Ember.Helper.helper(some);
});