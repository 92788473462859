define("admin/models/package-addon", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    verticals: _emberData.default.hasMany('vertical', {
      async: false
    }),
    is_organization: _emberData.default.attr('boolean')
  });
});