define("admin/routes/missions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    queryParams: {
      defaultLocationId: {
        refreshModel: false
      }
    },
    model(params) {
      if (params.client_id == null) {
        return this.buildModel(null);
      } else {
        const _this = this;
        return this.store.findRecord('client', params.client_id).then(client => _this.buildModel(client));
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      model.locationsForSelect = model.locations.toArray();
      model.organizationPortfoliosForSelect = model.organization_portfolios.toArray();
      model.packagesForSelect = model.packages.toArray().sortBy('vertical.short_name', 'name', 'price');
      model.verticalsForSelect = model.verticals.toArray().sortBy('vertical.short_name');
      model.stripeLegalEntities = model.legalEntities.filter(legalEntity => legalEntity.get('stripe_enabled'));
      model.payoutAvailableCurrencies = model.currencies.filter(currency => currency.get('payout_allowed'));
      model.pricingAvailableCurrencies = model.currencies.filter(currency => currency.get('pricing_allowed'));
      return model.shotListsForSelect = model.shot_lists.toArray().sortBy('name');
    },
    buildModel(client) {
      return Ember.RSVP.hash({
        client,
        hidePackageFlightAppEditor: false,
        currencies: this.store.findAll('currency'),
        mission: client ? this.store.createRecord('mission', {
          client
        }) : this.store.createRecord('mission'),
        location: client ? this.store.createRecord('location', {
          client
        }) : this.store.createRecord('location'),
        locations: client ? this.store.query('location', {
          client_id: client.id
        }) : [],
        organization_portfolios: client.organization ? client.organization.organization_portfolios : [],
        package: client ? this.store.createRecord('package', {
          client
        }) : this.store.createRecord('package'),
        packages: client ? client.get('client_available_packages').map(availablePackage => availablePackage.get('package')).filter(Boolean) : this.store.query('package', {
          public: true
        }),
        badges: this.store.query('badge', {}),
        accountReps: this.store.query('admin', {
          role: 'account_rep'
        }),
        verticals: this.store.query('vertical', {}),
        shot_lists: this.store.findAll('shot_list'),
        drones: this.store.findAll('drone'),
        devices: this.store.findAll('device'),
        equipment: this.store.findAll('pilot_equipment'),
        work_types: this.store.query('deliverable_work_type', {}),
        package_addons: this.store.findAll('package_addon'),
        flightApps: this.store.findAll('flight-app'),
        legalEntities: this.store.findAll('legal-entity'),
        workOrders: this.store.findAll('site-inspection'),
        actions: this.actions
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('defaultLocationId', null);
      }
    },
    actions: {
      newPackage(model) {
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      editPackage(model, edit_package) {
        model.package = edit_package;
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      savePackage(model) {
        return model.package.save().then(() => {
          model.packagesForSelect.pushObject(model.package);
          model.mission.set('package', model.package);
          return this.send('closeModal');
        }, () => {}
        // noop
        );
      },

      save(model) {
        const _this = this;
        return model.mission.save().then(() => _this.transitionTo('missions.edit', model.mission.id), function () {});
      },
      //Noop
      createPackage(model) {
        const _this = this;
        const isUpdate = !model.package.get('isNew');
        return model.package.save().then(function (response) {
          if (isUpdate) {
            model.packagesForSelect.removeObject(response);
          }
          model.packagesForSelect.pushObject(response);
          model.mission.set('package', response);
          return _this.send('closeModal');
        }, function () {});
      },
      //Noop
      close() {
        return this.send('closeModal');
      },
      willTransition() {
        if (this.controller.get('model.mission.isNew')) {
          this.controller.get('model.mission').deleteRecord();
        }
        if (this.controller.get('model.location.isNew')) {
          this.controller.get('model.location').deleteRecord();
        }
        if (this.controller.get('model.package.isNew')) {
          return this.controller.get('model.package').deleteRecord();
        }
      }
    }
  });
});