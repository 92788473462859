define("admin/components/hold-mission-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HoldMissionModalComponent;
  HoldMissionModalComponent = Ember.Component.extend({
    promiseDateDisabled: Ember.computed('reason', function () {
      var ref;
      return ((ref = this.get('reason')) != null ? ref.promised_date_flexibility : void 0) !== true;
    }),
    sortedHoldReasons: Ember.computed('model.holdReasons', function () {
      return this.get('model.holdReasons').sortBy('name');
    }),
    actions: {
      close: function () {
        return this.get('close')();
      },
      setHoldReason: function (reasonId) {
        return this.set('reason', this.get('model.holdReasons').findBy('id', reasonId));
      },
      hold: function (mission) {
        var hold;
        this.get('model.mission').save();
        if (!$('.form-horizontal select').val()) {
          $($('.error-message')[0]).show();
          return;
        } else {
          $($('.error-message')[0]).hide();
        }
        this.get('close')();
        hold = mission.store.createRecord('hold', {
          'mission': mission,
          'mission_hold_reason': this.get('reason'),
          'reason_notes': this.get('reasonDetails')
        });
        return this.action(mission, hold);
      }
    }
  });
  var _default = _exports.default = HoldMissionModalComponent;
});