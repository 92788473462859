define("admin/models/image-marker", ["exports", "ember-data", "admin/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    gps_latitude: _emberData.default.attr('string'),
    gps_longitude: _emberData.default.attr('string'),
    shot: _emberData.default.belongsTo('shot', {
      async: false
    }),
    mission: _emberData.default.belongsTo('mission', {
      async: false
    })
  });
});