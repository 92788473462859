define("admin/components/reshoot-edit-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ReshootEditBoxComponent;
  ReshootEditBoxComponent = Ember.Component.extend();
  var _default = _exports.default = ReshootEditBoxComponent;
});