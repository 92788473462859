define("admin/mirage/serializers/shot-list", ["exports", "admin/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    include: ['shotTypes']
  });
});