define("admin/data/delivery-sla-starts-on-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Mission Creation",
    value: 'mission_creation'
  }, {
    label: "Data Capture",
    value: 'data_capture'
  }];
});