define("admin/data/searches/site_inspections_search_attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Synced with attributes hash at SiteInspectionsSearch at Sake
  var _default = _exports.default = [{
    label: 'ID',
    value: 'external_id'
  }, {
    label: 'DBSI',
    value: 'number'
  }, {
    label: 'Site ID (DBML)',
    value: 'dbml'
  }, {
    label: 'Site Name',
    value: 'site_name'
  }, {
    label: 'Formatted Address',
    value: 'formatted_address'
  }, {
    label: 'Address',
    value: 'address'
  }, {
    label: 'City',
    value: 'city'
  }, {
    label: 'State',
    value: 'state'
  }, {
    label: 'Postal Code',
    value: 'postal_code'
  }, {
    label: 'Country',
    value: 'country'
  }, {
    label: 'Capture Group Name',
    value: 'cg_name'
  }, {
    label: 'Capture Group ID (DBCG)',
    value: 'dbcg'
  }, {
    label: 'Client Name / Email',
    value: 'client'
  }, {
    label: 'Organization',
    value: 'company'
  }, {
    label: 'Onsite Contact Name / Email',
    value: 'onsite_contact'
  }, {
    label: 'Package Name',
    value: 'package'
  }, {
    label: 'PO #',
    value: 'po_number'
  }, {
    label: 'Ops Coordinator',
    value: 'rep'
  }];
});