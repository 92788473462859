define("admin/serializers/package", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PackageSerializer;
  PackageSerializer = _application.default.extend({
    attrs: {
      vertical: {
        deserialize: 'records'
      },
      shot_list: {
        deserialize: 'records'
      },
      accountRep: {
        deserialize: 'records'
      }
    }
  });
  var _default = _exports.default = PackageSerializer;
});