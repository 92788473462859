define("admin/models/credit-card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CreditCard;
  CreditCard = _emberData.default.Model.extend({
    provider_token: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),
    last_4: _emberData.default.attr('string'),
    exp_month: _emberData.default.attr('number'),
    exp_year: _emberData.default.attr('number')
  });
  var _default = _exports.default = CreditCard;
});