define("admin/components/select-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectCustomComponent;
  SelectCustomComponent = Ember.Component.extend({
    content: null,
    prompt: null,
    optionValuePath: 'id',
    optionLabelPath: 'fullName',
    action: Ember.K,
    classNames: ['form-group'],
    selectClass: 'select-custom',
    assignSelectedObject: true,
    useSendAction: false,
    reset: true,
    selectName: null,
    promptDisabled: true,
    displayedOptions: [],
    selectOpened: false,
    hideIconWhenValuePresent: false,
    searchIcon: false,
    inputValue: '',
    didReceiveAttrs: function (attrs) {
      this._super(attrs);
      this.set('displayedOptions', this.get('content'));
      if (this.selection) {
        this.set('inputValue', this.selection.get(this.get('optionLabelPath')));
      }
      if (!this.get('content')) {
        return this.set('content', []);
      }
    },
    init: function () {
      this._super();
      return window.addEventListener('click', function (_this) {
        return function (e) {
          var select;
          select = document.querySelector('.select-custom-wrapper');
          if (select && !select.contains(e.target) && !_this.get('isDestroying') && !_this.get('isDestroyed')) {
            return _this.set('selectOpened', false);
          }
        };
      }(this));
    },
    showIcon: Ember.computed('hideIconWhenValuePresent', 'inputValue.length', function () {
      return !(this.get('hideIconWhenValuePresent') && this.get('inputValue.length') > 0);
    }),
    actions: {
      toggleSelectOpened: function () {
        return this.toggleProperty('selectOpened');
      },
      inputChanged: function (e) {
        var filter, newOptions, options;
        options = this.get('content');
        filter = e.target.value;
        this.set('inputValue', filter);
        newOptions = options.filter(function (_this) {
          return function (option) {
            return option.get(_this.optionLabelPath).toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          };
        }(this));
        return this.set('displayedOptions', newOptions);
      },
      setValue: function (item) {
        var changeCallback, selectedValue;
        this.set('inputValue', item.get(this.optionLabelPath));
        selectedValue = item.get(this.optionValuePath);
        changeCallback = this.get('action');
        if (this.assignSelectedObject) {
          if (this.useSendAction) {
            return this.action(item);
          } else {
            return changeCallback(item);
          }
        } else {
          if (this.useSendAction) {
            return this.action(selectedValue);
          } else {
            return changeCallback(selectedValue);
          }
        }
      },
      changeValue: function () {
        var changeCallback, content, contentIndex, hasPrompt, selectEl, selectedIndex, selectedObject, selectedValue;
        selectEl = this.$('select')[0];
        this.$('select').addClass('changed');
        selectedIndex = selectEl.selectedIndex;
        content = this.get('content');
        hasPrompt = !!this.get('prompt');
        contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
        selectedObject = content.objectsAt ? content.objectsAt([contentIndex])[0] : content[contentIndex];
        selectedValue = selectedObject ? selectedObject.get(this.optionValuePath) : null;
        changeCallback = this.get('action');
        if (this.assignSelectedObject) {
          if (this.useSendAction) {
            this.action(selectedObject);
          } else {
            changeCallback(selectedObject);
          }
        } else {
          if (this.useSendAction) {
            this.action(selectedValue);
          } else {
            changeCallback(selectedValue);
          }
        }
        if (this.reset) {
          return selectEl.selectedIndex = 0;
        }
      }
    }
  });
  var _default = _exports.default = SelectCustomComponent;
});