define("admin/models/shot-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    shot_type_ids: _emberData.default.attr('array'),
    shot_types: _emberData.default.hasMany('shot-type', {
      async: false
    })
  });
});