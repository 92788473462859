define("admin/components/mission/assets/full-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MissionAssetsFullComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['mission-assets-full-view'],
    classNameBindings: ['isFullView:show'],
    shotAssetsCount: 24,
    currentShot: null,
    currentPage: 1,
    assetsPerPage: 90,
    isShowToTopLink: false,
    onKeyDown: Ember.on('init', function () {
      document.onkeydown = event => {
        if (this.get('currentView') === 'full') {
          if (event.keyCode === 27) {
            this.send('closeFullView');
          }
        }
        if (this.get('currentAsset')) {
          if (event.keyCode === 37) {
            this.send('previousAsset');
          }
          if (event.keyCode === 39) {
            this.send('nextAsset');
          }
        }
      };
    }),
    isFullView: Ember.computed('currentView', function () {
      if (this.get('assetMarker')) {
        this.set('markers', [this.get('assetMarker')]);
      } else {
        this.set('markers', this.get('fullViewMarkers'));
      }
      return this.get('currentView') === 'full';
    }),
    isAllShots: Ember.computed('currentShot', 'currentAsset', function () {
      return this.get('currentShot') === null && !this.get('currentAsset');
    }),
    isSingleAsset: Ember.computed('currentAsset', function () {
      return this.get('currentAsset') != null;
    }),
    nextShot: Ember.computed('currentShot', function () {
      let currentIndex = this.get('mission.shots').indexOf(this.get('currentShot'));
      if (currentIndex >= this.get('mission.shots.length') - 1) {
        currentIndex = -1;
      }
      return this.get('mission.shots').objectAt(currentIndex + 1);
    }),
    totalPages: Ember.computed('currentShot', function () {
      return Math.ceil(this.get('currentShot.readyAssetsCount') / this.get('assetsPerPage'));
    }),
    scrollAssetsToTop: function () {
      Ember.$('.full-view-assets').animate({
        scrollTop: 0
      }, 500);
    },
    actions: {
      closeFullView: function () {
        this.set('currentAsset', null);
        this.set('currentShot', null);
        this.set('markers', null);
        this.closeFullViewAction();
      },
      deleteAsset: function (asset) {
        if (asset && window.confirm('Are you sure? This will permanently remove this asset.')) {
          const shot = asset.shot;
          const request = asset.destroyRecord().then(response => {
            this.set('totalImages', this.get('totalImages') - 1);
            shot.set('imageCount', shot.get('imageCount') - 1);
            this.send('goToShot', null);
          }).catch(response => {
            return alert('There was an issue deleting this asset');
          });
          if (this.get('onBusy')) {
            this.get('onBusy')(request);
          }
          return request;
        }
      },
      toggleFullscreenOrthoModal: function (ortho) {
        return this.toggleFullscreenOrthoModalAction(ortho);
      },
      goToAsset: function (asset, index) {
        if (!asset) {
          index = 0;
          asset = this.get('mission.shots.firstObject.timeSortedImages.firstObject');
        }
        this.set('currentAsset', asset);
        this.set('currentShot', null);
        const currentAssetMarker = this.store.peekRecord('image_marker', asset.id);
        this.set('markers', [currentAssetMarker]);
        let currentAssetNumber = index + 1;
        let shotIndex = 0;
        this.set('assetIndex', index);
        this.get('mission.shots').forEach(shot => {
          if (asset.get('shot.id') !== shot.get('id')) {
            currentAssetNumber += shot.get('timeSortedImages.length');
            shotIndex++;
          } else {
            this.set('shotIndex', shotIndex);
            this.set('currentAssetNumber', currentAssetNumber);
            return;
          }
        });
      },
      goToShot: function (shot) {
        this.set('currentAsset', null);
        this.set('currentShot', shot);
        this.set('currentPage', 1);
        this.set('currentAssetNumber', null);
        let newMarkers = null;
        if (shot === null) {
          newMarkers = this.get('mission.image_markers');
        } else {
          shot.loadImages();
          newMarkers = shot.get('image_markers');
        }
        this.set('markers', newMarkers);
        this.scrollAssetsToTop();
      },
      scrollToTop: function () {
        this.scrollAssetsToTop();
      },
      nextPage: function () {
        if (this.get('currentPage') < this.get('totalPages')) {
          this.incrementProperty('currentPage');
          this.scrollAssetsToTop();
          this.get('currentShot').loadImages();
        }
      },
      prevPage: function () {
        if (this.get('currentPage') > 1) {
          this.decrementProperty('currentPage');
          this.scrollAssetsToTop();
        }
      },
      previousAsset: function () {
        if (this.get('currentAssetNumber') === 1) {
          return;
        }
        const assetIndex = this.get('assetIndex');
        let shotBucket;
        if (assetIndex === 0) {
          this.set('shotIndex', this.get('shotIndex') - 1);
          shotBucket = this.get('mission.shots').objectAt(this.get('shotIndex'));
          this.set('assetIndex', shotBucket.get('timeSortedImages').length - 1);
        } else {
          shotBucket = this.get('mission.shots').objectAt(this.get('shotIndex'));
          this.set('assetIndex', assetIndex - 1);
        }
        const asset = shotBucket.get('timeSortedImages').objectAt(this.get('assetIndex'));
        this.set('currentAsset', asset);
        const currentAssetMarker = this.store.peekRecord('image_marker', asset.id);
        this.set('markers', [currentAssetMarker]);
        this.set('currentAssetNumber', this.get('currentAssetNumber') - 1);
      },
      nextAsset: function () {
        if (this.get('currentAssetNumber') === this.get('totalImages')) {
          return;
        }
        this.set('markers', null);
        let shotBucket = this.get('mission.shots').objectAt(this.get('shotIndex'));
        const assetIndex = this.get('assetIndex');
        if (assetIndex === shotBucket.get('imageCount') - 1) {
          this.set('shotIndex', this.get('shotIndex') + 1);
          shotBucket = this.get('mission.shots').objectAt(this.get('shotIndex'));
          shotBucket.loadImagesPage(1, this.assetsPerPage);
          this.set('assetIndex', 0);
        } else {
          shotBucket.loadImages();
          this.set('assetIndex', assetIndex + 1);
        }
        const asset = shotBucket.get('timeSortedImages').objectAt(this.get('assetIndex'));
        this.set('currentAsset', asset);
        const currentAssetMarker = this.store.peekRecord('image_marker', asset.id);
        this.set('markers', [currentAssetMarker]);
        this.set('currentAssetNumber', this.get('currentAssetNumber') + 1);
      },
      setShowToTopLink: function (value) {
        this.set('isShowToTopLink', value);
      },
      downloadAsset: function () {
        window.location = this.get('currentAsset.downloadUrl');
      },
      toggleSourceType: function () {
        // TODO: think about how to make promote/unpromote action sharable
        if (this.get('currentAsset.source_type') === 'raw') {
          this.set('currentAsset.source_type', 'edit');
        } else {
          this.set('currentAsset.source_type', 'raw');
        }
        const request = this.get('currentAsset').save().catch(function (response) {
          if (this.get('currentAsset.source_type') === 'raw') {
            this.set('currentAsset.source_type', 'edit');
          } else {
            this.set('currentAsset.source_type', 'raw');
          }
          return alert(`There was an error updating asset ${this.get('currentAsset.id')}`);
        });
        return request;
      }
    }
  });
  var _default = _exports.default = MissionAssetsFullComponent;
});