define("admin/components/open-pilot-app", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OpenPilotAppComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    pilotLoginUrl: Ember.computed('params', function () {
      const adminToken = this.get('session.data.authenticated.token');
      const adminEmail = this.get('session.data.authenticated.email');
      let url = `${_environment.default.pilots.host}/?admin_token=${adminToken}&admin_email=${adminEmail}`;
      const object = this.get('params');
      for (let name in object) {
        const prop = object[name];
        const nameEncoded = encodeURIComponent(name);
        const propEncoded = encodeURIComponent(prop);
        url = url + `&${nameEncoded}=${propEncoded}`;
      }
      return url;
    }),
    actions: {
      openPilotApp() {
        return window.open(this.get('pilotLoginUrl'));
      }
    }
  });
  var _default = _exports.default = OpenPilotAppComponent;
});