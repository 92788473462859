define("admin/serializers/mission", ["exports", "admin/serializers/shareable"], function (_exports, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionSerializer;
  MissionSerializer = _shareable.default.extend({
    attrs: {
      "package": {
        deserialize: 'records'
      },
      location: {
        deserialize: 'records'
      },
      credit_card: {
        deserialize: 'records'
      },
      client: {
        deserialize: 'records'
      },
      pilot: {
        deserialize: 'records',
        serialize: false
      },
      accountRep: {
        deserialize: 'records'
      },
      productionRep: {
        deserialize: 'records'
      },
      collaborators: {
        deserialize: 'records'
      },
      videos: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });
  var _default = _exports.default = MissionSerializer;
});