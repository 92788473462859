define("admin/templates/locations/create-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ds9kiaW5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"action\"],[\"close\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"action\",\"model\",\"title\"],[[30,[36,1],[[32,0],\"createLocation\"],null],[35,0],\"New Location\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"mission-map\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/locations/create-modal.hbs"
    }
  });
});