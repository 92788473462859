define("admin/adapters/bucket", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/admin/missions',
    urlTemplate: '{+namespace}/{missionId}/buckets{/id}',
    queryUrlTemplate: '{+namespace}/{missionId}/buckets',
    urlSegments: {
      missionId: function (type, id, snapshot, query) {
        if (query == undefined) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return query.missionId;
        }
      }
    },
    promoteAssets: function (bucket) {
      var url = `${_environment.default.api.host}/v1/admin/missions/${bucket.get('mission.id')}/buckets/${bucket.id}/promote_all_assets`;
      return this.ajax(url, 'POST');
    }
  });
});