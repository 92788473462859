define("admin/models/bucket-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    extension_list: _emberData.default.attr('array'),
    extension_list_string: Ember.computed("extension_list.[]", function () {
      return this.get('extension_list').filter(Boolean).map(ext => ext.toLowerCase()).join(',');
    })
  });
});