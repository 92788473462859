define("admin/components/input-trigger-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputTriggerSaveComponent;
  InputTriggerSaveComponent = Ember.TextField.extend({
    classNames: ['form-control'],
    type: 'text',
    keyPress: function (e) {
      if (e.which === 13) {
        return this.save();
      }
    },
    focusOut: function () {
      return this.save();
    }
  });
  var _default = _exports.default = InputTriggerSaveComponent;
});