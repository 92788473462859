define("admin/templates/components/reusable/pill-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "n4jIz992",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"selected-item-name\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"checkmark\"],[12],[10,\"img\"],[14,\"src\",\"/assets/images/checkmark.svg\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[34,2]],[2,\"\\n  \"],[11,\"span\"],[24,0,\"delete-item\"],[4,[38,3],[[32,0],\"cancel\"],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showCheckmark\",\"if\",\"text\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/pill-component.hbs"
    }
  });
});