define("admin/templates/components/open-client-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I5519nFp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-as-customer-link\"],[12],[2,\"\\n  \"],[11,\"a\"],[4,[38,0],[[32,0],\"openClientApp\"],null],[12],[1,[34,1]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"label\"]}",
    "meta": {
      "moduleName": "admin/templates/components/open-client-app.hbs"
    }
  });
});