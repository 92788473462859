define("admin/templates/components/mission-sidebar-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mLv/pANG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"sidebar-map\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-sidebar-map.hbs"
    }
  });
});