define("admin/templates/components/inventory/pilot-available-missions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uybZxpDZ",
    "block": "{\"symbols\":[\"mission\"],\"statements\":[[10,\"div\"],[14,0,\"pilot-available-missions-table\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"Pilot's Active Missions:\"],[13],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"DBM\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Address\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Client\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Package Name\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Created Date\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Scheduled Date\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"nowrap inventory-checkbox\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"model\",\"selections\",\"byAttribute\",\"nameAttribute\"],[[32,1],[35,0],\"id\",\"id\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"checkmark\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"location\",\"formatted_address\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"nowrap\"],[12],[1,[32,1,[\"client\",\"fullName\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"package\",\"fullName\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"created_on\"]],\"MM/DD/YYYY\"],null]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"scheduled_at_start\"]],\"MM/DD/YYYY\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedMissions\",\"checkbox-item\",\"moment-format\",\"missions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inventory/pilot-available-missions.hbs"
    }
  });
});