define("admin/helpers/initialize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([name]) {
      if (name == null || name == undefined) {
        return '';
      }
      return name.split(" ").map(name => name.charAt(0).toUpperCase()).join('');
    }
  });
});