define("admin/mirage/models/shot-list", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    shotTypes: (0, _emberCliMirage.hasMany)('shot-type')
  });
});