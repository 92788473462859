define("admin/mirage/factories/currency", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    code: _faker.default.finance.currencyCode(),
    name: _faker.default.finance.currencyName(),
    symbol: _faker.default.finance.currencySymbol(),
    payout_allowed: true,
    pricing_allowed: true
  });
});