define("admin/components/pano-viewer", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    idSuffix: 'default',
    didInsertElement: function () {
      this.initPano();
    },
    initPano: function () {
      this.$(`#pano${this.get('panorama.accessKey')}-${this.get('idSuffix')}`).on('click', () => {
        var krPano = document.getElementById(`krpanoSWFObject${this.get('panorama.accessKey')}-${this.get('idSuffix')}`);
        if (krPano.get('autorotate.isrotating')) {
          krPano.call('autorotate.stop();');
        }
      });

      // krPano callback when it's fully initialized
      function krpanoReady(krPano) {
        krPano;
      }
      return embedpano({
        swf: '/panorama/krpano.swf',
        xml: `${_environment.default.api.host}/v1/admin/panorama/${this.get('panorama.accessKey')}.xml`,
        id: `krpanoSWFObject${this.get('panorama.accessKey')}-${this.get('idSuffix')}`,
        target: `pano${this.get('panorama.accessKey')}-${this.get('idSuffix')}`,
        mobilescale: 1.0,
        passQueryParameters: true,
        consolelog: true,
        onready: krpanoReady,
        mwheel: false
      });
    }
  });
});