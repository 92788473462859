define("admin/templates/components/mission-assets-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nUPimzWa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"assets-header-button\"],[24,6,\"javascript:void(0)\"],[24,\"data-toggle\",\"tooltip\"],[24,\"data-placement\",\"bottom\"],[24,\"title\",\"Share Assets\"],[4,[38,2],[[32,0],\"shareShareable\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"button-default\"],[14,\"alt\",\"Share\"],[14,\"src\",\"/assets/images/mission-assets/share_icon.svg\"],[12],[13],[2,\"\\n    \"],[10,\"img\"],[14,0,\"button-hover\"],[14,\"alt\",\"Share\"],[14,\"src\",\"/assets/images/mission-assets/share_icon_hover.svg\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[14,0,\"assets-header-button single disabled\"],[14,6,\"javascript:void(0)\"],[14,\"data-toggle\",\"tooltip\"],[14,\"data-placement\",\"top\"],[14,\"title\",\"Not Available\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"button-disabled\"],[14,\"alt\",\"Share\"],[14,\"src\",\"/assets/images/mission-assets/share_icon_disable.svg\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"hideShareModal\",\"mission\",\"shareable\"],[[30,[36,2],[[32,0],\"hideShareModal\"],null],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"shareable\",\"mission\",\"action\",\"asset-share-modal\",\"isShareable\",\"if\",\"showShareModal\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-assets-share.hbs"
    }
  });
});