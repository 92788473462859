define("admin/models/package-flight-app", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PackageFlightApp;
  PackageFlightApp = _emberData.default.Model.extend({
    flight_app: _emberData.default.belongsTo('flight_app', {
      async: false
    }),
    "package": _emberData.default.belongsTo('package', {
      async: false
    }),
    value: _emberData.default.attr('raw'),
    delivery_to_url: _emberData.default.attr('string')
  });
  var _default = _exports.default = PackageFlightApp;
});