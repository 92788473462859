define("admin/models/standby-hours-line-item", ["exports", "@ember-data/model", "moment-timezone"], function (_exports, _model, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    starts_at: (0, _model.attr)('string'),
    ends_at: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    billable_to_customer: (0, _model.attr)('boolean'),
    rejection_reason: (0, _model.attr)('string'),
    rejection_comment: (0, _model.attr)('string'),
    site_inspection: (0, _model.belongsTo)('site-inspection', {
      async: true
    }),
    pilot: (0, _model.belongsTo)('pilot', {
      async: true
    }),
    standby_hours_reason: (0, _model.belongsTo)('standby-hours-reason', {
      async: true
    }),
    periodInSeconds: Ember.computed('starts_at', 'ends_at', function () {
      return (0, _momentTimezone.default)(this.get('ends_at')).diff((0, _momentTimezone.default)(this.get('starts_at')), 'seconds', true);
    })
  });
});