define("admin/templates/four-oh-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LjNkk7HZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center four-oh-three\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/dronebase_logo.svg\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"orange-divider\"],[12],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"You don't currently have permission to access DroneBase admin.\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Please contact your manager.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "admin/templates/four-oh-three.hbs"
    }
  });
});