define("admin/models/airspace", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Airspace;
  Airspace = _emberData.default.Model.extend({
    city: _emberData.default.attr('string'),
    ceiling: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    laanc_enabled: _emberData.default.attr('boolean')
  });
  var _default = _exports.default = Airspace;
});