define("admin/templates/components/asset-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "i/JGXnDa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[16,0,[31,[[34,4]]]],[4,[38,2],[[32,0],\"shareShareable\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-share-alt\"],[12],[13],[2,\" \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[15,0,[31,[[34,4],\" disabled\"]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-share-alt\"],[12],[13],[2,\" \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"hideShareModal\",\"mission\",\"shareable\"],[[30,[36,2],[[32,0],\"hideShareModal\"],null],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"shareable\",\"mission\",\"action\",\"asset-share-modal\",\"buttonClassNames\",\"linkText\",\"isShareable\",\"if\",\"showShareModal\"]}",
    "meta": {
      "moduleName": "admin/templates/components/asset-share.hbs"
    }
  });
});