define("admin/data/subscription-offer/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.durationValues = void 0;
  const values = [{
    label: '6 months',
    value: 6
  }, {
    label: '1 year',
    value: 12
  }, {
    label: '1.5 year',
    value: 18
  }, {
    label: '2 years',
    value: 24
  }, {
    label: '3 years',
    value: 36
  }];
  const durationValues = _exports.durationValues = values.map(({
    label,
    value
  }) => {
    return Ember.Object.create({
      label,
      value
    });
  });
});