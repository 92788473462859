define("admin/templates/equipment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EFKE1Uaa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-header clearfix\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"pull-left\"],[12],[2,\"Pilot Equipment\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],null,[[\"droneManufacturers\",\"model\",\"cameras\",\"recordType\"],[[35,0,[\"droneManufacturers\"]],[35,0,[\"drones\"]],[35,0,[\"cameras\"]],\"drone\"]]]],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"droneManufacturers\",\"model\",\"recordType\"],[[35,0,[\"droneManufacturers\"]],[35,0,[\"cameras\"]],\"drone-camera\"]]]],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"model\",\"recordType\"],[[35,0,[\"devices\"]],\"device\"]]]],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"model\",\"recordType\"],[[35,0,[\"pilotEquipment\"]],\"pilot-equipment\"]]]],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"recordType\"],[\"pilot-compliance-documents\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"drone-index-list\",\"camera-index-list\",\"device-index-list\",\"pilot-equipment-index-list\",\"pilot-compliance-documents-index-list\"]}",
    "meta": {
      "moduleName": "admin/templates/equipment/index.hbs"
    }
  });
});