define("admin/components/mission/assets/full-view-map", ["exports", "admin/components/mission-asset-map"], function (_exports, _missionAssetMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _missionAssetMap.default.extend({
    mapSelector: 'full-view-map',
    didInsertElement: function () {
      this.addObserver('isShow', this, 'showMap');
    },
    showMap: function () {
      if (this.get('isShow')) {
        this.clearMarkers();
        this.initMap();
        this.addObserver('mapImageMarkers', this, 'refreshMap');
      }
    },
    mapOptions: function () {
      return {
        zoom: 17,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId['HYBRID'],
        mapTypeControl: true,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        draggable: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        disableDoubleClickZoom: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        }
      };
    },
    fitAllMarkers: function (bounds) {
      if (this.get('allMarkers')) {
        this.get('allMarkers').forEach(marker => {
          if (marker.get('gps_latitude') && marker.get('gps_longitude') && marker.get('gps_latitude') !== '0' && marker.get('gps_longitude') !== '0') {
            return bounds.extend(this.buildLatLng(marker.get('gps_latitude'), marker.get('gps_longitude')));
          }
        });
      }
    }
  });
});