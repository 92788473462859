define("admin/data/mission-order-flow-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'Standard',
    value: 'standard'
  }, {
    label: 'Client Upload',
    value: 'client_upload'
  }];
});