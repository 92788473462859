define("admin/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexRoute;
  IndexRoute = Ember.Route.extend({
    redirect: function () {
      return this.transitionTo('missions');
    }
  });
  var _default = _exports.default = IndexRoute;
});