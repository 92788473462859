define("admin/helpers/work-order-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([services]) {
      const implicitServices = ['Capture'];
      const addlServices = services?.map(service => {
        // see list in deliverable_work_types table
        const wordsInServiceName = service.split(' ');
        // we use the 2nd word if there is one (ignore qualifier)
        const shortHand = wordsInServiceName[1] || wordsInServiceName[0];
        return shortHand.toLowerCase();
      }) || [];
      return implicitServices.concat(addlServices).join(", ");
    }
  });
});