define("admin/components/db-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: Ember.A(['db-select']),
    classNameBindings: ['selectOpened:opened'],
    options: Ember.A([]),
    _wrappedOptions: Ember.A([]),
    valuePath: 'value',
    labelPath: 'label',
    value: null,
    selectOpened: false,
    disabled: false,
    placeholder: null,
    didReceiveAttrs: function () {
      this._super();
      if (['string', 'number'].includes(typeof this.get('options')[0])) {
        // array of strings or numbers
        const _wrappedOptions = this.get('options').map(option => Ember.Object.create({
          value: option,
          label: option
        }));
        this.set('_wrappedOptions', _wrappedOptions);
      } else {
        // array of Ember objects
        this.set('_wrappedOptions', this.get('options'));
      }
    },
    selectedItem: Ember.computed('value', 'valuePath', '_wrappedOptions.[]', function () {
      if (this.get('value')) {
        let value = this.get('value');
        if (this.get('value.changeset')) {
          value = this.get('value.content');
        }
        return this.get('_wrappedOptions').findBy(this.get('valuePath'), value);
      }
    }),
    actions: {
      toggleSelectOpened: function () {
        this.toggleProperty('selectOpened');
        if (this.get('selectOpened') && this.get('_wrappedOptions.length') > 0) {
          Ember.run.schedule('afterRender', this, () => {
            let offsetTop = 0;
            if (this.get('selectedItem')) {
              const selectedElement = this.$('.options a.selected')[0];
              if (selectedElement) {
                offsetTop = selectedElement.offsetTop - selectedElement.offsetHeight;
              }
            }
            this.$('.options')[0].scrollTo({
              top: offsetTop
            });
          });
        }
      },
      setValue: function (value) {
        if (this.action) {
          const result = this.action(value);
          if (result === false) {
            return false;
          }
        } else {
          this.set('value', value);
        }
        this.set('selectOpened', false);
      },
      clickOutside: function () {
        this.set('selectOpened', false);
      }
    }
  });
});