define("admin/components/subscription-offers/form-modal", ["exports", "ember-changeset", "admin/data/subscription-offer/duration", "admin/data/subscription-offer/valid-for", "ember-changeset-validations", "admin/validations/subscription-offer", "moment", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _emberChangeset, _duration, _validFor, _emberChangesetValidations, _subscriptionOffer, _moment, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    durationValues: _duration.durationValues,
    validForValues: _validFor.validForValues,
    subscriptionOffer: null,
    activeWarningOpen: Ember.computed('changeset.active', function () {
      return !this.get('changeset.active');
    }),
    currencyOptions: Ember.computed('currencies', function () {
      return this.get('currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    stateOptions: Ember.computed('states', function () {
      return this.get('states').map(state => {
        return Ember.Object.create({
          label: state.name,
          value: state
        });
      });
    }),
    formattedCreatedAt: Ember.computed('subscriptionOffer.created_at', function () {
      return (0, _moment.default)(this.get('subscriptionOffer.created_at')).format('MM/DD/YYYY');
    }),
    isNew: Ember.computed('subscriptionOffer', function () {
      return this.get('subscriptionOffer.isNew');
    }),
    setCurrency: function (currency) {
      this.changeset.set('currency', currency);
    },
    init() {
      this._super();
      if (!this.get('subscriptionOffer')) {
        this.set('subscriptionOffer', this.store.createRecord('subscription-offer', {
          active: true
        }));
      }
      this.changeset = new _emberChangeset.default(this.get('subscriptionOffer'), (0, _emberChangesetValidations.default)(_subscriptionOffer.default), _subscriptionOffer.default);
      this.setCurrency(this.currencies.findBy('code', this.changeset.get('currency.code') || 'USD'));
    },
    actions: {
      close() {
        if (this.get('subscriptionOffer.id')) {
          this.get('subscriptionOffer').reload();
        } else {
          this.get('subscriptionOffer').deleteRecord();
        }
        return this.closeAction();
      },
      submit: function () {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.changeset.save().then(() => {
              this.flashMessages.success('Subscription offer was saved!');
              return this.closeAction();
            }).catch(response => {
              (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
              this.flashMessages.error('Failed to save subscription offer');
            });
          }
        });
      },
      changeSubscriptionOfferCurrency: function (currency) {
        this.setCurrency(currency);
      },
      closeWarning: function () {
        this.set('activeWarningOpen', false);
      }
    }
  });
});