define("admin/utils/flatten", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var flatten;
  flatten = function (array) {
    var flattened, i, len, value;
    flattened = [];
    for (i = 0, len = array.length; i < len; i++) {
      value = array[i];
      if (Ember.isArray(value)) {
        flattened.push(flatten(value));
      } else {
        flattened.push(value);
      }
    }
    return flattened;
  };
  var _default = _exports.default = flatten;
});