define("admin/components/site-inspections/pilot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    workOrder: null,
    completedMissionsByPilot: Ember.computed("workOrder.{missions.[],pilots.[]}", function () {
      return this.get("workOrder.pilots").reduce((hash, pilot) => {
        hash[pilot.get("id")] = this.get("workOrder.missions").filter(mission => mission.get("assetsClassified") && mission.get("pilot.id") === pilot.get("id")).length;
        return hash;
      }, {});
    }),
    totalMissionsInWorkOrder: Ember.computed("workOrder.missions", function () {
      return this.get("workOrder.missions").length;
    }),
    actions: {
      togglePilotEditModal(pilot = null) {
        this.set("selectedSIPilot", pilot);
        this.toggleProperty("showPilotEditModal");
      }
    }
  });
});