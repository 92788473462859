define("admin/templates/components/shot-list/shot-types-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "458V5nmR",
    "block": "{\"symbols\":[\"shotType\"],\"statements\":[[10,\"div\"],[14,0,\"sidebar__actions\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"sidebar-link \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"toggleSelectedType\",[32,1]],null],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sidebar-horizontal-divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedType\",\"is-equal\",\"if\",\"action\",\"types\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/shot-list/shot-types-selector.hbs"
    }
  });
});