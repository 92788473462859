define("admin/components/inventory/inventory-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['inventory-item'],
    isModalOpen: false,
    isRepairModalOpen: false,
    activeItemKind: null,
    currentSelectedItem: null,
    isArchived: Ember.computed('kind', function () {
      return this.get('kind') === 'archived';
    }),
    isCheckInModalOpen: false,
    scheduledMissions: [],
    selectedCount: Ember.computed('kind', 'selectedInventories.[]', function () {
      const kindToTypes = {
        drones: 'Drone',
        cameras: 'DroneCamera',
        adapters: 'Adapter',
        'other-equipment': 'PilotEquipment'
      };
      return this.get('selectedInventories').filterBy('inventory_type', kindToTypes[this.get('kind')]).length;
    }),
    actions: {
      toggleCollapsed() {
        this.toggleCollapsedAction();
      },
      addRecord(kind) {
        this.set('activeItemKind', kind);
        this.set('isModalOpen', true);
      },
      editRecord(item, kind) {
        this.set('currentSelectedItem', item);
        this.set('activeItemKind', kind);
        this.set('isModalOpen', true);
      },
      cloneRecord(item, kind) {
        let clonedRecord = this.get('store').createRecord('inventory-item', item.serialize().data.attributes);
        clonedRecord.set('inventoriable', item.inventoriable);
        this.set('currentSelectedItem', clonedRecord);
        this.set('activeItemKind', kind);
        this.set('isModalOpen', true);
      },
      changeStatus(item, status) {
        let scheduledMissions;
        if (Ember.isPresent(item.active_dropship)) {
          scheduledMissions = item.active_dropship.get('missions').filter(function (mission) {
            return moment().isBefore(mission.scheduled_at_end);
          });
        } else {
          scheduledMissions = [];
        }
        if (status === 'in_stock' && scheduledMissions.length > 0) {
          this.set('isCheckInModalOpen', true);
          this.set('scheduledMissions', scheduledMissions);
          this.set('pilotId', item.pilotId);
        } else {
          item.set('status', status);
          item.save().catch(error => {
            alert(error.errors.mapBy('detail').join(', '));
            item.rollbackAttributes();
          });
        }
      },
      closeCheckInModal() {
        this.set('isCheckInModalOpen', false);
      },
      closeModal(item = null) {
        if (item) {
          this.addSavedItem(item);
        }
        this.set('activeItemKind', null);
        this.set('currentSelectedItem', null);
        this.set('isModalOpen', false);
      },
      openRepairModal(item) {
        this.set('currentSelectedItem', item);
        this.set('isRepairModalOpen', true);
      },
      closeRepairModal() {
        this.set('currentSelectedItem', null);
        this.set('isRepairModalOpen', false);
      },
      moveFromRepair(item) {
        const currentRepair = item.get('active_repair');
        currentRepair.set('status', 'inactive');
        currentRepair.save().then(() => {
          item.reload();
        });
      },
      moveToArchive(item) {
        const currentRepair = item.get('active_repair');
        if (Ember.isPresent(currentRepair)) {
          currentRepair.set('status', 'inactive');
          currentRepair.save();
        }
        this.send('changeStatus', item, 'archived');
      },
      stopPropogationAction: function (event) {
        event.stopPropagation();
        // Action used to just not trigger nested actions of element
      }
    }
  });
});