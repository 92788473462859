define("admin/components/onboarding/pilot-drone-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    totalSelectedCount: Ember.computed('selectedDrones.[]', 'selectedCameras.[]', function () {
      return this.get('selectedDrones.length') + this.get('selectedCameras.length');
    }),
    didInsertElement() {
      const _this = this;
      if (this.get('drones.length') === undefined) {
        this.store.query('drone', {}).then(function (data) {
          _this.set('drones', data.toArray());
          _this.cacheObjects('drones', data.toArray());
          _this.get('drones').forEach(function (drone) {
            if (_this.get('droneIds').includes(drone.get('id'))) {
              _this.get('selectedDrones').pushObject(drone);
            }
          });
        });
      }
      if (this.get('cameras.length') === undefined) {
        this.store.query('drone-camera', {}).then(function (data) {
          _this.set('cameras', data.toArray());
          _this.cacheObjects('cameras', data.toArray());
          _this.get('cameras').forEach(function (drone) {
            if (_this.get('cameraIds').includes(drone.get('id'))) {
              _this.get('selectedCameras').pushObject(drone);
            }
          });
        });
      }
    },
    actions: {
      clear() {
        this.set('selectedDrones', []);
        this.set('droneIds', []);
        this.set('selectedCameras', []);
        this.set('cameraIds', []);
      },
      setPanel() {
        this.setPanel(null, null);
      }
    }
  });
});