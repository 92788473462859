define("admin/controllers/waivers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sortProperties: ['updated_at:desc'],
    sortedWaivers: Ember.computed.sort('model.waivers', 'sortProperties'),
    actions: {
      sortBy(sortProperties) {
        return this.set('sortProperties', [sortProperties]);
      }
    }
  });
});