define("admin/data/shot-type/area-of-interest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: 'Roof shingles',
    value: 'roof_shingles'
  }, {
    name: 'Roof corners',
    value: 'roof_corners'
  }, {
    name: 'Roof panel',
    value: 'roof_panel'
  }, {
    name: 'Roof appurtenances',
    value: 'roof_appurtenances'
  }, {
    name: 'Roof gutter',
    value: 'roof_gutter'
  }, {
    name: 'Wall gutter',
    value: 'wall_gutter'
  }, {
    name: 'Wall',
    value: 'wall'
  }, {
    name: 'Window',
    value: 'window'
  }, {
    name: 'Front door',
    value: 'front_door'
  }, {
    name: 'Back door',
    value: 'back_door'
  }, {
    name: 'Sideyard',
    value: 'sideyard'
  }, {
    name: 'Back yard',
    value: 'back_yard'
  }, {
    name: 'Front of the property',
    value: 'front_of_the_property'
  }, {
    name: 'Corners',
    value: 'corners'
  }, {
    name: 'Anchor tenants',
    value: 'anchor_tenants'
  }, {
    name: 'Subject property',
    value: 'subject_property'
  }, {
    name: 'Street view',
    value: 'street_view'
  }, {
    name: 'Hvac units',
    value: 'hvac_units'
  }, {
    name: 'Trailers',
    value: 'trailers'
  }, {
    name: 'Pavement',
    value: 'pavement'
  }, {
    name: 'Amenities',
    value: 'amenities'
  }, {
    name: 'Store fronts',
    value: 'store_fronts'
  }, {
    name: 'Street view',
    value: 'street_view'
  }, {
    name: 'Roof entire roof',
    value: 'roof_entire_roof'
  }, {
    name: 'Point of interest',
    value: 'point_of_interest'
  }, {
    name: 'Turbine blade',
    value: 'turbine_blade'
  }];
});