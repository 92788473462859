define("admin/models/shareable", ["exports", "ember-data", "admin/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    shares: _emberData.default.hasMany('share', {
      async: false,
      inverse: 'shareable'
    }),
    processing_status: _emberData.default.attr('string'),
    share_token: _emberData.default.attr('string'),
    shareLink: Ember.computed('share_token', function () {
      if (this.get('share_token')) {
        var shareable = this.get('constructor.modelName');
        return _environment.default.clients.host + "/" + shareable.charAt(0) + "/" + this.get('share_token');
      }
    }),
    isShareable: Ember.computed('processing_status', function () {
      return this.get('processing_status') === 'ready';
    })
  });
});