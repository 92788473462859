define("admin/models/point-of-interest", ["exports", "ember-data", "admin/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PointOfInterest;
  PointOfInterest = _emberData.default.Model.extend();
  var _default = _exports.default = PointOfInterest;
});