define("admin/components/shot-list/shot-type-form", ["exports", "admin/data/shot-type/area-of-interest", "admin/data/shot-type/camera-requirements", "admin/data/shot-type/camera-sensor", "admin/data/shot-type/file-type", "admin/data/shot-type/level-of-detail", "admin/data/shot-type/pattern", "admin/data/shot-type/shot-template-types", "admin/data/shot-type/time-of-day", "admin/data/shot-type/asset-type", "admin/config/environment", "admin/validations/shot-type", "ember-changeset-validations", "ember-changeset", "admin/utils/ember-changeset", "admin/mixins/post-process-options", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response", "admin/data/allowed-extensions"], function (_exports, _areaOfInterest, _cameraRequirements, _cameraSensor, _fileType, _levelOfDetail, _pattern, _shotTemplateTypes, _timeOfDay, _assetType, _environment, _shotType, _emberChangesetValidations, _emberChangeset, _emberChangeset2, _postProcessOptions, _sendErrorToSentry, _getErrorMessageFromResponse, _allowedExtensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_postProcessOptions.default, {
    sessionAccount: Ember.inject.service(),
    areaOfInterestOptions: _areaOfInterest.default,
    cameraRequirementOptions: _cameraRequirements.default,
    cameraSensorOptions: _cameraSensor.default,
    levelOfDetailOptions: _levelOfDetail.default,
    patternOptions: _pattern.default,
    shotTemplateTypeOptions: _shotTemplateTypes.default,
    timeOfDayOptions: _timeOfDay.default,
    assetTypes: _assetType.default,
    objectPostProcesses: Ember.computed.reads('shotType.post_processes'),
    storePostProcesses: Ember.computed.reads('postProcesses'),
    postProcessLabel: 'nameLong',
    sampleImageUploadExtensions: _allowedExtensions.baseList.join(','),
    machineLearningAnalysisProcessOptions: Ember.computed('postProcesses', function () {
      return this.get('postProcesses').filterBy('process_type', 'ml_analysis').map(postProcess => {
        return Ember.Object.create({
          label: postProcess.name,
          value: postProcess
        });
      });
    }),
    imagesProcessing: Ember.computed("shotType.{sample_images,sample_images.@each.processing}", function () {
      return this.get('shotType.sample_images').filterBy('processing');
    }),
    galleryImages: Ember.computed('shotType.sample_images', function () {
      return this.get('shotType.sample_images').map(image => {
        if (image.get('processing_status') !== 'ready') {
          image.set('processing', true);
        }
        if (!image.get('processing')) {
          return this.buildGalleryImages(image);
        }
      }).sortBy('record.source_type', 'record.id:desc');
    }),
    // Plupload fails on init at test env
    uploaderCanBeShown: Ember.computed('shotType.id', function () {
      return _environment.default.environment !== 'test' && Ember.isPresent(this.get('shotType.id'));
    }),
    buildGalleryImages(image) {
      return {
        src: image.get('version_urls.medium_1024'),
        msrc: image.get('version_urls.small_640'),
        w: 1024,
        h: image.get('height') / image.get('width') * 1024,
        title: image.get('name'),
        record: image
      };
    },
    maxFiles: Ember.computed('shotType.sample_images.length', function () {
      return Math.max(0, 9 - this.get('shotType.sample_images.length'));
    }),
    fileTypeOptions: Ember.computed('changeset.asset_type', function () {
      switch (this.changeset.get('asset_type')) {
        case 'video':
          return _fileType.default.video;
        case 'image':
          return _fileType.default.image;
        default:
          return _fileType.default.default;
      }
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('changeset', new _emberChangeset.default(this.get('shotType'), (0, _emberChangesetValidations.default)(_shotType.default), _shotType.default));
      this.initProcessType();
    },
    changeFileType(value) {
      this.changeset.set('file_type', value);
    },
    toggleSelectedPostProcess(post_process) {
      if (this.changeset.get('post_processes').includes(post_process)) {
        (0, _emberChangeset2.removeFromHasMany)(this.get('changeset'), 'post_processes', post_process);
      } else {
        (0, _emberChangeset2.addToHasMany)(this.get('changeset'), 'post_processes', post_process);
      }
    },
    actions: {
      submit() {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.changeset.save().then(() => {
              this.flashMessages.success('Shot Type was saved!');
            }).catch(response => {
              (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
              this.flashMessages.error('Failed to save Shot Type');
            });
          }
        });
      },
      deleteAsset(asset, proxyAsset) {
        if (window.confirm('Are you sure? This will permanently remove this asset.')) {
          const {
            id
          } = asset;
          return Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/shot_types/${this.changeset.get('id')}/sample_images/${id}`,
            headers: this.get('sessionAccount.headers'),
            type: 'DELETE',
            dataType: 'json'
          }).then(() => {
            if (proxyAsset) {
              this.get('galleryImages').removeObject(proxyAsset);
            }
            return this.changeset.get('sample_images').removeObject(this.changeset.get('sample_images').findBy('id', id));
          }, function () {
            console.log('could not delete');
            return alert('There was an issue deleting this asset: ', id);
          });
        }
      },
      refreshPage() {
        return location.reload();
      },
      changeAssetType(value) {
        if (value === 'video') {
          this.unsetChangesetProcessTypes(this.changeset);
          if (!_fileType.videoTypes.includes(this.get('changeset.file_type'))) {
            this.changeFileType(null);
          }
        } else if (value === 'image' && !_fileType.imageTypes.includes(this.get('changeset.file_type'))) {
          this.changeFileType(null);
        }
        return this.set('changeset.asset_type', value);
      },
      changeProcessType(value) {
        return this.setChangesetProcessType(this.get('changeset'), value);
      },
      changeFileTypeAction(value) {
        return this.changeFileType(value);
      },
      removeAsset(uploader, file) {
        return this.removeAsset(uploader, file);
      },
      addAsset(file) {
        return this.onfileadd(file, {
          "shot_type_id": this.get('shotType.id')
        }, this.get('shotType'));
      },
      onstartupload(uploader) {
        return this.startUpload(uploader);
      },
      removeShotType() {
        return this.removeShotTypeAction();
      },
      togglePostProcess(post_process) {
        this.toggleSelectedPostProcess(post_process);
      }
    }
  });
});