define("admin/components/missions/line-item", ["exports", "admin/data/line-item-reasons", "admin/services/titleizer"], function (_exports, _lineItemReasons, _titleizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['line-item']
  });
});