define("admin/components/capture-groups/pilot-edit-modal", ["exports", "ember-changeset", "ember-changeset-validations", "admin/validations/capture-group-pilot"], function (_exports, _emberChangeset, _emberChangesetValidations, _captureGroupPilot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    changeset: null,
    laborTypeValues: [{
      name: 'Not Selected',
      value: ''
    }, {
      name: 'Per Asset',
      value: 'per_asset'
    }, {
      name: 'Per Day',
      value: 'per_day'
    }],
    didReceiveAttrs: function () {
      this.set('changeset', new _emberChangeset.default(this.get('cg_pilot'), (0, _emberChangesetValidations.default)(_captureGroupPilot.default), _captureGroupPilot.default));
      if (!this.get('changeset.currency')) {
        this.get('changeset').set('currency', this.get('defaultCurrency'));
      }
    },
    currenciesForSelect: Ember.computed('currencies', function () {
      return this.get('currencies').filterBy('payout_allowed', true).addObject(this.get('cg_pilot.currency')).compact();
    }),
    laborTypeObserver: Ember.observer('changeset.change.estimated_labor_payout_type', function () {
      if (!this.get('changeset').get('estimated_labor_payout_type')) {
        this.get('changeset').set('estimated_labor_payout_amount', null);
      }
      this.get('changeset').validate('estimated_labor_payout_amount');
    }),
    actions: {
      currencyChange: function (currencyCode) {
        this.get('changeset').set('currency', this.get('currencies').find(currency => currency.code === currencyCode));
      },
      close: function () {
        this.closeAction();
      },
      save: function () {
        if (this.get('changeset.isValid')) {
          this.get('changeset').save().then(() => this.closeAction(), error => {
            console.log(error);
          });
        }
      }
    }
  });
});