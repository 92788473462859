define("admin/components/mission/assets/full-view-shot", ["exports", "admin/components/mission/assets/shot-base"], function (_exports, _shotBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shotBase.default.extend({
    classNames: ['mission-assets-shot-full'],
    actions: {
      goToAsset(asset, index) {
        this.goToAsset(asset, index);
      }
    }
  });
});