define("admin/helpers/is-payout-deletable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isPayoutDeletable = isPayoutDeletable;
  function isPayoutDeletable([status]) {
    if (status === 'created' || status === 'autopay_onboarding' || status === 'awaiting_top_up' || status === 'queued_for_payment') {
      return true;
    } else {
      return false;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(isPayoutDeletable);
});