define("admin/components/client-details-view", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientDetailsViewComponent;
  ClientDetailsViewComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    showDetails: Ember.computed('client', function () {
      return this.get('client') != null;
    }),
    clientLoginParams: Ember.computed('missionNumber', function () {
      return {
        client_email: this.get('client.email'),
        redirect_route: 'missions.show',
        mission_id: this.get('missionNumber')
      };
    })
  });
  var _default = _exports.default = ClientDetailsViewComponent;
});