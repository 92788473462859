define("admin/authorizers/devise", ["exports", "ember-simple-auth/authorizers/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeviseAuthorizer;
  DeviseAuthorizer = _devise.default.extend();
  var _default = _exports.default = DeviseAuthorizer;
});