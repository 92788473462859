define("admin/mirage/factories/subscription-offer", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    name: 'MK_Test',
    default_price: 0.3912e4,
    duration: _faker.default.random.number({
      min: 1,
      max: 12
    }),
    valid_for: 18,
    number: _faker.default.random.number({
      min: 100000,
      max: 10000000
    }),
    active: true,
    description: 'ABC',
    status: 'created',
    currency: (0, _emberCliMirage.association)(),
    organization: (0, _emberCliMirage.association)(),
    subscriptions: (0, _emberCliMirage.hasMany)('subscription'),
    withSubscriptions: (0, _emberCliMirage.trait)({
      afterCreate(subscriptionOffer, server) {
        server.createList('subscription', 3, {
          subscriptionOffer
        });
      }
    })
  });
});