define("admin/controllers/clients/organization", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    notFoundClients: '',
    saveState: 'initial',
    activeTab: 'packages',
    sortedClients: Ember.computed('model.organization.clients.@each.is_organization_owner', function () {
      const owners = this.get('model.organization.clients').filter(client => client.get('is_organization_owner')).sortBy('fullName');
      const notOwners = this.get('model.organization.clients').filter(client => !client.get('is_organization_owner')).sortBy('fullName');
      return owners.concat(notOwners);
    }),
    sortedOrganizationPackages: Ember.computed('model.organization.packages.length', function () {
      return this.get('model.organization.packages').toArray().sortBy('vertical.short_name', 'position', 'price', 'name');
    }),
    actions: {
      setTab(newTab) {
        return this.set('activeTab', newTab);
      },
      openAddPackageModal() {
        let model = this.get('model');
        delete model["package"];
        model["package"] = model.organization.get('packages').createRecord({
          currency: model.currencies.findBy('code', 'USD')
        });
        return this.send('openModal', 'clients.organization.modal-package', model);
      },
      openEditOrganization() {
        const model = this.get('model');
        return this.send('openModal', 'clients.organization.modal', model);
      },
      addNewClients() {
        this.set('notFoundClients', '');
        const clients = this.get('newClients');
        if (clients) {
          this.set('saveState', 'inProgress');
          this.set('newClients', null);
          const clientsArray = clients.replace(/ /g, '').split(',');
          clientsArray.forEach(client => {
            if (this.get('model.organization.clients').getEach('email').indexOf(client) < 0) {
              return Ember.$.ajax({
                url: _environment.default.api.host + "/v1/admin/organizations/" + this.get('model.organization.id') + "/add_client",
                type: 'POST',
                dataType: 'json',
                headers: this.get('sessionAccount.headers'),
                data: {
                  email: client
                }
              }).then(response => {
                this.get('model.organization.store').findRecord('client', response.data.id).then(client => {
                  return client.set('added', true);
                });
              }, response => {
                if (response.status === 422) {
                  const errors = response.responseJSON.errors;
                  return window.alert("Cannot save the client: " + errors.getEach('detail').join('; '));
                } else {
                  return this.send('addClientToListOfMissingClients', client, response);
                }
              });
            }
          });
          Ember.run.later(() => {
            if (this.get('notFoundClients.length') === 0) {
              this.set('saveState', 'success');
            } else {
              this.set('saveState', 'error');
            }
            Ember.run.later(() => {
              this.set('saveState', 'initial');
            }, 1500);
          }, 500);
        } else {
          return Ember.$("button.btnSubmit").removeClass('clicked');
        }
      },
      addClientToListOfMissingClients(client, response) {
        if (response.status !== 404) {
          return;
        }
        if (this.get('notFoundClients.length')) {
          return this.set('notFoundClients', this.get('notFoundClients') + (", " + client));
        } else {
          return this.set('notFoundClients', client);
        }
      },
      goToClientPage(clientId) {
        return this.transitionToRoute('clients.client', clientId);
      }
    }
  });
});