define("admin/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RadioButtonComponent;
  RadioButtonComponent = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],
    htmlChecked: Ember.computed('value', 'checked', function () {
      return this.get('value') === this.get('checked');
    }),
    change: function () {
      return this.set('checked', this.get('value'));
    },
    updateElementValue: Ember.observer('htmlChecked', function () {
      return Ember.run.next(this, function () {
        return this.$().prop('checked', this.get('htmlChecked'));
      });
    })
  });
  var _default = _exports.default = RadioButtonComponent;
});