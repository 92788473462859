define("admin/controllers/pilots/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    hideFilter: false,
    showBadgeOnboardingModal: false,
    badges: [],
    selectedPilots: [],
    selectedDrones: [],
    selectedCameras: [],
    selectedDevices: [],
    selectedLicenses: [],
    selectedPilotEquipment: [],
    selectedBadges: [],
    selectPilotBadgeStatuses: [],
    queryParams: ['q', 'distance', 'statuses[]', 'lat', 'lon', 'drone_ids[]', 'camera_ids[]', 'device_ids[]', 'license_ids[]', 'pilot_equipment_ids[]', 'pilot_badge_status_ids', 'pilot_badge_badge_ids', 'pilot_badge_include', 'pilot_without_badges'],
    sortProperties: ['distance:asc', 'fullName:asc'],
    sortedPilots: Ember.computed.sort('model', 'sortProperties'),
    q: null,
    distance: null,
    statuses: null,
    lat: null,
    lon: null,
    'drone_ids[]': [],
    'camera_ids[]': [],
    'device_ids[]': [],
    'license_ids[]': [],
    'pilot_equipment_ids[]': [],
    'pilot_badge_badge_ids': [],
    'pilot_badge_status_ids': [],
    'pilot_badge_include': true,
    'pilot_without_badges': false,
    activeModal: null,
    checkedAllOnPage: false,
    selectedAllPilots: false,
    deselectAllDisabled: true,
    selectedDronesObserver: Ember.observer('selectedDrones', function () {
      this.set('drone_ids[]', this.get('selectedDrones').getEach('id'));
    }),
    selectedCamerasObserver: Ember.observer('selectedCameras', function () {
      this.set('camera_ids[]', this.get('selectedCameras').getEach('id'));
    }),
    selectedDevicesObserver: Ember.observer('selectedDevices', function () {
      this.set('device_ids[]', this.get('selectedDevices').getEach('id'));
    }),
    selectedLicensesObserver: Ember.observer('selectedLicenses', function () {
      this.set('license_ids[]', this.get('selectedLicenses').getEach('id'));
    }),
    selectedPilotEquipmentObserver: Ember.observer('selectedPilotEquipment', function () {
      this.set('pilot_equipment_ids[]', this.get('selectedPilotEquipment').getEach('id'));
    }),
    selectedBadgesObserver: Ember.observer('selectedBadges', function () {
      this.set('pilot_badge_badge_ids', this.get('selectedBadges').getEach('id').uniq());
    }),
    selectPilotBadgeStatusesObserver: Ember.observer('selectPilotBadgeStatuses', function () {
      this.set('pilot_badge_status_ids', this.get('selectPilotBadgeStatuses').getEach('id').uniq());
    }),
    selectPilotBadgeIncludeObserver: Ember.observer('pilotBadgeInclude', function () {
      this.set('pilot_badge_include', this.get('pilotBadgeInclude'));
    }),
    pilotWithoutBadgesObserver: Ember.observer('pilotWithoutBadges', function () {
      this.set('pilot_without_badges', this.get('pilotWithoutBadges'));
    }),
    actions: {
      toggleBadgeOnboardingModal() {
        this.set('showBadgeOnboardingModal', !this.get('showBadgeOnboardingModal'));
      },
      checkAllOnPage() {
        this.set('deselectAllDisabled', false);
        this.set('checkedAllOnPage', true);
        Ember.$('tbody.loadable input[type="checkbox"]').prop('checked', true);
        this.set('selectedPilots', this.get('model').toArray());
      },
      selectAllPilots() {
        this.set('deselectAllDisabled', false);
        this.set('selectedAllPilots', true);
        Ember.$('tbody.loadable input[type="checkbox"]').prop('checked', true);
      },
      deselectAll() {
        this.set('deselectAllDisabled', true);
        this.set('selectedAllPilots', false);
        this.set('checkedAllOnPage', false);
        Ember.$('tbody.loadable input[type="checkbox"]').prop('checked', false);
        this.set('selectedPilots', []);
      },
      sortBy(sortProperties) {
        this.set('sortProperties', [sortProperties]);
      },
      clearQuery() {
        this.set('q', null);
      },
      toggleFilter() {
        this.set('hideFilter', !this.get('hideFilter'));
      }
    }
  });
});