define("admin/models/waiver", ["exports", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    number: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    class_type: _emberData.default.attr('string'),
    external_airport_id: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('date'),
    expires_at: _emberData.default.attr('date'),
    url: _emberData.default.attr('string'),
    waiver_extensions: _emberData.default.hasMany('waiver-extension', {
      async: false
    }),
    mission: _emberData.default.belongsTo('mission'),
    notes: _emberData.default.attr('string'),
    airport_name: _emberData.default.attr('string'),
    validations: {
      external_airport_id: {
        presence: true
      },
      class_type: {
        presence: true
      },
      status: {
        presence: true
      },
      number: {
        presence: true
      }
    }
  });
});