define("admin/controllers/clients/client/missions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientsClientMissionsEditController;
  ClientsClientMissionsEditController = Ember.Controller.extend({
    mission: Ember.computed('model.mission', function () {
      return this.get('model.mission');
    }),
    statusesForSelect: Ember.computed('model.mission_statuses', function () {
      return this.get('model.mission_statuses').map(function (item, index, enumerable) {
        return Em.Object.create(item);
      });
    }),
    selectedStatus: Ember.computed('model.mission.status', function () {
      return this.get('statusesForSelect').findBy('value', this.get('model.mission.status'));
    }),
    missionPayoutsReverse: Ember.computed('model.mission.payouts.[]', function () {
      return this.get('model.mission.payouts').toArray().reverse();
    })
  });
  var _default = _exports.default = ClientsClientMissionsEditController;
});