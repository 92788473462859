define("admin/helpers/format-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([minutes]) {
      let hour = parseInt(minutes) / 60;
      return moment(new Date().setHours(hour)).format('hA');
    }
  });
});