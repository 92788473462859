define("admin/data/shot-type/camera-sensor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    value: 'thermal_camera',
    name: 'Thermal'
  }, {
    value: 'lidar',
    name: 'LiDAR'
  }];
});