define("admin/components/add-drone-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddDroneModalComponent;
  AddDroneModalComponent = Ember.Component.extend({
    currentRecord: null,
    droneTypes: [{
      value: 'quadcopter',
      name: 'Quadcopter'
    }, {
      value: 'hexacopter',
      name: 'Hexacopter'
    }, {
      value: 'octocopter',
      name: 'Octocopter'
    }, {
      value: 'fixed_wing',
      name: 'Fixed Wing'
    }],
    actions: {
      close: function () {
        return this.get('close')();
      },
      selectManufacturer: function (selection) {
        console.log(selection.get('name'));
        this.set('currentRecord.drone_manufacturer', selection);
        return console.log(this.get('currentRecord.drone_manufacturer.name'));
      },
      submit: function () {
        var record;
        record = this.get('currentRecord');
        if (!record.validate()) {
          return;
        }
        return this.action();
      },
      addStockCamera: function (value) {
        this.get('currentRecord.stock_cameras').popObject();
        return this.get('currentRecord.stock_cameras').pushObject(value);
      },
      addOptionalCamera: function (value) {
        return this.get('currentRecord.optional_cameras').pushObject(value);
      },
      removeStockCamera: function (value) {
        return this.get('currentRecord.stock_cameras').removeObject(value);
      },
      removeOptionalCamera: function (value) {
        return this.get('currentRecord.optional_cameras').removeObject(value);
      }
    }
  });
  var _default = _exports.default = AddDroneModalComponent;
});