define("admin/components/searches/not-matched-queries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    notMatchedQueryLines: Ember.A([]),
    notMatchedQueryLinesVisibile: false,
    queryLinesCount: 0,
    copyButtonText: 'Copy',
    actions: {
      toggleNotMatchedQueryLinesVisibile() {
        this.toggleProperty('notMatchedQueryLinesVisibile');
      },
      copyNotMatchedLines() {
        navigator.clipboard.writeText(this.notMatchedQueryLines.join('\n'));
        this.set('copyButtonText', 'Copied!');
        setTimeout(() => {
          this.set('copyButtonText', 'Copy');
        }, '2000');
      }
    }
  });
});