define("admin/components/site-inspections/modals/bulk-action/add-to-new-capture-group-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    captureGroupName: null,
    assignIsDisabled: Ember.computed('captureGroupName', function () {
      return !this.get('captureGroupName');
    }),
    actions: {
      changeCaptureGroupName(event) {
        this.set('captureGroupName', event.target.value);
      },
      addInspectionsToNewCaptureGroup() {
        this.get('onSubmit')(this.get('captureGroupName'));
      },
      switchToExistingCaptureGroupModal() {
        this.onClose();
        this.showBulkExistingToNewCaptureGroupModal();
      },
      closeModal() {
        this.set('captureGroupName', null);
        this.onClose();
      }
    }
  });
});