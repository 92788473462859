define("admin/templates/components/missions/modals/bulk-assets-remove-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7hrY6N2/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"appendedClasses\",\"fullScreen\"],[\"modal-wrapper\",true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"remove-assets-modal\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"close-modal\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/X.svg\"],[14,\"alt\",\"X\"],[14,\"role\",\"button\"],[15,\"onclick\",[34,0]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ver-300 w-full\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ver-150\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title color-g900\"],[12],[2,\"Do you want to Remove selected assets?\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"body-2-regular color-g400\"],[12],[1,[35,1,[\"length\"]]],[2,\" selected asset(s) will be permanently removed.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"hor-300\"],[12],[2,\"\\n        \"],[8,\"button\",[[16,\"onclick\",[34,2]]],[[\"@size\"],[\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Remove\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"button\",[[16,\"onclick\",[34,0]]],[[\"@type\",\"@size\"],[\"text\",\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Cancel\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onClose\",\"selectedAssetsNumbers\",\"onSubmit\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/missions/modals/bulk-assets-remove-modal.hbs"
    }
  });
});