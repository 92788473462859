define("admin/components/shot-list/shot-types-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      toggleSelectedType: function (type) {
        this.get('saveShotAction')(this.get('selectedType')).then(saved => {
          if (saved) {
            this.set('selectedType', type);
          }
        });
      }
    }
  });
});