define("admin/components/dispatch-zone-autocomplete", ["exports", "admin/config/environment", "admin/mixins/google-map-init-mixin"], function (_exports, _environment, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    errorState: false,
    didInsertElement() {
      this._super(...arguments);
    },
    initMap() {
      this.setupAutocomplete();
    },
    inputClass: Ember.computed('error', function () {
      var output = 'place-autocomplete--input form-control';
      if (this.get('error')) {
        output = output + ' error';
      }
      return output;
    }),
    setupAutocomplete() {
      const input = this.$('.place-autocomplete--input')[0];
      const searchBox = new google.maps.places.SearchBox(input);
      input.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      return searchBox.addListener('places_changed', () => {
        this.set('message', null);
        if (searchBox.getPlaces().length === 0) {
          alert('We could not find a location based on your search.');
          return;
        }
        const place = searchBox.getPlaces()[0];
        try {
          const location = place['geometry']['location'];
          this.get('model').set('latitude', location.lat());
          this.get('model').set('longitude', location.lng());
          this.get('model').set('name', place.formatted_address);
          console.log(location.lat(), location.lng());
          this.set('error', this.get('model.latitude') == null || this.get('model.longitude') == null);
        } catch (err) {
          console.log("error", err);
          this.set('error', true);
        }
      });
    }
  });
});