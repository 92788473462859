define("admin/adapters/shot", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShotAdapter;
  ShotAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    urlTemplate: '{+namespace}/{parentType}/{parentId}/shots{/id}',
    urlSegments: {
      parentType: function (type, id, snapshot, query) {
        if (snapshot.belongsTo('mission', {
          id: true
        })) {
          return 'missions';
        } else if (snapshot.belongsTo('shot_list', {
          id: true
        })) {
          return 'shot_lists';
        }
      },
      parentId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('mission', {
          id: true
        }) || snapshot.belongsTo('shot_list', {
          id: true
        });
      }
    },
    downloadImages: function (shot) {
      var url;
      url = _environment.default.api.host + "/v1/admin/missions/" + shot.get('mission.id') + "/shots/" + shot.id + "/download_all_images";
      return this.ajax(url, 'POST');
    },
    promoteAssets: function (shot) {
      var url;
      url = _environment.default.api.host + "/v1/admin/missions/" + shot.get('mission.id') + "/shots/" + shot.id + "/promote_all_assets";
      return this.ajax(url, 'POST');
    },
    unpromoteAssets: function (shot) {
      var url;
      url = _environment.default.api.host + "/v1/admin/missions/" + shot.get('mission.id') + "/shots/" + shot.id + "/unpromote_all_assets";
      return this.ajax(url, 'POST');
    }
  });
  var _default = _exports.default = ShotAdapter;
});