define("admin/models/payout-line-item", ["exports", "@ember-data/model", "admin/data/line-item-reasons"], function (_exports, _model, _lineItemReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    payout: (0, _model.belongsTo)('payout', {
      async: false
    }),
    payoutable_type: (0, _model.attr)('string'),
    payoutable_id: (0, _model.attr)('string'),
    payoutable: (0, _model.belongsTo)('payoutable', {
      async: false,
      polymorphic: true,
      inverse: 'payouts'
    }),
    // Fields for adapter class
    saved_payoutable_type: (0, _model.attr)('string'),
    saved_payoutable_id: (0, _model.attr)('string'),
    reason: (0, _model.attr)('string'),
    amount: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    humanizedReason: Ember.computed('reason', function () {
      const reason = this.get('reason');
      const selectedOption = _lineItemReasons.payoutLineItemReasons.find(function (option) {
        return option['value'] === reason;
      });
      return selectedOption && selectedOption['label'];
    })
  });
});