define("admin/adapters/deliverable-work-type", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: _environment.default.api.namespace + '/admin'
  });
});