define("admin/serializers/client", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientSerializer;
  ClientSerializer = _application.default.extend();
  var _default = _exports.default = ClientSerializer;
});