define("admin/components/mission-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    notScheduled: Ember.computed('selectedStart', 'selectedEnd', function () {
      return !this.get('selectedStart') || !this.get('selectedEnd');
    }),
    didInsertElement() {
      this._super(...arguments);
      this.addObserver('selectedStart', function () {
        this.saveMissionScheduleForUpdate();
      });
      this.addObserver('selectedEnd', function () {
        this.saveMissionScheduleForUpdate();
      });
    },
    saveMissionScheduleForUpdate() {
      if (this.get('scheduleError')) {
        this.set('scheduleError', false);
      }
      const data = {
        'mission_scheduled_at_start': this.get('selectedStart'),
        'mission_scheduled_at_end': this.get('selectedEnd'),
        'reason_id': this.get('model.mission.store').peekAll('reschedule-reason').filterBy('slug', 'initial_schedule')[0].id
      };
      this.set('model.scheduleDataForUpdate', data);
    },
    actions: {
      rescheduleFlight: function () {
        return this.rescheduleFlightModal();
      },
      chooseSchedule: function () {
        this.set('model.busy', true);
        const promises = [];
        [1, 2, 3, 4, 5, 6, 7].forEach(day => {
          promises.push(this.get('model.mission').loadCapacityEstimations(day, this.get('sessionAccount.headers')));
        });
        Ember.RSVP.all(promises).then(array => {
          const availability_slots = [];
          array.map(function (response) {
            availability_slots.push(response[0]);
          });
          this.set('model.busy', false);
          return this.showCapacityModal(availability_slots);
        }, error => {
          this.set('model.busy', false);
          if (error.errors) {
            let message = error.errors[0].detail;
            this.set('scheduleError', message);
            return alert(message);
          }
        });
      }
    }
  });
});