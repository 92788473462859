define("admin/controllers/capture-groups/pilots-assign-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      searchPilots: function (query, model) {
        this.store.query('pilot', query).then(function (response) {
          Ember.set(model, 'meta', response.meta);
          Ember.set(model, 'pilots', response.toArray());
        }, function (error) {
          alert('There was an issue retrieving pilots');
        });
      },
      closeAction: function () {
        this.send('closeModal');
      }
    }
  });
});