define("admin/utils/uploader/s3", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    multipartComplete(file) {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/s3_uploads/complete_multipart_s3_upload`,
        type: 'POST',
        dataType: 'json',
        headers: file.get('authHeaders'),
        data: {
          file_name: file.get('upload_file_name'),
          original_created_at: file.get('date_created'),
          original_file_name: file.name,
          parts: file.get('parts').uniq(),
          shot_id: file.get('shot_id'),
          bucket_id: file.get('bucket_id'),
          bucket_type_slug: file.get('bucket_type_slug'),
          size: file.size,
          type: `${file.get('file_type')}s`,
          upload_id: file.get('upload_id'),
          shot_type_id: file.get('shot_type_id'),
          mission_id: file.get('mission_id')
        }
      });
    },
    multipartUpload(uploadUrl, chunk) {
      return Ember.$.ajax({
        url: uploadUrl,
        type: 'PUT',
        data: chunk,
        processData: false,
        contentType: false
      });
    },
    setupMultipartUpload(file) {
      const uploadID = file.get('upload_id'); // will exist if previously set up
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/s3_uploads/setup_multipart_s3_upload`,
        type: 'POST',
        dataType: 'json',
        headers: file.get('authHeaders'),
        data: {
          type: `${file.get('file_type')}s`,
          // Sake generates unique name on first setup call, then we reuse along with upload ID
          ...(uploadID ? {
            unique_file_name: file.get('upload_file_name'),
            upload_id: uploadID
          } : {
            file_name: file.get('sanitizedName')
          }),
          shot_id: file.get('shot_id'),
          shot_type_id: file.get('shot_type_id'),
          mission_id: file.get('mission_id'),
          start_part: file.get('start_part'),
          end_part: file.get('end_part')
        }
      });
    }
  });
});