define("admin/helpers/format-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDistance = _exports.default = void 0;
  var FormatDistanceHelper, formatDistance;
  _exports.formatDistance = formatDistance = function (arg) {
    var distance, milename, ref;
    distance = arg[0], milename = (ref = arg[1]) != null ? ref : "mi";
    if (distance != null) {
      distance = Number.parseFloat(distance);
      return distance.toFixed(2) + " " + milename;
    }
  };
  FormatDistanceHelper = Ember.Helper.helper(formatDistance);
  var _default = _exports.default = FormatDistanceHelper;
});