define("admin/helpers/rating-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([rating]) {
      return [1, 2, 3, 4, 5].map(function (index) {
        if (rating >= index) {
          return 'yellow_star.svg';
        } else {
          return 'white_empty_star.svg';
        }
      });
    }
  });
});