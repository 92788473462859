define("admin/controllers/subscription-offers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    createModalOpen: false,
    actions: {
      toggleCreateModal() {
        this.toggleProperty('createModalOpen');
        return null;
      }
    }
  });
});