define("admin/templates/components/mission-weather-forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0SZCOT8I",
    "block": "{\"symbols\":[],\"statements\":[[10,\"b\"],[12],[1,[30,[36,6],[[35,5],\"ha\"],[[\"timeZone\"],[[35,4,[\"location\",\"timezone_id\"]]]]]],[13],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"icon\",\"width\"],[[35,2],75]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,8],[[35,8],\"F\"],null]],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,1]],[2,\"% rain\"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"% cloudy\"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[34,9]],[2,\" mph wind\"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"cloudCover\",\"precipProbability\",\"icon\",\"weather-icon\",\"mission\",\"time\",\"moment-format\",\"if\",\"temperature\",\"windSpeed\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-weather-forecast.hbs"
    }
  });
});