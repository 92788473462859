define("admin/mirage/models/drone-camera", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    drone_manufacturer: (0, _emberCliMirage.belongsTo)('drone-manufacturer'),
    pilotDrone: (0, _emberCliMirage.belongsTo)('pilot-drone'),
    badge: (0, _emberCliMirage.belongsTo)('badge')
  });
});