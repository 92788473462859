define("admin/helpers/lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lowercase = lowercase;
  function lowercase([str]) {
    if (!str) {
      return null;
    }
    return str.toLowerCase();
  }
  var _default = _exports.default = Ember.Helper.helper(lowercase);
});