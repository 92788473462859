define("admin/mixins/post-process-options", ["exports", "admin/models/post-process", "admin/utils/ember-changeset"], function (_exports, _postProcess, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    chosenProcessType: _postProcess.NOT_SET,
    // for radio option
    _previousProcessType: _postProcess.NOT_SET,
    // to remove previous value

    initProcessType: function () {
      if (!!this.get('objectPostProcesses')) {
        const currentProcessType = this.get('objectPostProcesses').filter(type => ['stitching', 'measurements'].includes(type.process_type)).sortBy('id').map(postProcess => postProcess.id).join(',') || _postProcess.NOT_SET;
        this.set('chosenProcessType', currentProcessType);
        this.set('_previousProcessType', currentProcessType);
      }
    },
    postProcessTypeOptions: Ember.computed('storePostProcesses', function () {
      let options = Ember.A([]);
      const notSetName = this.postProcessLabel === 'nameShort' ? 'None' : 'Without Processing';
      options.pushObject(Ember.Object.create({
        label: notSetName,
        value: _postProcess.NOT_SET
      }));
      ['stitching', 'measurements'].forEach(processType => {
        options.pushObjects(this.get('postProcesses').filterBy('process_type', processType).map(postProcess => {
          return Ember.Object.create({
            label: postProcess.get(this.postProcessLabel),
            value: postProcess.id
          });
        }));
      });
      const roofMeasurementsAndRbgOrthoStitching = this.get('postProcesses').filter(t => ['roof_measurements', 'rgb_ortho_stitching'].includes(t.slug)).sortBy('id');
      const combinedNameFunction = this.postProcessLabel === 'nameShort' ? 'nameShort' : 'name';
      let combinedName = roofMeasurementsAndRbgOrthoStitching.map(type => type.get(combinedNameFunction)).join(' + ');
      if (this.postProcessLabel === 'nameLong') combinedName += ' (if it’s checked on the mission)';
      options.pushObject(Ember.Object.create({
        label: combinedName,
        value: roofMeasurementsAndRbgOrthoStitching.map(type => type.id).join(',')
      }));
      return options;
    }),
    unsetChangesetProcessTypes: function (changeset) {
      this.clearChangesetProcessTypes(changeset);
      this.set('_previousProcessType', _postProcess.NOT_SET);
      this.set('chosenProcessType', _postProcess.NOT_SET);
    },
    clearChangesetProcessTypes: function (changeset) {
      const previousIds = this.get('_previousProcessType').split(',');
      const previousPostProcesses = this.get('storePostProcesses').filter(type => previousIds.includes(type.id));
      previousPostProcesses.forEach(previousPostProcess => (0, _emberChangeset.removeFromHasMany)(changeset, 'post_processes', previousPostProcess));
    },
    setChangesetProcessType: function (changeset, value) {
      this.clearChangesetProcessTypes(changeset);
      if (value !== _postProcess.NOT_SET) {
        const nextIds = value.split(',');
        const nextPostProcesses = this.get('storePostProcesses').filter(type => nextIds.includes(type.id));
        nextPostProcesses.forEach(nextPostProcess => (0, _emberChangeset.addToHasMany)(changeset, 'post_processes', nextPostProcess));
      }
      return this.set('_previousProcessType', value);
    },
    unsetObjectProcessTypes: function (object) {
      this.clearObjectProcessTypes(object);
      this.set('_previousProcessType', _postProcess.NOT_SET);
      this.set('chosenProcessType', _postProcess.NOT_SET);
    },
    clearObjectProcessTypes: function (object) {
      const previousIds = this.get('_previousProcessType').split(',');
      const previousPostProcesses = this.get('storePostProcesses').filter(type => previousIds.includes(type.id));
      previousPostProcesses.forEach(previousPostProcess => object.get('post_processes').removeObject(previousPostProcess));
    },
    setObjectProcessType: function (object, value) {
      this.clearObjectProcessTypes(object);
      if (value !== _postProcess.NOT_SET) {
        const nextIds = value.split(',');
        const nextPostProcesses = this.get('storePostProcesses').filter(type => nextIds.includes(type.id));
        nextPostProcesses.forEach(nextPostProcess => object.get('post_processes').pushObject(nextPostProcess));
      }
      return this.set('_previousProcessType', value);
    }
  });
});