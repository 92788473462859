define("admin/templates/inventories-lists/dropships-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W0LgeB6j",
    "block": "{\"symbols\":[\"dropshipGroup\"],\"statements\":[[10,\"div\"],[14,0,\"dropships-list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropships-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dropships-page-title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Dropshipped Items\"],[13],[10,\"div\"],[14,0,\"note\"],[12],[2,\"(Active list)\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dropship-item\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"dropships.new\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"btn-primary btn-md\"],[12],[2,\"\\n          Dropship Item\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"group\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inventory/dropship-items\",\"link-to\",\"dropshipsByPilots\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/inventories-lists/dropships-list.hbs"
    }
  });
});