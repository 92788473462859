define("admin/mirage/factories/site-inspection-pilot", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    user: (0, _emberCliMirage.association)(),
    afterCreate(siteInspectionPilot, server) {
      if (siteInspectionPilot.user === undefined) {
        siteInspectionPilot.update({
          user: server.create('pilot')
        });
      }
    }
  });
});