define("admin/adapters/standby-hours-line-item", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/site_inspections/{siteInspectionId}/standby_hours_line_items',
    urlSegments: {
      siteInspectionId: function (type, id, snapshot, query) {
        if (query === undefined) {
          return snapshot.belongsTo('site_inspection', {
            id: true
          });
        } else {
          return query.siteInspectionId;
        }
      }
    },
    listStandbyHoursRejectionReasons: (headers, data) => {
      const url = `${_environment.default.api.host}/v1/public/standby_hours_rejection_reasons`;
      const xhrOptions = {
        url,
        data,
        method: 'GET',
        headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    updateStandbyHoursLineItem: (headers, siteInspectionNumber, lineItemId, data) => {
      const url = `${_environment.default.api.host}/v1/admin/site_inspections/${siteInspectionNumber}/standby_hours_line_items/${lineItemId}`;
      const xhrOptions = {
        url,
        data,
        method: 'PATCH',
        headers
      };
      return Ember.$.ajax(xhrOptions);
    }
  });
});