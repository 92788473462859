define("admin/controllers/partner-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PartnerIntegrationController;
  PartnerIntegrationController = Ember.Controller.extend({
    sortedFlightApps: Em.computed('model.flight_apps', function () {
      return this.get('model.flight_apps').sortBy('name');
    }),
    actions: {
      toggleSection: function (section) {
        section.set('opened', !section.get('opened'));
        if (!section.get('activeTab')) {
          section.set('activeTab', 0);
        }
        return false;
      },
      toggleContent: function (tab, section) {
        return section.set('activeTab', tab);
      },
      saveFlightApp: function (flightApp) {
        this.set('selectedApp', flightApp);
        return flightApp.save().then(function (_this) {
          return function () {
            flightApp.set('saved', true);
            return Em.run.later(function () {
              return _this.set('selectedApp.saved', false);
            }, 1500);
          };
        }(this));
      }
    }
  });
  var _default = _exports.default = PartnerIntegrationController;
});