define("admin/templates/components/errors-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hc7TySKM",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/errors-for.hbs"
    }
  });
});