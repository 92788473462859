define("admin/components/mission/assets/full-view-asset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FullViewAsset = Ember.Component.extend({
    imagePreviewPath: Ember.computed('asset', function () {
      const asset = this.get('asset');
      return `/missions/${asset.get('mission.id')}?shot=${asset.get('shot.id')}&image_preview=${asset.id}`;
    })
  });
  var _default = _exports.default = FullViewAsset;
});