define("admin/components/main-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MainNavigationComponent;
  MainNavigationComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    tagName: 'nav',
    classNames: ["navbar", "navbar-default"],
    highlightedMissionGroups: Ember.computed('router.currentPath', function () {
      return ['site-inspections.index', 'site-inspections.show', 'capture-groups.index', 'capture-groups.show'].includes(this.get('router.currentPath'));
    }),
    highlightedMissions: Ember.computed('router.currentPath', function () {
      return ['missions.index', 'missions.edit', 'missions.map'].includes(this.get('router.currentPath'));
    }),
    highlightedTrainingMissions: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath').includes('training_missions');
    }),
    highlightedClients: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath').includes('clients');
    }),
    highlightedPilots: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath').includes('pilots');
    }),
    highlightedAdminTools: Ember.computed('router.currentPath', function () {
      return this.get('router.currentPath').includes('waivers') || this.get('router.currentPath').includes('shot_lists') || this.get('router.currentPath').includes('partner_integration') || this.get('router.currentPath').includes('equipment') || this.get('router.currentPath').includes('badges') || this.get('router.currentPath').includes('inventory.inventory-list');
    }),
    click: function (e) {
      var el;
      el = this.$('.navbar-collapse.in');
      if (el.length > 0) {
        return el.collapse('hide');
      }
    },
    actions: {
      invalidateSession: function () {
        return this.get('session').invalidate();
      }
    }
  });
  var _default = _exports.default = MainNavigationComponent;
});