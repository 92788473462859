define("admin/data/reschedule-options-with-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Clear date and cancel pilot",
    value: 'clearDateCancelPilot'
  }, {
    label: "Set a new date/time",
    value: 'reschedule'
  }, {
    label: "Set a date/time from pilot availability",
    value: 'pilotAvailability'
  }];
});