define("admin/routes/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FourOhFourRoute;
  FourOhFourRoute = Ember.Route.extend({
    redirect: function () {
      var url;
      url = this.router.location.formatURL('/four-oh-four');
      if (window.location.pathname !== url) {
        return this.transitionTo('/four-oh-four');
      }
    }
  });
  var _default = _exports.default = FourOhFourRoute;
});