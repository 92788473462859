define("admin/models/collaborator", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Collaborator;
  Collaborator = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission', {
      async: false
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + " " + this.get('last_name');
    })
  });
  var _default = _exports.default = Collaborator;
});