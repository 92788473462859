define("admin/helpers/format-with-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([value, placeholder]) {
      if (value && value !== 'Invalid date') {
        return value;
      } else if (placeholder) {
        return placeholder;
      } else {
        return '-';
      }
    }
  });
});