define("admin/controllers/capture-groups/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      openPilotAssignmentModal() {
        return this.send("openModal", "capture-groups.pilots-assign-modal", this.get("model"));
      }
    }
  });
});