define("admin/templates/components/integrations/mindflash-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "s8UDNhD1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"close\",\"appendedClasses\",\"translucentOverlay\",\"hasOverlay\",\"fullScreen\"],[[30,[36,0],[[32,0],\"close\"],null],\"full-screen-modal mindflash-modal\",true,true,\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"full-screen-modal-toprow\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"Assign Onboarding\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\" close-modal\"],[12],[2,\"\\n        \"],[11,\"img\"],[24,\"src\",\"/assets/images/X.svg\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[1,[35,1,[\"length\"]]],[2,\" pilots selected to take the online courses below:\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"turquoise-button pull-right\"],[4,[38,0],[[32,0],\"invitePilots\"],null],[12],[2,\"INVITE\"],[13],[2,\"\\n        \"],[11,\"div\"],[24,0,\"turquoise-border-button pull-right\"],[4,[38,0],[[32,0],\"cancelInvitePilots\"],null],[12],[2,\"CANCEL\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"gray-divider\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        mindflash courses\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedPilots\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/components/integrations/mindflash-modal.hbs"
    }
  });
});