define("admin/helpers/titleize", ["exports", "admin/services/titleizer"], function (_exports, _titleizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.titleize = titleize;
  function titleize([string]) {
    return (0, _titleizer.default)(string);
  }
  var _default = _exports.default = Ember.Helper.helper(titleize);
});