define("admin/templates/components/input-checkbox-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SK5dN6YA",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[1,[34,3]],[2,\" \"],[1,[30,[36,6],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[35,5],[35,4]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modelErrors\",\"-track-array\",\"each\",\"placeholder\",\"disabled\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/input-checkbox-inplace-edit.hbs"
    }
  });
});