define("admin/components/mission/payouts/related-payout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    displayDetails: false,
    actions: {
      toggleDetailsVisibility: function () {
        if (this.get('payout.payout_line_items').length) this.toggleProperty('displayDetails');
      },
      deletePayout: function () {
        this.get('deletePayout')(this.get('payout'));
      }
    }
  });
});