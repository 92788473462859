define("admin/components/pilot-approval-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotApprovalButtonComponent;
  PilotApprovalButtonComponent = Ember.Component.extend({
    tagName: 'button',
    type: 'submit',
    name: 'approve',
    classNames: ['btn', 'btn-primary', 'pilot-action-btn', 'btn-sm'],
    classNameBindings: ['isDisabled:hide'],
    isDisabled: Ember.computed('model.status', function () {
      return this.get('model.status') === 'approved';
    }),
    click: function (e) {
      var model, reject_status;
      e.preventDefault();
      e.stopPropagation();
      model = this.get('model');
      reject_status = model.get('reject');
      model.set('approve', true);
      model.set('reject', false);
      return model.save().then(null, function (response) {
        model.set('approve', false);
        model.set('reject', reject_status);
        return alert('There was a problem approving this pilot');
      });
    }
  });
  var _default = _exports.default = PilotApprovalButtonComponent;
});