define("admin/mirage/factories/subscription", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `${i + 1}`;
    },
    name: 'MK_regression testing_new subscription 02_edit',
    price: 0.3912e4,
    duration: _faker.default.random.number({
      min: 1,
      max: 12
    }),
    valid_from: _faker.default.date.past(2),
    valid_until: _faker.default.date.recent(),
    valid_for: 18,
    number: _faker.default.random.number({
      min: 100000,
      max: 10000000
    }),
    po_number: null,
    created_at: _faker.default.date.recent(),
    updated_at: _faker.default.date.recent(),
    description: 'ABC',
    status: 'created',
    salesforce_opportunity_id: null,
    currency: (0, _emberCliMirage.association)(),
    organization: (0, _emberCliMirage.association)(),
    subscriptionOffer: (0, _emberCliMirage.association)()
  });
});