define("admin/models/subscription-offer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    number: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    duration: (0, _model.attr)('number'),
    valid_for: (0, _model.attr)('number'),
    active: (0, _model.attr)('boolean'),
    default_price: (0, _model.attr)('number'),
    created_at: (0, _model.attr)('date'),
    organization: (0, _model.belongsTo)('organization'),
    states: (0, _model.hasMany)('state'),
    currency: (0, _model.belongsTo)('currency')
  });
});