define("admin/models/dispatch-zone", ["exports", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    name: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    distance: _emberData.default.attr('number'),
    hasLatLng: Ember.computed('latitude', 'longitude', function () {
      console.log(this.get('latitude'), this.get('longitude'));
      return this.get('latitude') !== undefined && this.get('longitude') !== undefined;
    }),
    validations: {
      name: {
        presence: true
      },
      latitude: {
        presence: true
      },
      longitude: {
        presence: true
      },
      distance: {
        presence: true
      }
    }
  });
});