define("admin/routes/site-inspections/show", ["exports", "admin/mixins/css-class-namespace"], function (_exports, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_cssClassNamespace.default, {
    store: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        workOrder: this.store.findRecord('site-inspection', params.site_inspection_id),
        presetSearchFilters: this.store.query('preset-search-filter', {}),
        drones: this.store.findAll('drone'),
        devices: this.store.findAll('device'),
        equipment: this.store.findAll('pilot_equipment'),
        licenses: this.store.findAll('license'),
        badges: this.store.query('badge', {}),
        payout: this.store.createRecord('payout'),
        pilots: [],
        operationReps: this.store.query('admin', {
          role: 'operation_rep'
        }),
        currencies: this.store.query('currency', {
          all: true
        }),
        standbyHoursReasons: this.store.findAll('standby-hours-reason'),
        standbyHoursLineItems: this.store.query('standby-hours-line-item', {
          siteInspectionId: params.site_inspection_id
        }),
        invoiceLineItems: this.store.query('invoice-line-item', {
          siteInspectionId: params.site_inspection_id
        }),
        // to recalculate pilot standby hours line items
        standbyHoursLineItemsObserve: this.store.peekAll('standby-hours-line-item'),
        legalEntities: this.store.findAll('legal-entity'),
        defaultCurrencyCode: 'USD',
        lineItemRejectionReasons: this.store.adapterFor('standby-hours-line-item').listStandbyHoursRejectionReasons(this.get('sessionAccount.headers')).then(response => response.data)
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      model.workOrder.loadOnsiteContact();
      model.stripeLegalEntities = model.legalEntities.filter(legalEntity => legalEntity.get('stripe_enabled'));
      if (controller.activeTab === 'activityLogs') model.workOrder.loadActivityLogs();
    }
  });
});