define("admin/validations/shot-list", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    'name': [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 75,
      message: 'Please enter s descriptive name. 3-75 characters.'
    })]
  };
});