define("admin/mirage/factories/comprehensive-report-purchase", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return `10${i}`;
    },
    number: _faker.default.random.number({
      min: 100000,
      max: 10000000
    }),
    price: 0.1365e6,
    status: 'created',
    currency: (0, _emberCliMirage.association)(),
    organization: (0, _emberCliMirage.association)()
  });
});