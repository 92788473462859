define("admin/templates/components/pilot-device-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "C4JzaB0D",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pilot-section-col\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"normal-field-label\"],[12],[2,\"Device \"],[1,[34,0]],[2,\":\"],[13],[2,\"\\n    \"],[1,[35,1,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"displayedIndex\",\"device\"]}",
    "meta": {
      "moduleName": "admin/templates/components/pilot-device-view.hbs"
    }
  });
});