define("admin/data/shot-type/time-of-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'Night time',
    value: 'night_time'
  }, {
    label: 'Day time',
    value: 'day_time'
  }];
});