define("admin/models/client-available-package", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    client: (0, _model.belongsTo)('client', {
      async: false
    }),
    package: (0, _model.belongsTo)('package', {
      async: false
    })
  });
});