define("admin/validations/payout", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    amount: (0, _validators.validateNumber)({
      gt: 0
    }),
    pilot: (0, _validators.validatePresence)(true),
    currency: (0, _validators.validatePresence)(true),
    saved_payoutable_id: (0, _validators.validatePresence)(true),
    saved_payoutable_type: (0, _validators.validateInclusion)({
      list: ['site_inspections', 'missions', 'capture_groups']
    }),
    payout_to: (0, _validators.validateInclusion)({
      list: ['pilot', 'editor', 'analyst']
    })
  };
});