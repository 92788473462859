define("admin/adapters/subscription", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/organizations/{organizationId}/subscriptions{/id}',
    sessionAccount: Ember.inject.service(),
    urlSegments: {
      organizationId: function (type, id, snapshot, query) {
        if (query === undefined) {
          return snapshot.belongsTo('organization', {
            id: true
          });
        } else {
          return query.organization_id;
        }
      },
      id: function (type, id, snapshot, query) {
        if (query === undefined) {
          return snapshot.id;
        }
        return query.id;
      }
    },
    updateStatus: (headers, data, subscription) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/organizations/${subscription.get('organization.id')}/subscriptions/${subscription.get('id')}/status`;
      const xhrOptions = {
        url: url,
        data: data,
        method: 'PATCH',
        headers: headers
      };
      return Ember.$.ajax(xhrOptions);
    }
  });
});