define("admin/components/scroll-event-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement() {
      const anchorIds = this.get('anchorIds');
      let anchors = {};
      let currentAnchorTop = 0;
      let lastAnchorTop = 0;
      let repeatedTopIndex = 0;
      anchorIds.forEach(anchorId => {
        const element = this.$(document).find(`#${anchorId}`);
        if (!element.offset()) {
          return;
        }
        currentAnchorTop = element.offset().top;
        if (lastAnchorTop === currentAnchorTop) {
          repeatedTopIndex += 1;
          currentAnchorTop += 10 * repeatedTopIndex;
        } else {
          repeatedTopIndex = 0;
          lastAnchorTop = currentAnchorTop;
        }
        anchors[anchorId] = currentAnchorTop;
      });
      const rootElement = this.$(document).find(`.${this.get('rootElementClass')}`);
      const rootElementOffset = rootElement.offset().top;
      this.$(document).on('scroll', event => {
        const container = this.$(event.target);
        if (!container) {
          return;
        }
        const closestAnchorBlock = this.closestAnchor(anchors, container.scrollTop() + rootElementOffset);
        this.setActiveSubTab(closestAnchorBlock);
      });
    },
    closestAnchor(anchors, goal) {
      const closesValue = Object.values(anchors).reduce((prev, curr) => {
        return goal >= curr && goal >= prev ? curr : prev;
      });
      return this.getKeyByValue(anchors, closesValue);
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }
  });
});