define("admin/data/shot-type/file-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.videoTypes = _exports.imageTypes = _exports.default = void 0;
  const videoTypes = _exports.videoTypes = ['mov', 'mp4'];
  const imageTypes = _exports.imageTypes = ['dng', 'jpeg', 'rjpeg'];
  var _default = _exports.default = {
    default: [{
      label: '.DNG',
      value: 'dng'
    }, {
      label: '.JPEG',
      value: 'jpeg'
    }, {
      label: '.RJPEG',
      value: 'rjpeg'
    }, {
      label: '.Mov',
      value: 'mov'
    }, {
      label: '.MP4',
      value: 'mp4'
    }],
    video: [{
      label: '.Mov',
      value: 'mov'
    }, {
      label: '.MP4',
      value: 'mp4'
    }],
    image: [{
      label: '.DNG',
      value: 'dng'
    }, {
      label: '.JPEG',
      value: 'jpeg'
    }, {
      label: '.RJPEG',
      value: 'rjpeg'
    }]
  };
});