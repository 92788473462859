define("admin/components/inventory/inventory-model-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    searchValue: null,
    searchResults: [],
    isSearchResultPresent: Ember.computed('searchResults.[]', function () {
      return Ember.isPresent(this.get('searchResults'));
    }),
    debouncer: null,
    actions: {
      selectExistsOrCreateNewRelationBy(searchValue) {
        const totalMatch = this.get('modelsList').findBy('name', searchValue);

        // Remove new created items from array
        this.set('modelsList', this.get('modelsList').filterBy('isNew', false));
        if (totalMatch) {
          this.selectRelationAction(totalMatch);
        } else {
          this.newRelationAction(searchValue);
        }
      },
      debouncedUpdateSearchValue() {
        Ember.run.cancel(this.get('debouncer'));
        const debounceCall = Ember.run.debounce(() => {
          this.send('updateSearchValue');
        }, 300);
        this.set('debouncer', debounceCall);
      },
      updateSearchValue() {
        const searchValue = this.get('searchValue');
        this.send('selectExistsOrCreateNewRelationBy', searchValue);
        if (Ember.isPresent(searchValue)) {
          this.set('searchResults', this.get('modelsList').filter(item => {
            let regexp = new RegExp(this.get('searchValue'), 'i');
            return item.name.match(regexp);
          }).slice(0, 5));
        } else {
          this.set('searchResults', []);
        }
      },
      selectItem(searchValue) {
        this.send('selectExistsOrCreateNewRelationBy', searchValue);
        this.set('searchValue', searchValue);
        this.set('searchResults', []);
      },
      outsideClick() {
        this.set('searchResults', []);
      }
    }
  });
});