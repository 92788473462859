define("admin/routes/missions/index", ["exports", "ember-infinity/mixins/route", "admin/mixins/mission-share"], function (_exports, _route, _missionShare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, _missionShare.default, {
    session: Ember.inject.service(),
    model(params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      params['mission_types'] = 'client';
      return Ember.RSVP.hash({
        missions: this.infinityModel('mission', params).catch(data => {
          // NOTE: Trying to handle CORS PreflightMissingAllowOriginHeader error
          // when search query is too long and url is over 8,192 bytes
          if (data.message.includes('aborted')) {
            alert('Failed to fetch missions. Looks like you reached search query length limit (~8k characters)');
            const cutQuery = this.controller.get('qInput')?.substring(0, 8000);
            this.controller.set('qInput', cutQuery);
            this.controller.set('q', cutQuery);
            return;
          }
        }),
        countries: this.store.findAll('country'),
        countryCodesParams: params.country_codes,
        currencies: this.store.query('currency', {
          all: true
        }),
        currentAdmin: this.get('store').findRecord('admin', this.get('session.data.authenticated.id')),
        cancellationReasons: this.get('store').query('cancellation-reason', {}),
        captureGroups: this.infinityModel('capture-group')
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('countries', model.countries);
      controller.set('country_codes', model.countryCodesParams);
      if (controller.get('qInput.length') === 0) controller.set('qInput', controller.get('q'));
      if (model.currentAdmin && !model.currentAdmin.canSeeBulkUpdater) {
        return this.transitionTo({
          queryParams: {
            bulk_update_enabled: null
          }
        });
      }
    },
    serializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    queryParams: {
      q: {
        refreshModel: true
      },
      attr: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      start_daterange: {
        refreshModel: true
      },
      end_daterange: {
        refreshModel: true
      },
      vertical: {
        refreshModel: true
      },
      lat: {
        refreshModel: true
      },
      lon: {
        refreshModel: true
      },
      distance: {
        refreshModel: true
      },
      assets_late: {
        refreshModel: true
      },
      on_hold: {
        refreshModel: true
      },
      reshoot: {
        refreshModel: true
      },
      sort_attribute: {
        refreshModel: true
      },
      sort_order: {
        refreshModel: true
      },
      include_client_ids: {
        refreshModel: true
      },
      exclude_client_ids: {
        refreshModel: true
      },
      bulk_update_enabled: {
        refreshModel: true
      },
      'country_codes': {
        refreshModel: true,
        type: 'array'
      }
    }
  });
});