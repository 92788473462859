define("admin/models/laanc-flight", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LaancFlight;
  LaancFlight = _emberData.default.Model.extend({
    authorizations: _emberData.default.attr('')
  });
  var _default = _exports.default = LaancFlight;
});