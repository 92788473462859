define("admin/data/mission_statuses_transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [
  // to Invoice Needed
  {
    from: 'created',
    to: 'invoice_needed'
  }, {
    from: 'assets_classified',
    to: 'invoice_needed'
  }, {
    from: 'in_production',
    to: 'invoice_needed'
  }, {
    from: 'awaiting_payment',
    to: 'invoice_needed'
  },
  // to Invoiced
  {
    from: 'awaiting_payment',
    to: 'invoiced'
  }, {
    from: 'invoice_needed',
    to: 'invoiced'
  },
  // to Complete
  {
    from: 'awaiting_payment',
    to: 'complete'
  }, {
    from: 'invoiced',
    to: 'complete'
  },
  // to Canceled
  {
    from: 'created',
    to: 'canceled'
  }, {
    from: 'scheduling_call',
    to: 'canceled'
  }, {
    from: 'confirmed',
    to: 'canceled'
  }, {
    from: 'pilots_notified',
    to: 'canceled'
  }, {
    from: 'pilot_accepted',
    to: 'canceled'
  }];
});