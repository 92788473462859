define("admin/templates/components/daterange-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7RWuwTNp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"daterange-filter \",[34,0]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"subtitle\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"date-pickers\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"search-field schedule\"],[12],[2,\"From\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"autocomplete\"],[\"text\",\"form-control disabled start-day-filter\",\"MM/DD/YYYY\",[35,3],false]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"search-field schedule\"],[12],[2,\"To\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"autocomplete\"],[\"text\",\"form-control disabled end-day-filter\",\"MM/DD/YYYY\",[35,5],false]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"buttons-block\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-primary\"],[4,[38,6],[[32,0],\"setDateRange\"],null],[12],[2,\"Filter\"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"clear-link\"],[4,[38,6],[[32,0],\"clearDateRange\"],null],[12],[2,\"Clear\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"class\",\"title\",\"subtitle\",\"startDate\",\"input\",\"endDate\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/daterange-filter.hbs"
    }
  });
});