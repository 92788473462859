define("admin/templates/components/modal-dialog-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "x4E9VJdn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"modal-overlay\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"modal-content clearfix  \",[34,1]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"appendedClasses\"]}",
    "meta": {
      "moduleName": "admin/templates/components/modal-dialog-custom.hbs"
    }
  });
});