define("admin/components/equipment-index-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EquipmentIndexListComponent;
  EquipmentIndexListComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    collapsed: true,
    showModal: false,
    currentRecord: null,
    cachedRecords: [],
    recordType: null,
    didInsertElement: function () {
      return this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
    },
    allRecords: Ember.computed('model', 'model.[]', 'cachedRecords.[]', function () {
      var temp;
      temp = [];
      temp.pushObjects(this.cachedRecords);
      return temp.pushObjects(this.get('model').toArray());
    }),
    actions: {
      toggleCollapsed: function () {
        return this.set('collapsed', !this.get('collapsed'));
      },
      toggleModal: function () {
        return this.set('showModal', !this.get('showModal'));
      },
      saveRecord: function () {
        var _this, addToCache, record;
        _this = this;
        record = this.get('currentRecord');
        addToCache = record.get('isNew');
        return record.save().then(function (response) {
          if (addToCache) {
            return _this.get('cachedRecords').pushObject(response);
          }
        });
      },
      cloneRecord: function (record) {
        this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
        this.get('currentRecord').setProperties(record.serialize().data.attributes);
        return this.set('showModal', true);
      },
      editRecord: function (record) {
        this.set('currentRecord', record);
        return this.set('showModal', true);
      },
      addRecord: function () {
        this.set('currentRecord', this.get('store').createRecord(this.get('recordType')));
        return this.set('showModal', true);
      }
    }
  });
  var _default = _exports.default = EquipmentIndexListComponent;
});