define("admin/components/input-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputInplaceEditComponent;
  InputInplaceEditComponent = Ember.Component.extend({
    classNames: ['form-group-inplace'],
    click: function () {
      if (!this.get('isEditing')) {
        this.set('isEditing', true);
        return Ember.run.scheduleOnce('afterRender', this, this.focusTextField);
      }
    },
    focusTextField: function () {
      var $input, val;
      $input = this.$('input, textarea');
      val = $input.val();
      $input.focus();
      $input.val('');
      return $input.val(val);
    },
    flashSuccess: function () {
      return this.$('.inline-input').addClass('success');
    },
    actions: {
      save: function () {
        return this.get('model').save().then(function (_this) {
          return function () {
            if (_this.$('input').attr('type') === "password") {
              _this.set('value', null);
            }
            _this.set('isEditing', false);
            return Ember.run.scheduleOnce('afterRender', _this, _this.flashSuccess);
          };
        }(this), function (_this) {
          return function (response) {
            return _this.$('input').addClass('error');
          };
        }(this));
      }
    }
  });
  var _default = _exports.default = InputInplaceEditComponent;
});