define("admin/adapters/activity-log", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/admin',
    queryUrlTemplate: '{+namespace}/{logableType}/{logableId}/activity_logs',
    urlSegments: {
      logableType: function (type, id, snapshot, query) {
        if (query.missionId) {
          return 'missions';
        } else {
          return 'site_inspections';
        }
      },
      logableId: function (type, id, snapshot, query) {
        if (query.missionId) {
          return query.missionId;
        } else {
          return query.siteInspectionId;
        }
      }
    }
  });
});