define("admin/routes/admins/edit", ["exports", "admin/config/environment", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _environment, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    store: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        admin: this.store.findRecord('admin', params.admin_id)
      });
    }
  });
});