define("admin/helpers/format-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPrice = formatPrice;
  const defaultCurrency = Ember.Object.create({
    code: 'USD',
    symbol: '$'
  });
  function formatPrice([cents, currency]) {
    // backwards compatibility for existing nulls that data migrations haven't cleaned up
    const renderCurrency = currency?.get('symbol') && currency?.get('code') ? currency : defaultCurrency;
    if (cents === null || cents === undefined) {
      return '-';
    } else {
      let sign = '';
      if (cents < 0) sign = '−';
      const displayValue = Math.abs(cents) / 100;

      // format as: −$50 USD or -$50.10 USD
      return `${sign}${renderCurrency.get('symbol')}${displayValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${renderCurrency.get('code')}`;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(formatPrice);
});