define("admin/components/checkbox-aria", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CheckboxAriaComponent;
  CheckboxAriaComponent = Ember.Component.extend({
    init: function () {
      this._super();
      if (this.get('includeManufacturer')) {
        return this.send("checkPresenceInSelection", "full_name");
      } else if (this.get('byAttribute')) {
        return this.send("checkIfInSelection", this.get('byAttribute'));
      } else {
        return this.send("checkPresenceInSelection", "name");
      }
    },
    selectionsObserver: Ember.observer('selections', 'selections.length', function () {
      if (this.get('includeManufacturer')) {
        return this.send("checkPresenceInSelection", "full_name");
      } else if (this.get('byAttribute')) {
        return this.send("checkIfInSelection", this.get('byAttribute'));
      } else {
        return this.send("checkPresenceInSelection", "name");
      }
    }),
    label: Ember.computed('model', 'includeManufacturer', function () {
      if (this.get('includeManufacturer')) {
        return this.get('model.full_name');
      } else if (this.get('labelSource')) {
        return this.get("model." + this.get('labelSource'));
      } else {
        return this.get('model.name');
      }
    }),
    formattedLabel: Ember.computed('label', function () {
      if (this.get('label') == null) {
        return 'untitled';
      }
      return this.get('label').toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');
    }),
    valueChanged: Ember.observer('checked', function () {
      var checked, selections, target;
      checked = this.get('checked');
      selections = this.get('selections');
      if (!selections) {
        selections = [];
      }
      if (checked) {
        if (typeof selections.then === 'function') {
          selections.pushObject(this.get('model'));
        } else {
          selections = selections.without('a b c d').toArray();
          if (this.get('selectionsIsPlainArray')) {
            selections.push(this.get("model." + this.get('byAttribute')));
          } else {
            selections.push(this.get('model'));
          }
        }
      } else {
        if (this.get('selectionsIsPlainArray')) {
          target = this.get("model." + this.get('byAttribute'));
          selections = selections.reject(function (value) {
            return value === target;
          });
        } else if (typeof selections.then === 'function') {
          selections.removeObject(this.get('model'));
        } else {
          if (this.get('model.id') === void 0) {
            selections = selections.without(this.get('model'));
          } else {
            selections = selections.rejectBy('id', this.get('model.id'));
          }
        }
      }
      return this.set('selections', selections);
    }),
    actions: {
      checkIfInSelection: function (byAttribute) {
        var list, searchValue;
        searchValue = this.get("model." + byAttribute);
        if (this.get('selectionsIsPlainArray')) {
          list = this.get("selections").compact();
        } else {
          list = this.get("selections").compact().getEach(byAttribute);
        }
        if (list.indexOf(searchValue) >= 0) {
          return this.set("checked", true);
        }
      },
      checkPresenceInSelection: function (byAttribute) {
        if (this.get("selections").compact().getEach(byAttribute).indexOf(this.get("label")) >= 0) {
          return this.set("checked", true);
        }
      }
    }
  });
  var _default = _exports.default = CheckboxAriaComponent;
});