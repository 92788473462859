define("admin/components/site-inspections/add-payout", ["exports", "ember-changeset", "ember-changeset-validations", "admin/validations/payout", "admin/data/line-item-reasons"], function (_exports, _emberChangeset, _emberChangesetValidations, _payout, _lineItemReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const [STRIPE_PROCESSOR, PAYPAL_PROCESSOR, OTHER_PROCESSOR] = ['Stripe', 'Paypal', 'Other'];
  var _default = _exports.default = Ember.Component.extend({
    elementId: 'add_payouts',
    classNames: ['work-order-section', 'payout'],
    store: Ember.inject.service(),
    workOrder: null,
    payout: null,
    lineItem: null,
    changeset: null,
    showLineItemForm: false,
    showSendPayoutModal: false,
    showConfirmPayoutModal: false,
    payoutLineItemReasonOptions: _lineItemReasons.payoutLineItemReasons,
    maxPayoutItemAmount: 3000,
    disabled: true,
    noAssignedPilots: Ember.computed.equal('workOrder.site_inspection_pilots.length', 0),
    noAssignedLegalEntity: Ember.computed('workOrder.legal_entity', function () {
      return !this.get('workOrder.legal_entity');
    }),
    payoutSaveDisabled: Ember.computed('payout.payout_line_items.length', function () {
      return this.noAssignedPilots || this.get('payout.payout_line_items.length') == 0;
    }),
    payoutLineItemAddDisabled: Ember.computed('lineItem.{reason,amount}', function () {
      return !(this.get('lineItem.reason') && this.get('lineItem.amount') && this.get('lineItem.amount') > 0);
    }),
    siteInspectionPilots: Ember.computed('workOrder.site_inspection_pilots', function () {
      return this.get('workOrder.site_inspection_pilots').filter(sip => !sip.isDeleted);
    }),
    pilots: Ember.computed('workOrder.site_inspection_pilots', function () {
      return this.siteInspectionPilots.mapBy('user');
    }),
    selectedSiteInspectionPilot: Ember.computed('changeset.pilot', function () {
      return this.siteInspectionPilots.find(sip => sip.get('user.id') === this.get('changeset.pilot.id'));
    }),
    selectedPilotHasNoSetUpStripeAccount: Ember.computed('changeset.{pilot.id,payment_processor_value}', function () {
      const payoutPaymentProcessor = this.changeset.get('payment_processor');
      if (!this.get('changeset.pilot') || payoutPaymentProcessor !== STRIPE_PROCESSOR) return false;
      const _this = this;
      const selectedLegalEntity = this.changeset.get('legal_entity');
      const pilotActiveStripeAccount = this.get('workOrder.stripe_accounts').find(function (stripeAccount) {
        if (stripeAccount.get('pilot_id') === _this.get('changeset.pilot.id') && stripeAccount.get('legal_entity_id') === Number(selectedLegalEntity.get('id')) && stripeAccount.get('autopay_enabled')) {
          return stripeAccount;
        }
      });
      return !pilotActiveStripeAccount;
    }),
    selectedPaymentMethod: Ember.computed('changeset.payment_processor_value', function () {
      const paymentProcesor = this.get('changeset.payment_processor');
      if (paymentProcesor !== STRIPE_PROCESSOR) return paymentProcesor;
      return `${STRIPE_PROCESSOR} (${this.changeset.get('legal_entity.name')})`;
    }),
    paymentProcessorOptions: Ember.computed('changeset.pilot.id', function () {
      const otherOption = {
        value: OTHER_PROCESSOR,
        label: OTHER_PROCESSOR
      };
      if (!this.changeset) return [otherOption];
      const options = this.get('legalEntities').map(legalEntity => ({
        value: legalEntity.slug,
        label: `Stripe (${legalEntity.name})`
      }));
      // Show Paypal option for pilots who had filled `paypal_email` field
      const pilot = this.pilots.find(pilot => pilot.id == this.get('changeset.pilot.id'));
      if (pilot.paypal_email) {
        options.push({
          value: PAYPAL_PROCESSOR,
          label: PAYPAL_PROCESSOR
        });
      }
      options.push(otherOption);
      return options;
    }),
    defaultPaymentProcessorOption: Ember.computed('paymentProcessorOptions', 'workOrder.legal_entity', function () {
      const legalEntity = this.get('workOrder.legal_entity');
      return this.paymentProcessorOptions.find(o => o.value === legalEntity?.slug) || this.paymentProcessorOptions.at(-1);
    }),
    defaultPaymentProcessor: Ember.computed('defaultPaymentProcessorOption', function () {
      const legalEntitiesSlugs = this.get('legalEntities').map(legalEntity => legalEntity.slug);
      return legalEntitiesSlugs.includes(this.defaultPaymentProcessorOption.value) ? STRIPE_PROCESSOR : this.defaultPaymentProcessorOption.value;
    }),
    didReceiveAttrs() {
      this._super();
      this.set('disabled', this.get('noAssignedPilots') || this.get('noAssignedLegalEntity'));
      this.initNewPayout();
    },
    didUpdate() {
      this._super();
      this.set('disabled', this.get('noAssignedPilots') || this.get('noAssignedLegalEntity'));
    },
    initNewPayout: function () {
      if (this.get('noAssignedPilots')) return;
      const sip = this.get('workOrder.site_inspection_pilots.firstObject');
      this.set('payout', this.store.createRecord('payout', {
        payout_to: 'pilot',
        saved_payoutable_type: 'site_inspections',
        saved_payoutable_id: this.get('workOrder.id'),
        pilot: sip.get('user'),
        currency: sip.get('currency') || this.get('defaultCurrency'),
        amount: 0
      }));
      this.set('changeset', new _emberChangeset.default(this.get('payout'), (0, _emberChangesetValidations.default)(_payout.default), _payout.default));
      this.setDefaultLegalEntityAndPaymentProcessor();
    },
    legalEntityObserver: Ember.observer('workOrder.legal_entity', function () {
      if (!this.changeset) return;
      this.setDefaultLegalEntityAndPaymentProcessor();
    }),
    initLineItem: function () {
      const lineItemsCount = this.get('payout.payout_line_items.length');
      const initialReasonIndex = lineItemsCount >= _lineItemReasons.payoutLineItemReasons.length ? 0 : lineItemsCount % _lineItemReasons.payoutLineItemReasons.length;
      const initialReason = _lineItemReasons.payoutLineItemReasons[initialReasonIndex]['value'];
      this.set('lineItem', this.store.createRecord('payout_line_item', {
        saved_payoutable_type: 'site_inspections',
        saved_payoutable_id: this.get('workOrder.id'),
        reason: initialReason,
        amount: this.defaultLineItemAmount(initialReason)
      }));
    },
    addLineItem: function (continueAdding) {
      this.get('payout.payout_line_items').addObject(this.get('lineItem'));
      this.set('payout.amount', (this.get('payout.amount') || 0) + this.get('lineItem.amount'));
      if (continueAdding) {
        this.clearLineItem();
      } else {
        this.set('lineItem', null);
        this.initLineItem();
      }
    },
    confirmAddLineItem: function (continueAdding = true) {
      const amountPayoutItem = this.get('lineItem.amount');
      if (amountPayoutItem / 100 > this.get('maxPayoutItemAmount')) {
        this.set('showConfirmPayoutModal', true);
      } else {
        this.addLineItem(continueAdding);
      }
    },
    allowAddLineItem: function (continueAdding = true) {
      this.addLineItem(continueAdding);
      this.set('showConfirmPayoutModal', false);
      window.scrollTo(0, 700);
    },
    defaultLineItemAmount: function (reason) {
      const {
        user: pilotId,
        estimated_labor_payout_amount,
        estimated_labor_payout_type,
        estimated_standby_payout,
        estimated_mobilization_payout
      } = this.selectedSiteInspectionPilot.toJSON();
      if (reason === 'labor' && estimated_labor_payout_type === 'per_asset') {
        const missionsCount = this.completedMissionsByPilot[pilotId] || 0;
        return estimated_labor_payout_amount * missionsCount;
      } else if (reason === 'standby_time') {
        const totalTimeStandByHours = this.totalTimeStandByHoursLineItemsByPilot[pilotId] || 0;
        return estimated_standby_payout * totalTimeStandByHours;
      } else if (reason === 'mobilization') {
        return estimated_mobilization_payout;
      } else {
        return 0;
      }
    },
    completedMissionsByPilot: Ember.computed('workOrder.{missions,pilots}.[]', function () {
      return this.pilots.reduce((hash, pilot) => {
        hash[pilot.get('id')] = this.get('workOrder.missions').filter(mission => mission.get('assetsClassified') && mission.get('pilot.id') === pilot.get('id')).length;
        return hash;
      }, {});
    }),
    standByHoursLineItemsByPilot: Ember.computed('workOrder.standby_hours_line_items.length', function () {
      return this.pilots.reduce((hash, pilot) => {
        hash[pilot.get('id')] = pilot.get('standby_hours_line_items').filter(({
          site_inspection
        }) => site_inspection.get('id') === this.get('workOrder.id'));
        return hash;
      }, {});
    }),
    totalTimeStandByHoursLineItemsByPilot: Ember.computed('standByHoursLineItemsByPilot', function () {
      const hash = {};
      for (const pilotId in this.get('standByHoursLineItemsByPilot')) {
        hash[pilotId] = this.get('standByHoursLineItemsByPilot')[pilotId].filter(item => item.status === 'approved').reduce((sum, item) => sum + (item.get('periodInSeconds') || 0) / 3600, 0);
      }
      return hash;
    }),
    clearLineItem: function () {
      this.set('lineItem', null);
      this.set('showLineItemForm', false);
    },
    siteInspectionPilotCurrenciesObserver: Ember.observer('workOrder.site_inspection_pilots', 'workOrder.site_inspection_pilots.@each.currency', function () {
      // Ember returns deleted records in relation. Have to filter them
      const siteInspectionPilots = this.get('workOrder.site_inspection_pilots').filter(sip => !sip.isDeleted);
      if (siteInspectionPilots.length === 0) {
        this.set('changeset', null);
        this.set('lineItem', null);
      } else {
        if (this.changeset) {
          const selectedPilotId = this.changeset.get('pilot.id');
          const selectedPilot = siteInspectionPilots.find(sip => sip.get('user.id') === selectedPilotId);
          if (selectedPilot) {
            // selected pilot still assigned after site_inspection_pilots changed => update payout currency
            this.changeset.set('currency', this.pilotCurrency(selectedPilotId));
          } else {
            // previously selected pilot has been unassigned => set first pilot
            const newPilot = siteInspectionPilots.firstObject.get('user');
            this.setPayoutPilot(newPilot.id);
          }
        } else {
          this.initNewPayout();
        }
      }
    }),
    /* eslint-enable ember/no-observers */

    setPayoutPilot: function (pilotId) {
      let pilot = this.pilots.find(pilot => pilot.id == pilotId);
      this.changeset.set('pilot', pilot);
      this.changeset.set('currency', this.pilotCurrency(pilotId));
      this.setDefaultLegalEntityAndPaymentProcessor();
      if (this.get('lineItem')) {
        this.set('lineItem.amount', this.defaultLineItemAmount(this.get('lineItem.reason')));
      }
    },
    setPaymentMethod: function (paymentProcessor) {
      if (paymentProcessor === OTHER_PROCESSOR || paymentProcessor === PAYPAL_PROCESSOR) {
        this.changeset.set('legal_entity', null);
        this.changeset.set('payment_processor', paymentProcessor);
      } else {
        this.changeset.set('legal_entity', this.get('legalEntities').find(le => le.slug === paymentProcessor));
        this.changeset.set('payment_processor', STRIPE_PROCESSOR);
      }
      this.changeset.set('payment_processor_value', paymentProcessor);
    },
    setDefaultLegalEntityAndPaymentProcessor: function () {
      if (this.defaultPaymentProcessor === STRIPE_PROCESSOR) {
        this.changeset.set('legal_entity', this.get('workOrder.legal_entity'));
      }
      this.changeset.set('payment_processor', this.defaultPaymentProcessor);
      this.changeset.set('payment_processor_value', this.defaultPaymentProcessorOption.value);
    },
    pilotCurrency: function (pilotId) {
      const siteInspectionPilot = this.get('workOrder.site_inspection_pilots').find(sip => sip.get('user.id') === pilotId);
      if (!siteInspectionPilot) {
        return null;
      }
      return siteInspectionPilot.get('currency') || this.get('defaultCurrency');
    },
    actions: {
      updatePilot: function (pilotId) {
        this.setPayoutPilot(pilotId);
      },
      updatePaymentMethod: function (event) {
        this.setPaymentMethod(event);
      },
      updateReason: function (reason) {
        if (this.get('lineItem')) {
          this.set('lineItem.reason', reason);
          this.set('lineItem.amount', this.defaultLineItemAmount(reason));
        }
      },
      save: function () {
        const saveLineItemsInOrderCreated = lineItems => {
          if (lineItems.length > 0) {
            const lineItem = lineItems.shift();
            lineItem.save().then(() => saveLineItemsInOrderCreated(lineItems));
          }
        };
        // this gets set at actual time of payment
        if (this.get('changeset.payment_processor') !== OTHER_PROCESSOR) this.changeset.set('payment_processor', null);
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.get('changeset').save().then(() => {
              const lineItems = this.get('payout.payout_line_items').toArray();
              saveLineItemsInOrderCreated(lineItems);
              this.set('showSendPayoutModal', false);
              this.set('payout.newlyAdded', true);
              this.initNewPayout();
            });
          }
        });
      },
      clearPayout: function () {
        this.clearLineItem();
        this.initNewPayout();
      },
      showLineItemForm: function () {
        this.set('showLineItemForm', true);
        this.initLineItem();
      },
      addLineItem: function () {
        this.addLineItem();
      },
      confirmAddLineItem: function () {
        this.confirmAddLineItem();
      },
      allowAddLineItem: function () {
        this.allowAddLineItem();
      },
      closeConfirmationModal: function () {
        this.set('showConfirmPayoutModal', false);
        window.scrollTo(0, 700);
      },
      addAnotherLineItem: function () {
        this.addLineItem(false);
      },
      clearLineItem: function () {
        this.clearLineItem();
      },
      showModal: function () {
        this.set('showSendPayoutModal', true);
      },
      closeModal: function () {
        this.set('showSendPayoutModal', false);
      }
    }
  });
});