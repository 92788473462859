define("admin/data/mission_statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    value: 'created',
    name: 'Created'
  }, {
    value: 'scheduling_call',
    name: 'Scheduling Call'
  }, {
    value: 'confirmed',
    name: 'Confirmed'
  }, {
    value: 'pilots_notified',
    name: 'Pilots Notified'
  }, {
    value: 'pilot_accepted',
    name: 'Pilot Accepted'
  }, {
    value: 'flight_complete',
    name: 'Flight Complete'
  }, {
    value: 'assets_uploaded',
    name: 'Assets Uploaded'
  }, {
    value: 'processing_shots',
    name: 'Processing Shots'
  }, {
    value: 'assets_classified',
    name: 'Assets Classified'
  }, {
    value: 'in_production',
    name: 'In Production'
  }, {
    value: 'awaiting_payment',
    name: 'Awaiting Payment'
  }, {
    value: 'invoice_needed',
    name: 'Invoice Needed'
  }, {
    value: 'invoiced',
    name: 'Invoiced'
  }, {
    value: 'complete',
    name: 'Complete'
  }, {
    value: 'canceled',
    name: 'Canceled',
    separator: true
  }, {
    value: 'rejected',
    name: 'Rejected'
  },
  // enable this when backend support is added
  // { value: 'reshoot', name: 'Reshoot' },
  {
    value: 'unfulfilled',
    name: 'Unfulfilled'
  }];
});