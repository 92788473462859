define("admin/templates/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Eo4j4O9A",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\"],\"statements\":[[10,\"div\"],[14,0,\"flash-messages-container\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"flashMessages\",\"arrangedQueue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"flash\"],[[32,1]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,0],[[32,2,[\"flashType\"]],\"Success\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/images/checkmark_icon.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,2,[\"flashType\"]],\"Error\"],null],[30,[36,0],[[32,2,[\"flashType\"]],\"Danger\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/images/X-white.svg\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"      \"],[2,[32,3,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"either\",\"if\",\"flash-message\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/flash-messages.hbs"
    }
  });
});