define("admin/components/admins/edit", ["exports", "admin/data/admin_roles", "ember-changeset"], function (_exports, _admin_roles, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    allRoles: _admin_roles.default,
    init: function () {
      this._super();
      this.changeset = new _emberChangeset.default(this.get('model.admin'));
    },
    removeItem: function (value) {
      this.changeset.set('roles', this.get('changeset.roles').filter(selectedOption => selectedOption !== value));
    },
    addItem: function (value) {
      this.changeset.set('roles', this.get('changeset.roles').concat(value));
    },
    actions: {
      toggleRolesOption: function (value) {
        if (this.get('changeset.roles').indexOf(value) === -1) {
          this.addItem(value);
        } else {
          this.removeItem(value);
        }
      },
      save: function () {
        this.get('changeset').save();
      },
      cancel: function () {
        this.get('changeset').rollback();
      }
    }
  });
});