define("admin/components/pilot-reject-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotRejectButtonComponent;
  PilotRejectButtonComponent = Ember.Component.extend({
    tagName: 'button',
    type: 'submit',
    name: 'reject',
    classNames: ['btn', 'btn-danger', 'pilot-action-btn', 'btn-sm'],
    classNameBindings: ['isDisabled:hide'],
    isDisabled: Ember.computed('model.status', function () {
      return this.get('model.status') === 'rejected';
    }),
    click: function (e) {
      var approve_status, model;
      e.preventDefault();
      e.stopPropagation();
      model = this.get('model');
      approve_status = model.get('approve');
      model.set('reject', true);
      model.set('approve', false);
      return model.save().then(null, function (response) {
        model.set('reject', false);
        model.set('approve', approve_status);
        return alert('There was a problem rejecting this pilot');
      });
    }
  });
  var _default = _exports.default = PilotRejectButtonComponent;
});