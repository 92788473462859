define("admin/templates/components/mission/shots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "99HiDiD2",
    "block": "{\"symbols\":[\"shot\"],\"statements\":[[2,\"\\n\"],[10,\"ul\"],[14,0,\"shot-list\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,8],[[35,1,[\"length\"]],10],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    +\"],[1,[30,[36,2],[[35,1,[\"length\"]],-10],null]],[2,\" more. \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[4,[38,0],[[32,0],\"moreOrLessShots\"],null],[12],[2,\"Show all\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[4,[38,0],[[32,0],\"moreOrLessShots\"],null],[12],[2,\"Show less\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"sum\",\"hideShots\",\"if\",\"shots\",\"-track-array\",\"each\",\"is-greater\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission/shots.hbs"
    }
  });
});