define("admin/routes/waivers/edit", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        waiver: this.store.findRecord('waiver', params.waiver_id)
      });
    }
  });
});