define("admin/models/capture-group-pilot", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    user: (0, _model.belongsTo)('user', {
      polymorphic: true,
      async: false,
      inverse: 'capture_group_pilots'
    }),
    capture_group: (0, _model.belongsTo)('capture-group', {
      async: false
    }),
    estimated_labor_payout_amount: (0, _model.attr)('number'),
    estimated_labor_payout_type: (0, _model.attr)('string'),
    estimated_standby_payout: (0, _model.attr)('number'),
    estimated_mobilization_payout: (0, _model.attr)('number'),
    humanizedLaborType: Ember.computed('estimated_labor_payout_type', function () {
      if (this.get('estimated_labor_payout_type')) {
        return this.get('estimated_labor_payout_type').replace('_', ' ');
      }
      return;
    })
  });
});