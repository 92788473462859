define("admin/models/logable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    activity_logs: _emberData.default.hasMany('activity_log', {
      async: true,
      inverse: 'logable'
    })
  });
});