define("admin/components/changeset-simple-mde", ["exports", "ember-simplemde/components/simple-mde"], function (_exports, _simpleMde) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _simpleMde.default.extend({
    lastValueUpdate: 0,
    didReceiveAttrs() {
      // we need to modify this method for changeset, cause origin method creates an infinity loop
      const editor = this.get('currentEditor');
      if (Ember.isEmpty(editor)) {
        return;
      }
      const cursor = editor.codemirror.getDoc().getCursor();
      if (Date.now() - this.lastValueUpdate > 100) {
        editor.value(this.get('value') || '');
        this.lastValueUpdate = Date.now();
      }
      editor.codemirror.getDoc().setCursor(cursor);
    }
  });
});