define("admin/helpers/sun-calculations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute([date, timezone, latitude, longitude]) {
      if (date == undefined || latitude == undefined || longitude == undefined) {
        return null;
      } else {
        var times = SunCalc.getTimes(new Date(date), latitude, longitude);
        return 'Solar noon: ' + moment.tz(times.solarNoon, timezone).format('hh:mmA') + ' | Sunset: ' + moment.tz(times.sunset, timezone).format('hh:mmA');
      }
    }
  });
});