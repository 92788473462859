define("admin/helpers/date-range", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const formatDate = date => {
    const isValid = (0, _momentTimezone.default)(date).isValid();
    return isValid ? (0, _momentTimezone.default)(date).format('MM/DD/YY') : null;
  };
  var _default = _exports.default = Ember.Helper.extend({
    compute([startDate, endDate, placeholder = '-']) {
      if (startDate) startDate = formatDate(startDate);
      if (endDate) endDate = formatDate(endDate);
      if (startDate && endDate) return `${startDate} - ${endDate}`;
      if (startDate || endDate) return `${startDate || placeholder} - ${endDate || placeholder}`;
      return "-";
    }
  });
});