define("admin/models/data-file", ["exports", "ember-data", "admin/models/shareable"], function (_exports, _emberData, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shareable.default.extend({
    name: _emberData.default.attr('string'),
    version_urls: _emberData.default.attr(),
    processing: _emberData.default.attr('boolean'),
    source_type: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot'),
    downloadUrl: Ember.computed('version_urls.{}', function () {
      return this.get('version_urls.download');
    }),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') === 'edit';
    })
  });
});