define("admin/routes/missions/edit", ["exports", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response", "admin/config/environment", "admin/mixins/s3-asset-uploads", "admin/mixins/mission-share", "ember-infinity/mixins/route", "admin/models/payout"], function (_exports, _sendErrorToSentry, _getErrorMessageFromResponse, _environment, _s3AssetUploads, _missionShare, _route, _payout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsEditRoute;
  MissionsEditRoute = Ember.Route.extend(_route.default, _s3AssetUploads.default, _missionShare.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    pusherData: Ember.inject.service(),
    model: function (params) {
      var mission;
      return mission = this.store.findRecord('mission', params.mission_id).then(function (_this) {
        return function (mission) {
          var callActions, client, location, ref;
          client = mission.get('client');
          location = mission.get('location');
          callActions = [{
            id: 'not_needed',
            name: 'Not Needed'
          }, {
            id: 'in_advance_to_coordinate',
            name: 'In Advance to Coordinate'
          }, {
            id: 'when_you_arrive',
            name: 'When You Arrive'
          }, {
            id: 'in_case_of_emergency',
            name: 'In Case Of Emergency'
          }];
          if (!mission.get('onsite_contact')) {
            _this.store.createRecord('onsite_contact', {
              mission: mission,
              call_action: 'not_needed'
            });
          }
          return Em.RSVP.hash({
            client: client,
            hidePackageFlightAppEditor: true,
            mission: mission,
            flightApps: _this.store.findAll('flight_app'),
            location: location ? location : _this.store.createRecord('location', {
              client: client
            }),
            locations: client ? _this.store.query('location', {
              client_id: client.id
            }) : [mission.get('location')],
            packages: client ? ((ref = client.get('client_available_packages')) != null ? ref.map(function (availablePackage) {
              return availablePackage.get('package');
            }).filter(Boolean) : void 0) || [] : _this.store.query('package', {
              "public": true
            }),
            "package": client ? _this.store.createRecord('package', {
              client: client
            }) : _this.store.createRecord('package'),
            drones: _this.store.findAll('drone'),
            devices: _this.store.findAll('device'),
            equipment: _this.store.findAll('pilot_equipment'),
            licenses: _this.store.findAll('license'),
            admins: _this.store.findAll('admin'),
            verticals: _this.store.findAll('vertical'),
            mission_statuses: Ember.$.getJSON(_environment.default.api.host + "/v1/missions/statuses"),
            pilots: [],
            payout: _this.store.createRecord('payout'),
            badges: _this.store.query('badge', {}),
            call_actions: callActions,
            presetSearchFilters: _this.store.query('preset-search-filter', {}),
            holdReasons: _this.store.query('mission-hold-reason', {}),
            cancellationReasons: _this.store.query('cancellation-reason', {}),
            rejectionReasons: _this.store.query('mission-rejection-reason', {}),
            rescheduleReasons: _this.store.query('reschedule-reason', {}),
            filteredMapImages: mission.loadImageMarkers(),
            work_types: _this.store.query('deliverable_work_type', {}),
            actions: _this.actions,
            currencies: _this.store.query('currency', {}),
            legalEntities: _this.store.findAll('legal-entity'),
            postProcesses: _this.store.findAll('post-process'),
            captureGroups: _this.infinityModel('capture-group'),
            workOrders: _this.infinityModel('site-inspection')
          });
        };
      }(this));
    },
    setupController: function (controller, model) {
      var packages, ref, ref1;
      this._super(controller, model);
      Ember.run.schedule('afterRender', function (_this) {
        return function () {
          $('[data-toggle="tooltip"]').tooltip();
          if (_environment.default.environment !== 'test') {
            return _this._listenToPusherEvents(model.mission);
          }
        };
      }(this));
      controller.set('createPayout', this.actions.createPayout.bind(this));
      controller.set('deletePayout', this.actions.deletePayout.bind(this));
      controller.set('hideShots', true);
      model.locationsForSelect = model.locations.toArray();
      model.verticalsForSelect = model.verticals.toArray().sortBy('vertical.short_name');
      model.accountReps = Ember.A([]);
      model.productionReps = Ember.A([]);
      model.operationReps = Ember.A([]);
      model.drones = model.drones.toArray();
      model.busy = false;
      model.productionDeliverables = model.work_types.filterBy('process_type', 'production');
      model.propertiesDeliverables = model.work_types.filterBy('process_type', 'properties');
      model.windDeliverables = model.work_types.filterBy('process_type', 'wind');
      model.solarDeliverables = model.work_types.filterBy('process_type', 'solar');
      model.otherDeliverables = model.work_types.filterBy('process_type', 'other');
      model.telecomDeliverables = model.work_types.filterBy('process_type', 'telecom');
      model.currentAdminName = this.store.peekRecord('admin', this.get('session.data.authenticated.id')).fullName;
      model.defaultCurrency = model.currencies.findBy('code', 'USD');
      model.defaultLegalEntity = model.legalEntities.findBy('slug', 'us');
      model.stripeLegalEntities = model.legalEntities.filterBy('stripe_enabled', true);
      if (controller.activeTab === 'assets') {
        model.mission.shots.forEach(function (shot) {
          return shot.loadImagesPage(1, 90);
        });
        model.mission.loadVideos();
        model.mission.loadPanoramas();
        model.mission.loadOrthoPhotoMaps();
        model.mission.loadDataFiles();
      } else if (controller.activeTab === 'activityLogs') {
        model.mission.loadActivityLogs();
      } else {
        model.mission.loadPayments();
        model.mission.loadDropships();
      }
      model.admins.forEach(function (admin) {
        if (admin.get('roles').includes('account_rep')) {
          model.accountReps.addObject(admin);
        }
        if (admin.get('roles').includes('production_rep')) {
          model.productionReps.addObject(admin);
        }
        if (admin.get('roles').includes('operation_rep')) {
          return model.operationReps.addObject(admin);
        }
      });
      packages = model.packages.toArray();
      packages.pushObject(model.mission.get('package'));
      model.packagesForSelect = packages.uniq().sortBy('vertical.short_name', 'name', 'price');
      model["package"] = ((ref = model.mission.get('package.name')) != null ? ref.match(/Custom/) : void 0) ? model.mission.get('package') : this.store.createRecord('package', {
        name: 'Custom'
      });
      if (!model.mission.get('duration')) {
        model.mission.set('duration', (ref1 = model.mission["package"]) != null ? ref1.get('mission_duration') : void 0);
      }
      model.missionMarkers = model.mission.image_markers.toArray();
      model.fullViewMarkers = model.missionMarkers;
      ['pilot', 'analyst', 'editor', 'roof_measurement'].forEach(function (_this) {
        return function (payoutType) {
          if (!model.mission.get("estimated_payouts").findBy('payout_type', payoutType)) {
            return _this.store.createRecord('estimated_payout', {
              mission: model.mission,
              currency: model.defaultCurrency,
              amount: model.mission.get("estimated_" + payoutType + "_payout") || 0,
              payout_type: payoutType
            });
          }
        };
      }(this));
      model.mission.set('estimated_pilot_payout_obj', model.mission.get('estimated_payouts').findBy('payout_type', 'pilot'));
      model.mission.set('estimated_analyst_payout_obj', model.mission.get('estimated_payouts').findBy('payout_type', 'analyst'));
      model.mission.set('estimated_editor_payout_obj', model.mission.get('estimated_payouts').findBy('payout_type', 'editor'));
      model.mission.set('estimated_roof_measurement_payout_obj', model.mission.get('estimated_payouts').findBy('payout_type', 'roof_measurement'));
      return controller.set('currentRoute', this);
    },
    _listenToPusherEvents: function (mission) {
      return this.get('pusherData').subscribeToMissionChannel(mission);
    },
    _saveOnsiteContact: function () {
      var onsiteContact;
      onsiteContact = this.controller.get('model.mission.onsite_contact');
      if (!onsiteContact) {
        this.alertAndThrow("Error saving: onsiteContact == null");
      }
      return onsiteContact.save().then(function (response) {}, function (_this) {
        return function (error) {
          return _this.alertAndThrow("Error saving onsite contacts: " + error);
        };
      }(this));
    },
    _saveSchedulingContact: function () {
      var schedulingContact;
      schedulingContact = this.controller.get('model.mission.scheduling_contact');
      if (schedulingContact) {
        return schedulingContact.save().then(function (response) {}, function (_this) {
          return function (error) {
            return _this.alertAndThrow("Error saving scheduling contacts: " + error);
          };
        }(this));
      }
    },
    alertAndThrow: function (message) {
      alert(message);
      throw new Error(message);
    },
    actions: {
      busy: function (promise) {
        var _this, body, callback;
        body = Ember.$('body');
        body.addClass('busy');
        this.set('context.busy', true);
        _this = this;
        callback = function (response) {
          body.removeClass('busy');
          return _this.set('context.busy', false);
        };
        if (promise["finally"]) {
          return promise["finally"](callback);
        } else {
          return promise.always(callback);
        }
      },
      loading: function (transition) {
        this.controllerFor('missions.edit').set('pageReady', false);
        transition.promise["finally"](function (_this) {
          return function () {
            return _this.controllerFor('missions.edit').set('pageReady', true);
          };
        }(this));
        return true;
      },
      reshoot: function (model, data) {
        if (data.promised_delivery_date) {
          data.promised_delivery_date = moment.tz(data.promised_delivery_date, model.get('location.timezone_id')).utc().toString();
        }
        if (data.planned_delivery_date) {
          data.planned_delivery_date = moment.tz(data.planned_delivery_date, model.get('location.timezone_id')).utc().toString();
        }
        return Em.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + model.id + "/reshoot",
          type: 'POST',
          dataType: 'json',
          data: data,
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            return _this.transitionTo('missions.edit', response.mission_id);
          };
        }(this), function (_this) {
          return function (error) {
            return console.log('failed to reshoot', response);
          };
        }(this));
      },
      autoDispatchInvites: function (mission) {
        var _this, request;
        _this = this;
        request = Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/notifications",
          headers: this.get('sessionAccount.headers'),
          type: 'POST',
          dataType: 'json',
          data: {
            auto_dispatch: true
          }
        }).then(function (_this) {
          return function (response) {
            mission.set('pilot_invitations_dispatch', response.data.attributes);
            mission.set('status', 'pilots_notified');
            if (response.data.attributes['dispatch_status'] === 'in_progress') {
              _this.controller.set('showInvitePilotLink', false);
              _this.controller.set('invitationDispatchInProgress', true);
              return mission.reload();
            }
          };
        }(this), function (_this) {
          return function (error) {
            return console.log('failed to resume', error);
          };
        }(this));
        this.send('busy', request);
        return request;
      },
      cancelAutoDispatch: function (mission) {
        var _this;
        _this = this;
        return Em.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/pilot_invitations_dispatches/" + mission.get('pilot_invitations_dispatch').id,
          type: 'PUT',
          dataType: 'json',
          data: {
            cancelled: true
          },
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            mission.set('pilot_invitations_dispatch.dispatch_status', 'cancelled');
            _this.controller.set('showInvitePilotLink', true);
            return _this.controller.set('invitationDispatchInProgress', false);
          };
        }(this), function (_this) {
          return function (error) {
            return console.log('failed to resume', error);
          };
        }(this));
      },
      hold: function (mission, hold) {
        var _this, request;
        _this = this;
        request = hold.save().then(function (_this) {
          return function (response) {
            mission.set('isOnHold', true);
            if (_this.controller.get('invitationDispatchInProgress')) {
              mission.set('pilot_invitations_dispatch.dispatch_status', 'cancelled');
              _this.controller.set('invitationDispatchInProgress', false);
            }
            mission.get('notified_pilots').map(function (np) {
              if (np.status === 'notified') {
                return np.set('status', 'no_longer_available');
              }
            });
            return mission.reload();
          };
        }(this), function (_this) {
          return function (error) {
            return console.log('failed to put on hold', error);
          };
        }(this));
        this.send('busy', request);
        return request;
      },
      resume: function (mission) {
        var _this, hold, request;
        hold = mission.get('hold');
        hold.set('resolved', true);
        _this = this;
        request = hold.save().then(function (_this) {
          return function (response) {
            mission.set('isOnHold', false);
            return mission.reload();
          };
        }(this), function (_this) {
          return function (error) {
            return console.log('failed to resume', error);
          };
        }(this));
        this.send('busy', request);
        return request;
      },
      checkAirspace: function (mission, button) {
        return mission.checkAirspace();
      },
      viewParentMission: function (mission) {
        return this.transitionTo('missions.edit', mission.id);
      },
      rebuildArchives: function (model) {
        if (confirm('Are you sure you want to regenerate the archives?')) {
          return Em.$.ajax({
            url: _environment.default.api.host + "/v1/admin/missions/" + model.id + "/regenerate_archives",
            type: 'POST',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(function (_this) {
            return function (response) {
              model.reload();
              return alert("Archives are now regenerating");
            };
          }(this), function (_this) {
            return function (error) {
              return alert("There was a problem with your request");
            };
          }(this));
        }
      },
      reload: function (mission) {
        mission.set('pusher_updated_at', null);
        return mission.reload();
      },
      saveSchedule: function (model) {
        var data, mission, promise, scheduled_end_formatted, scheduled_start_formatted;
        data = model.scheduleDataForUpdate;
        mission = model.mission;
        scheduled_start_formatted = moment.tz(data.mission_scheduled_at_start, mission.get('location.timezone_id')).utc().toString();
        scheduled_end_formatted = moment.tz(data.mission_scheduled_at_end, mission.get('location.timezone_id')).utc().toString();
        promise = Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + mission.id + "/schedule",
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data: {
            scheduled_at_start: scheduled_start_formatted,
            scheduled_at_end: scheduled_end_formatted,
            reschedule_reason_id: data.reason_id,
            notes: data.notes,
            mission_id: mission.id,
            capacity_data: data.capacity_data
          }
        }).then(function (_this) {
          return function (response) {
            return mission.reload();
          };
        }(this))["catch"](function (_this) {
          return function (response) {
            return alert(response.errors[0].detail);
          };
        }(this));
        mission.set('isScheduled', true);
        this.send('busy', promise);
        return promise;
      },
      save: function (model) {
        var _this, promise, schedulingContact;
        _this = this;
        schedulingContact = model.mission.get('scheduling_contact');
        if (model.mission.get('scheduling_requirement') === 'call_to_schedule') {
          if (!schedulingContact || !schedulingContact.validate()) {
            this.alertAndThrow("Error saving: Provide please correct Scheduling Contact");
            return false;
          }
        }
        promise = model.mission.save().then(function (response) {
          _this.set('pilotChanged', null);
          _this.transitionTo('missions.edit', model.mission.id);
          if (Ember.isPresent(model.scheduleDataForUpdate)) {
            model.mission.set('admin_scheduled', true);
            _this.send('saveSchedule', model);
          }
          _this._saveOnsiteContact(model.mission.get('onsite_contact'));
          _this._saveSchedulingContact(model.mission.get('scheduling_contact'));
          Ember.notifyPropertyChange(model, 'formattedPromisedDate');
          Ember.notifyPropertyChange(model, 'formattedPlannedDate');
          if (model.mission.get('status') === 'created') {
            if (model.mission.get('scheduling_requirement') === 'call_to_schedule') {
              return model.mission.set('recalculateStatus', true);
            }
          }
        }, function (_this) {
          return function (error) {
            alert('There was an issue saving this mission');
            return console.log('error saving mission: ' + error);
          };
        }(this));
        return this.send('busy', promise);
      },
      cancel: function (model, data) {
        return Em.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + model.id + "/cancellations",
          type: 'POST',
          dataType: 'json',
          data: data,
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            model.set('cancellation', response);
            return model.reload();
          };
        }(this), function (_this) {
          return function (error) {
            var errors;
            errors = '';
            error.responseJSON.errors.forEach(function (e) {
              return errors = errors + e.detail + '\n';
            });
            return alert(errors);
          };
        }(this));
      },
      updateCreativeMissionMeta: function (model) {
        return alert('No longer maintaing this multi update');
      },
      searchPilots: function (query, model) {
        return this.store.query('pilot', query).then(function (response) {
          Ember.set(model, 'meta', response.meta);
          return Ember.set(model, 'pilots', response.toArray());
        }, function (error) {
          alert('There was an issue retrieving pilots');
          return console.log('error retrieving pilots: ' + error);
        });
      },
      createPayout: function (model) {
        var _this, legalEntityName, new_payout, paymentProcessor, paymentProcessorIsStripe, saveLineItemsInOrderCreated, selectedLegalEntity;
        this.flashMessages.success("Payout to " + model.payout.get('payee_name') + " has been created");
        setTimeout(function () {
          return _this.send('closeModal');
        }, 5000);
        _this = this;
        paymentProcessor = model.payout.get('payment_processor');
        paymentProcessorIsStripe = paymentProcessor.includes(_payout.PAYMENT_PROCESSORS.stripe);
        if (paymentProcessorIsStripe) {
          legalEntityName = paymentProcessor.split('(')[1].split(')')[0];
        } else {
          legalEntityName = null;
        }
        if (legalEntityName) {
          selectedLegalEntity = model.legalEntities.findBy('name', legalEntityName);
        } else {
          selectedLegalEntity = null;
        }
        if (paymentProcessor !== _payout.PAYMENT_PROCESSORS.other) {
          paymentProcessor = null;
        }
        new_payout = this.get('store').createRecord('payout', {
          pilot: model.payout.get('pilot'),
          saved_payoutable_type: 'missions',
          saved_payoutable_id: model.mission.id,
          amount: model.payout.get('amount'),
          currency: model.payout.get('currency'),
          notes: model.payout.get('notes'),
          payee_name: model.payout.get('payee_name'),
          payout_to: model.payout.get('payout_to'),
          legal_entity: selectedLegalEntity,
          payment_processor: paymentProcessor,
          payout_line_items: model.payout.get('payout_line_items')
        });
        saveLineItemsInOrderCreated = function (lineItems) {
          var lineItem;
          if (lineItems.length > 0) {
            lineItem = lineItems.shift();
            return lineItem.save().then(function () {
              return saveLineItemsInOrderCreated(lineItems);
            });
          }
        };
        return new_payout.save().then(function (response) {
          var lineItems;
          lineItems = new_payout.get('payout_line_items').toArray();
          saveLineItemsInOrderCreated(lineItems);
          model.payout.set('amount', null);
          model.payout.set('payee_name', null);
          model.payout.set('notes', null);
          model.payout.set('pilot', null);
          model.payout.set('payout_to', null);
          return model.payout.set('payment_processor', null);
        }, function (error) {
          alert('There was an issue creating this payout');
          return console.log('error saving new_payout: ' + error);
        });
      },
      deletePayout: function (payout) {
        if (window.confirm("Are you sure you want to delete this payout? This cannot be undone.")) {
          payout.set('saved_payoutable_id', payout.payoutable.id);
          payout.set('saved_payoutable_type', 'missions');
          payout.deleteRecord();
          return payout.save().then(function (response) {}, function (error) {
            alert('There was an issue deleting this payout, the status may have changed. Please refresh and try again.');
            return console.log('error while saving: ' + error);
          });
        }
      },
      removeFromCaptureGroup: function (mission) {
        var _this;
        _this = this;
        if (window.confirm("Do you want to remove inspection from Capture Group?")) {
          mission.set('site_inspection.capture_group', null);
          return mission.get('site_inspection').save().then(function () {
            mission.reload();
            return _this.flashMessages.success("You have removed mission from Capture group!");
          });
        }
      },
      newPackage: function (model) {
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      editPackage: function (model, edit_package) {
        model["package"] = edit_package;
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      savePackage: function (model) {
        return model["package"].save().then(function (_this) {
          return function (response) {
            model.packagesForSelect.pushObject(model["package"]);
            model.mission.set('package', model["package"]);
            return model.mission.save().then(function (response) {
              _this.send('closeModal');
              model.mission.reload();
              return model.invoiceLineItems.reload();
            }, function (error) {
              return console.log('error while saving: ' + error);
            });
          };
        }(this), function (error) {
          return console.log('error while saving: ' + error);
        });
      },
      close: function () {
        return this.send('closeModal');
      },
      willTransition: function (transition) {
        var mission;
        if (this.controller.get('model.package.isNew')) {
          this.controller.get('model.package').deleteRecord();
        }
        mission = this.controller.get('model.mission');
        if (mission.get('isDirty')) {
          mission.set('pilot', null);
          return mission.rollback();
        }
      },
      regeneratePdf: function () {
        if (confirm('Are you sure you want to regenerate PDF?')) {
          Em.$.ajax({
            url: _environment.default.api.host + "/v1/admin/missions/" + this.controller.get('model.mission.id') + "/regenerate_pdf",
            type: 'POST',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(function (_this) {
            return function (response) {
              _this.store.pushPayload(response);
              return alert("PDF is now regenerating");
            };
          }(this), function (_this) {
            return function (error) {
              return alert("There was a problem with your request");
            };
          }(this));
        }
        return false;
      }
    }
  });
  var _default = _exports.default = MissionsEditRoute;
});