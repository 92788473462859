define("admin/models/subscription", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    number: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    po_number: (0, _model.attr)('string'),
    salesforce_opportunity_id: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    valid_from: (0, _model.attr)('date'),
    valid_until: (0, _model.attr)('date'),
    valid_for: (0, _model.attr)('number'),
    duration: (0, _model.attr)('number'),
    invoiced_at: (0, _model.attr)('date'),
    created_at: (0, _model.attr)('date'),
    states: (0, _model.hasMany)('state'),
    subscriptionOffer: (0, _model.belongsTo)('subscription-offer', {
      async: true
    }),
    organization: (0, _model.belongsTo)('organization'),
    currency: (0, _model.belongsTo)('currency')
  });
});