define("admin/templates/components/inputs/input-symbol-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZG+B7V+t",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group symbol-add\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"value\",\"type\",\"maxlength\",\"class\",\"placeholder\",\"disabled\"],[[35,3],\"text\",[35,2],\"form-control\",[35,1],[35,0]]]]],[2,\"\\n  \"],[11,\"div\"],[24,0,\"input-group-addon turquoise-addon\"],[4,[38,5],[[32,0],\"save\"],null],[12],[2,\"+\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"placeholder\",\"maxlength\",\"value\",\"input\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inputs/input-symbol-add.hbs"
    }
  });
});