define("admin/routes/pilots/index", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    store: Ember.inject.service(),
    queryParams: {
      q: {
        refreshModel: true
      },
      lat: {
        refreshModel: true
      },
      lon: {
        refreshModel: true
      },
      distance: {
        refreshModel: true
      },
      'statuses[]': {
        refreshModel: true
      },
      'country_codes': {
        refreshModel: true,
        type: 'array'
      }
    },
    model(params) {
      params['perPage'] = 100;
      params['startingPage'] = 1;
      return Ember.RSVP.hash({
        pilots: this.infinityModel('pilot', params),
        countries: this.store.findAll('country'),
        countryCodesParams: params.country_codes
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('country_codes', model.countryCodesParams);
    },
    serializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    }
  });
});