define("admin/templates/capture-groups/pilots-assign-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DvWROp1R",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"close\",\"hasOverlay\",\"translucentOverlay\",\"fullScreen\",\"appendedClasses\"],[[30,[36,0],[[32,0],\"closeAction\"],null],true,true,\"true\",\"assign-pilot-modal full-screen-modal\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container-fluid work-order-assign\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"full-screen-modal-toprow\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"\\n          Assign Pilot\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"close-modal\"],[12],[2,\"\\n          \"],[11,\"img\"],[24,\"src\",\"/assets/images/X.svg\"],[4,[38,0],[[32,0],\"closeAction\"],null],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n          \"],[1,[30,[36,2],null,[[\"search\",\"results\",\"closeAction\",\"model\",\"assign\",\"placeholder\"],[[30,[36,0],[[32,0],\"searchPilots\"],null],[35,1,[\"pilots\"]],[30,[36,0],[[32,0],\"closeAction\"],null],[35,1],true,\"Pilot First Name, Last Name, City, Zip, Drone type\"]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"capture-groups/pilot-assign\",\"modal-dialog-custom\"]}",
    "meta": {
      "moduleName": "admin/templates/capture-groups/pilots-assign-modal.hbs"
    }
  });
});