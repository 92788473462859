define("admin/templates/components/inputs/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7SmReXOR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input input-group\"],[12],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1,[\"symbol\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"currency input-group-addon\"],[12],[1,[35,1,[\"symbol\"]]],[2,\" \"],[1,[35,1,[\"code\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"currency input-group-addon\"],[12],[2,\"$ USD\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,8],null,[[\"type\",\"value\",\"min\",\"focus-out\",\"input\",\"class\",\"placeholder\",\"disabled\"],[\"number\",[35,7],0,[30,[36,6],[[32,0],\"focusOut\"],null],[30,[36,6],[[32,0],\"observeInput\"],null],\"form-control\",[35,5],[35,4]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"currency\",\"if\",\"showCurrencyLabel\",\"disabled\",\"placeholder\",\"action\",\"displayValue\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/inputs/price.hbs"
    }
  });
});