define("admin/components/checkbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    showLabel: true,
    init: function () {
      this._super();
      if (this.get('includeManufacturer')) {
        return this.send("checkPresenceInSelection", "full_name");
      } else if (this.get('byAttribute')) {
        return this.send("checkIfInSelection", this.get('byAttribute'));
      } else {
        return this.send("checkPresenceInSelection", "name");
      }
    },
    selectionsObserver: Ember.observer('selections', 'selections.length', function () {
      if (this.get('includeManufacturer')) {
        return this.send("checkPresenceInSelection", "full_name");
      } else if (this.get('byAttribute')) {
        return this.send("checkPresenceInSelection", this.get('byAttribute'));
      } else {
        return this.send("checkPresenceInSelection", "name");
      }
    }),
    label: Ember.computed('model', 'includeManufacturer', 'nameAttribute', function () {
      if (this.get('includeManufacturer')) {
        return this.get('model.full_name');
      } else if (this.get('nameAttribute')) {
        return this.get('model').get(this.get('nameAttribute'));
      } else {
        return this.get('model.name');
      }
    }),
    formattedLabel: Ember.computed('label', function () {
      if (this.get('label') == null) {
        return 'untitled';
      }
      return this.get('label').toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');
    }),
    didInsertElement() {
      if (this.disabled) {
        this.$('input').prop('disabled', true);
        this.$('span').addClass('disabled');
      }
    },
    change: function (checkbox) {
      var elem, selections;
      elem = Ember.$(checkbox.target)[0];
      selections = this.get('selections');
      if (!selections) {
        selections = [];
      }
      if (elem.checked) {
        if (typeof selections.then === 'function') {
          selections.then(resolvedSelections => {
            resolvedSelections.pushObject(this.get('model'));
            this.set('selections', resolvedSelections);
          });
        } else {
          selections = selections.without('a b c d').toArray();
          selections.push(this.get('model'));
          this.set('selections', selections);
        }
      } else {
        if (typeof selections.then === 'function') {
          selections.then(resolvedSelections => {
            resolvedSelections.removeObject(this.get('model'));
            this.set('selections', resolvedSelections);
          });
        } else {
          if (this.get('model.id') === void 0) {
            selections = selections.without(this.get('model'));
          } else {
            selections = selections.rejectBy('id', this.get('model.id'));
          }
          this.set('selections', selections);
        }
      }
    },
    actions: {
      checkIfInSelection: function (byAttribute) {
        if (this.get("selections").getEach(byAttribute).indexOf(this.get('model').get(byAttribute)) >= 0) {
          return this.set("checked", true);
        }
      },
      checkPresenceInSelection: function (byAttribute) {
        var targetAttributeValue;
        if (this.get('byAttribute')) {
          targetAttributeValue = this.get('model').get(this.get('byAttribute'));
        } else {
          targetAttributeValue = this.get('label');
        }
        if (this.get("selections").getEach(byAttribute).indexOf(targetAttributeValue) >= 0) {
          return this.set("checked", true);
        } else {
          return this.set("checked", false);
        }
      }
    }
  });
});