define("admin/data/sla-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var days = [{
    name: "1 day",
    value: "24"
  }];
  for (var day = 2; day <= 30; day++) {
    days.push({
      name: day + " days",
      value: (day * 24).toString()
    });
  }
  var _default = _exports.default = days;
});