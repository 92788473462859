define("admin/mirage/factories/client", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id: 'DBC9368530777',
    first_name: 'test',
    last_name: 'local',
    upsellable: false,
    email: 'test4@local.com',
    token: 'UkKm02F73okMwBtlhEKb1aZjO6M',
    withUplessable: (0, _emberCliMirage.trait)({
      upsellable: true
    })
  });
});