define("admin/components/subscription-offers/gear-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    showActions: false,
    subscriptionOffer: null,
    initDropdown: function () {
      this.element.querySelector('.gear').addEventListener('click', event => {
        event.stopPropagation();
        Ember.$('.action-gear-block-wrapper .dropdown').removeClass('active');
        Ember.$('.action-gear-block-wrapper .gear').removeClass('active');
        this.toggleProperty('showActions');
        Ember.$('html').one('click', () => {
          this.set('showActions', false);
        });
      });
    },
    didInsertElement() {
      this.initDropdown();
    },
    actions: {
      toggleEditModal: function () {
        this.toggleEditModalAction();
      },
      activate: function () {
        this.set('subscriptionOffer.active', true);
        this.get('subscriptionOffer').save();
      },
      deactivate: function () {
        this.set('subscriptionOffer.active', false);
        this.get('subscriptionOffer').save();
      }
    }
  });
});