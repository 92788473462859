define("admin/mirage/factories/shot-type", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id(i) {
      return i;
    },
    description: 'shot type description',
    name: 'pano shot',
    slug: 'pano_shot'
  });
});