define("admin/routes/missions/map", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "admin/data/shot_list", "admin/mixins/css-class-namespace"], function (_exports, _authenticatedRouteMixin, _shot_list, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _cssClassNamespace.default, {
    sessionAccount: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        shot_lists: this.get('store').findAll('shot_list'),
        mission: this.get('store').find('mission', params['mission_id']),
        features: Ember.A([]),
        shot_list: _shot_list.default,
        post_processes: this.store.findAll('post-process')
      });
    },
    setupController(controller, model) {
      controller.set('currentRoute', this);
      return this._super(controller, model);
    },
    actions: {
      redirectToMission(mission_id) {
        return this.transitionTo('missions.edit', mission_id);
      }
    }
  });
});