define("admin/components/collaborator-list", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollaboratorListComponent;
  CollaboratorListComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    isValidationError: true,
    showError: false,
    validateParams: Ember.observer('newEmail', 'newFirstName', 'newLastName', function () {
      this.set('showError', false);
      if (Ember.isPresent(this.get('newEmail')) && Ember.isPresent(this.get('newFirstName')) && Ember.isPresent(this.get('newLastName'))) {
        return this.set('isValidationError', false);
      } else {
        return this.set('isValidationError', true);
      }
    }),
    actions: {
      "delete": function (collaborator, mission) {
        return collaborator.destroyRecord().then(function () {
          return collaborator.unloadRecord();
        });
      },
      reset: function () {
        this.set('createMode', false);
        return this.reset();
      },
      add: function (mission) {
        return this.createCollaborator(mission);
      }
    },
    reset: function () {
      this.set('newFirstName', '');
      return this.set('newLastName', '');
    },
    createCollaborator: function (mission) {
      if (this.get('isValidationError')) {
        return this.set('showError', true);
      }
      Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + this.get('mission.id') + "/collaborators",
        method: 'POST',
        data: {
          email: this.get('newEmail'),
          first_name: this.get('newFirstName'),
          last_name: this.get('newLastName')
        },
        headers: this.get('sessionAccount.headers')
      }).then(function (_this) {
        return function (response) {
          console.log('created collaborator');
          return _this.get('mission').store.pushPayload(response);
        };
      }(this));
      this.set('newEmail', '');
      this.set('createMode', false);
      return this.reset();
    }
  });
  var _default = _exports.default = CollaboratorListComponent;
});