define("admin/components/email-notify", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmailNotifyComponent;
  EmailNotifyComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    state: 'idle',
    message: '',
    classNames: ['email-notify'],
    idle: Ember.computed('state', function () {
      return this.get('state') === 'idle';
    }),
    clicked: Ember.computed('state', function () {
      return this.get('state') === 'clicked';
    }),
    confirmed: Ember.computed('state', function () {
      return this.get('state') === 'confirmed';
    }),
    error: Ember.computed('state', function () {
      return this.get('state') === 'error';
    }),
    sendPilotNotification: function () {
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + this.get('mission.id') + "/notifications",
        headers: this.get('sessionAccount.headers'),
        type: 'POST',
        dataType: 'json',
        data: {
          pilot: this.get('pilot.id')
        }
      }).then(function (_this) {
        return function (response) {
          _this.get('mission').reload();
          _this.set('message', 'Sent!');
          return _this.set('state', 'confirmed');
        };
      }(this), function (_this) {
        return function (response) {
          _this.set('state', 'error');
          return _this.set('message', response.responseJSON.errors[0].detail);
        };
      }(this));
    },
    actions: {
      setIdle: function () {
        return this.set('state', 'idle');
      },
      setClicked: function () {
        return this.set('state', 'clicked');
      },
      setConfirmed: function () {
        return this.sendPilotNotification();
      }
    }
  });
  var _default = _exports.default = EmailNotifyComponent;
});