define("admin/components/button-to-circle", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ButtonToCircleComponent;
  ButtonToCircleComponent = Ember.Component.extend({
    text: 'Save',
    didInsertElement: function () {
      var btn;
      btn = $("button.btnSubmit");
      return btn.on('click', function () {
        if ($(this).hasClass('clicked')) {
          return;
        }
        return $(this).addClass('clicked');
      });
    },
    saveStateObserver: Ember.observer('saveState', function () {
      var btn, checkmarkTemplate, crossTemplate, loaderTemplate;
      btn = $("button.btnSubmit");
      loaderTemplate = $("svg.loader");
      checkmarkTemplate = $("svg.checkmark");
      crossTemplate = $("svg.cross");
      if (this.get('saveState') === 'inProgress') {
        btn.append(loaderTemplate.clone());
        btn.find('svg').removeClass('svg--template');
        btn.find('svg').css('display', 'initial');
      }
      if (this.get('saveState') === 'success') {
        btn.text('');
        btn.find('svg').remove();
        btn.append(checkmarkTemplate.clone());
        btn.find('svg').css('display', 'initial');
        btn.find('svg').removeClass('svg--template');
        btn.addClass('done');
      }
      if (this.get('saveState') === 'error') {
        btn.text('');
        btn.find('svg').remove();
        btn.append(crossTemplate.clone());
        btn.find('svg').css('display', 'initial');
        btn.find('svg').removeClass('svg--template');
        btn.addClass('error');
      }
      if (this.get('saveState') === 'initial' || this.get('saveState') === 'failed') {
        btn.find('svg').remove();
        btn.text(this.get('text'));
        btn.removeClass('clicked');
        btn.removeClass('done');
        return btn.removeClass('error');
      }
    }),
    actions: {
      buttonAction: function () {
        return this.submit();
      }
    }
  });
  var _default = _exports.default = ButtonToCircleComponent;
});