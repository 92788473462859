define("admin/components/pilot-equipment-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'row pilot-section',
    displayedIndex: Ember.computed('index', function () {
      return this.get('index') + 1;
    })
  });
});