define("admin/transforms/cents", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Cents;
  Cents = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      return (serialized / 100.00).toFixed(2);
    },
    serialize: function (deserialized) {
      return Math.round(deserialized * 100.00);
    }
  });
  var _default = _exports.default = Cents;
});