define("admin/models/comprehensive-report-rate", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    base_size: (0, _model.attr)('number'),
    subscriber_rate_per_mw: (0, _model.attr)('number'),
    subscriber_rate_per_site: (0, _model.attr)('number'),
    non_subscriber_rate_per_mw: (0, _model.attr)('number'),
    non_subscriber_rate_per_site: (0, _model.attr)('number'),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    })
  });
});