define("admin/mirage/models/client", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    verticals: (0, _emberCliMirage.hasMany)('vertical'),
    organization: (0, _emberCliMirage.belongsTo)('organization'),
    collaborators: (0, _emberCliMirage.hasMany)('collaborator'),
    client_mission_csvs: (0, _emberCliMirage.hasMany)('client-mission-csvs'),
    packages: (0, _emberCliMirage.hasMany)('package'),
    available_packages: (0, _emberCliMirage.hasMany)('client-available-package'),
    available_addons: (0, _emberCliMirage.hasMany)('package-addon')
  });
});